import { Button, Center, Text } from '@chakra-ui/react';
import React from 'react';

type DeliveryActionButtonProps = React.ComponentProps<typeof Button> & {
  icon: React.ReactNode;
  label: string;
};

export const DeliveryActionButton: React.FC<DeliveryActionButtonProps> = ({
  icon,
  label,
  ...props
}) => (
  <Button
    borderRadius={'2xl'}
    fontSize="xs"
    h="50px"
    w="60px"
    colorScheme={'brandSecondary'}
    {...props}
  >
    <Center position={'relative'} bottom="1">
      {icon}
    </Center>
    <Text fontSize={10} position={'absolute'} bottom="1">
      {label}
    </Text>
  </Button>
);
