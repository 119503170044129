import React, { useState } from 'react';
import { Box, Center, Icon, Stack, Text } from '@chakra-ui/react';
import { AiFillShop } from 'react-icons/ai';
import { brandPrimaryColor } from '../theme/colors';

export const MerchantMarker: React.FC<{
  name: string;
  onClick?: () => void;
  alwaysShowDetails?: boolean;
}> = ({ name, onClick, alwaysShowDetails = false }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      onMouseOver={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
    >
      {(showDetails || alwaysShowDetails) && (
        <Box
          bg={'white'}
          position={'absolute'}
          top={-8}
          minWidth={'fit-content'}
          p={1}
          borderRadius={4}
          boxShadow={'md'}
        >
          <Text noOfLines={2} whiteSpace={'nowrap'}>
            {name}
          </Text>
        </Box>
      )}
      <Stack alignItems={'center'}>
        <Center bg={brandPrimaryColor} borderRadius={'full'} h={7} w={7}>
          <Icon as={AiFillShop} fontSize={18} />
        </Center>
      </Stack>
    </Box>
  );
};
