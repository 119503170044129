import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { CreateOrder, DeliveryOptionId } from '@tradeaze-packages/schemas';
import { calculateDeliveryWindow } from '@tradeaze/shared/utils';

export const setDeliveryTimes = ({
  fromDateTime,
  deliveryOption,
  totalTimeEstimate,
  scheduledDateTime,
  setValue,
  getValues,
}: {
  fromDateTime: Date;
  deliveryOption: DeliveryOptionId | null;
  totalTimeEstimate?: number;
  scheduledDateTime?: Date;
  setValue: UseFormSetValue<CreateOrder>;
  getValues: UseFormGetValues<CreateOrder>;
}) => {
  if (!deliveryOption) {
    setValue('deliveryWindowStart', null as unknown as string);
    setValue('deliveryWindowEnd', null as unknown as string);
    return;
  }

  const deliveryWindow = calculateDeliveryWindow({
    deliveryOptionId: deliveryOption,
    fromDateTime,
    totalTimeEstimate,
    scheduledTime: scheduledDateTime,
  });

  const windowStart = deliveryWindow.start.toISOString();
  const windowEnd = deliveryWindow.end.toISOString();

  setValue('deliveryWindowStart', windowStart);
  setValue('deliveryWindowEnd', windowEnd);

  const deliveryStops = getValues('deliveryStops');

  // When pickup and delivery stops have their own time windows, this will need to be updated
  deliveryStops.forEach((_, index) => {
    setValue(`deliveryStops.${index}.windowStart`, windowStart);
    setValue(`deliveryStops.${index}.windowEnd`, windowEnd);
  });
};
