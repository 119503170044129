import { z } from 'zod';
import { DeliveryOptionIdSchema } from './delivery-option-schema';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';

export const OptimisationDeliverySchema = z.object({
  deliveryId: z.string(),
  createdAt: z.string(),
  deliveryOptionId: DeliveryOptionIdSchema,
  volume: z.number(), // in cubic meters
  weight: z.number(), // in kg
  length: z.number(), // in meters
  allowedVehicleTypes: z.array(DeliveryVehicleIdSchema),
  pickup: z.object({
    deliveryStopId: z.string(),
    windowStart: z.string(),
    windowEnd: z.string(),
    position: z.object({
      latitude: z.number(),
      longitude: z.number(),
    }),
  }),
  dropOff: z.object({
    deliveryStopId: z.string(),
    windowStart: z.string(),
    windowEnd: z.string(),
    position: z.object({
      latitude: z.number(),
      longitude: z.number(),
    }),
  }),
});

// a delivery that has been assigned
export const AssignedDeliveryInfoSchema = z.object({
  fleetVehicleId: z.string(),
  deliveryId: z.string(),
  allowedVehicleTypes: z.array(DeliveryVehicleIdSchema),
});

// a delivery that has already been collected
export const LoadedDeliveryInfoSchema = z.object({
  fleetVehicleId: z.string(),
  deliveryId: z.string(),
  allowedVehicleTypes: z.array(DeliveryVehicleIdSchema),
});

export const StopIdsByDeliverySchema = z.record(
  z.string(),
  z.object({
    PICKUP: z.string(),
    DELIVERY: z.string(),
  })
);

export const OptimisedRouteResultSchema = z.array(
  z.object({
    riderId: z.string(),
    fleetVehicleId: z.string(),
    deliveryIds: z.array(z.string()),
    route: z.array(
      z.object({
        deliveryStopId: z.string(),
        eta: z.string(),
        deliveryId: z.string(),
      })
    ),
  })
);

export type OptimisationDelivery = z.infer<typeof OptimisationDeliverySchema>;
export type AssignedDeliveryInfo = z.infer<typeof AssignedDeliveryInfoSchema>;
export type LoadedDeliveryInfo = z.infer<typeof LoadedDeliveryInfoSchema>;
export type StopIdsByDelivery = z.infer<typeof StopIdsByDeliverySchema>;
export type OptimisedRouteResult = z.infer<typeof OptimisedRouteResultSchema>;
