import { z } from 'zod';
import { RiderLocationMapSchema } from '../../../models';

export const GetRiderLocationsBodySchema = z.object({
  riderIds: z.array(z.string()),
});

export const GetRiderLocationsResponseSchema = RiderLocationMapSchema;

export type GetRiderLocationsBody = z.infer<typeof GetRiderLocationsBodySchema>;
export type GetRiderLocationsResponse = z.infer<
  typeof GetRiderLocationsResponseSchema
>;
