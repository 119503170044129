import { DeliveryVehicleId, FuelType } from '../models';

export const ALL_DELIVERY_VEHICLE_IDS: DeliveryVehicleId[] = [
  'BIKE',
  'CAR',
  'VAN_LARGE',
  'VAN_XLARGE',
  'LUTON',
  'FLATBED',
];

export const ALL_FUEL_TYPES: FuelType[] = [
  'DIESEL',
  'PETROL',
  'ELECTRIC',
  'HYBRID',
];

export const defaultVehicleCapacities: {
  [key in DeliveryVehicleId]: {
    length: number;
    width: number;
    height: number;
    weight: number;
  };
} = {
  BIKE: {
    length: 0.5,
    width: 0.5,
    height: 0.5,
    weight: 25,
  },
  CAR: {
    length: 1.5,
    width: 1,
    height: 1,
    weight: 300,
  },
  VAN_LARGE: {
    length: 3.3,
    width: 1.5,
    height: 2,
    weight: 1200,
  },
  VAN_XLARGE: {
    length: 4.2,
    width: 1.5,
    height: 2,
    weight: 1200,
  },
  LUTON: {
    length: 4,
    width: 1.9,
    height: 2.1,
    weight: 1200,
  },
  FLATBED: {
    length: 7,
    width: 1.5,
    height: 2,
    weight: 1350,
  },
};

export const MAX_LOAD_LENGTH = 7000;
export const MAX_LOAD_WIDTH = 1900;
export const MAX_LOAD_HEIGHT = 2100;
export const MAX_LOAD_WEIGHT = 1350;
