import { z } from 'zod';

export const DateOrStringSchema = z.union([z.string(), z.date()]);

export const buildArrayOrSingleSchema = <T>(
  schema: z.ZodType<T>,
  isRequired = false,
) =>
  z.preprocess(
    (arg) => {
      if (!arg) return undefined;
      return Array.isArray(arg) ? arg : [arg];
    },
    isRequired ? z.array(schema) : z.array(schema).optional(),
  );
