import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Input, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DropDownItem } from './DropDownItem';
import { useClickOutside } from '@tradeaze/frontend/hooks';

export const SelectDropdown: React.FC<{
  placeholder: string;
  results?: { label: string; value: any; isSelected?: boolean }[];
  onSelect: (value: any) => void;
  isLoading: boolean;
  showClear?: boolean;
  isDropDownDisabled?: boolean;
  isMultiSelect: boolean;
  icon?: React.ReactNode;
}> = ({
  isLoading,
  results,
  onSelect,
  placeholder,
  showClear,
  isDropDownDisabled,
  isMultiSelect,
  icon,
}) => {
  const [isFocussed, setIsFocussed] = useState(false);
  const [filter, setFilter] = useState('');
  const cypressAttrDropdown = `select-dropdown-${placeholder
    .toLowerCase()
    .replace(/ /g, '-')}`;
  const boxRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const filteredResults = useMemo(() => {
    if (!filter) {
      return results;
    }
    return results?.filter((result) =>
      result.label.toLowerCase().includes(filter.toLowerCase())
    );
  }, [results, filter]);

  const removeFocus = () => {
    setIsFocussed(false);
  };

  const handleClickDropdown = () => {
    setIsFocussed((prev) => !prev);
  };

  const handleSelect = (value: unknown) => {
    onSelect(value);
    if (!isMultiSelect) {
      removeFocus();
    }
  };

  const isDisabled = isLoading || !results?.length;

  const ref = useClickOutside<HTMLDivElement>(removeFocus);

  useEffect(() => {
    if (!isFocussed) {
      setFilter('');
    }
  }, [isFocussed]);

  return (
    <Box ref={ref} position={'relative'}>
      <Button
        ref={boxRef}
        onClick={handleClickDropdown}
        variant={'unstyled'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        borderWidth={'1px'}
        borderColor={'rgba(0,0,0,0.1)'}
        borderRadius={'md'}
        transition="all 0.2s"
        _hover={{
          borderColor: 'rgba(0,0,0,0.2)',
        }}
        width={'100%'}
        isDisabled={isDisabled}
        height={'auto'}
        whiteSpace="pre-wrap"
        overflowWrap="break-word"
        data-cy={cypressAttrDropdown}
      >
        <Text fontWeight={'normal'} paddingY={2} paddingX={4}>
          {placeholder}
        </Text>
        <Box paddingX={2}>
          {isLoading ? (
            <Spinner size={'sm'} color={'gray.400'} />
          ) : (
            <ChevronDownIcon fontSize={20} />
          )}
        </Box>
      </Button>
      {!!(results?.length && isFocussed) && (
        <Box
          ref={dropdownRef}
          position={'absolute'}
          bg="white"
          border="1px"
          marginTop={1}
          borderColor={'rgba(0,0,0,0.1)'}
          width={boxRef.current?.clientWidth}
          borderRadius="md"
          maxH={250}
          zIndex={10}
          boxShadow="md"
          display={'flex'}
          flexDirection={'column'}
        >
          <Input
            autoFocus
            placeholder="Filter Results"
            position={'sticky'}
            py={5}
            onChange={handleFilterChange}
          />
          <Box overflowY="scroll">
            {filteredResults && filteredResults?.length > 0 ? (
              <>
                {showClear && (
                  <DropDownItem
                    key={'clear-selection'}
                    label={'Clear Selection'}
                    value={undefined}
                    onSelect={handleSelect}
                    isLast={false}
                    color={'blackAlpha.500'}
                  />
                )}
                {filteredResults.map((option, index) => (
                  <DropDownItem
                    key={option.value + index}
                    label={option.label}
                    value={option.value}
                    onSelect={handleSelect}
                    isLast={index === filteredResults.length - 1}
                    isSelected={option.isSelected}
                    isDisabled={isDropDownDisabled && !option.isSelected}
                    icon={icon}
                    cypressAttribute={`select-dropdown-item-${index}`}
                  />
                ))}
              </>
            ) : (
              <Box padding={3}>
                <Text>No Results</Text>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
