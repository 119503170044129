import { z } from 'zod';
import { GigJobSchema, UpdateGigJobSchema } from '../../../models';

export const UpdateGigJobBodySchema = UpdateGigJobSchema;

export const UpdateGigJobResponseSchema = GigJobSchema;

export type UpdateGigJobBody = z.infer<typeof UpdateGigJobBodySchema>;

export type UpdateGigJobResponse = z.infer<typeof UpdateGigJobResponseSchema>;
