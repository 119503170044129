import {
  useMutation,
  useQueryClient,
  QueryKey,
  UseMutationOptions,
} from '@tanstack/react-query';
import {
  MerchantDefinedSiteContact,
  MerchantSiteContact,
} from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

interface UseCreateSiteContactOptions
  extends UseMutationOptions<
    MerchantSiteContact,
    unknown,
    MerchantDefinedSiteContact
  > {
  invalidateQueryKeys?: QueryKey[];
}

export const useCreateSiteContact = (options?: UseCreateSiteContactOptions) => {
  const queryClient = useQueryClient();

  return useMutation<MerchantSiteContact, unknown, MerchantDefinedSiteContact>(
    (sitecontact) => {
      const apiName = 'MerchantApi';
      const path = '/createSiteContact';
      const options: {
        body: MerchantDefinedSiteContact;
      } = {
        body: sitecontact,
      };
      return API.post(apiName, path, options);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.invalidateQueryKeys?.forEach((queryKey) => {
          queryClient.invalidateQueries(queryKey);
        });
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};
