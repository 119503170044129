import { z } from 'zod';
import { RiderAccountSchema } from '../../../models';

export const ApproveRiderResponseSchema = z.object({
  message: z.string(),
  rider: RiderAccountSchema,
});

export type ApproveRiderResponse = z.infer<
  typeof ApproveRiderResponseSchema
>;
