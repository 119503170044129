import { Box, Center, Icon, Text } from '@chakra-ui/react';
import { TradeazeLogoNoText } from '../brand/TradeazeLogoNoText';

export const EmptyDeliveriesPage = ({ isFiltered }: { isFiltered: boolean }) => {
  return (
    <Center h="60vh" flexDirection={'column'}>
      <Box>
        <Icon as={TradeazeLogoNoText} fontSize={200} h="120" />
      </Box>
      <Box>
        {isFiltered ? (
          <Center flexDirection={'column'}>
            <Text>No deliveries found with these filters</Text>
          </Center>
        ) : (
          <Text>No deliveries to display</Text>
        )}
      </Box>
    </Center>
  );
};
