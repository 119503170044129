import React from 'react';
import { AppSettings } from '@tradeaze/frontend/hooks';
import { Button, HStack } from '@chakra-ui/react';
import { IoGrid } from 'react-icons/io5';
import { FaListUl } from 'react-icons/fa';
import {
  DeliveryQueryOptionsDrawer,
  DeliveryQueryStore,
} from './delivery-query-options';
import { DownloadMerchantCsv } from '../csv';
import { brandSecondaryColor } from '../theme';
import { NAVBAR_HEIGHT } from '../navbar';

export const DeliveryGridButtons: React.FC<{
  appSettings: AppSettings;
  deliveryQueryStore: DeliveryQueryStore;
  merchantId: string;
  allowListView: boolean;
}> = ({ appSettings, deliveryQueryStore, merchantId, allowListView }) => {
  const buttonSizes = 'sm';

  return (
    <HStack
      position={'fixed'}
      zIndex={'modal'}
      width={'100%'}
      top={`${NAVBAR_HEIGHT}px`}
      left={0}
      paddingLeft={4}
      paddingY={2}
      paddingRight={4}
      backdropFilter={'blur(10px)'}
      backgroundColor={brandSecondaryColor}
      spacing={10}
      justifyContent={'flex-end'}
    >
      {allowListView ? (
        <HStack>
          <Button
            size={buttonSizes}
            aria-label="Grid View"
            leftIcon={<IoGrid />}
            onClick={() => appSettings.setDashboardView('grid')}
            variant={appSettings.dashboardView === 'grid' ? 'solid' : 'outline'}
          >
            Grid
          </Button>
          <Button
            size={buttonSizes}
            aria-label="List View"
            leftIcon={<FaListUl />}
            onClick={() => appSettings.setDashboardView('list')}
            variant={appSettings.dashboardView === 'list' ? 'solid' : 'outline'}
          >
            List
          </Button>
        </HStack>
      ) : null}
      <HStack>
        <DeliveryQueryOptionsDrawer
          {...deliveryQueryStore}
          buttonProps={{
            size: buttonSizes,
          }}
          isAdmin={false}
        />
        {merchantId ? (
          <DownloadMerchantCsv
            merchantId={merchantId}
            downloadQueryParams={deliveryQueryStore}
            buttonProps={{
              size: buttonSizes,
            }}
          />
        ) : null}
      </HStack>
    </HStack>
  );
};
