import {
  GetDeliveryByIdResponse,
  HydratedDelivery,
} from '@tradeaze-packages/schemas';
import { useCancelGigJob, useGetDelivery } from '@tradeaze/frontend/hooks';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { ExistingGigJobModal } from './ExistingGigJobModal';

export type AssignDeliveryContextType = {
  deliveryToAssign?: HydratedDelivery;
  isLoading: boolean;
  handleStartAssign: (delivery: HydratedDelivery) => void;
  handleCancelAssign: () => void;
  handleOpenExistingGigJobModal: () => void;
  handleCloseExistingGigJobModal: () => void;
};

export const AssignDeliveryContext = React.createContext<
  AssignDeliveryContextType | undefined
>(undefined);

export const AssignDeliveryProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [initialDeliveryToAssign, setInitialDeliveryToAssign] =
    useState<HydratedDelivery>();

  const reassignDisclosure = useDisclosure();

  const getDeliveryQuery = useGetDelivery(initialDeliveryToAssign?.deliveryId, {
    initialData: initialDeliveryToAssign,
  });

  const cancelGigJobMutation = useCancelGigJob({
    onSuccess: () => {
      reassignDisclosure.onClose();
    },
  });

  const deliveryToAssign = getDeliveryQuery.data;

  const gigJobId = deliveryToAssign?.gigJobId;

  const handleStartAssign = useCallback((delivery: HydratedDelivery) => {
    setInitialDeliveryToAssign(delivery);
  }, []);

  const handleCancelAssign = useCallback(() => {
    setInitialDeliveryToAssign(undefined);
  }, []);

  const handleOpenExistingGigJobModal = useCallback(() => {
    reassignDisclosure.onOpen();
  }, [reassignDisclosure]);

  const handleCloseExistingGigJobModal = useCallback(() => {
    reassignDisclosure.onClose();
  }, [reassignDisclosure]);

  const state = useMemo(
    () => ({
      deliveryToAssign,
      isLoading: getDeliveryQuery.isLoading,
      handleStartAssign,
      handleCancelAssign,
      handleOpenExistingGigJobModal,
      handleCloseExistingGigJobModal,
    }),
    [
      deliveryToAssign,
      getDeliveryQuery.isLoading,
      handleStartAssign,
      handleCancelAssign,
      handleOpenExistingGigJobModal,
      handleCloseExistingGigJobModal,
    ],
  );

  return (
    <AssignDeliveryContext.Provider value={state}>
      {children}
      <ExistingGigJobModal
        isOpen={Boolean(gigJobId) && reassignDisclosure.isOpen}
        onClose={handleCloseExistingGigJobModal}
        gigJobId={gigJobId ?? ''}
        handleCancelJob={() => {
          if (!gigJobId) return;
          cancelGigJobMutation.mutate({ gigJobId });
        }}
        isLoadingCancel={cancelGigJobMutation.isLoading}
      />
    </AssignDeliveryContext.Provider>
  );
};

export const useAssignDeliveryContext = () => {
  const context = useContext(AssignDeliveryContext);
  if (!context) {
    throw new Error(
      'useAssignDeliveryContext must be used within a AssignDeliveryProvider',
    );
  }
  return context;
};
