import React from 'react';
import { brandSecondaryColor } from '../theme/colors';

export const TradeazeLogoNoText = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      fill={brandSecondaryColor}
      {...props}
      viewBox="0 0 1373.000000 650.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,650.000000) scale(0.100000,-0.100000)">
        <path
          d="M7790 6313 c-272 -45 -504 -257 -565 -516 -16 -70 -20 -223 -5 -232
5 -3 42 -14 83 -24 177 -46 286 -113 582 -361 152 -127 275 -220 292 -220 9 0
168 209 162 214 -78 59 -469 380 -468 384 0 4 28 64 62 134 l62 126 97 -38
c54 -21 170 -64 259 -96 132 -46 163 -54 170 -43 14 22 10 179 -6 239 -26 100
-73 179 -154 260 -126 126 -265 181 -450 179 -53 -1 -107 -4 -121 -6z"
        />
        <path
          d="M6940 5284 c-246 -84 -609 -377 -932 -754 -282 -330 -588 -839 -753
-1255 -33 -82 -64 -153 -70 -157 -15 -11 -457 -9 -560 2 -290 34 -512 120
-710 275 -44 35 -160 139 -257 232 -97 93 -212 194 -255 225 -281 203 -579
282 -1053 282 -197 -1 -254 -4 -354 -22 -308 -57 -573 -193 -723 -373 -46 -55
-141 -216 -131 -223 1 -1 288 -94 636 -206 l633 -205 19 -43 c38 -86 79 -230
94 -334 43 -290 -14 -560 -157 -747 -93 -122 -266 -229 -437 -272 l-85 -21
-658 166 c-363 92 -661 166 -663 164 -7 -8 116 -321 171 -431 315 -638 861
-1022 1533 -1078 207 -17 459 12 658 76 114 38 287 124 374 187 229 167 295
212 392 267 334 191 625 263 1218 301 91 6 219 17 285 25 289 35 1057 105
1153 105 l33 0 -79 -102 c-113 -147 -202 -278 -202 -298 0 -28 138 -178 293
-319 233 -213 401 -341 449 -341 22 0 118 133 165 227 47 95 55 166 23 206
-12 15 -55 50 -95 77 -75 51 -115 94 -134 142 -9 24 1 40 131 215 300 403 442
641 489 823 29 112 29 258 1 347 -49 155 -375 558 -688 849 -63 59 -114 111
-114 115 0 22 448 524 678 760 142 145 161 161 197 166 44 6 55 1 1030 -454
259 -121 561 -257 672 -303 111 -46 200 -87 197 -91 -8 -14 -189 -67 -315 -94
-270 -57 -557 -83 -1320 -120 -299 -15 -550 -29 -558 -31 -10 -3 -1 -19 31
-52 52 -54 277 -340 362 -460 163 -232 185 -424 84 -742 -33 -104 -116 -286
-173 -381 -21 -33 -35 -62 -33 -64 2 -2 251 12 553 30 575 36 818 42 938 24
308 -46 533 -165 836 -440 385 -351 503 -437 719 -523 147 -59 243 -77 442
-83 496 -15 1049 118 1385 335 103 66 233 201 279 290 21 41 36 75 34 76 -1 1
-319 83 -706 182 l-703 179 -40 62 c-56 86 -115 226 -145 347 -32 123 -44 392
-25 528 45 316 168 534 358 636 123 65 66 73 826 -107 373 -89 681 -161 684
-161 10 0 -6 216 -22 288 -44 206 -134 361 -302 523 -155 148 -364 279 -638
400 -434 193 -726 259 -1018 231 -208 -19 -350 -59 -542 -152 -210 -102 -358
-213 -570 -427 -72 -73 -135 -133 -139 -133 -36 0 -719 450 -1131 745 -313
224 -482 350 -709 533 -296 237 -441 333 -555 366 -85 25 -211 21 -301 -10z"
        />
        <path
          d="M3141 5285 c6 -5 2953 -315 2997 -315 20 0 54 24 127 88 55 48 142
118 193 156 50 37 92 70 92 72 0 2 -768 4 -1707 4 -938 0 -1704 -2 -1702 -5z"
        />
        <path
          d="M3532 4735 c3 -4 2177 -235 2209 -235 6 0 116 131 182 216 l19 24
-1208 0 c-664 0 -1205 -2 -1202 -5z"
        />
        <path
          d="M4205 4133 c63 -8 1174 -123 1193 -123 13 0 31 19 56 61 20 33 36 62
36 65 0 2 -296 3 -657 3 -362 -1 -644 -4 -628 -6z"
        />
      </g>
    </svg>
  );
};
