import { Avatar, AvatarProps } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useStorageBucket } from '../context/StorageBucketContext';
import { useGetRiderProfilePic } from './useGetRiderProfilePic';

export const RiderAvatar: React.FC<
  {
    firstName?: string;
    lastName?: string | null;
    profilePicKey?: string | null;
    size?: AvatarProps['size'];
    showProfilePic?: boolean;
  } & React.ComponentProps<typeof Avatar>
> = ({
  firstName,
  lastName,
  profilePicKey,
  size,
  showProfilePic,
  ...props
}) => {
  const { riderProfilePicBucket } = useStorageBucket();

  const { data: profilePicUrlData } = useGetRiderProfilePic(
    showProfilePic ? profilePicKey : null,
    riderProfilePicBucket
  );

  const name = useMemo(() => {
    let name = '';
    if (firstName) {
      name += firstName;
    }
    if (lastName) {
      name += ` ${lastName}`;
    }
    return name;
  }, [firstName, lastName]);

  return (
    <Avatar
      size={size}
      src={showProfilePic ? profilePicUrlData : undefined}
      name={name}
      {...props}
    />
  );
};
