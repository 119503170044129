type ApiResponse<TData, TError> = (
  | {
      data: TData;
      error: undefined;
    }
  | {
      data: undefined;
      error: TError;
    }
) & {
  request: Request;
  response: Response;
};

export const handleResponse = async <TData, TError>(
  apiCall: Promise<ApiResponse<TData, TError>>,
): Promise<TData> => {
  const response = await apiCall;
  if (response.error) {
    throw response.error;
  }
  return response.data as TData;
};
