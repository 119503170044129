import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo } from 'react';
import { useAuthContext } from '../auth/AuthProvider';

export const PostHogInitiator: React.FC = () => {
  const posthogClient = usePostHog();

  const { cognitoUserId, cognitoEmail, cognitoUsername } = useAuthContext();

  const merchantQuery = useGetMerchantAccount(cognitoUserId);

  const merchantName = useMemo(
    () => merchantQuery.data?.merchantName ?? '',
    [merchantQuery.data]
  );

  useEffect(() => {
    if (
      posthogClient &&
      cognitoUsername &&
      cognitoEmail &&
      cognitoUserId &&
      merchantName
    ) {
      posthogClient.identify(cognitoUserId, {
        email: cognitoEmail,
        username: cognitoUsername,
        merchantName: merchantName,
      });
    } else if (posthogClient && !cognitoUserId) {
      posthogClient?.reset();
    }
  }, [
    posthogClient,
    cognitoUsername,
    cognitoEmail,
    cognitoUserId,
    merchantName,
  ]);

  return null;
};
