const SERIALIZED_POSTCODE_SEPARATOR = ',';

/**
 * Serializes stop postcodes into a string to allow react to track changes - array / object references change each render
 */
export const serializeStopPostcodes = (
  stops?: { postCode: string }[]
): string | undefined =>
  stops?.map((stop) => stop.postCode).join(SERIALIZED_POSTCODE_SEPARATOR);

export const splitSerializedPostcodes = (
  serializedPostcodes?: string
): string[] => serializedPostcodes?.split(SERIALIZED_POSTCODE_SEPARATOR) ?? [];
