import { z } from 'zod';
import { PublicDeliveryUpdateSchema } from '../../../models';

export const PublicPatchDeliveryRequestSchema = PublicDeliveryUpdateSchema;

export const PublicPatchDeliveryResponseSchema = z.object({
  id: z.string(),
});

export type PublicPatchDeliveryResponse = z.infer<
  typeof PublicPatchDeliveryResponseSchema
>;
export type PublicPatchDeliveryRequest = z.infer<
  typeof PublicPatchDeliveryRequestSchema
>;
