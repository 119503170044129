import { Box, Button, Flex } from '@chakra-ui/react';
import { HiCheck } from 'react-icons/hi2';

export const DropDownItem: React.FC<
  React.ComponentProps<typeof Button> & {
    value: any;
    label: string;
    onSelect: (value: any) => void;
    isLast: boolean;
    isSelected?: boolean;
    icon?: React.ReactNode;
    cypressAttribute?: string;
  }
> = (props) => {
  return (
    <Button
      borderBottom={props.isLast ? 0 : '1px'}
      borderColor={'rgba(0,0,0,0.1)'}
      borderRadius={0}
      padding={3}
      _hover={{
        bg: 'rgba(0,0,0,0.03)',
      }}
      transition="all 0.2s"
      cursor="pointer"
      onClick={() => {
        props.onSelect(props.value);
      }}
      variant={'unstyled'}
      width={'100%'}
      textAlign={'left'}
      fontWeight={'normal'}
      paddingY={2}
      paddingX={4}
      height={'auto'}
      whiteSpace="pre-wrap"
      overflowWrap="break-word"
      data-cy={props.cypressAttribute}
      {...props}
    >
      <Flex align="center">
        {props.isSelected ? <Box as={HiCheck} marginRight={2} /> : null}
        {props.icon}
        {props.label}
      </Flex>
    </Button>
  );
};
