import { CreateOrder, SiteContact } from '@tradeaze-packages/schemas';
import { useCallback } from 'react';
import { UseFieldArrayInsert, UseFormSetValue } from 'react-hook-form';
import { formatNumberIfValid } from '../utils';

type Contacts = CreateOrder['deliveryStops'][number]['contacts'];

type Contact = Contacts[number];

type UseContactCardActions = {
  contactsAccessor: `deliveryStops.${number}.contacts`;
  contacts: Contacts;
  setValue: UseFormSetValue<CreateOrder>;
  remove: (index: number) => void;
  append: (value: Contact) => void;
  insert: UseFieldArrayInsert<CreateOrder, `deliveryStops.${number}.contacts`>;
};

export const useContactCardActions = ({
  contacts,
  contactsAccessor,
  setValue,
  remove,
  append,
  insert,
}: UseContactCardActions) => {
  const CONTACTS_LIMIT = 5;

  const isContactsLimitReached = useCallback((): boolean => {
    return (
      contacts.filter(
        (f: Contact) => f.contactName !== '' && f.contactNumber !== ''
      ).length === CONTACTS_LIMIT
    );
  }, [contacts]);

  const isSingleEmptyField = useCallback((): boolean => {
    return (
      contacts.length === 1 &&
      contacts[0].contactName === '' &&
      contacts[0].contactNumber === ''
    );
  }, [contacts]);

  const getIsContactSelected = useCallback(
    (siteContact: SiteContact) => {
      return contacts.some(
        (field) =>
          field.contactName === siteContact.contactName &&
          field.contactNumber === siteContact.contactNumber
      );
    },
    [contacts]
  );

  const findContactIndex = useCallback(
    ({
      contactName,
      contactNumber,
    }: {
      contactName: string;
      contactNumber: string;
    }): number =>
      contacts.findIndex(
        (field) =>
          field.contactName === contactName &&
          field.contactNumber === contactNumber
      ),
    [contacts]
  );

  const isExistingContact = useCallback(
    (value: SiteContact): boolean => findContactIndex(value) >= 0,
    [findContactIndex]
  );

  const updateContact = useCallback(
    (index: number, data: Partial<Contact>) => {
      Object.entries(data).forEach(([key, value]) => {
        setValue(`${contactsAccessor}.${index}.${key as keyof Contact}`, value);
      });
    },
    [contactsAccessor, setValue]
  );

  const resetContactField = useCallback(
    (index: number): void => {
      updateContact(index, {
        contactName: '',
        contactNumber: '',
        trackingEnabled: index === 0,
      });
    },
    [updateContact]
  );

  const removeContact = useCallback(
    (contact: { contactName: string; contactNumber: string }): void => {
      if (isSingleEmptyField()) {
        return;
      }

      const fieldIndex = findContactIndex(contact);

      if (fieldIndex >= 0) {
        if (contacts.length === 1) {
          resetContactField(fieldIndex);
        } else {
          remove(fieldIndex);
          // if primary contact is removed set default params for new primary contact
          if (fieldIndex === 0) {
            updateContact(fieldIndex, {
              isPrimary: true,
              trackingEnabled: true,
            });
          }
        }
      }
    },
    [
      contacts.length,
      isSingleEmptyField,
      findContactIndex,
      resetContactField,
      updateContact,
      remove,
    ]
  );

  const appendNewContact = useCallback(() => {
    if (contacts.length === CONTACTS_LIMIT) {
      return;
    }
    append({
      contactName: '',
      contactNumber: '',
      trackingEnabled: false,
      isPrimary: false,
    });
  }, [contacts.length, append]);

  const appendExistingContact = useCallback(
    (contact: Contact) => {
      if (contacts.length === CONTACTS_LIMIT) {
        return;
      }
      append({
        contactName: contact.contactName,
        contactNumber:
          formatNumberIfValid(contact.contactNumber) || contact.contactNumber,
        isPrimary: contact.isPrimary,
        trackingEnabled: contact.trackingEnabled,
        siteContactId: contact.siteContactId,
      });
    },
    [contacts.length, append]
  );

  const replaceContact = useCallback(
    (index: number, value: SiteContact) => {
      remove(index);
      insert(index, {
        contactName: value.contactName,
        contactNumber: value.contactNumber,
        trackingEnabled: index === 0,
        isPrimary: index === 0,
        siteContactId: value.siteContactId,
      });
    },
    [remove, insert]
  );

  const getNextEmptyFieldIndex = useCallback((): number => {
    return contacts.findIndex(
      (field) => field.contactName === '' && field.contactNumber === ''
    );
  }, [contacts]);

  const getIsDropdownDisabled = useCallback(
    () => isContactsLimitReached() && getNextEmptyFieldIndex() === -1,
    [isContactsLimitReached, getNextEmptyFieldIndex]
  );

  const addExistingContact = useCallback(
    (contact: SiteContact) => {
      if (isExistingContact(contact) || isContactsLimitReached()) {
        return;
      }

      const nextEmptyFieldIndex = getNextEmptyFieldIndex();

      if (nextEmptyFieldIndex >= 0) {
        replaceContact(nextEmptyFieldIndex, contact);
      } else {
        appendExistingContact({
          ...contact,
          trackingEnabled: false,
          isPrimary: false,
        });
      }
    },
    [
      isExistingContact,
      isContactsLimitReached,
      getNextEmptyFieldIndex,
      appendExistingContact,
      replaceContact,
    ]
  );

  const handleDropdownSelect = (value: SiteContact) => {
    if (isExistingContact(value)) {
      removeContact(value);
      return;
    }

    if (isContactsLimitReached()) {
      return;
    }

    addExistingContact(value);
  };

  const handleGroupTracking = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    contacts.forEach((_field, index) => {
      if (isChecked === false && index === 0) {
        return;
      }
      updateContact(index, {
        trackingEnabled: isChecked,
      });
    });
  };

  return {
    handleDropdownSelect,
    handleRemoveContactCard: removeContact,
    handleAddContactCard: appendNewContact,
    handleUpdateContact: updateContact,
    handleGroupTracking,
    getIsContactSelected,
    getIsDropdownDisabled,
  };
};
