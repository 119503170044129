import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';

export const ListCardContainer: React.FC<
  React.ComponentProps<typeof Box> & {
    children?: React.ReactNode;
  }
> = ({ children, ...props }) => {
  return (
    <Box
      as={motion.div}
      borderColor={'border'}
      borderWidth={1}
      borderRadius={'14px'}
      overflow={'hidden'}
      position={'relative'}
      whileHover={{
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
