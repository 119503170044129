import { z } from 'zod';
import { RiderAccountCreateSchema, RiderAccountSchema } from '../../../models';

export const CreateRiderBodySchema = RiderAccountCreateSchema;

export const CreateRiderResponseSchema = RiderAccountSchema;

export type CreateRiderBody = z.infer<typeof CreateRiderBodySchema>;

export type CreateRiderResponse = z.infer<typeof CreateRiderResponseSchema>;
