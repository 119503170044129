import { z } from 'zod';

export const AcceptGigJobResponseSchema = z.object({
  gigJobId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  status: z.string(),
  riderId: z.string().nullable(),
  acceptedAt: z.string().nullable(),
  rejectedAt: z.string().nullable(),
  rejectedReason: z.string().nullable(),
  riderGigJobId: z.string(),
  adminAcceptedAt: z.string().nullable(),
  adminRejectedAt: z.string().nullable(),
});

export type AcceptGigJobResponse = z.infer<typeof AcceptGigJobResponseSchema>;