import { formatDuration, intervalToDuration } from 'date-fns';

export const getDifferenceInTime = (date1: Date, date2: Date) => {
  const values = intervalToDuration({
    start: date1,
    end: date2,
  });

  let text: string;

  if (values.years) {
    text = formatDuration(values, {
      format: ['years', 'months'],
      delimiter: ' ',
    });
  } else if (values.months) {
    text = formatDuration(values, {
      format: ['months', 'days'],
      delimiter: ' ',
    });
  } else if (values.days) {
    text = formatDuration(values, {
      format: ['days', 'hours'],
      delimiter: ' ',
    });
  } else {
    text = formatDuration(values, {
      format: ['hours', 'minutes'],
      delimiter: ' ',
    });
  }

  if (date2 < date1) {
    text = `${text} late`;
  } else {
    text = `${text} remaining`;
  }

  return {
    values,
    text,
  };
};
