import { z } from 'zod';
import { ClockInWorkTypeSchema, RiderAccountSchema, RiderStatusSchema } from '../../../models';
import { processStringBoolean } from '../../shared';

export const GetRidersQuerySchema = z.object({
  withLocation: z.preprocess(processStringBoolean, z.boolean()).optional(),
  withLastUsedVehicle: z.preprocess(processStringBoolean, z.boolean()).optional(),
  includeDeleted: z.preprocess(processStringBoolean, z.boolean()).optional(),
  riderName: z.string().optional(),
  includeUnassignable: z
    .preprocess(processStringBoolean, z.boolean())
    .optional(),
  workType: ClockInWorkTypeSchema.optional(),
  status: RiderStatusSchema.optional(),
});

export const GetRidersResponseSchema = z.array(RiderAccountSchema);

export type GetRidersQuery = z.infer<typeof GetRidersQuerySchema>;
export type GetRidersResponse = z.infer<typeof GetRidersResponseSchema>;
