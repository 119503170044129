import { Position } from '@tradeaze-packages/schemas';

// when markers are overalpping, this function will adjust the position of the markers to be side by side
export const adjustOverlappingPositions = <
  T extends { position?: Position | null }
>(
  markers: T[],
  shouldInclude: (marker: T) => boolean = () => true
): T[] => {
  const nudgeFactor = 0.0006; // Increasing this based on zoom is ideal but too complex to be worth it right now
  const grid = new Map();

  // Create a shallow copy of allStops
  const adjustedMarkers = markers.filter(shouldInclude).map((m) => ({ ...m }));

  adjustedMarkers.forEach((m, index) => {
    if (!m.position) {
      return;
    }
    const latBin = Math.floor(m.position.latitude / nudgeFactor);
    const lngBin = Math.floor(m.position.longitude / nudgeFactor);
    const key = `${latBin}-${lngBin}`;

    if (grid.has(key)) {
      grid.get(key).push(index);
    } else {
      grid.set(key, [index]);
    }
  });

  for (const indices of grid.values()) {
    if (indices.length > 1) {
      let nudgeAmount = -nudgeFactor * ((indices.length - 1) / 2);
      indices.forEach((index: number) => {
        const latitude = adjustedMarkers[index].position?.latitude;
        const longitude = adjustedMarkers[index].position?.longitude;
        if (!latitude || !longitude) {
          return;
        }
        adjustedMarkers[index].position = {
          latitude,
          longitude: longitude + nudgeAmount,
        };
        nudgeAmount += nudgeFactor;
      });
    }
  }

  return adjustedMarkers;
};
