import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { CgNotes } from 'react-icons/cg';

interface DeliveryNotesModalProps {
  isOpen: boolean;
  onClose: () => void;
  notes: string[];
}

export const DeliveryNotesModal: React.FC<DeliveryNotesModalProps> = ({
  isOpen,
  onClose,
  notes,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth="1px">
          <HStack>
            <Box display="inline-block">
              <CgNotes size={22} />
            </Box>
            <Text>Delivery Notes</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody minHeight={'250px'} maxHeight={'350px'} overflow={'auto'}>
          {notes.map((note, index) => (
            <Box key={index}>
              <Text whiteSpace={'pre-wrap'}>{note}</Text>
              <Divider my={3} />
            </Box>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
