import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

type TextSearchProps = React.ComponentProps<typeof Input> & {
  value: string;
  inputPlaceholder: string;
  onTextChange: (value: string) => void;
  isLoading: boolean;
};

export const TextSearch: React.FC<TextSearchProps> = ({
  value,
  isLoading,
  onTextChange,
  inputPlaceholder,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Box position={'relative'}>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
          height={props.height}
        />
        <Input
          ref={inputRef}
          value={value}
          onChange={(event) => onTextChange(event.target.value)}
          placeholder={inputPlaceholder}
          bg={'white'}
          {...props}
        />
        {isLoading ? (
          <InputRightElement height={props.height}>
            <Spinner size={'sm'} color={'gray.400'} />
          </InputRightElement>
        ) : null}
      </InputGroup>
    </Box>
  );
};
