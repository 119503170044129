import { Auth } from 'aws-amplify';

export const getJwt = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const getAuthHeader =
  (
    onError?: () => void
  ): (() => Promise<{
    Authorization: string;
  }>) =>
  async () => {
    try {
      const jwt = await getJwt();

      return {
        Authorization: `Bearer ${jwt}`,
      };
    } catch (error) {
      onError?.();
      return {
        Authorization: '',
      };
    }
  };
