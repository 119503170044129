import {
  useMutation,
  useQueryClient,
  QueryKey,
  UseMutationOptions,
} from '@tanstack/react-query';
import {
  Address,
  CompanyAddress,
  MerchantDefinedCompanyAddress,
} from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

interface UseCreateMerchantCompanyOptions
  extends UseMutationOptions<Address, unknown, CompanyAddress> {
  invalidateQueryKeys?: QueryKey[];
}

export const useCreateMerchantCompanyAddress = (
  options?: UseCreateMerchantCompanyOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<Address, unknown, CompanyAddress>(
    (merchantCompany) => {
      const apiName = 'MerchantApi';
      const path = '/createMerchantCompanyAddress/' + merchantCompany.companyId;
      const options: {
        body: MerchantDefinedCompanyAddress;
      } = {
        body: merchantCompany,
      };
      return API.post(apiName, path, options);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.invalidateQueryKeys?.forEach((queryKey) => {
          queryClient.invalidateQueries(queryKey);
        });
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};
