import { z } from 'zod';
import { CreateFleetVehicleSchema, FleetVehicleSchema } from '../../../models';

export const CreateFleetVehicleRequestSchema = CreateFleetVehicleSchema;

export const CreateFleetVehicleResponseSchema = FleetVehicleSchema;

export type CreateFleetVehicleRequest = z.infer<
  typeof CreateFleetVehicleRequestSchema
>;

export type CreateFleetVehicleResponse = z.infer<
  typeof CreateFleetVehicleResponseSchema
>;
