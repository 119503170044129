import React from 'react';
import { Storage } from 'aws-amplify';

type StorageBucketContextType = {
  riderProfilePicBucket: string;
  uploadInvoiceBucket: string;
};

const StorageBucketContext = React.createContext(
  {} as StorageBucketContextType
);

export const StorageBucketProvider: React.FC<{
  children: React.ReactNode;
  buckets: StorageBucketContextType;
}> = ({ children, buckets }) => {
  return (
    <StorageBucketContext.Provider value={buckets}>
      {children}
    </StorageBucketContext.Provider>
  );
};

export const useStorageBucket = () => {
  if (!React.useContext(StorageBucketContext)) {
    throw new Error(
      'useStorageBucket must be used within a StorageBucketProvider'
    );
  }
  return React.useContext(StorageBucketContext);
};

export const uploadInvoice = async (
  file: File,
  bucket: string,
  key: string
) => {
  const result = await Storage.put(key, file, {
    bucket,
    contentType: file.type,
  });
  return result;
};

export const getInvoice = async (bucket: string, key: string) => {
  const result = await Storage.get(key, {
    bucket,
  });
  return result;
};
