import { z } from 'zod';
import { OrdersQueryParamsSchema } from '../../../models';

export const DownloadMerchantOrdersCsvQuerySchema = OrdersQueryParamsSchema.omit({
  startIndex: true,
  limit: true,
}).extend({
  merchantId: z.string(),
});

export const DownloadMerchantOrdersCsvResponseSchema = z.object({
  url: z.string(),
});

export type DownloadMerchantOrdersCsvQuery = z.infer<
  typeof DownloadMerchantOrdersCsvQuerySchema
>;

export type DownloadMerchantOrdersCsvResponse = z.infer<
  typeof DownloadMerchantOrdersCsvResponseSchema
>;
