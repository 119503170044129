import { isSameDay } from 'date-fns';

export function shouldShowRiderMarker(
  updatedAt: Date | string,
  hideAfterMinutes?: number
) {
  const updatedAtDate = new Date(updatedAt);
  const now = new Date();

  if (hideAfterMinutes) {
    const timeDiff = now.getTime() - updatedAtDate.getTime();
    const minutesDiff = timeDiff / (1000 * 60);
    return minutesDiff < hideAfterMinutes;
  }

  return isSameDay(updatedAtDate, now);
}
