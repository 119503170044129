import { z } from 'zod';
import { EtaStatusSchema, DeliveryStopTypeSchema } from '../../../models';

export const GetDeliveryEtaQuerySchema = z.object({
  deliveryId: z.string(),
});

export const GetDeliveryStopsETASchema = z.object({
  deliveryStopId: z.string(),
  earliestArrival: z.string().nullable(),
  latestArrival: z.string().nullable(),
  stopIndex: z.number().nullable(),
  completedAt: z.string().nullable(),
  type: DeliveryStopTypeSchema,
});

export const GetDeliveryEtaResponseSchema = z.object({
  etaStatus: EtaStatusSchema,
  stops: z.array(GetDeliveryStopsETASchema),
});

export type GetDeliveryStopsETAResponse = z.infer<
  typeof GetDeliveryStopsETASchema
>;

export type GetDeliveryEtaResponse = z.infer<
  typeof GetDeliveryEtaResponseSchema
>;

export type GetDeliveryEtaQuery = z.infer<
  typeof GetDeliveryEtaQuerySchema
>;
