import { z } from 'zod';
import {
  DeliveryScheduleWithStopsSchema,
  GeofenceSchema,
} from '../../../models';

export const GetRiderDeliveryScheduleQuerySchema = z.object({
  date: z.string(),
  includeGeofences: z
    .preprocess((arg) => arg === 'true' || arg === true, z.boolean())
    .optional(),
});

export const GetRiderDeliveryScheduleResponseSchema =
  DeliveryScheduleWithStopsSchema.extend({
    geofences: GeofenceSchema.array().optional(),
  }).nullish();

export type GetRiderDeliveryScheduleQuery = z.infer<
  typeof GetRiderDeliveryScheduleQuerySchema
>;

export type GetRiderDeliveryScheduleResponse = z.infer<
  typeof GetRiderDeliveryScheduleResponseSchema
>;
