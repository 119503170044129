import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';
import { CreateDeliveryItemSchema, UpdateDeliveryItemSchema } from './delivery-item-schema';
import { DeliveryOptionIdSchema } from './delivery-option-schema';
import {
  CreateDeliveryStopSchema,
  DuplicateDeliveryStopSchema,
  UpdateDeliveryStopSchema,
} from './delivery-stop-schema';
import {
  DeliveryVehicleIdSchema,
} from './delivery-vehicle-schema';
import { PaginationParamsSchema } from './paginated-data-schema';
import { buildArrayOrSingleSchema } from '../utils';

extendZodWithOpenApi(z);

export const DeliveryWindowSchema = z.object({
  from: z.string().datetime(),
  to: z.string().datetime(),
});

/**
 * Standard Orders
 */
export const OrderStatusSchema = z.enum([
  'PENDING',
  'CONFIRMED',
  'DELIVERED',
  'REJECTED',
  'CANCELLED',
]);

export const OrderTypeSchema = z.enum([
  'A_TO_B',
  'MULTI_COLLECTION',
  'MULTI_DROP',
]);

const stringRequired = (fieldName: string, customMessage?: string) =>
  z
    .string({
      invalid_type_error: customMessage || `${fieldName} must be a string`,
      required_error: customMessage || `${fieldName} is required`,
    })
    .trim()
    .min(1, { message: customMessage || `${fieldName} is required` });

const numberRequired = (fieldName: string, customMessage?: string) =>
  z
    .number({
      invalid_type_error: customMessage || `${fieldName} must be a number`,
      required_error: customMessage || `${fieldName} is required`,
    })
    .min(0, {
      message: customMessage || `${fieldName} must be a positive number`,
    });

const booleanRequired = (fieldName: string, customMessage?: string) =>
  z.boolean({
    invalid_type_error: customMessage || `${fieldName} must be true or false`,
    required_error: customMessage || `${fieldName} is required`,
  });

export const OrderSchema = z.object({
  createdAt: z.string(),
  updatedAt: z.string(),
  orderId: z.string(),
  merchantId: z.string().nullish(),
  apiClientId: z.string().nullish(),
  type: OrderTypeSchema,
  orderStatus: OrderStatusSchema,
  bookedBy: z.string().nullish(),
  deliveryPrice: z.number(),
  merchantServiceCharge: z.number(),
  invoicingName: z.string().nullish(),
  cancellationFee: z.number().nullish(),
  companyId: z.string().nullish(),
  companyName: z.string().nullish(),
  cancelledAt: z.string().nullish(),
  confirmedAt: z.string().nullish(),
  deliveredAt: z.string().nullish(),
  cancellationReason: z.string().nullish(),
  deliveryDuration: z.number().nullish(),
  deliveryDistance: z.number().nullish(),
  totalTimeEstimate: z.number().nullish(),
  endUserId: z.string().nullish(),
  isPriceEdited: z.boolean().nullish(),
  merchantOrderReference: z.string().nullish(),
  tradeazeComments: z.string().nullish(),
  notes: z.string().nullish(),
  metadata: z.record(z.any()).nullish(),
  deliveryWindowStart: z.string(),
  deliveryWindowEnd: z.string(),
});

export const CreateOrderSchema = z.object({
  orderId: z.string().optional(),
  merchantId: z.string().nullish(),
  orderStatus: OrderStatusSchema.optional(),
  deliveryOption: DeliveryOptionIdSchema,
  deliveryVehicle: DeliveryVehicleIdSchema,
  createdAt: z.string().optional(),
  bookedBy: stringRequired('Booked by'),
  deliveryPrice: numberRequired('Delivery price'),
  tips: z.number().nullish(),
  merchantServiceCharge: numberRequired('Service charge'),
  deliveryWindowStart: stringRequired('Delivery window start'),
  deliveryWindowEnd: stringRequired('Delivery window end'),
  invoicingName: z.string().nullish(),
  updatedAt: z.string().optional(),
  companyId: z.string().nullish(),
  companyName: stringRequired('Company name'),
  confirmedAt: z.string().optional(),
  deliveredAt: z.string().optional(),
  cancelledAt: z.string().optional(),
  collectionReady: z.boolean().nullish(),
  deliveryDuration: z.number().nullish(),
  deliveryDistance: z.number().nullish(),
  totalTimeEstimate: z.number().nullish(),
  isPriceEdited: z.boolean().nullish(),
  merchantOrderReference: stringRequired('Order reference'),
  heavySideItems: booleanRequired(
    'Type of items',
    'Please select type of items'
  ),
  notes: z.string().nullish(),
  metadata: z.record(z.any()).nullish(),
  deliveryStops: z.array(CreateDeliveryStopSchema),
  deliveryItems: z.array(CreateDeliveryItemSchema),
  endUserId: z.string().nullish(),
});

export const CreateOrderTravisPerkinsSchema = CreateOrderSchema.extend({
  merchantOrderReference: z
    .string({
      invalid_type_error: 'PO number must be a string',
      required_error: 'PO number is required',
    })
    .refine((arg) => arg.length === 9 || arg.length === 11, {
      message: 'PO number must be 9 or 11 characters long',
    }),
});

export const CreateOrderMpMoranSchema = CreateOrderSchema.extend({
  merchantOrderReference: z
    .string({
      invalid_type_error: 'Order reference must be a string',
      required_error: 'Order reference is required',
    })
    .refine((arg) => arg.length === 7, {
      message: 'MP Moran references must be 7 characters long',
    })
    .refine((arg) => arg.startsWith('5'), {
      message: 'MP Moran references must start with a 5',
    }),
});

export const UpdateOrderSchema = CreateOrderSchema.extend({
  orderId: z.string(),
  deliveryStops: z.array(UpdateDeliveryStopSchema),
  bookedBy: z.string().nullish(), // old orders have this as null
  deliveryItems: z.array(UpdateDeliveryItemSchema),
});

export const DuplicateOrderSchema = CreateOrderSchema.extend({
  deliveryStops: z.array(DuplicateDeliveryStopSchema),
  bookedBy: z.string().nullish(), // old orders have this as null,
}).partial();

const SortByEnum = z.enum(['createdAt', 'deliveredAt', 'deliveryWindowEnd']);

/**
 * Query Params for querying orders
 */
export const OrdersArrayQueryParamsSchema = z.object({
  orderStatuses: buildArrayOrSingleSchema(OrderStatusSchema, false),
  riderIds: buildArrayOrSingleSchema(z.string(), false),
  deliveryVehicles: buildArrayOrSingleSchema(DeliveryVehicleIdSchema, false),
});

export const OrdersStringQueryParamsSchema = z.object({
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  sortBy: SortByEnum.optional(),
  deliveryToday: z.preprocess(
    (arg) => (arg === 'true' ? true : arg === 'false' ? false : undefined),
    z.boolean().optional()
  ),
  merchantId: z.string().optional(),
  merchantOrderReference: z.string().optional(),
  ...PaginationParamsSchema.shape,
});

export const OrdersQueryParamsSchema = z.object({
  ...OrdersArrayQueryParamsSchema.shape,
  ...OrdersStringQueryParamsSchema.shape,
});

export const AllDeliveryPricesQueryParamsSchema = z.object({
  fromPostcode: z.string().transform((arg) => arg.replace(/\s/g, '')),
  toPostcode: z.string().transform((arg) => arg.replace(/\s/g, '')),
  deliveryVehicle: DeliveryVehicleIdSchema,
  date: z.string().datetime(),
});

/**
 * Types
 */
export type CreateOrder = z.infer<typeof CreateOrderSchema>;
export type UpdateOrder = z.infer<typeof UpdateOrderSchema>;
export type DuplicateOrder = z.infer<typeof DuplicateOrderSchema>;
export type Order = z.infer<typeof OrderSchema>;
export type OrderSortBy = z.infer<typeof SortByEnum>;
export type OrderQueryParams = z.infer<typeof OrdersQueryParamsSchema>;
export type OrderStatus = z.infer<typeof OrderStatusSchema>;
export type OrderType = z.infer<typeof OrderTypeSchema>;
export type DeliveryWindow = z.infer<typeof DeliveryWindowSchema>;
export type GetAllDeliveryPricesParams = z.infer<
  typeof AllDeliveryPricesQueryParamsSchema
>;
