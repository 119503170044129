import { z } from 'zod';

export const RegionIdEnumSchema = z.enum(['LONDON']);

export const RegionSchema = z.object({
  regionId: RegionIdEnumSchema,
  name: z.string(),
});

export type RegionId = z.infer<typeof RegionIdEnumSchema>;
export type Region = z.infer<typeof RegionSchema>;
