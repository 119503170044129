export const getStopFormKeys = (
  deliveryStopAccesor: `deliveryStops.${number}`
) =>
  ({
    addressId: `${deliveryStopAccesor}.addressId`,
    deliveryStopId: `${deliveryStopAccesor}.deliveryStopId`,
    addressLine1: `${deliveryStopAccesor}.addressLine1`,
    addressLine2: `${deliveryStopAccesor}.addressLine2`,
    addressLine3: `${deliveryStopAccesor}.addressLine3`,
    postCode: `${deliveryStopAccesor}.postCode`,
    position: `${deliveryStopAccesor}.position`,
    companyName: `${deliveryStopAccesor}.companyName`,
    contactName: `${deliveryStopAccesor}.contactName`,
    contactNumber: `${deliveryStopAccesor}.contactNumber`,
    collectionReference: `${deliveryStopAccesor}.collectionReference`,
    deliveryNotes: `${deliveryStopAccesor}.deliveryNotes`,
    requiresImage: `${deliveryStopAccesor}.requiresImage`,
    requiresSignature: `${deliveryStopAccesor}.requiresSignature`,
    contacts: `${deliveryStopAccesor}.contacts`,
  } as const);
