import {
  DeliveryItem,
  DeliveryVehicle,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';

type RequiredProps = Pick<
  DeliveryItem,
  'length' | 'width' | 'height' | 'weight' | 'widthUnit'
>;
type OptionalProps = Partial<
  Omit<DeliveryItem, 'length' | 'width' | 'height' | 'weight' | 'widthUnit'>
>;
type CustomDeliveryItem = RequiredProps & OptionalProps;
type MeasurementUnit = 'mm' | 'cm' | 'm';

type Dimensions = {
  length: number;
  width: number;
  xPosition: string;
  yPosition: string;
};

export const convertToUnit = (mmValue: number, unit: MeasurementUnit) => {
  switch (unit) {
    case 'cm':
      return mmValue / 10;
    case 'm':
      return mmValue / 1000;
    default:
      return mmValue;
  }
};

export const unitConverter = (
  value: number | undefined,
  fromUnit: MeasurementUnit | undefined,
  toUnit: MeasurementUnit
): number | undefined => {
  const conversionRates: { [key in MeasurementUnit]: number } = {
    mm: 1,
    cm: 10,
    m: 1000,
  };
  if (!value || !fromUnit) {
    return undefined;
  }
  const baseValue = value * conversionRates[fromUnit];
  const convertedValue = baseValue / conversionRates[toUnit];
  return convertedValue;
};

export const convertToMeters = (
  value: number,
  currentUnit: MeasurementUnit
): number => {
  switch (currentUnit) {
    case 'mm':
      return value / 1000;
    case 'cm':
      return value / 100;
    case 'm':
      return value;
    default:
      return value;
  }
};

export const getUnitDescription = (
  mmMaxValue: number,
  unit: MeasurementUnit
) => {
  return `Max. ${convertToUnit(mmMaxValue, unit)} ${unit}`;
};

export const filterVehiclesByItemSize = (
  deliveryVehicle: DeliveryVehicle,
  deliveryItemsValues: CustomDeliveryItem
) => {
  const { length, width, height, weight, widthUnit } = deliveryItemsValues;
  const { lengthCapacity, widthCapacity, heightCapacity, weightCapacity } =
    deliveryVehicle;

  if (!lengthCapacity || !widthCapacity || !heightCapacity || !weightCapacity) {
    return false;
  }

  const convertedLength = convertToMeters(length, widthUnit);
  const convertedWidth = convertToMeters(width, widthUnit);
  const convertedHeight = convertToMeters(height, widthUnit);

  if (convertedLength > lengthCapacity) {
    return false;
  }

  if (convertedWidth > widthCapacity) {
    return false;
  }

  if (convertedHeight > heightCapacity) {
    return false;
  }

  if (weight > weightCapacity) {
    return false;
  }

  return true;
};

const vehicleDimensions: Record<
  DeliveryVehicleId,
  Record<'SIDE' | 'REAR', Dimensions>
> = {
  BIKE: {
    REAR: {
      length: 72,
      width: 72,
      xPosition: '133',
      yPosition: '56',
    },
    SIDE: {
      length: 72,
      width: 72,
      xPosition: '144',
      yPosition: '56',
    },
  },
  CAR: {
    REAR: {
      length: 74,
      width: 87,
      xPosition: '139',
      yPosition: '56',
    },
    SIDE: {
      length: 74,
      width: 114,
      xPosition: '139',
      yPosition: '56',
    },
  },
  FLATBED: {
    REAR: {
      length: 128.341,
      width: 102,
      xPosition: '141',
      yPosition: '52',
    },
    SIDE: {
      length: 111,
      width: 354,
      xPosition: '141',
      yPosition: '56',
    },
  },
  VAN_LARGE: {
    REAR: {
      length: 128.341,
      width: 102,
      xPosition: '141',
      yPosition: '56',
    },
    SIDE: {
      length: 111,
      width: 173,
      xPosition: '141',
      yPosition: '56',
    },
  },
  VAN_XLARGE: {
    REAR: {
      length: 128.341,
      width: 102,
      xPosition: '141',
      yPosition: '52',
    },
    SIDE: {
      length: 111,
      width: 268,
      xPosition: '141',
      yPosition: '56',
    },
  },
  LUTON: {
    REAR: {
      length: 136.727,
      width: 137,
      xPosition: '138',
      yPosition: '55',
    },
    SIDE: {
      length: 125,
      width: 233,
      xPosition: '141',
      yPosition: '56',
    },
  },
};

export const getVehicleDimensions = (
  vehicleId: DeliveryVehicleId | undefined,
  facing: 'SIDE' | 'REAR'
): Dimensions | null => {
  if (!vehicleId || !facing) {
    return null;
  }
  return vehicleDimensions[vehicleId][facing];
};