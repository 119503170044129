import { UseFormHandleSubmit } from 'react-hook-form';
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import toast from 'react-hot-toast';
import { CreateOrder } from '@tradeaze-packages/schemas';
import {
  orderKeyToLabel,
  travisPerkinsOrderKeyToLabel,
} from '@tradeaze/shared/utils';

export const CompleteOrderFormButton: React.FunctionComponent<{
  isLoading: boolean;
  handleSubmit: UseFormHandleSubmit<CreateOrder>;
  onSubmit: (order: CreateOrder) => void;
  isUpdatingOrder?: boolean;
  isTravisPerkins?: boolean;
}> = ({
  isLoading,
  handleSubmit,
  onSubmit,
  isUpdatingOrder = false,
  isTravisPerkins = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const getMissingFieldsText = React.useCallback(
    (errors: Record<string, unknown>) => {
      const labelMap = isTravisPerkins
        ? travisPerkinsOrderKeyToLabel
        : orderKeyToLabel;

      const missingFields = Object.keys(errors).map(
        (key) => labelMap[key as keyof CreateOrder]
      );

      return `Please ensure all fields are valid: ${missingFields.join(', ')}`;
    },
    [isTravisPerkins]
  );

  const onConfirm = async () => {
    onClose();

    handleSubmit(
      (data) => onSubmit(data),
      (errors) => toast.error(getMissingFieldsText(errors))
    )();
  };

  const onClick = async () => {
    onOpen();
  };

  return (
    <>
      <Button
        fontSize={20}
        borderRadius={16}
        w="100%"
        h={16}
        colorScheme="yellow"
        isLoading={isLoading}
        onClick={onClick}
        data-cy={
          isUpdatingOrder ? 'update-order-button' : 'create-order-button'
        }
      >
        {isUpdatingOrder ? 'Update order' : 'Create Order'}
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            {isUpdatingOrder ? 'Update' : 'Create'} order?
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to {isUpdatingOrder ? 'update' : 'create'}{' '}
            this order?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button onClick={onConfirm} colorScheme="yellow" ml={3}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
