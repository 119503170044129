import {
  DeliveryVehicleId,
  OrderStatus,
  OrderSortBy,
} from '@tradeaze-packages/schemas';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { formatDateIgnoreTime } from '@tradeaze/shared/utils';

export type OrderQueryStore = {
  sortBy: OrderSortBy;
  endDate?: string;
  startDate?: string;
  deliveryToday?: boolean;
  merchantId?: string;
  orderStatuses: OrderStatus[];
  riderIds?: string[];
  merchantOrderReference?: string;
  deliveryVehicles?: DeliveryVehicleId[];
  getActiveFilters: () => number;
  handleStatusFilterChange: (status: OrderStatus) => void;
  handleToggleDeliveryToday: () => void;
  handleChangeSortBy: (sortBy: OrderSortBy) => void;
  handleChangeStartDate: (startDate: Date) => void;
  handleChangeEndDate: (endDate: Date) => void;
  handleClearDates: () => void;
  handleChangeOrderReference: (newOrderReference: string) => void;
  handleToggleRider: (riderId: string) => void;
  handleSelectAllRiders: () => void;
  handleToggleDeliveryVehicle: (deliveryVehicle: DeliveryVehicleId) => void;
  handleSelectAllDeliveryVehicles: () => void;
  handleChangeMerchantFilter: (merchantId?: string) => void;
};

export const useOrderQueryStore = create<OrderQueryStore>()(
  persist(
    (set, get) => ({
      endDate: undefined,
      merchantId: undefined,
      orderStatuses: ['PENDING', 'CONFIRMED'],
      riderIds: undefined,
      sortBy: 'createdAt',
      deliveryToday: false,
      startDate: undefined,
      merchantOrderReference: undefined,
      deliveryVehicles: undefined,
      getActiveFilters: () => {
        const {
          startDate,
          endDate,
          merchantId,
          riderIds,
          merchantOrderReference,
          deliveryVehicles,
          orderStatuses,
          deliveryToday,
        } = get();

        let count = 0;
        if (startDate) count++;
        if (endDate) count++;
        if (merchantId) count++;
        if (riderIds) count++;
        if (merchantOrderReference) count++;
        if (deliveryVehicles) count++;
        if (orderStatuses.length !== 5 && orderStatuses.length !== 0) count++;
        if (deliveryToday) count++;
        return count;
      },
      handleStatusFilterChange: (status: OrderStatus) =>
        set(({ orderStatuses }) =>
          orderStatuses?.includes(status)
            ? {
                orderStatuses: orderStatuses.filter((s) => s !== status),
              }
            : {
                orderStatuses: [...orderStatuses, status],
              }
        ),
      handleChangeSortBy: (sortBy: OrderSortBy) => set({ sortBy }),
      handleToggleDeliveryToday: () =>
        set(({ deliveryToday }) => ({
          deliveryToday: !deliveryToday,
        })),
      handleChangeStartDate: (newDate) => {
        set({ startDate: formatDateIgnoreTime(newDate) });
      },
      handleChangeEndDate: (newDate) => {
        set({ endDate: formatDateIgnoreTime(newDate) });
      },
      handleChangeOrderReference: (newOrderReference) => {
        set({ merchantOrderReference: newOrderReference });
      },
      handleClearDates: () => {
        set({ startDate: undefined, endDate: undefined });
      },
      handleToggleRider: (riderId: string) => {
        set(({ riderIds }) => ({
          riderIds: riderIds?.includes(riderId)
            ? riderIds.filter((id) => id !== riderId)
            : [...(riderIds ?? []), riderId],
        }));
      },
      handleSelectAllRiders: () => {
        set({ riderIds: undefined });
      },
      handleChangeMerchantFilter: (merchantId?: string) => {
        set({ merchantId });
      },
      handleToggleDeliveryVehicle: (deliveryVehicle: DeliveryVehicleId) => {
        set(({ deliveryVehicles }) => {
          return {
            deliveryVehicles: deliveryVehicles?.includes(deliveryVehicle)
              ? deliveryVehicles.filter((id) => id !== deliveryVehicle)
              : [...(deliveryVehicles ?? []), deliveryVehicle],
          };
        });
      },
      handleSelectAllDeliveryVehicles: () => {
        set({ deliveryVehicles: undefined });
      },
    }),
    {
      name: 'order-query-store',
      getStorage: () => sessionStorage,
    }
  )
);
