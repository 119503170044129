import React from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { CgNotes } from 'react-icons/cg';

export const ListCardNotesPreview: React.FC<{
  notesPreview: string;
}> = ({ notesPreview }) => {
  return (
    <HStack spacing={2} color={'blackAlpha.800'}>
      <Icon as={CgNotes} />
      <Text fontSize={'0.9em'} noOfLines={1}>
        {notesPreview}
      </Text>
    </HStack>
  );
};
