import React, { useMemo } from 'react';
import {
  Layout,
  Map,
  MobileMapWithToggle,
  TwoColumnLayout,
  useDashboardMapMarkers,
  useMarkerHover,
  DeliveryGridButtons,
  DeliveryQueryPage,
  useDeliveryQueryStore,
} from '@tradeaze/frontend/ui-web';
import { useAuthContext } from '../components/auth/AuthProvider';
import {
  useAppSettingsStore,
  useGetHoveredDeliveryRiderLocation,
  useGetMerchantAccount,
  useGetMerchantDeliveries,
} from '@tradeaze/frontend/hooks';
import { Box, Show } from '@chakra-ui/react';
import { useWindowSize } from 'react-use';
import { useNavigate } from 'react-router-dom';

export const DeliveryDashboardPage = () => {
  const navigate = useNavigate();

  const deliveryQueryStore = useDeliveryQueryStore();

  const { cognitoUserId } = useAuthContext();

  const { data: merchantAccount } = useGetMerchantAccount(cognitoUserId);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetMerchantDeliveries(cognitoUserId, {
    endDate: deliveryQueryStore.endDate,
    deliveryStatuses: deliveryQueryStore.deliveryStatuses,
    sortBy: deliveryQueryStore.sortBy,
    startDate: deliveryQueryStore.startDate,
    merchantOrderReference: deliveryQueryStore.merchantOrderReference,
    deliveryToday: deliveryQueryStore.deliveryToday,
    deliveryVehicles: deliveryQueryStore.deliveryVehicles,
    limit: 12,
  });

  const deliveries = useMemo(
    () => data?.pages.flatMap((page) => page.deliveries) || [],
    [data]
  );

  const markerHover = useMarkerHover();

  const hoveredRiderData = useGetHoveredDeliveryRiderLocation(
    deliveries,
    markerHover.hoveredDeliveryId
  );

  const onPressGoToOrder = (orderId: string) => {
    navigate(`/orders/${orderId}`);
  };

  const { mapMarkers } = useDashboardMapMarkers({
    initialFilters: {
      MERCHANT: true,
      DELIVERY: true,
      RIDER: true,
      DROP_OFF: true,
      PICK_UP: true,
      ADDRESS: false,
    },
    isAdmin: false,
    merchants: merchantAccount ? [merchantAccount] : [],
    riders: hoveredRiderData.rider ? [hoveredRiderData.rider] : [],
    riderLocations: hoveredRiderData.riderLocations,
    deliveries,
    hoveredDeliveryId: markerHover.hoveredDeliveryId,
    onHoverDelivery: markerHover.onHoverDelivery,
    onHoverLeaveDelivery: markerHover.onHoverLeaveDelivery,
    onClickDelivery: ({ orderId }) => onPressGoToOrder(orderId),
  });

  const windowSize = useWindowSize();

  const appSettings = useAppSettingsStore();

  const allowListView = windowSize.width > 1268;

  const view = allowListView ? appSettings.dashboardView : 'grid';

  const DeliveryGrid = (
    <>
      <DeliveryGridButtons
        appSettings={appSettings}
        deliveryQueryStore={deliveryQueryStore}
        merchantId={merchantAccount?.merchantId || ''}
        allowListView={allowListView}
      />
      <Box paddingTop={8}>
        <DeliveryQueryPage
          view={view}
          onPressGoTo={onPressGoToOrder}
          fetchNextPage={fetchNextPage}
          isFiltered={deliveryQueryStore.getActiveFilters() > 0}
          hasNextPage={hasNextPage}
          isAdmin={false}
          isError={isError}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          deliveries={deliveries}
          columns={[1, 1, 1, 1, 2]}
          hoveredDeliveryId={markerHover.hoveredDeliveryId}
          onHoverDelivery={markerHover.onHoverDelivery}
          onHoverLeaveDelivery={markerHover.onHoverLeaveDelivery}
        />
      </Box>
    </>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={DeliveryGrid}
          right={<Map markers={mapMarkers} />}
          leftContainerProps={{
            paddingX: 6,
          }}
        />
      </Show>
      <Show below="md">
        <Layout>
          {DeliveryGrid}
          <MobileMapWithToggle markers={mapMarkers} extraTopPadding={40} />
        </Layout>
      </Show>
    </>
  );
};
