import { useAuthStore } from '@tradeaze/frontend/ui-web';
import { createContext, ReactNode, useContext } from 'react';

/**
 * This can all eventually be accesses directly from useAuthStore
 * this is just to limit amount of changes after a refactor (11/2023)
 */

interface AuthContextType {
  cognitoUser: any;
  cognitoUserId?: string;
  cognitoUsername?: string;
  cognitoEmail?: string;
  authLoading: boolean;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const useAuthContext: () => AuthContextType = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuthStore();

  const cognitoUser = auth.user;

  const cognitoUserId = cognitoUser?.attributes?.sub;

  const cognitoUsername = cognitoUser?.username;

  const cognitoEmail = cognitoUser?.attributes.email;

  const authLoading = auth.loading;

  return (
    <AuthContext.Provider
      value={{
        cognitoUser,
        cognitoUserId,
        cognitoUsername,
        cognitoEmail,
        authLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
