import { isSameDay } from 'date-fns';

/**
 * If a driver forgets to clock out, their on duty flag will be true for a following day.
 * So use the flag and the last clocked in date to determine if they are on duty.
 *
 * Once we clock people out automatically, this function will no longer be needed.
 */
export const getIsOnDuty = (
  lastClockedInAt: Date | string,
  onDutyFlag?: boolean | null
) => {
  if (!lastClockedInAt) {
    return false;
  }
  const lastClockedInDate = new Date(lastClockedInAt);
  const today = new Date();

  return isSameDay(lastClockedInDate, today) && (onDutyFlag ?? true); // (if onDutyFlag is undefined, assume they are on duty - shouldn't happen)
};
