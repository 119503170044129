import { z } from 'zod';
import { PositionSchema } from './shared-schema';
import { PaginationParamsSchema } from './paginated-data-schema';

const AddressTypeSchema = z.enum(['DELIVERY', 'PICK_UP']);

export const AddressSchema = z.object({
  addressId: z.string(),
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
  companyName: z.string().nullish(),
  instructions: z.string().nullish(),
  addressLine1: z.string({
    invalid_type_error: 'Address is required',
    required_error: 'Address is required',
  }),
  postCode: z.string({
    invalid_type_error: 'Postcode is required',
    required_error: 'Postcode is required',
  }),
  addressLine2: z.string().nullish(),
  addressLine3: z.string().nullish(),
  city: z
    .string({
      invalid_type_error: 'City is required',
      required_error: 'City is required',
    })
    .nullish(),
  country: z.string().nullish(),
  position: PositionSchema.nullish(),
  addressType: AddressTypeSchema.nullish(),
});

export const MerchantDefinedCompanyAddressSchema = AddressSchema.extend({
  companyId: z.string().nullish(),
  merchantId: z.string(),
}).omit({
  addressId: true,
  createdAt: true,
  updatedAt: true,
});

export const EndUserDefinedAddressSchema = AddressSchema.extend({
  endUserId: z.string(),
}).omit({
  addressId: true,
  createdAt: true,
  updatedAt: true,
});


export const AddressStringQueryParamsSchema = z.object({
  companyId: z.string().optional(),
  merchantId: z.string().optional(),
  endUserId: z.string().optional(),
  addressType: AddressTypeSchema.optional(),
  ...PaginationParamsSchema.shape,
});

export type CompanyAddress = z.infer<
  typeof MerchantDefinedCompanyAddressSchema
>;
export type MerchantDefinedCompanyAddress = z.infer<
  typeof MerchantDefinedCompanyAddressSchema
>;
export type EndUserDefinedAddress = z.infer<typeof EndUserDefinedAddressSchema>;
export type AddressStringQueryParams = z.infer<
  typeof AddressStringQueryParamsSchema
>;
export type Address = z.infer<typeof AddressSchema>;
export type PaginatedAddresses = {
  addresses: Address[];
  nextPageIndex: number;
};
export type AddressType = z.infer<typeof AddressTypeSchema>;
