import { Text, Box } from '@chakra-ui/react';
import { DeliveryVehicle } from '@tradeaze-packages/schemas';
import { VehicleDimensions } from './VehicleDimensions';

export const DeliveryVehicleInfo: React.FC<
  React.ComponentProps<typeof Box> & {
    selectedVehicle: DeliveryVehicle;
  }
> = ({ selectedVehicle, ...props }) => {
  return (
    <Box {...props}>
      <Text color={'blackAlpha.500'}>{selectedVehicle.description}</Text>
      {selectedVehicle.heightCapacity &&
      selectedVehicle.lengthCapacity &&
      selectedVehicle.weightCapacity &&
      selectedVehicle.widthCapacity ? (
        <VehicleDimensions
          direction={'row'}
          py={0.5}
          height={selectedVehicle.heightCapacity}
          length={selectedVehicle.lengthCapacity}
          weight={selectedVehicle.weightCapacity}
          width={selectedVehicle.widthCapacity}
        />
      ) : null}
    </Box>
  );
};
