import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import {
  CreateMerchantProfile,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';
import toast from 'react-hot-toast';

export const useCreateMerchantAccount = (
  options?: UseMutationOptions<MerchantAccount, unknown, CreateMerchantProfile>
) => {
  const queryClient = useQueryClient();

  let loadingToastId: string;

  return useMutation<MerchantAccount, unknown, CreateMerchantProfile>(
    (account) => {
      const apiName = 'MerchantApi';
      const path = '/createMerchant';
      const options: {
        body: CreateMerchantProfile;
      } = {
        body: account,
      };
      return API.post(apiName, path, options);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        // Invalidate and refetch
        toast.success('Created account!');
        queryClient.invalidateQueries(['getMerchantAccount']);
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        console.error(error);
        toast.error('Error');
        options?.onError?.(error, variables, context);
      },
      onMutate: (variables) => {
        loadingToastId = toast.loading('Creating account');
        options?.onMutate?.(variables);
      },
      onSettled: (data, error, variables, context) => {
        toast.dismiss(loadingToastId);
        options?.onSettled?.(data, error, variables, context);
      },
    }
  );
};
