import { z } from 'zod';
import { WebhookConfigSchema } from '../../../models';

export const CreateWebhookConfigRequestSchema = WebhookConfigSchema.omit({
  name: true,
  description: true,
});

export const CreateWebhookConfigResponseSchema = WebhookConfigSchema.pick({
  eventId: true,
  isActive: true,
  webhookUrl: true,
});

export type CreateWebhookConfigRequest = z.infer<
  typeof CreateWebhookConfigRequestSchema
>;

export type CreateWebhookConfigResponse = z.infer<
  typeof CreateWebhookConfigResponseSchema
>;
