import { z } from 'zod';
import { AddressSchema } from '../../../models';

export const UpdateAddressBodySchema = AddressSchema.extend({
  contactIds: z.array(z.string()).max(5).optional().nullish(),
}).omit({
  addressId: true,
  createdAt: true,
  updatedAt: true,
});

export const UpdateAddressResponseSchema = AddressSchema;

export type UpdateAddressBody = z.infer<typeof UpdateAddressBodySchema>;
export type UpdateAddressResponse = z.infer<typeof UpdateAddressResponseSchema>;
