import { DeliveryItem } from '@tradeaze-packages/schemas';
import { convertToMetres } from './convertToMetres';

export const getLongestDimension = (deliveryItems: DeliveryItem[]) => {
  return deliveryItems.reduce((acc, item) => {
    const { length, lengthUnit, width, widthUnit, height, heightUnit } = item;

    const lengthInMetres = convertToMetres(length, lengthUnit);
    const widthInMetres = convertToMetres(width, widthUnit);
    const heightInMetres = convertToMetres(height, heightUnit);

    return Math.max(acc, lengthInMetres, widthInMetres, heightInMetres);
  }, 0);
};
