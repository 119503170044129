import { Box, Text } from '@chakra-ui/react';
import { DescriptionColorScheme } from '../utils/getDescriptionColorScheme';

export const CardStatusDescription: React.FC<{
  colorScheme: DescriptionColorScheme;
  statusDescription: string;
}> = ({ colorScheme, statusDescription }) => {
  const { backgroundColor, borderColor } = {
    green: {
      backgroundColor: 'green.50',
      borderColor: 'green.100',
    },
    blue: {
      backgroundColor: 'blue.50',
      borderColor: 'blue.100',
    },
    yellow: {
      backgroundColor: 'yellow.50',
      borderColor: 'yellow.100',
    },
    red: {
      backgroundColor: 'red.50',
      borderColor: 'red.100',
    },
    gray: {
      backgroundColor: 'gray.50',
      borderColor: 'gray.100',
    },
  }[colorScheme];

  if (!statusDescription) return null;

  return (
    <Box
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderWidth={1}
      borderRadius={'md'}
      paddingY={1}
      paddingX={2}
      my={3}
      fontSize={'0.9em'}
    >
      <Text>{statusDescription}</Text>
    </Box>
  );
};
