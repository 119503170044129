import { buildProofDirectory } from './buildProofDirectory';
import { buildProofFileName } from './buildProofFileName';
import { ProofFilePath, ProofType } from '../types';

type BuildProofFilePathArgs = {
  orderId: string;
  stopId: string;
  type: ProofType;
  date: Date;
};

export const buildProofFilePath = ({
  orderId,
  stopId,
  type,
  date,
}: BuildProofFilePathArgs): ProofFilePath => {
  return `${buildProofDirectory(orderId, stopId)}${buildProofFileName(
    type,
    date
  )}`;
};
