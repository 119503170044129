import { z } from 'zod';

/**
 * https://transistorsoft.github.io/react-native-background-geolocation/interfaces/location.html
 */

export const TransistorsoftLocationSchema = z.object({
  coords: z.object({
    latitude: z.number(),
    longitude: z.number(),
    accuracy: z.number(),
    speed: z.number(),
    heading: z.number(),
    altitude: z.number(),
    ellipsoidal_altitude: z.number(),
  }),
  timestamp: z.string(),
  geofence: z
    .object({
      identifier: z.string(),
      action: z.string(), // ENTER|EXIT
    })
    .optional(),
});
