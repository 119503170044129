import { useQuery } from "@tanstack/react-query";
import { GetThresholdDistancesArrayQuerySchema, GetThresholdDistancesArrayQuery } from "@tradeaze-packages/schemas";
import { getThresholdDistances } from "@tradeaze-packages/api-client";
import { z } from "zod";
import { CustomUseQueryOptions } from "../shared/CustomUseQueryOptions";

export const getThresholdDistancesQueryKey = (args: z.infer<typeof GetThresholdDistancesArrayQuerySchema>) => {
    return ['getThresholdDistances', args];
}

export const useGetThresholdDistances = (
    args: GetThresholdDistancesArrayQuery,
    options?: CustomUseQueryOptions<typeof getThresholdDistances>
) => useQuery({
        queryKey: getThresholdDistancesQueryKey(args),
        queryFn: async () => getThresholdDistances(args),
        retry: false,
        staleTime: Infinity,
        ...options
    });
