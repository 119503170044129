import React from 'react';
import {
  IntercomProvider as ReactIntercomProvider,
  useIntercom,
} from 'react-use-intercom';
import { useAuthContext } from '../auth/AuthProvider';
import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';

const INTERCOM_APP_ID = import.meta.env['VITE_INTERCOM_APP_ID'];

export const IntercomProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <ReactIntercomProvider appId={INTERCOM_APP_ID}>
      <IntercomInitiator />
      {children}
    </ReactIntercomProvider>
  );
};

const IntercomInitiator: React.FC = () => {
  const { boot, shutdown, update } = useIntercom();

  const { cognitoUserId } = useAuthContext();

  const { data } = useGetMerchantAccount(cognitoUserId);

  const merchantName = React.useMemo(() => data?.merchantName ?? '', [data]);

  const contactEmail = React.useMemo(
    () => data?.contactEmails.find((ce) => ce.isPrimary)?.email ?? '',
    [data]
  );

  React.useEffect(() => {
    boot();
    return () => {
      shutdown();
    };
  }, [boot, shutdown]);

  React.useEffect(() => {
    update({
      userId: cognitoUserId,
      email: contactEmail,
      name: merchantName,
    });
  }, [contactEmail, merchantName, cognitoUserId, update]);

  return null;
};
