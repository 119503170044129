import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
} from '@chakra-ui/react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { ExpandedContactForm } from './ExpandedContactForm';
import { CollapsedContactForm } from './CollapsedContactForm';
import { CreateDeliveryStop } from '@tradeaze-packages/schemas';

export type Contact = CreateDeliveryStop['contacts'][number];

type ContactCardProps = {
  contact: Contact;
  index: number;
  contactAccessor: `deliveryStops.${number}.contacts.${number}`;
  stopSequence: number;
  handleRemoveCard: (contact: {
    contactName: string;
    contactNumber: string;
  }) => void;
  handleUpdateContact: (index: number, data: Partial<Contact>) => void;
  collapseCard?: boolean;
  isInvalid: boolean;
};

const RequiredAsterisk: React.FC = () => (
  <Box as="span" color="red.500" fontWeight={'bold'} fontSize="sm" mx={2}>
    *
  </Box>
);

export const ContactCard = ({
  contact: data,
  index,
  stopSequence,
  contactAccessor,
  collapseCard,
  isInvalid,
  handleRemoveCard,
  handleUpdateContact,
}: ContactCardProps) => {
  const [isExpanded, setIsExpanded] = useState(!collapseCard);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(!collapseCard);
  }, [collapseCard]);

  const hideRemove =
    data.contactName === '' && data.contactNumber === '' && index === 0;

  const showInvalidBorder = isInvalid && !isExpanded;

  return (
    <Box
      border="1px"
      borderColor={showInvalidBorder ? 'red.500' : 'gray.200'}
      borderRadius={8}
      padding={4}
      position="relative"
      marginY={6}
    >
      <FormControl></FormControl>
      <Flex justifyContent="space-between" mt={2}>
        <FormLabel>
          Contact {index + 1} {index === 0 && '(Primary)'}
          {index === 0 ? <RequiredAsterisk /> : null}
        </FormLabel>
        <IconButton
          aria-label={'toggle-expanded'}
          icon={
            isExpanded ? <BiChevronUp size={22} /> : <BiChevronDown size={22} />
          }
          variant={'unstyled'}
          display={'flex'}
          onClick={toggleExpand}
        />
      </Flex>
      <Box>
        {isExpanded ? (
          <ExpandedContactForm
            contact={data}
            stopSequence={stopSequence}
            index={index}
            contactsAccessor={contactAccessor}
            handleUpdateContact={handleUpdateContact}
          />
        ) : (
          <CollapsedContactForm contact={data} />
        )}
      </Box>
      {hideRemove ? null : (
        <Button
          position={'absolute'}
          right={2}
          top={-4}
          variant={'ghost'}
          colorScheme="red"
          size={'sm'}
          bg={'white'}
          onClick={() => handleRemoveCard(data)}
        >
          Remove
        </Button>
      )}
    </Box>
  );
};
