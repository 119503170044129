import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { useMemo } from 'react';
import { useSubscribeToRiderLocations } from './useSubscribeToRiderLocations';

export const useGetHoveredDeliveryRiderLocation = (
  deliveries: HydratedDelivery[],
  hoveredDeliveryId?: string
) => {
  const delivery = useMemo(
    () =>
      deliveries.find((delivery) => delivery.deliveryId === hoveredDeliveryId),
    [hoveredDeliveryId, deliveries]
  );

  const isActiveDelivery = useMemo(
    () => delivery?.status === 'CONFIRMED',
    [delivery]
  );

  const rider = useMemo(
    () =>
      delivery?.riderId
        ? {
            ...delivery.rider,
            riderId: delivery.riderId,
            firstName: delivery.rider?.firstName || 'Driver',
            workType: delivery.rider?.workType || 'SHIFT',
          }
        : null,
    [delivery?.rider, delivery?.riderId]
  );

  const riderLocations = useSubscribeToRiderLocations(
    isActiveDelivery && rider?.riderId ? [rider.riderId] : []
  );

  return {
    rider,
    riderLocations,
  };
};
