import { Box } from '@chakra-ui/react';
import React from 'react';

type Props = React.ComponentProps<typeof Box>;

export const Layout = (props: Props) => {
  return (
    <Box
      mx="auto"
      maxW={'container.xl'}
      pt={28}
      px={[8, 8, 12, 16]}
      pb={20}
      position="relative"
      {...props}
    >
      {props.children}
    </Box>
  );
};
