import { z } from 'zod';
import { HydratedClockInSchema } from '../../../models';

export const GetClockInsQuerySchema = z.object({
  fromDate: z.string(),
  toDate: z.string(),
  riderName: z.string().optional(),
  regNumber: z.string().optional(),
});

export const GetClockInsResponseSchema = z.array(HydratedClockInSchema);

export type GetClockInsQuery = z.infer<typeof GetClockInsQuerySchema>;
export type GetClockInsResponse = z.infer<typeof GetClockInsResponseSchema>;
