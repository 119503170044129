import { z } from 'zod';
import { TransistorsoftLocationSchema } from './transistorsoft-schema';

/**
 * Based on interface required by Transistorsoft's react-native-background-geolocation
 * - https://transistorsoft.github.io/react-native-background-geolocation/interfaces/geofence.html
 */

export const GeofenceSchema = z.object({
  /** A unique identifier for the geofence. */
  identifier: z.string(),
  /** The latitude of the center of the geofence. */
  latitude: z.number().optional(),
  /** The longitude of the center of the geofence. */
  longitude: z.number().optional(),
  /** The radius of the geofence in meters. Minimum reliable radius is 200 meters. */
  radius: z.number().optional(),
  /** Whether to trigger an event when entering the geofence. */
  notifyOnEntry: z.boolean().optional(),
  /** Whether to trigger an event when exiting the geofence. */
  notifyOnExit: z.boolean().optional(),
  /** Whether to trigger an event when dwelling within the geofence for a specified duration. */
  notifyOnDwell: z.boolean().optional(),
  /** The minimum time in milliseconds to spend inside the geofence to trigger a dwell event. */
  loiteringDelay: z.number().optional(),
  /** Optional arbitrary key-value pairs to attach with geofence events. */
  extras: z.record(z.any()).optional(),
});

export const GeofenceEventSchema = z.object({
  /**
   * The timestamp of the geofence transition event.
   */
  timestamp: z.string(),
  /**
   * The identifier of the geofence which fired.
   */
  identifier: z.string(),
  /**
   * The transition type: `ENTER`, `EXIT`, `DWELL`
   */
  action: z.union([z.literal('ENTER'), z.literal('EXIT'), z.literal('DWELL')]),
  /**
   * The [[Location]] where the geofence transition occurred.
   */
  location: TransistorsoftLocationSchema,
  /**
   * Optional [[Geofence.extras]]
   */
  extras: z.object({
    riderId: z.string(),
  }),
});

export type Geofence = z.infer<typeof GeofenceSchema>;

export type GeofenceEvent = z.infer<typeof GeofenceEventSchema>;
