import { z } from 'zod';

export const createPaginatedDataSchema = <T>(schema: z.ZodSchema<T>) => {
  return z.object({
    data: z.array(schema),
    nextPageIndex: z.number().nullable(),
  });
};

export const PaginationParamsSchema = z.object({
  limit: z.preprocess(
    (arg) => (arg ? Number(arg) : undefined),
    z.number().nonnegative().optional(),
  ),
  startIndex: z.preprocess(
    (arg) => (arg ? Number(arg) : undefined),
    z.number().nonnegative().optional(),
  ),
});

export type PaginatedData<T> = {
  data: T[];
  nextPageIndex: number | null;
};
