import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { DeliveryStop, HydratedDelivery } from '@tradeaze-packages/schemas';
import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { DeliverySummary } from './DeliverySummary';

const DeliveryPostcodes = ({
  pickup,
  dropOff,
}: {
  pickup: DeliveryStop;
  dropOff: DeliveryStop;
}) => {
  return (
    <HStack>
      <Text noOfLines={1}>{pickup.postCode?.toLocaleUpperCase()}</Text>
      <Icon as={FaLongArrowAltRight} color="blackAlpha.500" />
      <Text noOfLines={1}>{dropOff.postCode?.toLocaleUpperCase()}</Text>
    </HStack>
  );
};

export const DeliveryAccordionItem: React.FunctionComponent<{
  isAdmin: boolean;
  delivery: HydratedDelivery;
  proofOfDeliveryBucketname: string;
}> = ({ isAdmin, delivery, proofOfDeliveryBucketname }) => {
  return (
    <AccordionItem borderColor="transparent">
      <AccordionButton py={5} _hover={{ bg: 'transparent' }}>
        <Box flex="1" textAlign="left">
          <DeliveryPostcodes
            pickup={delivery.pickup}
            dropOff={delivery.dropOff}
          />
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel py={10}>
        <DeliverySummary
          delivery={delivery}
          isAdmin={isAdmin}
          shouldShowHeader={false}
          proofOfDeliveryBucketname={proofOfDeliveryBucketname}
          podLeftAlign
        />
      </AccordionPanel>
    </AccordionItem>
  );
};
