import React from 'react';
import {
  ChakraProvider,
  ComponentStyleConfig,
  extendTheme,
} from '@chakra-ui/react';
import '@fontsource/be-vietnam-pro/';
import { brandPrimaryColor, brandSecondaryColor } from './colors';
import { NAVBAR_HEIGHT } from '../navbar';

interface Props {
  children: React.ReactNode;
}

const theme = extendTheme({
  styles: {
    global: {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      '*': {
        '-webkit-tap-highlight-color': 'transparent',
      },
    },
  },
  colors: {
    yellow: {
      '50': '#FFFAE6',
      '100': '#FFF0B8',
      '200': '#FFE68A',
      '300': '#FEDD5C',
      '400': '#FED32F',
      '500': '#FEC901',
      '600': '#CBA101',
      '700': '#987901',
      '800': '#665000',
      '900': '#332800',
    },
    border: '#A7A7A8',
    'text-secondary': '#666668',
    brandSecondary: {
      50: '#e5e5e5',
      100: '#bfbfbf',
      200: '#999999',
      300: '#737373',
      400: '#595959',
      500: '#3f3f41',
      600: '#363636',
      700: '#2c2c2c',
      800: '#232323',
      900: '#191919',
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: 'yellow',
        backgroundColor: brandPrimaryColor,
        color: brandSecondaryColor,
      },
    } as ComponentStyleConfig,
    Modal: {
      baseStyle: {
        dialogContainer: {
          maxWidth: 'none',
        },
      },
      variants: {
        customVariant: {
          dialogContainer: {
            width: '50vw',
            right: 0,
            top: `${NAVBAR_HEIGHT}px`,
            left: 'unset',
            alignItems: 'center',
          },
        },
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          _selected: {
            color: '#877027',
            borderColor: '#877027',
            borderBottom: '2px solid',
            fontWeight: '600',
          },
        },
      },
    },
  },
});

export const ChakraStyleProvider = ({ children }: Props) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};
