import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { FaRegEye } from 'react-icons/fa';
import { brandSecondaryColor } from '../../theme';

type PreviewVehicleButtonProps = {
  setIsVehicleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PreviewVehicleButton = ({
  setIsVehicleModalOpen,
}: PreviewVehicleButtonProps) => {
  return (
    <Box
      position={'fixed'}
      top={24}
      right={4}
      maxWidth="100vw"
      flexWrap={'wrap'}
      justifyContent="flex-end"
      zIndex={1}
    >
      <Button
        background={brandSecondaryColor}
        colorScheme="brandSecondary"
        onClick={() => setIsVehicleModalOpen(true)}
      >
        <HStack spacing={1.5}>
          <FaRegEye />
          <Text>Preview Dimensions</Text>
        </HStack>
      </Button>
    </Box>
  );
};
