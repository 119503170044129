import { DeliveryStopType } from '@tradeaze-packages/schemas';

export const getStopTitle = ({
  stopType,
  stopSequence,
  stopsCount,
}: {
  stopType: DeliveryStopType;
  stopSequence: number;
  stopsCount: number;
}) => {
  let title = stopType === 'DELIVERY' ? 'Drop Off' : `Pick Up`;

  if (stopType === 'PICK_UP' && stopsCount > 2) {
    title += ` - ${stopSequence + 1}`;
  }

  return title;
};
