import { z } from 'zod';
import { AddressSchema, AddressStringQueryParamsSchema } from '../../../models';

export const GetAddressesByMerchantQuerySchema = AddressStringQueryParamsSchema;

export const GetAddressesByMerchantResponseSchema = z.object({
  addresses: z.array(AddressSchema),
  nextPageIndex: z.number().nullable(),
});

export type GetAddressesByMerchantQuery = z.infer<
  typeof GetAddressesByMerchantQuerySchema
>;

export type GetAddressesByMerchantResponse = z.infer<
  typeof GetAddressesByMerchantResponseSchema
>;
