import { z } from 'zod';

export const FleetVehicleStatsResponseSchema = z.object({
  fleetVehiclesCount: z.object({
    total: z.number(),
    approved: z.number(),
    pending: z.number(),
    rejected: z.number(),
  }),
});

export type FleetVehicleStatsResponse = z.infer<
  typeof FleetVehicleStatsResponseSchema
>;
