import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';

interface UseDeleteSiteContactOptions
  extends UseMutationOptions<unknown, unknown, { siteContactId: string }> {
  invalidateQueryKeys?: QueryKey[];
}

export const useDeleteSiteContact = (options?: UseDeleteSiteContactOptions) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { siteContactId: string }>({
    ...options,
    mutationFn: ({ siteContactId }) => {
      const apiName = 'MerchantApi';
      const path = `/deleteSiteContact/${siteContactId}`;
      const options = {};
      return API.del(apiName, path, options);
    },
    onSuccess: (data, variables, context) => {
      options?.invalidateQueryKeys?.forEach((queryKey) => {
        queryClient.invalidateQueries(queryKey);
      });
      options?.onSuccess?.(data, variables, context);
    },
  });
};
