import { DeliveryWindow } from '@tradeaze-packages/schemas';
import { format } from 'date-fns';

export const formatTimeRange = (
  deliveryWindow: DeliveryWindow,
  showDate = true
) => {
  const dateTimeRange = {
    from: new Date(deliveryWindow.from),
    to: new Date(deliveryWindow.to),
  };
  const from = format(dateTimeRange.from, 'hh:mm a');
  const to = format(dateTimeRange.to, 'hh:mm a');
  const date = format(dateTimeRange.to, 'dd MMM');
  return `${from} - ${to}${showDate ? ` · ${date}` : ''}`;
};
