import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { GetRidersQuery, RiderStatus } from '@tradeaze-packages/schemas';

export type RidersFiltersStore = {
  filters: GetRidersQuery;
  handleToggleIncludeDeleted: () => void;
  handleChangeRiderName: (riderName: string) => void;
  handleToggleIncludeUnassignable: () => void;
  handleChangeStatus: (status: RiderStatus | undefined) => void;
};

export const useRidersFiltersStore = create<RidersFiltersStore>()(
  persist(
    (set) => ({
      filters: {
        withLocation: false,
        includeDeleted: false,
        includeUnassignable: true,
        riderName: undefined,
        orderBy: undefined,
        status: undefined,
      },
      handleToggleIncludeDeleted: () =>
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            includeDeleted: !state.filters.includeDeleted,
          },
        })),
      handleChangeRiderName: (riderName: string) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, riderName },
        })),
      handleToggleIncludeUnassignable: () =>
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            includeUnassignable: !state.filters.includeUnassignable,
          },
        })),
      handleChangeStatus: (status: RiderStatus | undefined) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, status },
        })),
    }),
    {
      name: 'riders-filters-store',
      getStorage: () => sessionStorage,
    },
  ),
);
