import { z } from 'zod';
import { CompanySchema, CompanyStringQueryParamsSchema } from '../../../models';

export const GetCompaniesByMerchantQuerySchema = CompanyStringQueryParamsSchema.omit({
  merchantId: true
});

export const GetCompaniesByMerchantResponseSchema = z.object({
  companies: z.array(CompanySchema),
  nextPageIndex: z.number().nullable(),
});

export type GetCompaniesByMerchantQuery = z.infer<
  typeof GetCompaniesByMerchantQuerySchema
>;

export type GetCompaniesByMerchantResponse = z.infer<
  typeof GetCompaniesByMerchantResponseSchema
>;
