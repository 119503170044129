import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
  Tooltip,
  Icon,
  InputRightElement,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { FiLock } from 'react-icons/fi';
import React from 'react';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { FormInputNote } from './FormInputNote';

type FormAttributeProps<T extends FieldValues> = React.ComponentProps<
  typeof Box
> &
  ReturnType<UseFormRegister<T>> & {
    id: Path<T>;
    label: string;
    description?: string;
    error?: {
      message: string;
    };
    isRequired?: boolean;
    isDisabled?: boolean;
    isGreyedOut?: boolean;
    placeholder?: string;
    inputType?: 'text' | 'number' | 'textarea';
    disabledTooltip?: string;
    unit?: string;
    currency?: string;
  };

/**
 * Example use:
 *
 * ```jsx
 * <FormAttribute
 *   id={stopKeys.addressLine1}
 *   label="Address"
 *   isRequired
 *   isDisabled={!isManualAddress}
 *   placeholder="Address"
 *   mb={4}
 *   error={stopErrors?.addressLine1}
 *   {...register(stopKeys.addressLine1)}
 * />
 * ```
 *
 */
export const FormAttribute = React.forwardRef(
  <T extends FieldValues>(
    {
      id,
      label,
      description,
      error,
      placeholder,
      inputType,
      unit,
      currency,
      name,
      disabledTooltip,
      isRequired,
      isDisabled,
      isGreyedOut,
      onBlur,
      onChange,
      ...props
    }: FormAttributeProps<T>,
    forwardedRef: React.Ref<any>,
  ) => {
    let input: React.ReactNode;

    // There were complaints about the oapcity of important information when disabled
    const customDisabledStyles = {
      opacity: 1,
      cursor: 'not-allowed',
    };

    const inputProps = {
      id,
      placeholder,
      name,
      onBlur,
      onChange,
      ref: forwardedRef,
      sx: {
        ...(isGreyedOut
          ? {
              backgroundColor: '#F9F9F9',
              color: '#7F7F80',
              border: '1px solid #D5D5D5',
              _disabled: customDisabledStyles,
            }
          : { _disabled: customDisabledStyles }),
      },
    };

    switch (inputType) {
      case 'textarea':
        input = <Textarea {...inputProps} />;
        break;
      case 'number': {
        if (unit) {
          input = (
            <InputGroup>
              <Input
                {...inputProps}
                type={'number'}
                step={0.01}
                onWheel={(event) => event.currentTarget.blur()}
              />
              <InputRightElement
                pointerEvents="none"
                color="blackAlpha.600"
                fontSize="1.2em"
                children={unit}
              />
            </InputGroup>
          );
        } else if (currency) {
          input = (
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="blackAlpha.600"
                fontSize="1.2em"
                children={currency}
              />
              <Input
                {...inputProps}
                type={'number'}
                step={0.01}
                onWheel={(event) => event.currentTarget.blur()}
              />
            </InputGroup>
          );
        } else {
          input = <Input {...inputProps} type={'number'} step={0.01} />;
        }
        break;
      }
      case 'text':
      default:
        input = <Input {...inputProps} />;
        break;
    }

    return (
      <Box {...props}>
        <FormControl
          isRequired={isRequired}
          isDisabled={isDisabled}
          isInvalid={Boolean(error)}
        >
          <Flex>
            <FormLabel
              htmlFor={id}
              mr={2}
              sx={{ _disabled: customDisabledStyles }}
            >
              {label}
            </FormLabel>
            {!isRequired ? (
              <Text color={'blackAlpha.500'}>Optional</Text>
            ) : null}
          </Flex>
          {disabledTooltip && isDisabled ? (
            <InputGroup>
              {input}
              {isDisabled && (
                <Tooltip
                  borderRadius={'md'}
                  label={disabledTooltip}
                  aria-label={disabledTooltip}
                >
                  <InputRightElement>
                    <Icon as={FiLock} />
                  </InputRightElement>
                </Tooltip>
              )}
            </InputGroup>
          ) : (
            input
          )}
          {description ? <FormInputNote>{description}</FormInputNote> : null}
          <FormErrorMessage>
            <Text>{error?.message}</Text>
          </FormErrorMessage>
        </FormControl>
      </Box>
    );
  },
);
