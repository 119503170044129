import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { InfoIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { brandSecondaryColor } from '@tradeaze/frontend/ui-web';

interface SignUpInput {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
}

const SignUpForm: React.FC<{
  handleSignUp: ({
    email,
    username,
    password,
    confirmPassword,
  }: {
    email: string;
    username: string;
    password: string;
    confirmPassword: string;
  }) => void;
  authLoading: boolean;
}> = ({ handleSignUp, authLoading }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SignUpInput>();

  const [show, setShow] = useState(false);
  const handleToggleShow = () => setShow(!show);

  return (
    <form onSubmit={handleSubmit(handleSignUp)}>
      <FormLabel>Email</FormLabel>
      <Input
        id="email"
        placeholder="Email"
        type="email"
        {...register('email', {
          required: 'This is required',
          pattern: { value: /^\S+@\S+$/i, message: 'Must be email format' },
        })}
      />
      {/* USERNAME */}
      <FormControl my={4} isInvalid={Boolean(errors.username)}>
        <FormLabel htmlFor="username">
          Username
          <Tooltip
            bg="white"
            borderRadius={6}
            color="black"
            label="Username is case sensitive with no spaces allowed."
          >
            <InfoIcon ml={2} color={brandSecondaryColor} />
          </Tooltip>
        </FormLabel>
        <Input
          id="username"
          placeholder="Username"
          autoComplete="off"
          {...register('username', {
            required: 'This is required',
            onChange(event) {
              event.target.value = event.target.value.replace(/\s/g, '');
            },
            pattern: { value: /^\S+$/, message: 'No spaces allowed' },
          })}
        />
        <FormErrorMessage>
          <span>{errors.username?.message}</span>
        </FormErrorMessage>
      </FormControl>
      {/* PASSWORD */}
      <FormControl my={4} isInvalid={Boolean(errors.password)}>
        <FormLabel htmlFor="password">
          Password
          <Tooltip
            bg="white"
            borderRadius={6}
            color="black"
            label="Password must be at least 6 characters long and contain at least one uppercase."
          >
            <InfoIcon ml={2} color={brandSecondaryColor} />
          </Tooltip>
        </FormLabel>
        <InputGroup>
          <Input
            id="password"
            autoComplete="new-password"
            type={show ? 'text' : 'password'}
            placeholder="Password"
            {...register('password', {
              required: 'This is required',
              minLength: { value: 6, message: 'Minimum length should be 6' },
            })}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleToggleShow}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          <span>{errors.password?.message}</span>
        </FormErrorMessage>
      </FormControl>
      <Button
        colorScheme="yellow"
        isLoading={isSubmitting || authLoading}
        type="submit"
        w="100%"
        my={3}
      >
        Sign up
      </Button>
      <Center>
        <Text fontSize={14}>
          Already got an account?
          <Text as={Link} ml={1} color="yellow.600" to="/signin">
            Sign in
          </Text>
        </Text>
      </Center>
    </form>
  );
};

export default SignUpForm;
