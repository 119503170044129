import { Company } from '@tradeaze-packages/schemas';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Text, Flex } from '@chakra-ui/react';
import { useDeleteMerchantCompany } from '@tradeaze/frontend/hooks';
import { ButtonWithConfirmation } from '../shared';

export const MerchantCustomerCard: React.FunctionComponent<{
  customer: Company;
  merchantId: string;
}> = ({ merchantId, customer }) => {
  const navigate = useNavigate();

  const { mutate: deleteMerchantCustomer, isLoading } =
    useDeleteMerchantCompany({
      invalidateQueryKeys: [['getCompaniesByMerchant', merchantId]],
    });

  const deleteCustomer = (companyId: string) => {
    deleteMerchantCustomer({ merchantId, companyId });
  };

  const handleCardClick = () => {
    navigate(`/company/${customer.companyId}`);
  };

  return (
    <Box
      border={'1px'}
      borderRadius={'lg'}
      borderColor={'gray.300'}
      p={5}
      cursor="pointer"
      onClick={(event) => {
        handleCardClick();
      }}
      _hover={{
        borderColor: 'black',
      }}
      transition={'all 0.1s ease-in-out'}
      marginBottom={2}
    >
      <Stack spacing={2}>
        <Flex
          direction={['column', 'row']}
          alignItems={['left', 'center']}
          justifyContent="space-between"
        >
          <Text>{customer.companyName}</Text>
          <ButtonWithConfirmation
            alertTitle="Delete Customer"
            alertDescription="Are you sure you want to delete this customer?"
            onConfirm={() => deleteCustomer(customer.companyId)}
            colorScheme="red"
            size="xs"
            variant="outline"
            isLoading={isLoading}
            my={[4, 0]}
          >
            Delete
          </ButtonWithConfirmation>
        </Flex>
      </Stack>
    </Box>
  );
};

export default MerchantCustomerCard;
