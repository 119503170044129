import { HStack, Icon, Tag, Text } from '@chakra-ui/react';
import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { FaUserCircle } from 'react-icons/fa';
import { RiderAvatar } from '../../../../rider';
import { DotIcon } from '../../../../shared';
import { DeliveryVehicleIcon } from '../../../delivery-vehicle';

export const CardDriverInfo: React.FC<{
  driver: {
    firstName: string;
    lastName?: string | null;
    vehicleId?: DeliveryVehicleId | null;
  } | null;
  showVehicle?: boolean;
  isGigJob?: boolean;
  showGigJobTag: boolean;
}> = ({ driver, showVehicle, isGigJob, showGigJobTag }) => {
  if (!driver) {
    return (
      <HStack>
        <Icon as={FaUserCircle} fontSize={24} color={'blackAlpha.500'}></Icon>
        <Text>Unassigned</Text>
      </HStack>
    );
  }

  return (
    <HStack color={'blackAlpha.800'} >
      <RiderAvatar
        size={'xs'}
        firstName={driver.firstName}
        lastName={driver.lastName}
      />
      <Text>{driver.firstName}</Text>
      {showVehicle && driver.vehicleId ? (
        <>
          <DotIcon />
          <DeliveryVehicleIcon
            fontSize={'1em'}
            deliveryVehicle={driver.vehicleId}
          />
        </>
      ) : null}
      {showGigJobTag && isGigJob && (
        <Tag colorScheme="yellow" size="sm" fontWeight={'bold'} mx={2}>
          GIG
        </Tag>
      )}
    </HStack>
  );
};
