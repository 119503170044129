import React from 'react';
import { DeliveryVehicleId, DeliveryStatus } from '@tradeaze-packages/schemas';
import { HStack, Text } from '@chakra-ui/react';
import { TbCrane } from 'react-icons/tb';
import { CgNotes } from 'react-icons/cg';
import { formatDate } from '@tradeaze/shared/utils';
import startCase from 'lodash.startcase';
import { DotIcon } from '../../../../shared';
import { DeliveryVehicleIcon } from '../../../delivery-vehicle';

export const ListCardInfo: React.FC<{
  deliveryStatus: DeliveryStatus;
  deliveryVehicleId: DeliveryVehicleId;
  createdAt: string;
  hasNotes: boolean;
  isHeavyside: boolean;
  merchantName: string;
  showMerchantName?: boolean;
  companyName?: string;
}> = ({
  createdAt,
  deliveryVehicleId,
  hasNotes,
  isHeavyside,
  deliveryStatus,
  merchantName,
  showMerchantName,
  companyName
}) => {
  const formattedDeliveryStatus = startCase(deliveryStatus.toLocaleLowerCase());

  return (
    <HStack
      flexDirection={'row'}
      justifyContent={'space-between'}
      width={'100%'}
      paddingX={3}
      paddingY={1}
      color={'blackAlpha.800'}
      fontSize={'0.9em'}
    >
      <HStack>
        <DeliveryVehicleIcon deliveryVehicle={deliveryVehicleId} />
        <DotIcon fontSize={8} color={'blackAlpha.500'} />
        <Text>{formattedDeliveryStatus}</Text>
        <DotIcon fontSize={8} color={'blackAlpha.500'} />
        {showMerchantName ? (
          <>
            <Text>{merchantName}</Text>
            <DotIcon fontSize={8} color={'blackAlpha.500'} />
          </>
        ) : null}
        {companyName ? (
          <>
            <Text>For {companyName}</Text>
            <DotIcon fontSize={8} color={'blackAlpha.500'} />
          </>
        ) : null}
        <Text>Created {formatDate(new Date(createdAt))}</Text>
      </HStack>
      <HStack>
        {isHeavyside ? <TbCrane /> : null}
        {hasNotes ? <CgNotes /> : null}
      </HStack>
    </HStack>
  );
};
