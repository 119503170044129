import React from 'react';
import { Box } from '@chakra-ui/react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { DeliveryPreviews } from './DeliveryPreviews';
import { PageLoading } from '../layout/PageLoading';
import { PageError } from '../layout/PageError';
import { EmptyDeliveriesPage } from './EmptyDeliveriesPage';

export const DeliveryQueryPage = (props: {
  children?: React.ReactNode;
  deliveries: HydratedDelivery[];
  isAdmin: boolean;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  isError: boolean;
  columns?: number[];
  selectedDelivery?: HydratedDelivery;
  view: 'grid' | 'list';
  hoveredDeliveryId?: string;
  isFiltered: boolean;
  fetchNextPage: () => void;
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onHoverDelivery?: (deliveryId: string) => void;
  onHoverLeaveDelivery?: () => void;
  onPressGoTo: (orderId: string) => void;
}) => (
  <Box>
    {props.deliveries?.length && props.deliveries.length > 0 ? (
      <DeliveryPreviews
        view={props.view}
        deliveries={props.deliveries}
        isAdmin={props.isAdmin}
        onWaypointEnter={props.fetchNextPage}
        hasNextPage={props.hasNextPage}
        isFetchingNextPage={props.isFetchingNextPage}
        columns={props.columns}
        selectedDelivery={props.selectedDelivery}
        onSelectDelivery={props.onSelectDelivery}
        hoveredDeliveryId={props.hoveredDeliveryId}
        onHoverDelivery={props.onHoverDelivery}
        onHoverLeaveDelivery={props.onHoverLeaveDelivery}
        onPressGoTo={props.onPressGoTo}
      />
    ) : props.isLoading ? (
      <PageLoading />
    ) : props.isError ? (
      <PageError />
    ) : (
      <EmptyDeliveriesPage isFiltered={props.isFiltered} />
    )}
  </Box>
);
