import { z } from 'zod';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';
import { PositionSchema } from './shared-schema';

export const FuelTypeSchema = z.enum([
  'PETROL',
  'DIESEL',
  'ELECTRIC',
  'HYBRID',
]);

export const FleetVehicleStatusSchema = z.enum([
  'PENDING',
  'APPROVED',
  'REJECTED',
  'DELETED',
]);

const nonEmptyString = (message: string) => z.string().min(1, { message });

export const FleetVehicleSchema = z.object({
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
  deletedAt: z.string().nullish(),
  fleetVehicleId: z.string(),
  ownerId: z.string().nullish(),
  make: nonEmptyString('Make is required'),
  model: nonEmptyString('Model is required'),
  year: nonEmptyString('Year is required'),
  regNumber: nonEmptyString('Registration number is required'),
  fuelType: FuelTypeSchema,
  deliveryVehicleId: DeliveryVehicleIdSchema,
  weightCapacity: z.number({
    invalid_type_error: 'Weight is required',
  }),
  lengthCapacity: z.number({
    invalid_type_error: 'Length is required',
  }),
  heightCapacity: z.number({
    invalid_type_error: 'Height is required',
  }),
  widthCapacity: z.number({
    invalid_type_error: 'Width is required',
  }),
  status: FleetVehicleStatusSchema,
  rejectedAt: z.string().nullish(),
  approvedAt: z.string().nullish(),
  rejectedReason: z.string().nullish(),
});

export const CreateFleetVehicleSchema = FleetVehicleSchema.omit({
  fleetVehicleId: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
}).extend({
  status: FleetVehicleStatusSchema.optional(),
});

export const UpdateFleetVehicleSchema = FleetVehicleSchema.omit({
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
}).extend({
  status: FleetVehicleStatusSchema.optional(),
});

export const UpdateFleetVehicleRequestBodySchema = FleetVehicleSchema.omit({
  fleetVehicleId: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
}).extend({
  status: FleetVehicleStatusSchema.optional(),
});

export const FleetVehicleWithPositionSchema = FleetVehicleSchema.extend({
  riderId: z.string(),
  position: PositionSchema,
});

export const ActiveFleetVehicleSchema = FleetVehicleSchema.extend({
  riderId: z.string(),
});

export type FuelType = z.infer<typeof FuelTypeSchema>;
export type FleetVehicle = z.infer<typeof FleetVehicleSchema>;
export type FleetVehicleWithPosition = z.infer<
  typeof FleetVehicleWithPositionSchema
>;
export type CreateFleetVehicle = z.infer<typeof CreateFleetVehicleSchema>;
export type UpdateFleetVehicle = z.infer<typeof UpdateFleetVehicleSchema>;
export type FleetVehicleStatus = z.infer<typeof FleetVehicleStatusSchema>;
