import { Box, Text } from '@chakra-ui/react';
import { formatTimeRange } from '@tradeaze/shared/utils';

export const CardDeliveryWindow: React.FC<{
  deliveryWindowStart: string;
  deliveryWindowEnd: string;
}> = ({ deliveryWindowStart, deliveryWindowEnd }) => {
  return (
    <Box>
      <Text color={'blackAlpha.800'} fontSize={'0.9em'}>
        Delivery Window
      </Text>
      <Text fontWeight={600}>
        {formatTimeRange({
          from: deliveryWindowStart,
          to: deliveryWindowEnd,
        })}
      </Text>
    </Box>
  );
};
