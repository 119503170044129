import {
  Box,
  Button,
  HStack,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiFillFileImage,
} from 'react-icons/ai';
import { captureEvent } from '@tradeaze/frontend/utils';
import posthog from 'posthog-js';
import { UploadProof } from './UploadProof';
import {
  useInvalidateQueries,
  getRemoteProofImagesQueryKey,
  getRemoteProofSignatureQueryKey,
  useGetRemoteProofImages,
  useGetRemoteProofSignatures,
} from '@tradeaze/frontend/hooks';
import { ButtonWithConfirmation } from '../../shared';
import { CloseIcon } from '@chakra-ui/icons';
import { Storage } from 'aws-amplify';

export const ProofOfDelivery: React.FC<
  React.ComponentProps<typeof Box> & {
    orderId: string;
    stopId?: string;
    bucket: string;
    showUpload?: boolean;
    alwaysShow?: boolean;
    allowDelete?: boolean;
    podLeftAlign?: boolean;
  }
> = ({
  orderId,
  stopId,
  bucket,
  alwaysShow,
  showUpload,
  allowDelete,
  podLeftAlign,
  ...props
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const podWidth = isMobile ? '100%' : podLeftAlign ? '50%' : '100%';
  const invalidateQueries = useInvalidateQueries();
  const imagesQuery = useGetRemoteProofImages(bucket, orderId, stopId);
  const signaturesQuery = useGetRemoteProofSignatures(bucket, orderId, stopId);

  const onUploadComplete = () => {
    invalidateQueries([
      getRemoteProofImagesQueryKey(orderId),
      getRemoteProofSignatureQueryKey(orderId),
    ]);
  };

  const handleDeleteImage = async (key: string) => {
    await Storage.remove(key, {
      bucket,
      level: 'public',
    });
    invalidateQueries([
      getRemoteProofImagesQueryKey(orderId),
      getRemoteProofSignatureQueryKey(orderId),
    ]);
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const allAttachments = useMemo<
    {
      key: string;
      uri: string;
    }[]
  >(() => {
    return [...(imagesQuery.data ?? []), ...(signaturesQuery.data ?? [])];
  }, [imagesQuery.data, signaturesQuery.data]);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleViewProof = () => {
    openImageViewer(0);
    if (posthog) {
      captureEvent(posthog)('view_proof_of_delivery', { orderId, stopId });
    }
  };

  return (
    <>
      <Box {...props}>
        {alwaysShow ? (
          <HStack flexWrap={'wrap'} spacing={4}>
            {allAttachments.map((a, index) => (
              <Box key={a.key} position={'relative'}>
                {allowDelete ? (
                  <ButtonWithConfirmation
                    alertTitle="Delete Image"
                    alertDescription="Are you sure you want to delete this image?"
                    onConfirm={() => handleDeleteImage(a.key)}
                    position={'absolute'}
                    right={-2}
                    top={-2}
                    colorScheme="red"
                    size={'xs'}
                    padding={0}
                  >
                    <CloseIcon fontSize={9} />
                  </ButtonWithConfirmation>
                ) : null}
                <Image
                  src={a.uri}
                  cursor={'pointer'}
                  border={'1px solid'}
                  borderColor={'blackAlpha.500'}
                  borderRadius={'md'}
                  boxSize={'120px'}
                  fit={'cover'}
                  alt={`Proof of delivery ${index + 1}`}
                  onClick={() => openImageViewer(index)}
                />
              </Box>
            ))}{' '}
          </HStack>
        ) : (
          allAttachments &&
          allAttachments.length > 0 && (
            <Stack>
              <Button onClick={handleViewProof}>
                <Text mr={2}>View Attachments</Text>
                <AiFillFileImage fontSize={20} />
              </Button>
            </Stack>
          )
        )}
        {showUpload && stopId ? (
          <Box mt={3}>
            <UploadProof
              orderId={orderId}
              stopId={stopId}
              bucket={bucket}
              onUploadComplete={onUploadComplete}
            />
          </Box>
        ) : null}
      </Box>
      {isViewerOpen && allAttachments?.length ? (
        <ImageViewer
          src={allAttachments.map((a) => a.uri)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={true}
          backgroundStyle={{
            backgroundColor: '#ffffffa6',
            paddingTop: '150px',
            paddingBottom: '60px',
            width: podWidth,
          }}
          closeOnClickOutside={true}
          leftArrowComponent={<AiFillCaretLeft fontSize={42} color={'black'} />}
          rightArrowComponent={
            <AiFillCaretRight fontSize={42} color={'black'} />
          }
        />
      ) : null}
    </>
  );
};
