import { useQuery } from '@tanstack/react-query';
import { GetOrderByIdResponse } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

export const getOrderQueryKey = (orderId?: string) => {
  const key = ['getOrder']
  if (orderId) {
    key.push(orderId)
  }
  return key;
};

export const useGetOrder = (orderId: string | undefined) => {
  return useQuery<GetOrderByIdResponse>(
    getOrderQueryKey(orderId),
    async () => {
      if (!orderId) {
        return;
      }
      const apiName = 'OrderApi';
      const path = '/getOrderById/' + orderId;
      const options = {};
      return await API.get(apiName, path, options);
    },
    {
      enabled: !!orderId,
    }
  );
};
