import {
  FleetVehicleStatus,
  GetFleetVehiclesQuery,
} from '@tradeaze-packages/schemas';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type FleetVehicleFiltersStore = {
  filters: GetFleetVehiclesQuery;
  handleClearVehicleStatuses: () => void;
  handleChangeStatus: (verification: FleetVehicleStatus | undefined) => void;
  handleChangeOwnerName: (ownerName: string) => void;
  handleChangeRegNumber: (regNumber: string) => void;
  handleToggleIncludeDeleted: () => void;
};

export const useFleetVehicleFiltersStore = create<FleetVehicleFiltersStore>()(
  persist(
    (set) => ({
      filters: {
        status: undefined,
        ownerName: undefined,
        includeDeleted: false,
        regNumber: undefined,
      },
      handleClearVehicleStatuses: () =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, status: undefined },
        })),
      handleChangeStatus: (status: FleetVehicleStatus | undefined) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, status },
        })),
      handleChangeOwnerName: (ownerName: string) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, ownerName },
        })),
      handleChangeRegNumber: (regNumber: string) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, regNumber },
        })),
      handleToggleIncludeDeleted: () =>
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            includeDeleted: !state.filters.includeDeleted,
          },
        })),
    }),
    {
      name: 'fleet-vehicle-filters-store',
      getStorage: () => sessionStorage,
    },
  ),
);
