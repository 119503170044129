import { AppName } from '@tradeaze-packages/schemas';
import { getCurrentAppVersion } from '@tradeaze/frontend/versioning';
import { useEffect, useState } from 'react';
import { useGetLatestAppVersion } from './useGetLatestAppVersion';

export const useCheckAppVersion = (appName: Exclude<AppName, 'rider-app'>) => {
  const [isLatest, setIsLatest] = useState<boolean>();

  const currentVersion = getCurrentAppVersion(appName);

  const { data: latest, isLoading, isError } = useGetLatestAppVersion(appName);

  const latestVersion = latest?.version;

  useEffect(() => {
    if (!latestVersion) {
      return;
    }

    if (currentVersion !== latestVersion) {
      setIsLatest(false);
    }
  }, [currentVersion, latestVersion]);

  return {
    isLatest,
    isLoading,
    isError,
  };
};
