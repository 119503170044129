import { z } from 'zod';
import {
  ClockInWorkTypeSchema,
  DeliveryVehicleIdSchema,
  OptimisedRouteResultSchema,
} from '../../../models';

export const OptimiseRoutesBodySchema = z.object({
  startTime: z.string(),
  lockAssignedDeliveries: z.boolean(),
  includeAllConfirmed: z.boolean(),
  deliveryId: z.string().optional(),
  excludedRiderIds: z.array(z.string()).optional(),
  includedRiderIds: z.array(z.string()).optional(),
  workType: ClockInWorkTypeSchema.optional(),
  /**
   * Allowed vehicles for the order being assigned - not used if orderId is not provided
   */
  allowedVehicleTypes: z.array(DeliveryVehicleIdSchema).optional(),
});

export const OptimiseRoutesResponseSchema = OptimisedRouteResultSchema;

export type OptimiseRoutesBody = z.infer<typeof OptimiseRoutesBodySchema>;

export type OptimiseRoutesResponse = z.infer<
  typeof OptimiseRoutesResponseSchema
>;
