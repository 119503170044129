import { z } from 'zod';
import { HydratedOrderSchema, OrdersQueryParamsSchema } from '../../../models';

export const GetAdminOrdersQuerySchema = OrdersQueryParamsSchema;

export const GetAdminOrdersResponseSchema = z.object({
  orders: z.array(HydratedOrderSchema),
  nextPageIndex: z.number().nullable(),
});

export type GetAdminOrdersQuery = z.infer<typeof GetAdminOrdersQuerySchema>;

export type GetAdminOrdersResponse = z.infer<
  typeof GetAdminOrdersResponseSchema
>;
