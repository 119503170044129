import { DeliveryItem } from '@tradeaze-packages/schemas';
import { convertToMetres } from './convertToMetres';

export const getItemDimensionString = (item: DeliveryItem) => {
  const dimensionsInMetres = {
    length: convertToMetres(item.length, item.lengthUnit),
    width: convertToMetres(item.width, item.widthUnit),
    height: convertToMetres(item.height, item.heightUnit),
  };

  return `L ${dimensionsInMetres.length}m x W ${dimensionsInMetres.width}m x  H ${dimensionsInMetres.height}m`;
};

export const getAllItemDimensionsString = (deliveryItems: DeliveryItem[]) => {
  if (!deliveryItems || !deliveryItems.length) return 'N/A';

  const numberOfItems = deliveryItems.length;

  const largestItem = deliveryItems.reduce((prev, current) =>
    prev.height * prev.width * prev.length >
    current.height * current.width * current.length
      ? prev
      : current
  );

  const dimensionsInMetres = {
    length: convertToMetres(largestItem.length, largestItem.lengthUnit),
    width: convertToMetres(largestItem.width, largestItem.widthUnit),
    height: convertToMetres(largestItem.height, largestItem.heightUnit),
  };

  return `L ${dimensionsInMetres.length}m x W ${
    dimensionsInMetres.width
  }m x  H ${dimensionsInMetres.height}m${
    numberOfItems > 1 ? ` (+ ${numberOfItems - 1})` : ''
  }`;
};
