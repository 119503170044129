import { EtaStatus, DeliveryStatus } from '@tradeaze-packages/schemas';

export type ProgressColorScheme = 'green' | 'blue' | 'yellow';

export const getProgressColorScheme = ({
  deliveryStatus,
  etaStatus,
  isToday,
}: {
  deliveryStatus: DeliveryStatus;
  etaStatus?: EtaStatus | null;
  isToday: boolean;
}): ProgressColorScheme => {
  switch (etaStatus) {
    case 'PICKUP_COMPLETE':
    case 'DROPOFF_ARRIVED':
    case 'DROPOFF_ENROUTE':
    case 'DROPOFF_COMPLETE':
    case 'DROPOFF_FAILED':
      return 'green';
    case 'PICKUP_ARRIVED':
    case 'PICKUP_ENROUTE':
    case 'PICKUP_FAILED':
      return 'blue';
    case 'ASSIGNED': {
      if (isToday) {
        return 'blue';
      }
      return 'yellow';
    }
    default: {
      switch (deliveryStatus) {
        case 'PENDING':
        case 'CONFIRMED':
          return 'yellow';
        case 'DELIVERED':
          return 'green';
        default:
          return 'yellow';
      }
    }
  }
};
