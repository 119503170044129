import { Box, HStack, Text } from '@chakra-ui/react';
import { OrderType } from '@tradeaze-packages/schemas';
import { CgNotes } from 'react-icons/cg';
import { TbCrane } from 'react-icons/tb';
import { DotIcon } from '../../../../shared';
import startCase from 'lodash.startcase';

export const GridCardOrderReference: React.FC<{
  merchantName: string;
  orderReference: string;
  orderType: OrderType;
  hasNotes?: boolean;
  isHeavyside?: boolean;
  showMerchantName?: boolean;
  showNotesPreview?: boolean;
  notesPreview?: string | null;
}> = ({
  orderReference,
  merchantName,
  orderType,
  hasNotes,
  isHeavyside,
  showMerchantName,
  showNotesPreview,
  notesPreview,
}) => {
  return (
    <HStack justifyContent={'space-between'} fontSize={'0.9em'}>
      <Box>
        {showMerchantName ? <Text fontWeight={600}>{merchantName}</Text> : null}
        <HStack>
          <Text>
            {startCase(orderType.toLowerCase())}
          </Text>
          <DotIcon/>
        <Text noOfLines={1} color={'blackAlpha.800'}>
          {orderReference}
        </Text>
        </HStack>
        {showNotesPreview ? (
          <Text noOfLines={1} color="blackAlpha.800">
            {notesPreview}
          </Text>
        ) : null}
      </Box>
      <HStack>
        {hasNotes ? <CgNotes /> : null}
        {isHeavyside ? <TbCrane /> : null}
      </HStack>
    </HStack>
  );
};
