import {
  FormLabel,
  Radio,
  RadioGroup,
  Text,
  Stack,
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  InputGroup,
  Input,
  InputRightElement,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import {
  CreateOrder,
  DimensionUnit,
  MAX_LOAD_HEIGHT,
  MAX_LOAD_LENGTH,
  MAX_LOAD_WEIGHT,
  MAX_LOAD_WIDTH,
} from '@tradeaze-packages/schemas';
import {
  UseFormRegister,
  useFormContext,
  Controller,
  Control,
} from 'react-hook-form';
import { useState } from 'react';
import { unitConverter } from './deliveryOptionsUtils';
import { FormAttribute, FormInputNote } from '../../../form';

type ItemLoadSectionProps = {
  setIsPrimaryView: React.Dispatch<React.SetStateAction<boolean>>;
};

const measurmentUnits = [
  {
    label: 'millimetres',
    value: 'mm',
  },
  {
    label: 'centimetres',
    value: 'cm',
  },
  {
    label: 'metres',
    value: 'm',
  },
];

const getMaxValue = (
  unit: DimensionUnit,
  type: 'length' | 'width' | 'height'
) => {
  const maxValues = {
    m: {
      length: MAX_LOAD_LENGTH / 1000,
      width: MAX_LOAD_WIDTH / 1000,
      height: MAX_LOAD_HEIGHT / 1000,
    },
    cm: {
      length: MAX_LOAD_LENGTH / 10,
      width: MAX_LOAD_WIDTH / 10,
      height: MAX_LOAD_HEIGHT / 10,
    },
    mm: {
      length: MAX_LOAD_LENGTH,
      width: MAX_LOAD_WIDTH,
      height: MAX_LOAD_HEIGHT,
    },
  };
  return maxValues[unit][type];
};

const getStepByUnit = (unit: DimensionUnit) => {
  switch (unit) {
    case 'mm':
      return 10;
    case 'cm':
      return 1;
    case 'm':
      return 0.01;
  }
};

const DimensionInput = ({
  id,
  label,
  unit,
  max,
  onFocus,
  control,
  inputNote,
  width = "auto", // Default width
}: {
  id: Parameters<UseFormRegister<CreateOrder>>[0];
  label: string;
  unit: DimensionUnit;
  max: number;
  onFocus?: () => void;
  control: Control<CreateOrder>;
  inputNote?: string;
  width?: string[] | string | number;
}) => (
  <Box width={width}>
    <Controller
      name={id}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl isRequired isInvalid={Boolean(error)}>
          <Box mb={3}>
            <FormLabel htmlFor={id}>{label}</FormLabel>
            <InputGroup>
              <Input
                {...field}
                type="number"
                step={getStepByUnit(unit)}
                onWheel={(event) => event.currentTarget.blur()}
                onChange={(e) => {
                  field.onChange(parseFloat(e.target.value));
                }}
                onFocus={onFocus}
              />
              <InputRightElement
                pointerEvents="none"
                color="blackAlpha.600"
                fontSize="1.2em"
                children={unit}
              />
            </InputGroup>
            <FormInputNote>{inputNote}</FormInputNote>
          </Box>
          <Slider
            min={0}
            max={max}
            step={getStepByUnit(unit)}
            onChange={(val) => {
              onFocus?.();
              field.onChange(val);
            }}
            value={field.value ?? 0}
            focusThumbOnChange={false}
          >
            <SliderTrack>
              <SliderFilledTrack bg="yellow.600" />
            </SliderTrack>
            <SliderThumb bg="yellow.600" height={'18px'} width={'18px'} />
          </Slider>
          <FormErrorMessage>
            <Text>{error?.message}</Text>
          </FormErrorMessage>
        </FormControl>
      )}
    />
  </Box>
);

export const ItemLoadSection = ({ setIsPrimaryView }: ItemLoadSectionProps) => {
  const {
    setValue,
    formState: { errors },
    register,
    getValues,
    control,
  } = useFormContext<CreateOrder>();

  const [measurementUnit, setMeasurementUnit] = useState<DimensionUnit>(
    getValues('deliveryItems.0.widthUnit') || 'mm'
  );

  const handleMeasurementChange = (currentUnit: DimensionUnit) => {
    const { length, width, height } = getValues('deliveryItems.0');
    const existingWidthUnit = getValues('deliveryItems.0.widthUnit');
    const existingLengthUnit = getValues('deliveryItems.0.lengthUnit');
    const existingHeightUnit = getValues('deliveryItems.0.heightUnit');

    const convertedWidth = unitConverter(width, existingWidthUnit, currentUnit);
    const convertedLength = unitConverter(
      length,
      existingLengthUnit,
      currentUnit
    );
    const convertedHeight = unitConverter(
      height,
      existingHeightUnit,
      currentUnit
    );

    setMeasurementUnit(currentUnit);

    setValue('deliveryItems.0.widthUnit', currentUnit);
    setValue('deliveryItems.0.lengthUnit', currentUnit);
    setValue('deliveryItems.0.heightUnit', currentUnit);
    setValue('deliveryItems.0.width', convertedWidth as unknown as number); // the form will be validated on submission but we want to allow setting undefined here
    setValue('deliveryItems.0.length', convertedLength as unknown as number); // the form will be validated on submission but we want to allow setting undefined here
    setValue('deliveryItems.0.height', convertedHeight as unknown as number); // the form will be validated on submission but we want to allow setting undefined here
  };

  return (
    <Stack spacing={4}>
      <Box>
        <FormLabel>Total Load Estimate</FormLabel>
        <Text color={'blackAlpha.600'}>
          Please provide an estimate of the total dimensions of the load when
          packed into the vehicle. We will then suggest a suitable vehicle for
          your needs.
        </Text>
      </Box>
      <RadioGroup
        onChange={(value: DimensionUnit) => handleMeasurementChange(value)}
        value={measurementUnit}
      >
        <Stack direction={['column', 'column', 'row']}>
          {measurmentUnits.map((unit) => (
            <Radio key={unit.value} value={unit.value}>
              {unit.label} ({unit.value})
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      <Stack
        spacing={6}
        direction={['column', 'column', 'row']}
        alignItems={['center', 'center', 'flex-start']}
      >
        <DimensionInput
          id="deliveryItems.0.length"
          label="Load Length"
          unit={measurementUnit}
          max={getMaxValue(measurementUnit, 'length')}
          onFocus={() => setIsPrimaryView(true)}
          control={control}
          inputNote="Longest Item"
          width={['100%', '100%', 'auto']} // Full width on smaller screens
        />
        <DimensionInput
          id="deliveryItems.0.width"
          label="Load Width"
          unit={measurementUnit}
          max={getMaxValue(measurementUnit, 'width')}
          onFocus={() => setIsPrimaryView(false)}
          control={control}
          inputNote="Estimate"
          width={['100%', '100%', 'auto']} // Full width on smaller screens
        />
        <DimensionInput
          id="deliveryItems.0.height"
          label="Load Height"
          unit={measurementUnit}
          max={getMaxValue(measurementUnit, 'height')}
          control={control}
          inputNote="Estimate"
          width={['100%', '100%', 'auto']} // Full width on smaller screens
        />
      </Stack>
      <FormAttribute
        id={'deliveryItems.0.weight'}
        inputType={'number'}
        unit={'kg'}
        label={'Total Weight Estimate'}
        isRequired={true}
        description={`The total weight of the load being sent (Max. ${MAX_LOAD_WEIGHT}kg)`}
        error={errors.deliveryItems?.[0]?.weight}
        {...register('deliveryItems.0.weight', {
          setValueAs: (value) => (value === '' ? undefined : parseFloat(value)),
        })}
      />
    </Stack>
  );
};
