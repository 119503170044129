import { z } from 'zod';
import parsePhoneNumberFromString from 'libphonenumber-js';

export const requireUkPhoneNumber = z
  .string()
  .refine(
    (value) => parsePhoneNumberFromString(value, 'GB')?.isValid() || false,
    {
      message: 'Invalid UK phone number',
    }
  );
