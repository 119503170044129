import { z } from 'zod';

export const EndUserDefinedProfileSchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  companyName: z.string().min(1, { message: 'Company name is required' }),
});

export const CreateEndUserProfileSchema = EndUserDefinedProfileSchema.extend({
  endUserId: z.string(),
  contactNumber: z.string().min(1, { message: 'Contact number is required' }),
  contactEmail: z.string().min(1, { message: 'Contact email is required' }),
});

export const EndUserProfileSchema = CreateEndUserProfileSchema.extend({
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
  deletedAt: z.string().nullish(),
  endUserId: z.string(),
  avatarUrl: z.string().nullish(),
  expoPushToken: z.string().nullish(),
  shouldReceiveNotifications: z.boolean().nullish(),
  shouldReceiveNotificationSound: z.boolean().nullish(),
  biometricsPublicKey: z.string().nullish(),
});

export const EndUserStringQueryParamsSchema = z
  .object({
    merchantId: z.string().optional(),
    limit: z
      .string()
      .optional()
      .transform((arg) => (arg ? Number(arg) : undefined)),
    startIndex: z
      .string()
      .optional()
      .transform((arg) => (arg ? Number(arg) : undefined)),
  })
  .optional();

export type CreateEndUserProfile = z.infer<typeof CreateEndUserProfileSchema>;
export type EndUserDefinedProfile = z.infer<typeof EndUserDefinedProfileSchema>;
export type EndUserProfile = z.infer<typeof EndUserProfileSchema>;
