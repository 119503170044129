import { z } from 'zod';
import {
  MAX_LOAD_HEIGHT,
  MAX_LOAD_LENGTH,
  MAX_LOAD_WEIGHT,
  MAX_LOAD_WIDTH,
} from '../constants';

export const DimensionUnitSchema = z.enum(['m', 'cm', 'mm']);
export const WeightUnitSchema = z.enum(['kg']);

export const DeliveryItemSchema = z.object({
  id: z.string(),
  externalId: z.string().nullish(),
  deliveryId: z.string(),
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
  name: z.string(),
  description: z.string().nullish(),
  price: z.number().gt(0, 'Value must be greater than 0'),
  weight: z
    .number()
    .gt(0, 'Value must be greater than 0')
    .lte(
      MAX_LOAD_WEIGHT,
      `Weight must be less than or equal to ${MAX_LOAD_WEIGHT}kg`
    ),
  height: z
    .number()
    .gt(0, 'Value must be greater than 0')
    .lte(MAX_LOAD_HEIGHT, `No vehicles can take above ${MAX_LOAD_HEIGHT}mm`),
  width: z
    .number()
    .gt(0, 'Value must be greater than 0')
    .lte(MAX_LOAD_WIDTH, `No vehicles can take above ${MAX_LOAD_WIDTH}mm`),
  length: z
    .number()
    .gt(0, 'Value must be greater than 0')
    .lte(MAX_LOAD_LENGTH, `No vehicles can take above ${MAX_LOAD_LENGTH}mm`),
  quantity: z.number(),
  widthUnit: DimensionUnitSchema,
  lengthUnit: DimensionUnitSchema,
  heightUnit: DimensionUnitSchema,
  weightUnit: WeightUnitSchema,
});

export const CreateDeliveryItemSchema = DeliveryItemSchema.omit({
  deliveryId: true,
}).extend({
  id: z.string().optional(),
});

export const UpdateDeliveryItemSchema = DeliveryItemSchema.omit({
  deliveryId: true,
});

export type DeliveryItem = z.infer<typeof DeliveryItemSchema>;
export type CreateDeliveryItem = z.infer<typeof CreateDeliveryItemSchema>;
export type UpdateDeliveryItem = z.infer<typeof UpdateDeliveryItemSchema>;
export type DimensionUnit = z.infer<typeof DimensionUnitSchema>;
export type WeightUnit = z.infer<typeof WeightUnitSchema>;
