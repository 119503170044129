import { useCallback, useMemo, useState } from 'react';
import { MapMarkerType } from '../Map';

export type MapMarkerFilters = {
  [type in MapMarkerType]: boolean;
};

export const useMarkerFilters = (
  initialFilters: MapMarkerFilters = {
    MERCHANT: false,
    RIDER: true,
    PICK_UP: true,
    DROP_OFF: true,
    DELIVERY: true,
    ADDRESS: true,
  }
) => {
  const [markerFilters, setMarkerFilters] =
    useState<MapMarkerFilters>(initialFilters);

  const handleMarkerFilterChange = useCallback((markerType: MapMarkerType) => {
    setMarkerFilters((prev) => ({
      ...prev,
      [markerType]: !prev[markerType],
    }));
  }, []);

  return useMemo(
    () => ({ markerFilters, handleMarkerFilterChange }),
    [markerFilters, handleMarkerFilterChange]
  );
};
