import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type AppSettings = {
  dashboardView: 'grid' | 'list';
  setDashboardView: (view: 'grid' | 'list') => void;
};

export const useAppSettingsStore = create<AppSettings>()(
  persist(
    (set) => ({
      dashboardView: 'list',
      setDashboardView: (view) => set({ dashboardView: view }),
    }),
    {
      name: 'app-settings',
      getStorage: () => localStorage,
    }
  )
);
