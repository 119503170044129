export function getMapBounds(
  positions: ({ lat: number; lng: number } | undefined | null)[]
):
  | {
      minLat: number;
      maxLat: number;
      minLng: number;
      maxLng: number;
    }
  | undefined {
  if (!positions.filter((el) => Boolean(el))?.length) {
    return undefined;
  }

  let minLat = positions[0]!.lat;
  let maxLat = positions[0]!.lat;
  let minLng = positions[0]!.lng;
  let maxLng = positions[0]!.lng;

  for (const position of positions) {
    minLat = Math.min(minLat, position!.lat);
    maxLat = Math.max(maxLat, position!.lat);
    minLng = Math.min(minLng, position!.lng);
    maxLng = Math.max(maxLng, position!.lng);
  }

  return { minLat, maxLat, minLng, maxLng };
}
