import { z } from 'zod';
import { CompanySchema, MerchantDefinedCompanySchema } from '../../../models';

export const CreateMerchantCompanyBodySchema = MerchantDefinedCompanySchema;

export const CreateMerchantCompanyResponseSchema = CompanySchema;

export type CreateMerchantCompanyBody = z.infer<
  typeof CreateMerchantCompanyBodySchema
>;

export type CreateMerchantCompanyResponse = z.infer<
  typeof CreateMerchantCompanyResponseSchema
>;
