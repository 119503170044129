export const getIsEveryStopOverThreshold = (
  distancesFromCentre: {
    distance: number;
    duration: number;
  }[],
  maxDistance: number
) =>
  distancesFromCentre.every(
    (distanceFromCentre) => distanceFromCentre.distance > maxDistance
  );
