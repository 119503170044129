import { z } from 'zod';
import { ClockInWorkTypeSchema } from './rider-schema';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';

export const ClockInRecordSchema = z.object({
  id: z.string(),
  clockedInAt: z.string(),
  riderId: z.string().nullish(),
  clockedOutAt: z.string().nullish(),
  regNumber: z.string(),
  deliveryVehicleId: DeliveryVehicleIdSchema.nullish(),
  fleetVehicleId: z.string().nullish(),
  workType: ClockInWorkTypeSchema,
});

export type ClockInRecord = z.infer<typeof ClockInRecordSchema>;
