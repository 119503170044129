import { useInfiniteQuery } from '@tanstack/react-query';
import {
  GetAdminDeliveriesQuery,
} from '@tradeaze-packages/schemas';
import { getAdminDeliveries } from '@tradeaze-packages/api-client';

export const getAdminDeliveriesQueryKey = (filters?: GetAdminDeliveriesQuery) => {
  const key: (string | object)[] = ['getAdminDeliveries'];
  if (filters) {
    key.push(filters);
  }
  return key;
};

export const useGetAdminDeliveries = (filters: GetAdminDeliveriesQuery) => {
  return useInfiniteQuery(
    getAdminDeliveriesQueryKey(filters),
    async ({ pageParam }) =>
      getAdminDeliveries({
        ...filters,
        startIndex: pageParam?.startIndex,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.nextPageIndex) {
          return undefined;
        }
        return { startIndex: lastPage.nextPageIndex };
      },
      retry: false,
    }
  );
};

