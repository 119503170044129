import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  GetLatestAppVersionResponseSchema,
  CreateFeatureFlagSchema,
  CreateFeatureFlagResponseSchema,
  UpdateFeatureFlagSchema,
  UpdateFeatureFlagResponseSchema,
  UpdateMerchantFeatureFlagSchema,
  UpdateMerchantFeatureFlagResponseSchema,
  GetFeatureFlagsResponseSchema,
} from '../../config-service';
import { z } from 'zod';
import { AppNameSchema } from '../../../models';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/config';

/**
 * GET /getLatestAppVersion
 */
registry.registerPath({
  path: `${basePath}/getLatestAppVersion/{appName}`,
  description: 'Returns the latest app version.',
  method: 'get',
  operationId: 'getLatestAppVersion',
  request: {
    params: z.object({
      appName: AppNameSchema,
    }),
  },
  responses: {
    200: {
      description: 'Successfully retrieved app version.',
      content: {
        'application/json': {
          schema: GetLatestAppVersionResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /createFeatureFlag
 */
registry.registerPath({
  path: `${basePath}/createFeatureFlag`,
  description: 'create a feature flag.',
  method: 'post',
  operationId: 'createFeatureFlag',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateFeatureFlagSchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Feature Flag created successfully.',
      content: {
        'application/json': {
          schema: CreateFeatureFlagResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PUT /updateFeatureFlag/{featureFlagId}
 */
registry.registerPath({
  path: `${basePath}/updateFeatureFlag/{featureFlagId}`,
  description: 'update a feature flag.',
  method: 'put',
  operationId: 'updateFeatureFlag',
  request: {
    params: z.object({
      featureFlagId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateFeatureFlagSchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Feature Flag updated successfully.',
      content: {
        'application/json': {
          schema: UpdateFeatureFlagResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * DELETE /deleteFeatureFlag/{featureFlagId}
 */
registry.registerPath({
  path: `${basePath}/deleteFeatureFlag/{featureFlagId}`,
  description: 'update a feature flag.',
  method: 'delete',
  operationId: 'deleteFeatureFlag',
  request: {
    params: z.object({
      featureFlagId: z.string(),
    }),
  },
  responses: {
     200: {
      description: 'Feature Flag deleted successfully.',
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /featureFlag/{featureFlagId}/merchant/{merchantId}
 */
registry.registerPath({
  path: `${basePath}/featureFlag/{featureFlagId}/merchant/{merchantId}`,
  description: 'updated a merchant feature flag.',
  method: 'post',
  operationId: 'updateMerchantFeatureFlag',
  request: {
    params: z.object({
      featureFlagId: z.string(),
      merchantId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateMerchantFeatureFlagSchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Merchant Feature Flag updated successfully.',
      content: {
        'application/json': {
          schema: UpdateMerchantFeatureFlagResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getFeatureFlags
 */
registry.registerPath({
  path: `${basePath}/getFeatureFlags`,
  description: 'get list of feature flags.',
  method: 'get',
  operationId: 'getFeatureFlags',
  request: {
    query: z.object({
      merchantId: z.string().optional(),
      type: z.string().optional(),
    }),
  },
  responses: {
    200: {
      description: 'Merchant Feature Flags fetched successfully.',
      content: {
        'application/json': {
          schema: GetFeatureFlagsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const configApiRegistry = registry;