import { z } from 'zod';
import { GigJobSchema, CreateGigJobSchema } from '../../../models';

export const CreateGigJobBodySchema = CreateGigJobSchema;

export const CreateGigJobResponseSchema = GigJobSchema.extend({
  deliveryIds: z.array(z.string()),
});

export type CreateGigJobBody = z.infer<typeof CreateGigJobBodySchema>;

export type CreateGigJobResponse = z.infer<typeof CreateGigJobResponseSchema>;
