import { extendZodWithOpenApi } from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';

extendZodWithOpenApi(z);

export const PositionSchema = z
  .object({
    latitude: z.number(),
    longitude: z.number(),
  })
  .openapi('Position');

export type Position = z.infer<typeof PositionSchema>;
