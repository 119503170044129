import { z } from 'zod';
import { UpdateFleetVehicleRequestBodySchema, FleetVehicleSchema } from '../../../models';

export const UpdateFleetVehicleRequestSchema = UpdateFleetVehicleRequestBodySchema;

export const UpdateFleetVehicleBodySchema = FleetVehicleSchema;

export const UpdateFleetVehicleResponseSchema = FleetVehicleSchema;

export type UpdateFleetVehicleRequest = z.infer<
  typeof UpdateFleetVehicleRequestSchema
>;

export type UpdateFleetVehicleResponse = z.infer<
  typeof UpdateFleetVehicleResponseSchema
>;

export type UpdateFleetVehicleBody = z.infer<
  typeof UpdateFleetVehicleBodySchema
>;