import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  IconButton,
  Box,
  Text,
  HStack,
} from '@chakra-ui/react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import { CloseIcon } from '@chakra-ui/icons';
import React from 'react';
import { CreateMerchantProfile } from '@tradeaze-packages/schemas';
import { v4 as uuid } from 'uuid';

export const ContactEmailsFormSection: React.FC<{
  formMethods: UseFormReturn<CreateMerchantProfile>;
  isEditing?: boolean;
}> = ({ formMethods, isEditing }) => {
  const {
    control,
    register,
    formState: { errors },
  } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contactEmails',
  });

  const handleAddEmail = () => {
    append({
      contactEmailId: uuid(),
      isPrimary: false,
      email: '',
      enableNotifications: true,
    });
  };

  const handleRemoveEmail = (index: number) => {
    if (fields.length === 1) {
      return;
    }
    remove(index);
  };

  // TODO: toggle notifications enabled/disabled
  return (
    <Box>
      {fields.map((field, index) => {
        const canRemove = isEditing && fields.length > 1 && !field.isPrimary;

        const error = errors.contactEmails?.[index]?.email;

        return (
          <FormControl
            isInvalid={Boolean(error)}
            key={field.contactEmailId}
            mb={4}
          >
            {field.isPrimary ? (
              <Text
                color="blackAlpha.500"
                fontStyle={'italic'}
                fontSize={'xs'}
                px={2}
              >
                Primary Email
              </Text>
            ) : null}
            <HStack>
              <Input
                key={field.contactEmailId}
                isDisabled={!isEditing}
                {...register(`contactEmails.${index}.email`)}
              />
              {canRemove ? (
                <IconButton
                  aria-label="remove email"
                  icon={<CloseIcon fontSize={10} />}
                  size={'sm'}
                  colorScheme="red"
                  onClick={() => handleRemoveEmail(index)}
                />
              ) : null}
            </HStack>
            <FormErrorMessage>{error?.message}</FormErrorMessage>
          </FormControl>
        );
      })}
      {isEditing ? (
        <Button size={'sm'} variant={'outline'} onClick={handleAddEmail} mb={3}>
          Add Email
        </Button>
      ) : null}
    </Box>
  );
};
