import { z } from 'zod';
import { HydratedDeliverySchema, DeliveriesQueryParamsSchema } from '../../../models';

export const GetAdminDeliveriesQuerySchema = DeliveriesQueryParamsSchema;

export const GetAdminDeliveriesResponseSchema = z.object({
  deliveries: z.array(HydratedDeliverySchema),
  nextPageIndex: z.number().nullable(),
});

export type GetAdminDeliveriesQuery = z.infer<typeof GetAdminDeliveriesQuerySchema>;

export type GetAdminDeliveriesResponse = z.infer<
  typeof GetAdminDeliveriesResponseSchema
>;
