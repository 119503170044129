import { z } from 'zod';
import { DeliverySchema } from '../../../models';

export const CancelDeliveryRequestSchema = z.object({
  cancellationReason: z.string(),
});

export const CancelDeliveryResponseSchema = z.object({
  message: z.string(),
  data: DeliverySchema,
});

export type CancelDeliveryRequest = z.infer<typeof CancelDeliveryRequestSchema>;

export type CancelDeliveryResponse = z.infer<typeof CancelDeliveryResponseSchema>;
