import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DeliveryStatus } from '@tradeaze-packages/schemas';
import React from 'react';
import { DeliveryActionButton } from './DeliveryActionButton';
import { TradeazeLogoNoText } from '../../../brand';

type UpdateDeliveryStatusButtonProps = React.ComponentProps<typeof Button> & {
  onConfirm: () => void;
  deliveryStatus: DeliveryStatus;
  alertTitle: string;
  alertDescription: string;
  gigAlertDescription?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  icon: React.ReactNode;
  label: string;
  callback?: () => void;
  gigJobId?: string | null;
};

export const UpdateDeliveryStatusButton: React.FunctionComponent<
  UpdateDeliveryStatusButtonProps
> = ({
  onConfirm,
  icon,
  label,
  alertTitle,
  alertDescription,
  gigAlertDescription,
  confirmButtonText,
  cancelButtonText,
  callback,
  gigJobId,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpen();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
    callback?.();
  };

  return (
    <>
      <DeliveryActionButton
        onClick={handleOpen}
        icon={icon}
        label={label}
        {...props}
      />
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{alertTitle}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {gigJobId ? (
              <Box display={'flex'} flexDirection={'column'} gap={8}>
                <Box
                  textAlign={'center'}
                  backgroundColor={'#EEEEEF'}
                  borderRadius={'8px'}
                  padding={'16px 8px'}
                >
                  <Text>Gig Job Id: {gigJobId}</Text>
                </Box>
                <Text>{gigAlertDescription}</Text>
                <Text fontWeight={700}>{alertDescription}</Text>
              </Box>
            ) : (
              alertDescription
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {gigJobId ? cancelButtonText : 'No'}
            </Button>
            <Button
              onClick={handleConfirm}
              ml={3}
              variant={gigJobId ? 'outline' : undefined}
            >
              {gigJobId ? confirmButtonText : 'Yes'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export const getDeliveryStatusButtonProps = ({
  deliveryStatus,
  deliveryId,
  updateDeliveryStatus,
  isLoading,
  gigJobId,
}: {
  deliveryStatus: DeliveryStatus;
  deliveryId: string;
  updateDeliveryStatus: ({
    deliveryId,
    status,
  }: {
    deliveryId: string;
    status: Exclude<DeliveryStatus, 'CANCELLED'>;
  }) => void;
  isLoading: boolean;
  gigJobId?: string | null;
}): UpdateDeliveryStatusButtonProps[] => {
  const configs: UpdateDeliveryStatusButtonProps[] = [
    {
      alertDescription: 'Are you sure you want to confirm this delivery?',
      alertTitle: 'Confirm Delivery',
      icon: <Icon as={TradeazeLogoNoText} fontSize={'5xl'} />,
      label: 'Confirm',
      onConfirm: () =>
        updateDeliveryStatus({ deliveryId, status: 'CONFIRMED' }),
      colorScheme: 'yellow',
      deliveryStatus: 'CONFIRMED',
      isLoading,
    },
    {
      gigAlertDescription:
        'This delivery has a job attached. You’ll need to remember to cancel or amend the job separately.',
      alertDescription: 'Are you sure you want to reject this delivery?',
      alertTitle: 'Reject Delivery',
      confirmButtonText: 'Yes, reject delivery',
      cancelButtonText: 'No, back',
      icon: <Icon as={SmallCloseIcon} fontSize={'32'} />,
      label: 'Reject',
      onConfirm: () => updateDeliveryStatus({ deliveryId, status: 'REJECTED' }),
      colorScheme: 'red',
      deliveryStatus: 'REJECTED',
      isLoading,
      gigJobId,
    },
    {
      alertDescription: 'Are you sure you want to complete this delivery?',
      alertTitle: 'Complete Delivery',
      icon: <Icon as={CheckCircleIcon} fontSize={'20'} />,
      label: 'Complete',
      onConfirm: () => {
        updateDeliveryStatus({ deliveryId, status: 'DELIVERED' });
      },
      colorScheme: 'green',
      deliveryStatus: 'DELIVERED',
      isLoading,
    },
  ];

  return configs.filter((props) => props.deliveryStatus !== deliveryStatus);
};
