import { HydratedDelivery, Order } from '@tradeaze-packages/schemas';

export const buildNotesPreview = (
  order: Pick<Order, 'notes'>,
  delivery: {
    pickup: Pick<HydratedDelivery['pickup'], 'deliveryNotes'>;
    dropOff: Pick<HydratedDelivery['dropOff'], 'deliveryNotes'>;
    deliveryItems: Pick<
      HydratedDelivery['deliveryItems'][number],
      'description'
    >[];
  }
) => {
  return [
    order.notes,
    delivery.pickup.deliveryNotes,
    delivery.dropOff.deliveryNotes,
    ...delivery.deliveryItems.map((item) => item.description),
  ]
    .filter(Boolean)
    .join('; ');
};
