import { z } from 'zod';

export const EtaStatusSchema = z
  .enum([
    'CANCELLED',
    'NOT_ASSIGNED',
    'ASSIGNED',
    'PICKUP_ENROUTE',
    'PICKUP_ARRIVED',
    'PICKUP_COMPLETE',
    'PICKUP_FAILED',
    'DROPOFF_ENROUTE',
    'DROPOFF_ARRIVED',
    'DROPOFF_COMPLETE',
    'DROPOFF_FAILED',
  ])
  .openapi('DeliveryStatus');
  
export type EtaStatus = z.infer<typeof EtaStatusSchema>;