import { z } from 'zod';
import { UpdateOrderSchema } from '../../../models';

export const UpdateOrderBodySchema = UpdateOrderSchema;

export const UpdateOrderResponseSchema = z.literal('OK');

export type UpdateOrderBody = z.infer<typeof UpdateOrderBodySchema>;

export type UpdateOrderResponse = z.infer<typeof UpdateOrderResponseSchema>;
