import { zodResolver } from '@hookform/resolvers/zod';
import { HydratedGigJob } from '@tradeaze-packages/schemas';
import { useUpdateGigJob } from '@tradeaze/frontend/hooks';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const GigPaymentFormSchema = z.object({
  isPaid: z.boolean(),
  paymentAmount: z.number().min(0, 'Amount must be a positive number'),
});

export type GigPaymentFormSchemaType = z.infer<typeof GigPaymentFormSchema>;

type UseJobSummaryModal = {
  gigJob: HydratedGigJob;
  onClose: () => void;
};

export const useJobSummaryModal = ({ gigJob, onClose }: UseJobSummaryModal) => {
  const [isCancel, setIsCancel] = useState(false);

  const gigJobForm =
    useForm<GigPaymentFormSchemaType>({
      defaultValues: {
        isPaid: Boolean(gigJob.paidAt),
        paymentAmount: gigJob.paymentAmount,
      },
      resolver: zodResolver(GigPaymentFormSchema),
    });

  const updateGigJobMutation = useUpdateGigJob();

  const isPaid = gigJobForm.watch('isPaid');
  const paymentAmount = gigJobForm.watch('paymentAmount');

  const valuesHaveChanged =
    isPaid !== Boolean(gigJob.paidAt) ||
    paymentAmount !== gigJob.paymentAmount;

  const shouldDisplaySaveButton = valuesHaveChanged;

  const shouldDisplayCancelButton = gigJob.status !== 'CANCELLED' && !gigJob.paidAt;

  const onSubmit = (data: GigPaymentFormSchemaType) => {
    updateGigJobMutation.mutate({
      gigJobId: gigJob.gigJobId,
      body: {
        paymentAmount: data.paymentAmount,
        paymentCurrency: 'GBP',
        ...(data.isPaid && { paidAt: new Date().toISOString() }),
      },
    });
  };

  const handleCancel = () => {
    setIsCancel(!isCancel);
  };

  const handleModalClose = () => {
    setIsCancel(false);
    gigJobForm.reset();
    onClose();
  };

  return {
    updateGigJobMutation,
    isCancel,
    register: gigJobForm.register,
    handleSubmit: gigJobForm.handleSubmit,
    shouldDisplaySaveButton,
    shouldDisplayCancelButton,
    onSubmit,
    handleCancel,
    handleModalClose,
  };
};

