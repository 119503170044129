import { HydratedDelivery, Order } from '@tradeaze-packages/schemas';

export const getHasNotes = (
  order: Pick<Order, 'notes'>,
  delivery: {
    pickup: Pick<HydratedDelivery['pickup'], 'deliveryNotes'>;
    dropOff: Pick<HydratedDelivery['dropOff'], 'deliveryNotes'>;
  }
) =>
  Boolean(
    [delivery.pickup, delivery.dropOff].some((stop) =>
      Boolean(stop.deliveryNotes)
    ) || order.notes
  );
