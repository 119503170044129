import { z } from 'zod';
import {
  GetMerchantProfilesFiltersSchema,
  MerchantProfileSchema,
} from '../../../models';

export const GetMerchantProfilesQuerySchema = GetMerchantProfilesFiltersSchema;

export const GetMerchantProfileResponseSchema = MerchantProfileSchema;

export type GetMerchantProfilesQuery = z.infer<
  typeof GetMerchantProfilesQuerySchema
>;

export type GetMerchantProfileResponse = z.infer<typeof MerchantProfileSchema>;
