import React from 'react';
import { Button } from '@chakra-ui/react';
import {
  CreateDeliveryStop,
  CreateOrder,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { DeliveryStopFormSection } from './DeliveryStopFormSection';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';
import { v4 as uuid } from 'uuid';

export const AllDeliveryStopsFormSection: React.FC<{
  methods: UseFormReturn<CreateOrder>;
  isExistingOrder: boolean;
  isDuplicate: boolean;
  merchantAccount?: MerchantAccount;
  restrictedEditing?: boolean;
  removePickupMarker: RemoveMarkerFn;
  handleAddPickupMarker: AddMarkerFn;
  handleAddDeliveryMarker: AddMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
}> = ({
  isExistingOrder,
  isDuplicate,
  methods,
  merchantAccount,
  restrictedEditing,
  handleAddPickupMarker,
  removePickupMarker,
  handleAddDeliveryMarker,
  removeDeliveryMarker,
}) => {
  const { setValue, watch, getValues } = methods;

  const deliveryStops = watch('deliveryStops');

  const pickups = deliveryStops?.filter((ds) => ds.type === 'PICK_UP');
  const dropOffs = deliveryStops?.filter((ds) => ds.type === 'DELIVERY');

  const handleAddPickupStop = () => {
    const existingPickupStops = pickups || [];

    const existingDeliveryStops = dropOffs || [];

    const newPickupStop: CreateDeliveryStop = {
      deliveryStopId: uuid(),
      type: 'PICK_UP' as const,
      addressLine1: '',
      addressLine2: undefined,
      addressLine3: undefined,
      postCode: '',
      collectionReference: '',
      position: undefined,
      stopSequence: existingPickupStops.length,
      requiresImage: false,
      requiresSignature: false,
      contacts: [],
      windowStart: getValues('deliveryWindowStart'),
      windowEnd: getValues('deliveryWindowEnd'),
    };

    setValue('deliveryStops', [
      ...existingPickupStops,
      newPickupStop,
      ...existingDeliveryStops.map((stop) => ({
        ...stop,
        stopSequence: stop.stopSequence + 1,
      })),
    ]);
  };

  const removeDeliveryStop = (index: number) => {
    const deletedStopId = deliveryStops![index];

    // copy over the data from stops after the deleted stop
    const newDeliveryStops = deliveryStops!.slice(0, index);
    const stopsAfterDeleted = deliveryStops!.slice(index + 1);

    stopsAfterDeleted.forEach((stop) => {
      newDeliveryStops.push(stop);
    });

    removePickupMarker(deletedStopId.deliveryStopId);
    setValue('deliveryStops', newDeliveryStops);
  };

  const shouldAllowAddStop = !isExistingOrder;

  return (
    <>
      {pickups?.map((ds) => {
        const stopSequence = deliveryStops!.indexOf(ds);

        const shouldAllowRemove = stopSequence !== 0 && !isExistingOrder;

        return (
          <DeliveryStopFormSection
            key={`pickup-stop-${stopSequence}`}
            restrictedEditing={restrictedEditing}
            formMethods={methods}
            stopType={ds.type}
            stopSequence={stopSequence}
            merchantAccount={merchantAccount}
            showExpanded={isExistingOrder || isDuplicate}
            handleAddPositionMarker={handleAddPickupMarker}
            removePositionMarker={removePickupMarker}
            onRemove={
              shouldAllowRemove
                ? () => removeDeliveryStop(stopSequence)
                : undefined
            }
          />
        );
      })}
      {shouldAllowAddStop ? (
        <Button
          borderRadius={'lg'}
          variant={'outline'}
          onClick={() => handleAddPickupStop()}
          mt={-10}
        >
          Add another collection
        </Button>
      ) : null}
      {dropOffs?.map((ds) => {
        const stopSequence = deliveryStops!.indexOf(ds);
        return (
          <DeliveryStopFormSection
            key={`delivery-stop-${stopSequence}`}
            restrictedEditing={restrictedEditing}
            formMethods={methods}
            stopType={ds.type}
            stopSequence={stopSequence}
            merchantAccount={merchantAccount}
            isExistingOrder={isExistingOrder}
            showExpanded={isExistingOrder || isDuplicate}
            handleAddPositionMarker={handleAddDeliveryMarker}
            removePositionMarker={removeDeliveryMarker}
          />
        );
      })}
    </>
  );
};
