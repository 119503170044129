import { InfoIcon } from '@chakra-ui/icons';
import {
  Input,
  FormLabel,
  Button,
  FormControl,
  Stack,
  Tooltip,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { createAuthErrToast, useAuthStore } from '@tradeaze/frontend/ui-web';
import { useNavigate } from 'react-router-dom';

const ConfirmForgotPasswordForm: React.FC<{
  username: string;
}> = ({ username }) => {
  const navigate = useNavigate();

  const [confirmationCode, setConfirmationCode] = useState('');
  const [password, setPassword] = useState('');

  const { forgotPasswordSubmit } = useAuthStore();

  const [show, setShow] = useState(false);
  const handleToggleShow = () => setShow((prev) => !prev);

  const disableConfirmButton =
    !confirmationCode || !password || password.length < 6;

  const handleSubmit = async () => {
    if (!confirmationCode || !password) {
      return;
    }

    const onSuccess = () => {
      navigate('/signin', {
        state: {
          alert: {
            status: 'success',
            message: 'Password reset, you can now sign in',
          },
        },
      });
    };

    const onError = (error: unknown) => {
      createAuthErrToast(error);
    };

    forgotPasswordSubmit(
      username,
      confirmationCode,
      password,
      onSuccess,
      onError
    );
  };

  return (
    <Stack spacing={6}>
      <FormControl isRequired>
        <FormLabel>Confirmation Code</FormLabel>
        <Input
          autoComplete="one-time-code"
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>
          New Password
          <Tooltip
            bg="white"
            borderRadius={6}
            color="black"
            label="At least 6 characters long with at least 1 capital letter."
          >
            <InfoIcon ml={2} />
          </Tooltip>
        </FormLabel>
        <InputGroup>
          <Input
            autoComplete="new-password" // Helps to prevent auto-filling of password
            value={password}
            type={show ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputRightElement width={'4.5rem'}>
            <Button h="1.75rem" size="sm" onClick={handleToggleShow}>
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Button mt={7} disabled={disableConfirmButton} onClick={handleSubmit}>
        Confirm
      </Button>
    </Stack>
  );
};

export default ConfirmForgotPasswordForm;
