import { Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { DeliveryStatus } from '@tradeaze-packages/schemas';
import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';

export const CardPostcodes: React.FC<{
  deliveryStops: { postCode: string }[];
  deliveryStatus: DeliveryStatus;
}> = ({ deliveryStops, deliveryStatus }) => {
  return (
    <Flex flexWrap={'wrap'} fontWeight={600}>
      {deliveryStops.map((stop, index) => (
        <HStack key={index} mr="2">
          <Text noOfLines={1}>{stop.postCode?.toLocaleUpperCase()}</Text>
          {index !== deliveryStops.length - 1 && (
            <Icon as={FaLongArrowAltRight} color="blackAlpha.500" />
          )}
        </HStack>
      ))}
    </Flex>
  );
};
