import { differenceInMinutes, isSameDay } from 'date-fns';

export const shouldChargeCancellationFee = (
  createdAt: Date,
  deliveryTime: Date
) => {
  const GRACE_PERIOD_MINUTES = 15;

  return (
    isSameDay(new Date(deliveryTime), new Date()) &&
    differenceInMinutes(new Date(), new Date(createdAt)) > GRACE_PERIOD_MINUTES
  );
};
