import React from 'react';
import { Button, HStack, Text } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import {
  DeliveriesQueryParams,
} from '@tradeaze-packages/schemas';
import { useDownloadMerchantOrders } from './useDownloadMerchantOrder';

export const DownloadMerchantCsv: React.FC<{
  downloadQueryParams: DeliveriesQueryParams;
  merchantId: string;
  buttonProps: React.ComponentProps<typeof Button>;
}> = ({ downloadQueryParams, merchantId, buttonProps }) => {
  const { mutate: downloadOrders } = useDownloadMerchantOrders(merchantId);

  /**
   * When we have an order dashboard as well as a delivery dashboard, stop mapping deliveryStatus to orderStatus
   */
  const handleDownloadOrders = () => {
    downloadOrders({
      ...downloadQueryParams,
      orderStatuses: downloadQueryParams.deliveryStatuses,
    });
  };

  return (
    <Button
      aria-label="Download Orders"
      onClick={handleDownloadOrders}
      {...buttonProps}
    >
      <HStack>
        <DownloadIcon />
        <Text>CSV</Text>
      </HStack>
    </Button>
  );
};
