import { useGetMerchantAccount } from '@tradeaze/frontend/hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from './AuthProvider';
import { PageLoading } from '@tradeaze/frontend/ui-web';

const RequireAuth: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const auth = useAuthContext();
  const location = useLocation();

  const merchantQuery = useGetMerchantAccount(auth.cognitoUserId);

  const shouldForceSignIn =
    !auth.cognitoUser && !auth.authLoading && location.pathname !== '/signin';

  const shouldForceCreateAccount =
    auth.cognitoUser &&
    !merchantQuery.isLoading &&
    !merchantQuery.data &&
    location.pathname !== '/create-account';

  if (!auth.cognitoUser && auth.authLoading) {
    return <PageLoading />;
  }

  if (shouldForceSignIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (shouldForceCreateAccount) {
    return <Navigate to="/create-account" state={{ from: location }} replace />;
  }

  if (merchantQuery.isLoading && location.pathname !== '/create-account') {
    return <PageLoading />;
  }

  return children;
};

export default RequireAuth;
