import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { createContext, useContext } from 'react';

type DeliveryDashboardContextType = {
  handleStartAssign: (delivery: HydratedDelivery) => void;
  handlePreviewDelivery: (delivery: HydratedDelivery) => void;
};

export const DeliveryDashboardContext =
  createContext<DeliveryDashboardContextType>({
    handleStartAssign: () => {
      throw new Error('DeliveryDashboardContext not implemented');
    },
    handlePreviewDelivery: () => {
      throw new Error('DeliveryDashboardContext not implemented');
    },
  });

export const useDeliveryDashboardContext = () => {
  const context = useContext(DeliveryDashboardContext);
  if (!context) {
    throw new Error(
      'DeliveryDashboardContext must be used within a DeliveryDashboardContextProvider'
    );
  }
  return context;
};
