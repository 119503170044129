import { z } from 'zod';

const codeLengthMessage = 'Code is 6 digits';

export const ConfirmCodeSchema = z.object({
  username: z.string(),
  code: z
    .string()
    .min(6, { message: codeLengthMessage })
    .max(6, { message: codeLengthMessage }),
});

export type ConfirmCodeSchemaType = z.infer<typeof ConfirmCodeSchema>;
