import { z } from 'zod';
import { PaginationParamsSchema } from './paginated-data-schema';

export const MerchantDefinedCompanySchema = z.object({
  merchantId: z.string(),
  companyEmail: z.string().nullish(),
  companyName: z
    .string()
    .transform((value) => value.trim())
    .pipe(z.string().min(1, { message: 'Company name is required' })),
});

export const CompanySchema = MerchantDefinedCompanySchema.omit({
  merchantId: true,
}).extend({
  companyId: z.string(),
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
});

export const CompanyStringQueryParamsSchema = z.object({
  merchantId: z.string().optional(),
  ...PaginationParamsSchema.shape,
});

export type MerchantDefinedCompany = z.infer<
  typeof MerchantDefinedCompanySchema
>;

export type Company = z.infer<typeof CompanySchema>;
