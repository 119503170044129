import { z } from 'zod';
import { FleetVehicleSchema } from '../../../models';

export const RejectFleetVehicleRequestSchema = z.object({
  rejectedReason: z.string().optional(),
});

export const RejectFleetVehicleResponseSchema = z.object({
  message: z.string(),
  fleetVehicle: FleetVehicleSchema,
});

export type RejectFleetVehicleRequest = z.infer<
  typeof RejectFleetVehicleRequestSchema
>;

export type RejectFleetVehicleResponse = z.infer<
  typeof RejectFleetVehicleResponseSchema
>;
