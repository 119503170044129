import { useInfiniteQuery } from '@tanstack/react-query';
import {
  DeliveriesQueryParams,
  PaginatedDeliveryResponse,
} from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

export const getMerchantDeliveriesQueryKey = (
  merchantId?: string | null,
  filters?: DeliveriesQueryParams
) => {
  const key: unknown[] = ['getMerchantDeliveries'];
  if (merchantId) {
    key.push(merchantId);
  }
  if (filters) {
    key.push(filters);
  }
  return key;
};

export const useGetMerchantDeliveries = (
  merchantId: string | null | undefined,
  filters?: DeliveriesQueryParams
) => {
  return useInfiniteQuery<PaginatedDeliveryResponse>(
    getMerchantDeliveriesQueryKey(merchantId, filters),
    async ({ pageParam }) => {
      const apiName = 'DeliveryApi';
      const path = '/getDeliveriesByMerchantId/' + merchantId;
      const options = {
        queryStringParameters: {
          ...filters,
          startIndex: pageParam?.startIndex,
        },
      };
      return await API.get(apiName, path, options);
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.nextPageIndex) {
          return undefined;
        }
        return { startIndex: lastPage.nextPageIndex };
      },
      retry: false,
      enabled: Boolean(merchantId),
    }
  );
};
