import { QueryKey, useInfiniteQuery } from '@tanstack/react-query';
import { Address, AddressType } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

interface AddressesResponse {
  addresses: Address[];
  nextPageIndex: number | null;
}

export const getAddressesQueryKey = (params?: {
  addressType?: AddressType;
  companyId?: string | null;
  merchantId?: string | null;
}): QueryKey => {
  const key = ['getAddresses'];

  const { addressType, companyId, merchantId } = params || {};

  if (companyId) {
    key.push('companyId', companyId);
  }

  if (merchantId) {
    key.push('merchantId', merchantId);
  }

  if (addressType) {
    key.push(addressType);
  }

  return key;
};

export const useGetAddresses = ({
  companyId,
  merchantId,
  addressType,
}: {
  companyId: string | undefined | null;
  merchantId: string | undefined | null;
  addressType: AddressType;
}) =>
  useInfiniteQuery<AddressesResponse>(
    getAddressesQueryKey({ companyId, merchantId, addressType }),
    async ({ pageParam }) => {
      if (companyId) {
        const apiName = 'MerchantApi',
          path = '/getAddressesByCompanyId/' + companyId,
          options = {
            queryStringParameters: {
              startIndex: pageParam?.startIndex,
              addressType,
            },
          };
        return await API.get(apiName, path, options);
      }
      if (merchantId) {
        const apiName = 'MerchantApi',
          path = '/getAddressesByMerchantId/' + merchantId,
          options = {
            queryStringParameters: {
              startIndex: pageParam?.startIndex,
              addressType,
            },
          };
        return await API.get(apiName, path, options);
      }
      throw new Error('No company or merchant id provided');
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.nextPageIndex) {
          return undefined;
        }
        return { startIndex: lastPage.nextPageIndex };
      },
      retry: false,
      enabled: Boolean(companyId) || Boolean(merchantId),
    }
  );
