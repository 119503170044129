import { z } from 'zod';

export const OptimiseScheduleBodySchema = z.object({
  startTime: z.string(),
  riderId: z.string(),
  deliveryId: z.string().optional(),
});

export const OptimiseScheduleResponseSchema = z.object({
  deliveryStopIds: z.array(z.string()),
});

export type OptimiseScheduleBody = z.infer<typeof OptimiseScheduleBodySchema>;

export type OptimiseScheduleResponse = z.infer<
  typeof OptimiseScheduleResponseSchema
>;
