import { z } from 'zod';
import {
  SiteContactQueryParamsSchema,
  SiteContactSchema,
} from '../../../models';

export const GetSiteContactsByCompanyQuerySchema = SiteContactQueryParamsSchema.omit({
  companyId: true,
});

export const GetSiteContactsByCompanyResponseSchema = z.object({
  siteContacts: z.array(SiteContactSchema),
  nextPageIndex: z.number().nullable(),
});

export type GetSiteContactsByCompanyQuery = z.infer<
  typeof GetSiteContactsByCompanyQuerySchema
>;

export type GetSiteContactsByCompanyResponse = z.infer<
  typeof GetSiteContactsByCompanyResponseSchema
>;
