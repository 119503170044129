import { useQuery } from '@tanstack/react-query';
import {
  EtaStatus,
  GetDeliveryEtaQuery,
} from '@tradeaze-packages/schemas';
import { getDeliveryEta } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared/CustomUseQueryOptions';

/**
 * Although etaStatus is passed back from this endpoint, we're mainly using it for ETAs,
 * so when the etaStatus we already have from the order is updated - we want to refetch the ETA
 **/
type QueryValidationArgs = { etaStatus?: EtaStatus | null };

type Args = GetDeliveryEtaQuery & QueryValidationArgs;

export const getDeliveryEtaQueryKey = (args: Args) => {
  return ['getDeliveryEta', args];
};

export const useGetDeliveryEta = (
  args: Args,
  options?: CustomUseQueryOptions<typeof getDeliveryEta>
) =>
  useQuery({
    queryKey: getDeliveryEtaQueryKey(args),
    queryFn: async () => getDeliveryEta(args),
    ...options,
  });
