import React from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { brandPrimaryColor } from '../theme/colors';

export const DatePicker: React.FC<
  React.ComponentProps<typeof DayPicker> & {
    allowPastDates?: boolean;
    allowSundays?: boolean;
  }
> = ({ allowPastDates = false, allowSundays = false, ...props }) => {
  const disabledDays = [];

  if (!allowSundays) {
    disabledDays.push({
      dayOfWeek: [0],
    });
  }

  if (!allowPastDates) {
    disabledDays.push({
      before: new Date(),
    });
  }

  return (
    <DayPicker
      modifiersStyles={{
        selected: {
          backgroundColor: brandPrimaryColor,
          fontWeight: 'bold',
          fontSize: '1.1rem',
          transition: 'all 0.1s',
        },
      }}
      styles={{
        caption_label: {
          fontSize: '1.2rem',
          fontWeight: 'normal',
        },
      }}
      disabled={disabledDays}
      {...props}
    />
  );
};
