import {
  DeliveryVehicleId,
  GetDeliveryPriceQuery,
  GetDeliverySchedulesForDateResponse,
  GetDeliveryEtaQuery,
  GetThresholdDistancesArrayQuery,
  LocationWithWaitTime,
  OptimiseRoutesBody,
  OptimiseScheduleBody,
  DeliveryStatus,
  GetAdminDeliveriesQuery,
  CancelDeliveryRequest,
  GigJobAssignRider,
  RegionId,
  DeliveryOptionId,
  GetDeliveryByIdResponse,
  GetDeliveryOptionsResponse,
  GetRiderDeliveryScheduleResponse,
  SetDeliveryStopsSequenceResponse,
  GetTotalTimeEstimateResponse,
  GetDeliveryPriceResponse,
  OptimiseRoutesResponse,
  OptimiseScheduleResponse,
  CompleteDeliveryStopResponse,
  UndoCompleteDeliveryStopResponse,
  GetThresholdDistancesResponse,
  GetDeliveryEtaResponse,
  AssignRiderResponse,
  ConfirmDeliveryResponse,
  RejectDeliveryResponse,
  CompleteDeliveryResponse,
  GetAdminDeliveriesResponse,
  GetTrackingDeliveryResponse,
  EnableDeliveryOptionResponse,
  DisableDeliveryOptionResponse,
  CancelDeliveryResponse,
  ClockInWorkType,
  ApiError,
} from '@tradeaze-packages/schemas';
import { formatDateIgnoreTime, formatDateUk } from './utils';
import * as client from './client';
import { handleResponse } from './handle-response';

export const fetchDeliveryOptions = async (
  regionId: RegionId,
  date: Date,
): Promise<GetDeliveryOptionsResponse> =>
  handleResponse(
    client.getDeliveryOptions({
      query: {
        regionId,
        date: new Date(formatDateIgnoreTime(date)).toISOString(),
      },
    }),
  );

export const enableDeliveryOption = async ({
  deliveryOptionId,
  regionId,
  date,
}: {
  deliveryOptionId: DeliveryOptionId;
  regionId: RegionId;
  date: Date;
}): Promise<EnableDeliveryOptionResponse> =>
  handleResponse(
    client.enableDeliveryOption({
      body: {
        deliveryOptionId,
        regionId,
        date: date.toISOString(),
      },
    }),
  );

export const disableDeliveryOption = async ({
  deliveryOptionId,
  regionId,
  date,
}: {
  deliveryOptionId: DeliveryOptionId;
  regionId: RegionId;
  date: Date;
}): Promise<DisableDeliveryOptionResponse> =>
  handleResponse(
    client.disableDeliveryOption({
      body: {
        deliveryOptionId,
        regionId,
        date: date.toISOString(),
      },
    }),
  );

export const fetchRiderDeliverySchedule = async ({
  riderId,
  date,
}: {
  riderId: string;
  date: Date;
}): Promise<GetRiderDeliveryScheduleResponse> =>
  handleResponse(
    client.getRiderDeliverySchedule({
      path: {
        riderId,
      },
      query: {
        date: formatDateUk(new Date(date)),
      },
    }),
  );

export const fetchDeliverySchedulesForDate = async (
  regionId: string,
  date: Date,
): Promise<GetDeliverySchedulesForDateResponse> =>
  handleResponse(
    client.getDeliverySchedulesForDate({
      query: {
        regionId,
        date: formatDateUk(date),
      },
    }),
  );

export const setDeliveryStopsSequence = async ({
  deliveryScheduleId,
  deliveryStopIds,
}: {
  deliveryScheduleId: string;
  deliveryStopIds: string[];
}): Promise<SetDeliveryStopsSequenceResponse> =>
  handleResponse(
    client.setDeliveryStopsSequence({
      body: {
        deliveryScheduleId,
        deliveryStopIds,
      },
    }),
  );

export const fetchTotalScheduleTime = async (
  stops: LocationWithWaitTime[],
): Promise<GetTotalTimeEstimateResponse> =>
  handleResponse(
    client.getTotalTimeEstimate({
      body: {
        stops,
      },
    }),
  );

export const fetchDeliveryPrice = async (
  args: GetDeliveryPriceQuery,
): Promise<GetDeliveryPriceResponse> =>
  handleResponse(
    client.getDeliveryPrice({
      query: args,
    }),
  );

export const optimiseRoutes = async (
  args: OptimiseRoutesBody,
): Promise<OptimiseRoutesResponse> =>
  handleResponse(
    client.optimiseRoutes({
      body: args,
    }),
  );

export const getSuggestedRider = async ({
  deliveryId,
  startTime,
  allowedVehicleTypes,
  excludedRiderIds,
  workType,
}: {
  deliveryId: string;
  startTime: Date;
  allowedVehicleTypes: DeliveryVehicleId[];
  excludedRiderIds?: string[];
  workType?: ClockInWorkType;
}) => {
  const response = await optimiseRoutes({
    startTime: startTime.toISOString(),
    lockAssignedDeliveries: true,
    includeAllConfirmed: false,
    deliveryId,
    allowedVehicleTypes,
    excludedRiderIds,
    workType,
  });

  const route = response.find((r) => r.deliveryIds.includes(deliveryId));

  if (!route) {
    throw {
      error: {
        message: 'No route found for delivery',
      },
    } satisfies ApiError;
  }

  return {
    riderId: route.riderId,
    fleetVehicleId: route.fleetVehicleId,
    route: route.route,
  };
};

export const optimiseSchedule = async ({
  startTime,
  riderId,
  deliveryId,
}: OptimiseScheduleBody): Promise<OptimiseScheduleResponse> =>
  handleResponse(
    client.optimiseSchedule({
      body: {
        startTime,
        riderId,
        deliveryId,
      },
    }),
  );

export const completeDeliveryStop = async (
  deliveryStopId: string,
): Promise<CompleteDeliveryStopResponse> =>
  handleResponse(
    client.completeDeliveryStop({
      path: {
        deliveryStopId,
      },
    }),
  );

export const undoCompleteDeliveryStop = async (
  deliveryStopId: string,
): Promise<UndoCompleteDeliveryStopResponse> =>
  handleResponse(
    client.undoCompleteDeliveryStop({
      path: {
        deliveryStopId,
      },
    }),
  );

export const getThresholdDistances = async (
  query: GetThresholdDistancesArrayQuery,
): Promise<GetThresholdDistancesResponse> =>
  handleResponse(
    client.getThresholdDistances({
      query,
    }),
  );

export const getDeliveryEta = async (
  query: GetDeliveryEtaQuery,
): Promise<GetDeliveryEtaResponse> =>
  handleResponse(
    client.getDeliveryEta({
      query,
    }),
  );

export const assignRider = async ({
  deliveryId,
  riderId,
  sortedStopIds,
  gigJob,
}: {
  deliveryId: string;
  riderId: string;
  sortedStopIds?: string[];
  gigJob?: GigJobAssignRider;
}): Promise<AssignRiderResponse> =>
  handleResponse(
    client.assignRider({
      path: {
        deliveryId,
        riderId,
      },
      body: {
        sortedStopIds,
        gigJob,
      },
    }),
  );

export const confirmDelivery = async (
  deliveryId: string,
): Promise<ConfirmDeliveryResponse> =>
  handleResponse(
    client.confirmDelivery({
      path: {
        deliveryId,
      },
    }),
  );

export const rejectDelivery = async (
  deliveryId: string,
): Promise<RejectDeliveryResponse> =>
  handleResponse(
    client.rejectDelivery({
      path: {
        deliveryId,
      },
    }),
  );

export const completeDelivery = async (
  deliveryId: string,
): Promise<CompleteDeliveryResponse> =>
  handleResponse(
    client.completeDelivery({
      path: {
        deliveryId,
      },
    }),
  );

export const updateDeliveryStatus = async ({
  deliveryId,
  status,
}: {
  deliveryId: string;
  status: Exclude<DeliveryStatus, 'CANCELLED'>;
}) => {
  switch (status) {
    case 'CONFIRMED':
      return confirmDelivery(deliveryId);
    case 'REJECTED':
      return rejectDelivery(deliveryId);
    case 'DELIVERED':
      return completeDelivery(deliveryId);
    default:
      throw new Error('Invalid status');
  }
};

export const cancelDelivery = async ({
  deliveryId,
  body,
}: {
  deliveryId: string;
  body: CancelDeliveryRequest;
}): Promise<CancelDeliveryResponse> =>
  handleResponse(
    client.cancelDelivery({
      path: {
        deliveryId,
      },
      body,
    }),
  );

export const getAdminDeliveries = async (
  query: GetAdminDeliveriesQuery,
): Promise<GetAdminDeliveriesResponse> =>
  handleResponse(
    client.getAdminDeliveries({
      query,
    }),
  );

export const getDelivery = async (
  deliveryId: string | undefined,
): Promise<GetDeliveryByIdResponse | undefined> => {
  if (!deliveryId) {
    return;
  }
  return handleResponse(
    client.getDeliveryById({
      path: {
        deliveryId,
      },
    }),
  );
};

export const getTrackingDelivery = async (
  deliveryId?: string,
): Promise<GetTrackingDeliveryResponse | undefined> => {
  if (!deliveryId) {
    return;
  }
  return handleResponse(
    client.getTrackingDelivery({
      path: {
        deliveryId,
      },
    }),
  );
};
