import { z } from 'zod';
import {
  MerchantAccountSchema,
  UpdateMerchantAccountSchema,
} from '../../../models';

export const UpdateMerchantBodySchema = UpdateMerchantAccountSchema;

export const UpdateMerchantResponseSchema = MerchantAccountSchema;

export type UpdateMerchantBody = z.infer<typeof UpdateMerchantBodySchema>;

export type UpdateMerchantResponse = z.infer<
  typeof UpdateMerchantResponseSchema
>;
