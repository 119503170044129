import { DuplicateDeliveryStop, HydratedOrder } from '@tradeaze-packages/schemas';

// only works for the first delivery
export const buildReturnJourneyOrder = (order: HydratedOrder) => {
  const firstDelivery = order.deliveries[0];

  const firstStop = firstDelivery.pickup;

  const lastStop = firstDelivery.dropOff;

  const newPickup = {
    type: 'PICK_UP',
    addressLine1: lastStop.addressLine1,
    postCode: lastStop.postCode,
    position: lastStop.position,
    stopSequence: 0,
    contacts: lastStop.contacts || [],
    windowStart: lastStop.windowStart,
    windowEnd: lastStop.windowEnd,
    companyName: lastStop.companyName,
    addressLine2: lastStop.addressLine2,
    addressLine3: lastStop.addressLine3,
    city: lastStop.city,
    country: lastStop.country,
  } satisfies DuplicateDeliveryStop;

  const newDropOff = {
    type: 'DELIVERY',
    addressLine1: firstStop.addressLine1,
    postCode: firstStop.postCode,
    position: firstStop.position,
    stopSequence: 1,
    contacts: firstStop.contacts || [],
    windowStart: firstStop.windowStart,
    windowEnd: firstStop.windowEnd,
    companyName: firstStop.companyName,
    addressLine2: firstStop.addressLine2,
    addressLine3: firstStop.addressLine3,
    city: firstStop.city,
    country: firstStop.country,
  } satisfies DuplicateDeliveryStop;

  return {
    ...order,
    deliveries: [{ ...firstDelivery, pickup: newPickup, dropOff: newDropOff }],
  };
};
