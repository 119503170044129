import {
  Box,
  Button,
  ButtonProps,
  Center,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { brandSecondaryColor, notificationColor } from '../theme/colors';
import { NotificationIcon } from '../shared';

export const NavbarButton: React.FunctionComponent<
  ButtonProps & {
    label: string;
    notificationCount?: boolean,
  }
> = (props) => {
  return (
    <Button
      borderRadius={'2xl'}
      shadow={'md'}
      h={14}
      minW={24}
      maxW={28}
      onClick={props.onClick}
      bg="transparent"
      color={brandSecondaryColor}
      position="relative"
      {...props}
    >
      {props.notificationCount && <Stack position="absolute" right="5px" top="-5px">
        <NotificationIcon color={notificationColor} />
      </Stack>}
      <Stack h={'100%'} pt={'0.5'} pb={1} spacing={0} justify="space-between">
        <Center>{props.children}</Center>
        <Box position={'absolute'} bottom={'3px'} left={0} width={'100%'}>
          <Text fontSize={12}>{props.label}</Text>
        </Box>
      </Stack>
    </Button>
  );
};
