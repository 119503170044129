import { getUrlFromS3KeyAmplify } from './getUrlFromKeyAmplify';
import { searchFilesS3 } from './searchFilesS3';
import {
  ProofType,
  buildProofDirectory,
} from '@tradeaze-packages/proof-of-delivery';

export const searchProofS3 =
  (proofBucket: string) =>
  async ({
    orderId,
    stopId,
    type,
  }: {
    orderId: string;
    stopId?: string;
    type: ProofType;
  }) => {
    const proofDirectory = buildProofDirectory(orderId, stopId);
    const keys = await searchFilesS3(proofBucket)(proofDirectory, type);
    return Promise.all(
      keys.map((k) =>
        getUrlFromS3KeyAmplify(proofBucket)(k).then((uri) => ({ uri, key: k }))
      )
    );
  };
