import { AutocompleteResponse, GetAddressResponse } from './types';

export const findAddresses = async (
  postcode: string,
  {
    apiKey
  }: {
    apiKey: string
  }
): Promise<AutocompleteResponse['suggestions']> => {
  if (!apiKey) {
    throw new Error('VITE_GET_ADDRESS_API_KEY is not set');
  }
  try {
    const response = await fetch(
      `https://api.getAddress.io/autocomplete/${postcode}?api-key=${apiKey}`
    );
    const data: AutocompleteResponse = await response.json();
    
    return data?.suggestions;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAddress = async (id: string, {
  apiKey
}: {
  apiKey: string
}): Promise<GetAddressResponse> => {
  if (!apiKey) {
    throw new Error('VITE_GET_ADDRESS_API_KEY is not set');
  }
  try {
    const response = await fetch(
      `https://api.getAddress.io/get/${id}?api-key=${apiKey}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
