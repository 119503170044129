import { CheckCircleIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Tag, TagLabel } from '@chakra-ui/react';
import { OrderStatus } from '@tradeaze-packages/schemas';
import { FaHourglassHalf } from 'react-icons/fa';
import { RiMoneyPoundCircleFill } from 'react-icons/ri';
import { TradeazeLogoNoText } from '../brand/TradeazeLogoNoText';

interface Props {
  status: OrderStatus;
  inactive?: boolean;
}

export const OrderStatusTag = ({ status, inactive }: Props) => {
  const { colorScheme, bg, name, icon } = getTagProps(status);

  return (
    <Tag
      size="md"
      colorScheme={colorScheme}
      bg={bg}
      alignItems="center"
      opacity={inactive ? 0.3 : 1}
    >
      <Box mr={1}>{icon}</Box>
      <TagLabel>{name}</TagLabel>
    </Tag>
  );
};

const getTagProps = (status: OrderStatus) => {
  switch (status) {
    case 'DELIVERED':
      return {
        name: 'Delivered',
        colorScheme: 'green',
        icon: <CheckCircleIcon />,
      };
    case 'CONFIRMED':
      return {
        name: 'Confirmed',
        colorScheme: 'yellow',
        bg: 'yellow.400',
        icon: <TradeazeLogoNoText height={12} />,
      };
    case 'REJECTED':
      return {
        name: 'Rejected',
        colorScheme: 'red',
        icon: <SmallCloseIcon />,
      };
    case 'CANCELLED':
      return {
        name: 'Cancelled',
        colorScheme: 'red',
        icon: <SmallCloseIcon />,
      };
    case 'PENDING':
      return {
        name: 'Pending',
        colorScheme: 'gray',
        icon: <FaHourglassHalf />,
      };
    default:
      return null as never;
  }
};
