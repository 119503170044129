import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Text,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
} from '@chakra-ui/react';
import { BsCalculatorFill } from 'react-icons/bs';
import { PostHog } from 'posthog-js/react';
import { PricingCalculator } from './delivery-pricing-calcluator';


export const DeliveryPricingModal: React.FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  defaultFromPostcode?: string;
  merchantId?: string;
  isAdmin?: boolean;
  posthog?: PostHog;
  hidePriorityDelivery: boolean;
  allowLongDistance: boolean;
}> = ({
  isOpen,
  onClose,
  defaultFromPostcode,
  merchantId,
  isAdmin = false,
  hidePriorityDelivery,
  posthog,
  allowLongDistance,
}) => {
  return (
    <Modal
      scrollBehavior="outside"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent borderRadius="0px">
        <ModalHeader
          borderColor="blackAlpha.100"
          backgroundColor="#FEFAED"
        >
          <HStack>
            <BsCalculatorFill />
            <Text>Tradeaze Fee Calculator</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <Tabs position='relative' variant='unstyled'>
          <TabList
            backgroundColor="#FEFAED"
          >
            <Tab>A to B</Tab>
            <Tab>Multi-Collection</Tab>
          </TabList>
          <TabIndicator mt='-1.5px' height='2px' bg='#E0B940' />
          <TabPanels>
            <TabPanel>
              <PricingCalculator
                isAdmin={isAdmin}
                onClose={onClose}
                hidePriorityDelivery={hidePriorityDelivery}
                allowLongDistance={allowLongDistance}
                defaultFromPostcode={defaultFromPostcode}
                merchantId={merchantId}
                posthog={posthog}
                isAB={true}
              />
            </TabPanel>
            <TabPanel>
              <PricingCalculator
                isAdmin={isAdmin}
                onClose={onClose}
                hidePriorityDelivery={hidePriorityDelivery}
                allowLongDistance={allowLongDistance}
                defaultFromPostcode={defaultFromPostcode}
                merchantId={merchantId}
                posthog={posthog}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalContent>
    </Modal>
  );
};
