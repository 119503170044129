import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { useMemo } from 'react';

const vehicleLabelMap: Record<DeliveryVehicleId, string> = {
  BIKE: 'Bike',
  CAR: 'Car',
  FLATBED: 'Flatbed',
  LUTON: 'Luton Van',
  VAN_LARGE: 'Van (L)',
  VAN_XLARGE: 'Van (XL)',
};

export const DeliveryVehicleFilterMenu: React.FC<{
  handleToggleDeliveryVehicle: (value: DeliveryVehicleId) => void;
  handleSelectAllDeliveryVehicles: () => void;
  selectedVehicleIds?: DeliveryVehicleId[];
  size?: 'sm' | 'md' | 'lg';
  width?: string;
}> = ({
  handleToggleDeliveryVehicle,
  handleSelectAllDeliveryVehicles,
  selectedVehicleIds,
  size = 'sm',
  width = 'auto',
}) => {
  const title = useMemo(() => {
    if (!selectedVehicleIds?.length) {
      return 'Vehicles';
    }
    if (selectedVehicleIds?.length === 1) {
      return vehicleLabelMap[selectedVehicleIds[0]];
    }
    return `${selectedVehicleIds.length} Vehicles`;
  }, [selectedVehicleIds]);

  return (
    <Menu closeOnSelect={false}>
      <Box>
        <MenuButton
          colorScheme={'gray'}
          size={size}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          color={selectedVehicleIds?.length ? 'black' : 'gray.500'}
          textAlign={'left'}
          width={width}
        >
          {title}
        </MenuButton>
      </Box>
      <MenuList maxWidth={'max-content'}>
        <MenuItemOption
          isChecked={!selectedVehicleIds?.length}
          onClick={() => handleSelectAllDeliveryVehicles()}
          fontWeight="normal"
        >
          All
        </MenuItemOption>
        {Object.entries(vehicleLabelMap).map(([id, label]) => (
          <MenuItemOption
            key={id}
            isChecked={selectedVehicleIds?.includes(id as DeliveryVehicleId)}
            onClick={() =>
              handleToggleDeliveryVehicle(id as keyof typeof vehicleLabelMap)
            }
          >
            {label}
          </MenuItemOption>
        ))}
      </MenuList>
    </Menu>
  );
};
