import { z } from 'zod';
import { DurationToStopsSchema } from '../../../models';

export const LocationWithWaitTimeSchema = z.object({
  id: z.string(),
  location: z.string(),
  waitTime: z.number(),
});

export const GetTotalTimeEstimateBodySchema = z.object({
  stops: z.array(LocationWithWaitTimeSchema),
});

export const RouteLegSchema = z.object({
  distance: z.number(),
  duration: z.number(),
});

export const GetTotalTimeEstimateResponseSchema = z.object({
  distance: z.number(),
  duration: z.number(),
  routeLegs: z.array(RouteLegSchema),
  durationToStops: DurationToStopsSchema,
});

export type RouteLeg = z.infer<typeof RouteLegSchema>;

export type LocationWithWaitTime = z.infer<typeof LocationWithWaitTimeSchema>;

export type GetTotalTimeEstimateBody = z.infer<
  typeof GetTotalTimeEstimateBodySchema
>;

export type GetTotalTimeEstimateResponse = z.infer<
  typeof GetTotalTimeEstimateResponseSchema
>;
