import { z } from 'zod';

const InvoiceStatusSchema = z.enum([
  'PENDING',
  'PROCESSING',
  'PAID',
  'CANCELLED',
  'FAILED',
  'REFUNDED',
]);

const CurrencySchema = z.enum(['GBP']);

const CostBreakdownSchema = z.object({
  currency: CurrencySchema,
  tradeazeFee: z.number(),
  netPrice: z.number(),
  totalPrice: z.number(),
  totalVat: z.number(),
});

const LineItemSchema = z.object({
  description: z.string(),
  quantity: z.number(),
  unitPrice: z.number(),
  ...CostBreakdownSchema.shape,
});

export const CreateInvoiceSchema = z.object({
  attachmentUrl: z.string(),
  endUserId: z.string(),
  merchantId: z.string(),
  lineItems: z.array(LineItemSchema),
  orderId: z.string().nullish(),
  invoiceId: z.string().optional(),
  ...CostBreakdownSchema.shape,
});

export const InvoiceSchema = CreateInvoiceSchema.extend({
  createdAt: z.string(),
  invoiceId: z.string(),
  invoiceStatus: InvoiceStatusSchema,
  stripeAccountId: z.string().nullish(),
  updatedAt: z.string(),
});

export const InvoiceWithStripeAccount = InvoiceSchema.extend({
  stripeAccountId: z.string(),
});

export const InvoicesQueryParamsSchema = z.object({
  startIndex: z
    .string()
    .optional()
    .transform((arg) => (arg ? Number(arg) : undefined)),
  limit: z
    .string()
    .optional()
    .transform((arg) => (arg ? Number(arg) : undefined)),
  endUserId: z.string().optional(),
  merchantId: z.string().optional(),
  invoiceStatus: InvoiceStatusSchema.optional(),
  orderId: z.string().optional(),
});

export type InvoiceStatus = z.infer<typeof InvoiceStatusSchema>;
export type Invoice = z.infer<typeof InvoiceSchema>;
export type CreateInvoice = z.infer<typeof CreateInvoiceSchema>;
export type InvoicesQueryParams = z.infer<typeof InvoicesQueryParamsSchema>;
export type LineItem = z.infer<typeof LineItemSchema>;
export type Currency = z.infer<typeof CurrencySchema>;
