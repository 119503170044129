import { Position } from '@tradeaze-packages/schemas';

export const getStopPositionsFromRouteLegs = (
  routeLegs: {
    startLocation: Position;
    endLocation: Position;
  }[]
): Position[] => {
  return routeLegs.flatMap((leg, index) =>
    index === routeLegs.length - 1
      ? [leg.startLocation, leg.endLocation]
      : [leg.startLocation]
  );
};
