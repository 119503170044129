import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import { z } from 'zod';
import { GetNotificationStatsResponseSchema } from '../../notification-service';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/notification';

const config = {
  openapi: '3.1.0',
  info: {
    title: 'Notification API',
    version: '1.0.0',
    contact: {
      name: 'Ruben Engel',
      email: 'admin@tradeaze.uk',
    },
  },
  servers: [
    {
      url: `https://dev-api.tradeaze.app`,
      description: 'Development server',
    },
    {
      url: `https://stage-api.tradeaze.app`,
      description: 'Staging server',
    },
    {
      url: `https://api.tradeaze.app`,
      description: 'Production server',
    },
  ],
};

/**
 * GET /stats
 */
registry.registerPath({
  operationId: 'getNotificationStats',
  method: 'get',
  path: `${basePath}/stats`,
  description: 'Get notificaton stats',
  responses: {
     200: {
      description: 'Get notificaton stats',
      content: {
        'application/json': {
          schema: GetNotificationStatsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const notificationApiRegistry = registry;