import { useEffect } from 'react';
import { useAuthStore } from '../useAuthStore';

const CHECK_AUTH_SESSION_INTERVAL = 1000 * 30 * 60; // 30 minutes

export const useCheckAuthSession = async () => {
  const checkAuthSession = useAuthStore((state) => state.checkAuthSession);

  useEffect(() => {
    checkAuthSession();

    const interval = setInterval(() => {
      checkAuthSession();
    }, CHECK_AUTH_SESSION_INTERVAL);

    return () => clearInterval(interval);
  }, []);
};
