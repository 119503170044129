export const NoVehicleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    width="156"
    height="156"
    viewBox="0 0 156 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70.2001 27.2996C46.5071 27.2996 27.3001 46.5066 27.3001 70.1996C27.3001 93.8926 46.5071 113.1 70.2001 113.1C82.0482 113.1 92.7652 108.304 100.535 100.534C108.305 92.7647 113.1 82.0477 113.1 70.1996C113.1 46.5066 93.8931 27.2996 70.2001 27.2996ZM15.6001 70.1996C15.6001 40.0449 40.0453 15.5996 70.2001 15.5996C100.355 15.5996 124.8 40.0449 124.8 70.1996C124.8 83.1673 120.274 95.086 112.724 104.45L138.687 130.413C140.971 132.698 140.971 136.402 138.687 138.686C136.402 140.971 132.698 140.971 130.414 138.686L104.45 112.723C95.0865 120.273 83.1678 124.8 70.2001 124.8C40.0453 124.8 15.6001 100.354 15.6001 70.1996Z"
      fill="#AF9032"
    />
    <path
      d="M90.6067 50.0277C90.04 48.356 88.4533 47.166 86.5833 47.166H55.4167C53.5467 47.166 51.9883 48.356 51.3933 50.0277L45.5 66.9993V89.666C45.5 91.2243 46.775 92.4993 48.3333 92.4993H51.1667C52.725 92.4993 54 91.2243 54 89.666V86.8327H88V89.666C88 91.2243 89.275 92.4993 90.8333 92.4993H93.6667C95.225 92.4993 96.5 91.2243 96.5 89.666V66.9993L90.6067 50.0277ZM55.4167 78.3327C53.065 78.3327 51.1667 76.4343 51.1667 74.0827C51.1667 71.731 53.065 69.8327 55.4167 69.8327C57.7683 69.8327 59.6667 71.731 59.6667 74.0827C59.6667 76.4343 57.7683 78.3327 55.4167 78.3327ZM86.5833 78.3327C84.2317 78.3327 82.3333 76.4343 82.3333 74.0827C82.3333 71.731 84.2317 69.8327 86.5833 69.8327C88.935 69.8327 90.8333 71.731 90.8333 74.0827C90.8333 76.4343 88.935 78.3327 86.5833 78.3327ZM51.1667 64.166L55.4167 51.416H86.5833L90.8333 64.166H51.1667Z"
      fill="#666668"
    />
  </svg>
);
