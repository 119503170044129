import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { MerchantAccount } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

export const useGetAllMerchants = (
  options?: UseQueryOptions<MerchantAccount[]>
) => {
  return useQuery<MerchantAccount[]>(
    ['getAllMerchants'],
    async () => {
      const apiName = 'MerchantApi';
      const path = '/getAllMerchants';
      const options = {};
      const response = await API.get(apiName, path, options);
      return response;
    },
    options
  );
};
