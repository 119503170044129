import {
  Accordion,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { useCancelDelivery } from '@tradeaze/frontend/hooks';
import { HydratedDelivery, HydratedOrder } from '@tradeaze-packages/schemas';
import {
  formatTimeRange,
  orderKeyToLabel,
  shouldChargeCancellationFee,
  travisPerkinsOrderKeyToLabel,
} from '@tradeaze/shared/utils';
import startCase from 'lodash.startcase';
import React from 'react';
import toast from 'react-hot-toast';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiPackage, BiReceipt } from 'react-icons/bi';
import { CgTimelapse } from 'react-icons/cg';
import { FiCreditCard } from 'react-icons/fi';
import { GrLocation } from 'react-icons/gr';
import {
  DeliveryAccordionItem,
  DeliveryStatusTag,
  DeliveryVehicleIcon,
} from '../delivery';
import {
  Attribute,
  DateTimeValue,
  DeliveryOptionValue,
  OrderStatusValue,
  PriceAttribute,
  PriceValue,
  TextValue,
} from '../shared';
import { CancelConfirmation } from './CancelConfirmation';
import { OrderSection } from './OrderSection';
import { RiderAvatar } from '../rider';

const sectionColumns = [2, 3, 2, 2, 3];

const shouldShowCancelDelivery = (
  delivery: Pick<HydratedDelivery, 'status' | 'pickup'>
) => {
  // if delivery is not delivered and pickup has not been completed yet
  return (
    (delivery.status === 'PENDING' || delivery.status === 'CONFIRMED') &&
    !delivery.pickup.completedAt
  );
};

const OrderSummaryHeading: React.FC<{
  order: HydratedOrder;
  showMerchantName?: boolean;
  isAdmin?: boolean;
}> = ({ order, showMerchantName, isAdmin }) => {
  if (isAdmin) {
    return (
      <Stack
        justify={'space-between'}
        direction={['column', 'row']}
        spacing={[3, 10]}
        mb={4}
      >
        {showMerchantName && (
          <Box>
            <Heading size="md">
              {order.Merchant?.merchantName ||
                'Guest Order - ' + order.invoicingName}
            </Heading>
          </Box>
        )}
        {order.merchantOrderReference && (
          <Heading size={'md'}>{order.merchantOrderReference}</Heading>
        )}
      </Stack>
    );
  }

  return (
    <Stack
      justify={'space-between'}
      direction={['column', 'row']}
      spacing={[3, 10]}
      mb={4}
    >
      {order.merchantOrderReference && (
        <Heading size={'md'}>{order.merchantOrderReference}</Heading>
      )}
    </Stack>
  );
};

export const OrderSummary: React.FunctionComponent<{
  order: HydratedOrder;
  showMerchantName?: boolean;
  proofOfDeliveryBucketname: string;
  columns?: number[];
  isTravisPerkins?: boolean;
  isAdmin?: boolean;
}> = ({
  order,
  showMerchantName = false,
  proofOfDeliveryBucketname,
  columns,
  isTravisPerkins = false,
  isAdmin = false,
}) => {
  const labelMap = isTravisPerkins
    ? travisPerkinsOrderKeyToLabel
    : orderKeyToLabel;

  /**
   * TODO - have these details live on order so do not need to access first delivery
   */
  const deliveryVehicle = order.deliveries[0].deliveryVehicleId;
  const deliveryOption = order.deliveries[0].deliveryOptionId;
  const deliveryWindow =
    order.deliveries[0].dropOff.windowStart &&
    order.deliveries[0].dropOff.windowEnd
      ? formatTimeRange({
          from: order.deliveries[0].dropOff.windowStart,
          to: order.deliveries[0].dropOff.windowEnd,
        })
      : null;

  const cancelDeliveryMutation = useCancelDelivery({
    onError: () => {
      toast.error('Error updating delivery status');
    },
  });

  return (
    <Box>
      <Box mb={12}>
        <OrderSummaryHeading
          order={order}
          showMerchantName={showMerchantName}
          isAdmin={isAdmin}
        />
      </Box>
      <SimpleGrid columns={columns} spacing={12}>
        <OrderSection name="Status" icon={<CgTimelapse />}>
          <SimpleGrid columns={sectionColumns} spacing={4}>
            <Attribute name="Order Status">
              <OrderStatusValue orderStatus={order.orderStatus} />
            </Attribute>
            <Attribute name="Created">
              <DateTimeValue dateTime={order.createdAt} />
            </Attribute>
            <Attribute name="Updated">
              <DateTimeValue dateTime={order.updatedAt} />
            </Attribute>
            <Attribute name="Confirmed">
              <DateTimeValue dateTime={order.confirmedAt} />
            </Attribute>
            <Attribute name="Completed">
              <DateTimeValue dateTime={order.deliveredAt} />
            </Attribute>
            {order.cancelledAt ? (
              <Attribute name="Cancelled">
                <DateTimeValue dateTime={order.cancelledAt} />
              </Attribute>
            ) : null}
            {order.cancellationReason ? (
              <Attribute name="Cancellation Reason">
                <TextValue text={order.cancellationReason} />
              </Attribute>
            ) : null}
          </SimpleGrid>
        </OrderSection>

        {order.invoicingName ? (
          <OrderSection name="Invoicing Details" icon={<BiReceipt />}>
            <Attribute name={labelMap['invoicingName']}>
              <TextValue text={order.invoicingName} />
            </Attribute>
          </OrderSection>
        ) : null}

        <OrderSection name="Booking Info" icon={<AiOutlineInfoCircle />}>
          <SimpleGrid columns={sectionColumns} spacing={4}>
            <Attribute name={labelMap['bookedBy']}>
              <TextValue text={order.bookedBy} />
            </Attribute>
            <Attribute name={labelMap['merchantOrderReference']}>
              <TextValue text={order.merchantOrderReference} />
            </Attribute>
            <Attribute name={labelMap['type']}>
              <TextValue text={startCase(order.type.toLocaleLowerCase())} />
            </Attribute>
          </SimpleGrid>
          {order.notes ? (
            <Attribute name={labelMap['notes']}>
              <TextValue text={order.notes} />
            </Attribute>
          ) : null}
        </OrderSection>

        <OrderSection name="Delivery Details" icon={<GrLocation />}>
          <SimpleGrid columns={sectionColumns} spacing={4}>
            <Attribute name="Delivery Option">
              <HStack>
                <DeliveryVehicleIcon
                  fontSize={18}
                  deliveryVehicle={deliveryVehicle}
                />
                <DeliveryOptionValue deliveryOption={deliveryOption} />
              </HStack>
            </Attribute>
            {deliveryWindow ? (
              <Attribute name="Delivery Window">
                <TextValue text={deliveryWindow} />
              </Attribute>
            ) : null}
          </SimpleGrid>
        </OrderSection>

        <Accordion allowToggle defaultIndex={[0]}>
          <VStack spacing={10} width={'100%'}>
            {order.deliveries.map((delivery, index) => (
              <OrderSection
                width={'100%'}
                name={`Delivery ${
                  order.deliveries.length > 1 ? index + 1 : ''
                }`}
                icon={<BiPackage />}
              >
                <DeliveryAccordionItem
                  key={index}
                  delivery={{ ...delivery, order: order }}
                  proofOfDeliveryBucketname={proofOfDeliveryBucketname}
                  isAdmin={isAdmin}
                />
                <Divider mt={3} mb={5} />
                <HStack justifyContent={'space-between'}>
                  <HStack spacing={3}>
                    <DeliveryStatusTag status={delivery.status} />
                    {delivery.rider ? (
                      <RiderAvatar
                        riderId={delivery.rider.riderId}
                        firstName={delivery.rider.firstName}
                        lastName={delivery.rider.lastName}
                        size={'xs'}
                      />
                    ) : null}
                  </HStack>
                  <Box>
                    {shouldShowCancelDelivery(delivery) ? (
                      <CancelConfirmation
                        cancelType="delivery"
                        shouldChargeFee={shouldChargeCancellationFee(
                          new Date(order.createdAt),
                          new Date(order.deliveryWindowEnd)
                        )}
                        isLoading={cancelDeliveryMutation.isLoading}
                        onConfirm={(cancellationReason) =>
                          cancelDeliveryMutation.mutate({
                            deliveryId: delivery.deliveryId,
                            body: {
                              cancellationReason,
                            },
                          })
                        }
                        renderButton={({ onClick, isLoading }) => (
                          <Button
                            onClick={onClick}
                            isLoading={isLoading}
                            colorScheme="red"
                            variant="outline"
                            size="xs"
                          >
                            Cancel Delivery
                          </Button>
                        )}
                      />
                    ) : null}
                  </Box>
                </HStack>
              </OrderSection>
            ))}
          </VStack>
        </Accordion>

        <OrderSection name="Tradeaze Fees" icon={<FiCreditCard />}>
          {order.orderStatus === 'CANCELLED' ? (
            <PriceAttribute name="Cancellation Fee" excludingVat>
              <PriceValue price={order.cancellationFee} />
            </PriceAttribute>
          ) : (
            <Stack spacing={6}>
              <Stack spacing={6}>
                n
                <PriceAttribute name="Delivery Fee" excludingVat>
                  <PriceValue price={order.deliveryPrice} />
                </PriceAttribute>
                <PriceAttribute name="Service Fee" excludingVat>
                  <PriceValue price={order.merchantServiceCharge} />
                </PriceAttribute>
              </Stack>
              <Divider />
              <PriceAttribute name="Total" excludingVat>
                <PriceValue
                  price={order.deliveryPrice + order.merchantServiceCharge}
                />
              </PriceAttribute>
            </Stack>
          )}
        </OrderSection>
      </SimpleGrid>
    </Box>
  );
};
