import { z } from 'zod';

export const DeleteCognitoUserResponseSchema = z.literal('OK');

export type DeleteCognitoUserResponse = z.infer<
  typeof DeleteCognitoUserResponseSchema
>;

export const CreateCognitoUserSchema = z.object({
  userName: z.string(),
  email: z.string(),
  password: z.string(),
});

export type CreateCognitoUserBody = z.infer<typeof CreateCognitoUserSchema>;

export const CreateCognitoUserResponseSchema = z.object({
  riderId: z.string(),
});

export type CreateCognitoUserResponse = z.infer<
  typeof CreateCognitoUserResponseSchema
>;
