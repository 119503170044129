import React, { useMemo, useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Stack,
  Input,
  Divider,
} from '@chakra-ui/react';
import { useAuthContext } from '../components/auth/AuthProvider';
import {
  useGetCompaniesByMerchant,
  useGetMerchantAccount,
} from '@tradeaze/frontend/hooks';
import {
  MerchantCustomerForm,
  MerchantCustomerQueryPage,
} from '@tradeaze/frontend/ui-web';

export const AddressBookPage = () => {
  const { cognitoUserId } = useAuthContext();

  const { data: merchant } = useGetMerchantAccount(cognitoUserId);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [search, setSearch] = useState<string>();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const {
    data: merchantCustomers,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetCompaniesByMerchant(merchant?.merchantId);

  const customers = useMemo(
    // while query limit is infinty, can sort by name client side
    () =>
      merchantCustomers?.pages
        .flatMap((page) => page.companies)
        .filter((company) => {
          if (!search) {
            return true;
          }
          return company.companyName
            .toLowerCase()
            .includes(search.toLowerCase());
        })
        .sort((a, b) => a.companyName.localeCompare(b.companyName)) || [],
    [merchantCustomers, search]
  );

  if (!merchant) {
    return null;
  }

  return (
    <Container maxW="container.xl">
      <Box>
        <Stack
          direction={['column', 'row']}
          justifyContent={'space-between'}
          alignItems={['start', 'center']}
          mb={6}
          spacing={[2, 8]}
        >
          <Stack flexDirection="column">
            <Heading size="md">Address Book</Heading>
            <Text color={'blackAlpha.500'}>
              Add customers to your address book to speed up order creation.
            </Text>
          </Stack>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size="lg">
          <ModalOverlay />
          <ModalContent paddingX={6} paddingY={12} borderRadius={12}>
            <ModalCloseButton />
            <ModalBody>
              <MerchantCustomerForm
                onSubmit={onClose}
                merchantId={merchant.merchantId}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
        <Divider my={6} />
        <Stack
          direction={['column', 'column', 'row']}
          justifyContent={'space-between'}
          mb={8}
          spacing={8}
        >
          <Button onClick={onOpen} size={['md', 'md', 'sm']}>
            Add Customer
          </Button>
          <Box>
            <Input
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
            />
          </Box>
        </Stack>
        <MerchantCustomerQueryPage
          merchantCustomers={customers}
          merchantId={merchant.merchantId}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isError={isError}
          isFetchingNextPage={isFetchingNextPage}
          columns={[1, 1, 2, 3]}
        />
      </Box>
    </Container>
  );
};

export default AddressBookPage;
