import { z } from 'zod';
import { TransistorsoftLocationSchema } from '../../../models/transistorsoft-schema';

export const UpdateRiderLocationV2BodySchema = z.object({
  location: z.union([
    TransistorsoftLocationSchema,
    z.array(TransistorsoftLocationSchema),
  ]),
});

export const UpdateRiderLocationV2ResponseSchema = z.literal('OK');

export type UpdateRiderLocationV2Body = z.infer<
  typeof UpdateRiderLocationV2BodySchema
>;

export type UpdateRiderLocationV2Response = z.infer<
  typeof UpdateRiderLocationV2ResponseSchema
>;
