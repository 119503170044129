import { z } from "zod";

export const RejectGigJobResponseBodySchema = z.object({
    rejectedReason: z.string().optional(),
  });
  
export const RejectGigJobResponseResponseSchema = z.literal('OK');

export type RejectGigJobResponseBody = z.infer<
  typeof RejectGigJobResponseBodySchema
>;

export type RejectGigJobResponseResponse = z.infer<
  typeof RejectGigJobResponseResponseSchema
>;

