import { z } from 'zod';

export const UpdateProofNameBodySchema = z.object({
  proofName: z.string(),
});

export const UpdateProofNameResponseSchema = z.object({
  stopId: z.string(),
  proofName: z.string(),
});

export type UpdateProofNameBody = z.infer<typeof UpdateProofNameBodySchema>;
export type UpdateProofNameResponse = z.infer<
  typeof UpdateProofNameResponseSchema
>;
