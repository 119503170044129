import React from 'react';
import { Text } from '@chakra-ui/react';

export const FormInputNote: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Text color={'blackAlpha.600'} fontSize={12} mt={1}>
      {children}
    </Text>
  );
};
