import { z } from 'zod';
import { DeliveryOptionIdSchema } from '../../../models';

export const DisableDeliveryOptionBodySchema = z.object({
  deliveryOptionId: DeliveryOptionIdSchema,
  date: z.string().datetime(),
  regionId: z.string(),
});

export const DisableDeliveryOptionResponseSchema = z.boolean();

export type DisableDeliveryOptionBody = z.infer<
  typeof DisableDeliveryOptionBodySchema
>;
export type DisableDeliveryOptionResponse = z.infer<
  typeof DisableDeliveryOptionResponseSchema
>;
