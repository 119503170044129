import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FormInputNote } from '@tradeaze/frontend/ui-web';

interface Props {
  handleSignIn: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => void;
  isLoading: boolean;
  initialUsername?: string;
}

interface SignInInput {
  username: string;
  password: string;
}

const SignInForm = ({ handleSignIn, isLoading, initialUsername }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SignInInput>({ defaultValues: { username: initialUsername } });

  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => setShowPassword(!showPassword);

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSignIn)}>
        {/* USERNAME */}
        <FormControl my={6} isInvalid={Boolean(errors.username)}>
          <FormLabel htmlFor="username">Username or Email</FormLabel>
          <InputGroup>
            <Input
              id="username"
              placeholder="Username or Email"
              {...register('username', {
                required: 'This is required',
              })}
            />
          </InputGroup>
          <FormInputNote>This is a case sensitive field</FormInputNote>
          <FormErrorMessage data-cy="login-username-error">
            <span>{errors.username?.message}</span>
          </FormErrorMessage>
        </FormControl>
        {/* PASSWORD */}
        <FormControl my={6} isInvalid={Boolean(errors.password)}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <InputGroup size="md">
            <Input
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              {...register('password', {
                required: 'This is required',
                minLength: { value: 6, message: 'Minimum length should be 6' },
              })}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {showPassword ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage data-cy="login-password-error">
            <span>{errors.password?.message}</span>
          </FormErrorMessage>
        </FormControl>
        <Button
          colorScheme="yellow"
          isLoading={isLoading}
          type="submit"
          w="100%"
          my={3}
          data-cy="sign-in-button"
        >
          Sign in
        </Button>
      </form>
      <Box textAlign={'center'}>
        <Text fontSize={14} my={1}>
          Not got an account?{' '}
          <Text as={Link} ml={1} color="yellow.600" to="/signup">
            Sign up
          </Text>
        </Text>
        <Text fontSize={14} as={Link} to="/forgot-password">
          Forgot password?
        </Text>
      </Box>
    </Box>
  );
};

export default SignInForm;
