import { z } from 'zod';
import { CategoriesSchema } from '../../../models';

export const UpdateMerchantCategoryBodySchema = CategoriesSchema;

export const UpdateMerchantCategoryResponseSchema = z.array(z.string());

export type UpdateMerchantCategoryBody = z.infer<
  typeof UpdateMerchantCategoryBodySchema
>;

export type UpdateMerchantCategoryResponse = z.infer<
  typeof UpdateMerchantCategoryResponseSchema
>;
