import {
  Card,
  CardBody,
  HStack,
  VStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { DeliveryVehicleIcon } from '../../../delivery/delivery-vehicle/DeliveryVehicleIcon';
import { DeliveryVehicle } from '@tradeaze-packages/schemas';

type DeliveryVehicleCardProps = {
  deliveryVehicle: DeliveryVehicle;
  measurmentUnit: string;
  isSelected: boolean;
  handleClick: () => void;
};

export const DeliveryVehicleCard = ({
  deliveryVehicle,
  measurmentUnit,
  isSelected,
  handleClick,
}: DeliveryVehicleCardProps) => {
  const {
    deliveryVehicleId,
    heightCapacity,
    lengthCapacity,
    name,
    weightCapacity,
    widthCapacity,
  } = deliveryVehicle;

  const formatDimension = (
    value: number | undefined | null,
    measurmentUnit: string
  ): string => {
    if (value === undefined || value === null) return 'N/A';
    switch (measurmentUnit) {
      case 'cm':
        return `${value * 100}cm`;
      case 'mm':
        return `${value * 1000}mm`;
      case 'm':
      default:
        return `${value}m`;
    }
  };

  const height = formatDimension(heightCapacity, measurmentUnit);
  const length = formatDimension(lengthCapacity, measurmentUnit);
  const width = formatDimension(widthCapacity, measurmentUnit);

  const isBase = useBreakpointValue({ base: true, sm: false });

  return (
    <Card
      background={isSelected ? '#FEFAED' : '#F9F9F9'}
      border={isSelected ? '1px solid #AF9032' : '1px solid #D5D5D5'}
      onClick={handleClick}
      cursor={'pointer'}
    >
      <CardBody py={2} px={4}>
        {isBase ? (
          <VStack spacing={1} alignItems="flex-start">
            <HStack gap={4} alignItems="center">
              <DeliveryVehicleIcon
                deliveryVehicle={deliveryVehicleId}
                fontSize={'1rem'}
              />
              <Text fontSize="14px" fontWeight="600">
                {name}
              </Text>
            </HStack>
            <Text fontSize={'14px'} color="#666668">
              Max. Weight {weightCapacity}kg
            </Text>
            <Text fontSize={'14px'} color="#666668">
              L {length} x W {width} x H {height}
            </Text>
          </VStack>
        ) : (
          <VStack spacing={1} alignItems={'flex-start'} flex={1}>
            <HStack>
              <DeliveryVehicleIcon
                deliveryVehicle={deliveryVehicleId}
                fontSize={'1rem'}
              />
              <Text fontSize={'14px'} fontWeight="600">
                {name}
              </Text>
            </HStack>
            <Text fontSize={'14px'} color="#666668">
              Max. Weight {weightCapacity}kg
            </Text>
            <Text fontSize={'14px'} color="#666668">
              L {length} x W {width} x H {height}
            </Text>
          </VStack>
        )}
      </CardBody>
    </Card>
  );
};
