import { useState, useCallback, useMemo } from 'react';

export const useMarkerHover = () => {
  const [hoveredDeliveryId, setHoveredDeliveryId] = useState<string>();

  const onHoverDelivery = useCallback(
    (deliveryId: string) => setHoveredDeliveryId(deliveryId),
    []
  );

  const onHoverLeaveDelivery = useCallback(
    () => setHoveredDeliveryId(undefined),
    []
  );

  return useMemo(
    () => ({
      hoveredDeliveryId,
      onHoverDelivery,
      onHoverLeaveDelivery,
    }),
    [hoveredDeliveryId, onHoverDelivery, onHoverLeaveDelivery]
  );
};
