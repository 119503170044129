import { z } from 'zod';
import { CreateGigJobSchema, GigJobSchema } from '../../../models';

export const GigJobAssignRiderSchema = CreateGigJobSchema.pick({
  paymentAmount: true,
  paymentCurrency: true,
  description: true,
  gigJobId: true,
});

export const AssignRiderBodySchema = z.object({
  sortedStopIds: z.array(z.string()).optional(),
  gigJob: GigJobAssignRiderSchema.optional(),
});

export const AssignRiderResponseSchema = z.object({
  deliveryId: z.string(),
  riderId: z.string().nullable(),
  gigJob: GigJobSchema.pick({
    paymentAmount: true,
    paymentCurrency: true,
    description: true,
  })
    .extend({
      gigJobId: z.string().optional(),
    })
    .optional(),
});

export const AssignRiderErrorSchema = z.object({
  message: z.string(),
});

export type AssignRiderBody = z.infer<typeof AssignRiderBodySchema>;
export type AssignRiderResponse = z.infer<typeof AssignRiderResponseSchema>;
export type AssignRiderError = z.infer<typeof AssignRiderErrorSchema>;
export type GigJobAssignRider = z.infer<typeof GigJobAssignRiderSchema>;
