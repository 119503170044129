import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { fetchRiderLocations } from '@tradeaze-packages/api-client';

type Response = Awaited<ReturnType<typeof fetchRiderLocations>>;

export const useGetRiderLocations = (
  riderIds: string[],
  options?: UseQueryOptions<Response>
) =>
  useQuery<Response>({
    queryKey: ['getRiderLocations', ...riderIds],
    queryFn: async () => fetchRiderLocations(riderIds),
    ...options,
  });
