import { Address, CreateDeliveryStop } from '@tradeaze-packages/schemas';

export function hasAddressChanged(
  savedAddress: Address,
  deliveryStop: Partial<CreateDeliveryStop>
) {
  const fieldsToCompare = [
    'addressLine1',
    'postCode',
    'companyName',
    'instructions',
  ] as const;

  const getDeliveryStopValue = (field: (typeof fieldsToCompare)[number]) => {
    if (field === 'instructions') {
      return deliveryStop?.deliveryNotes || null;
    }
    return deliveryStop[field] || null;
  };

  return fieldsToCompare.some((field) => {
    const savedValue = savedAddress[field] || null;

    const deliveryStopValue = getDeliveryStopValue(field);

    return savedValue !== deliveryStopValue;
  });
}
