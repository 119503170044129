import * as z from 'zod';
import { ClockInWorkTypeSchema, DeliveryVehicleIdSchema } from '../../../models';

export const ClockInRiderBodySchema = z.object({
  regNumber: z.string(),
  deliveryVehicle: DeliveryVehicleIdSchema.optional(),
  fleetVehicleId: z.string().optional(),
  workType: ClockInWorkTypeSchema.optional().default('SHIFT'),
});

export const ClockInRiderResponseSchema = z.object({
  clockedInAt: z.string(),
  clockInId: z.string(),
});

export type ClockInRiderBody = z.infer<typeof ClockInRiderBodySchema>;
export type ClockInRiderResponse = z.infer<typeof ClockInRiderResponseSchema>;
