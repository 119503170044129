import { useEffect, useMemo, useState } from 'react';
import { RiderLocation } from '@tradeaze-packages/schemas';
import { useGetRiderLocations } from './useGetRiderLocations';
import { SupabaseClient } from '@supabase/supabase-js';

const supabase = new SupabaseClient(
  import.meta.env['VITE_SUPABASE_URL'],
  import.meta.env['VITE_SUPABASE_KEY']
);

export const useSubscribeToRiderLocations = (
  riderIds: string[],
  options: {
    enabled: boolean;
  } = { enabled: true }
) => {
  const riderLocationsQuery = useGetRiderLocations(riderIds, {
    enabled: riderIds.length > 0 && options.enabled,
  });

  const [riderLocationMap, setRiderLocationMap] = useState(
    riderLocationsQuery.data
  );

  useEffect(() => {
    setRiderLocationMap(riderLocationsQuery.data);
  }, [riderLocationsQuery.data]);

  useEffect(() => {
    if (!options.enabled) return;

    const channel = supabase
      .channel('rider-location-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'RiderLocation',
        },
        (payload) => {
          const updatedLocation = payload.new as RiderLocation & {
            riderId: string;
          };

          setRiderLocationMap((prev) => ({
            ...prev,
            [updatedLocation.riderId]: updatedLocation,
          }));
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [options.enabled]);

  return useMemo(() => riderLocationMap, [riderLocationMap]);
};

export const useSubscribeToRiderLocation = (
  riderId: string,
  options: {
    enabled: boolean;
  } = { enabled: true }
) => {
  return useSubscribeToRiderLocations([riderId], options)?.[riderId];
};
