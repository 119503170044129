import { NavLink } from 'react-router-dom';
import LogoSvg from '../assets/tradeaze-logo.svg';
import { Box, HStack, Image } from '@chakra-ui/react';
import React from 'react';
import { brandPrimaryColor } from '../theme/colors';

export const NAVBAR_HEIGHT = 80;

export const NavbarCommon: React.FunctionComponent<{
  hideFullNavbar: boolean;
  children: React.ReactNode;
  onHomeClick?: () => void;
}> = ({ hideFullNavbar, children, onHomeClick }) => (
  <Box
    zIndex={3}
    backgroundColor={brandPrimaryColor}
    position="fixed"
    w="100%"
    px={[3, 5, 8]}
    shadow="md"
    overflowX={'auto'}
    h={`${NAVBAR_HEIGHT}px`}
  >
    <HStack
      justify={hideFullNavbar ? 'center' : 'space-between'}
      align="center"
      alignItems={'center'}
      spacing={14}
      height={'100%'}
    >
      <Box minW={'85px'}>
        <NavLink to="/" onClick={onHomeClick}>
          <Image
            objectFit="cover"
            alt="Tradeaze Logo"
            src={LogoSvg}
            width={'85px'}
          />
        </NavLink>
      </Box>
      {!hideFullNavbar && children}
    </HStack>
  </Box>
);
