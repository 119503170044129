import { z } from 'zod';

export const FeatureFlagsSchema = z.object({
  mpMoran: z.boolean().optional(),
  travisPerkins: z.boolean().optional(),
  armaTool: z.boolean().optional(),
  allowLongDistance: z.boolean().optional(),
  hidePriorityDelivery: z.boolean().optional(),
});

export type FeatureFlags = z.infer<typeof FeatureFlagsSchema>;
