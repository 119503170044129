import { IoOptions } from 'react-icons/io5';
import { OrderSection } from '../../OrderSection';
import { ItemDescriptionSection } from './ItemDescriptionSection';
import { ItemTypeSection } from './ItemTypeSection';
import { ItemLoadSection } from './ItemLoadSection';
import { DeliveryVehicleSection } from './DeliveryVehicleSection';
import { VehicleModal } from './VehicleModal';
import { ItemPriceSection } from './ItemPriceSection';
import { DeliveryDateSection } from './DeliveryDateSection';
import { DeliveryOptionSection } from './DeliveryOptionSection';
import { DeliveryWindowAndPriceSection } from './DeliveryWindowAndPriceSection';
import { useDeliveryValues } from './useDeliveryValues';
import { UseFormReturn } from 'react-hook-form';
import { CreateOrder, MerchantAccount } from '@tradeaze-packages/schemas';
import { PostHog } from 'posthog-js/react';
import { useOnEntry } from './useOnEntry';
import { useState } from 'react';
import { Box, Divider, Show, useMediaQuery } from '@chakra-ui/react';
import { PreviewVehicleButton } from '../PreviewVehicleButton';
import { useGetDeliveryPrice } from '@tradeaze/frontend/hooks';

type DeliveryOptionsProps = {
  formMethods: UseFormReturn<CreateOrder>;
  posthog?: PostHog;
  merchantAccount?: MerchantAccount;
  initialDate?: Date;
  isVehicleModalOpen: boolean;
  setIsVehicleModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isAnyStopOverThreshold: boolean;
  isAdmin: boolean;
  hidePriorityDelivery: boolean;
  deliveryPriceQuery: ReturnType<typeof useGetDeliveryPrice>;
};

export const DeliveryDetailsSection = ({
  formMethods,
  posthog,
  merchantAccount,
  initialDate,
  isVehicleModalOpen,
  setIsVehicleModalOpen,
  isAnyStopOverThreshold,
  isAdmin = false,
  hidePriorityDelivery,
  deliveryPriceQuery,
}: DeliveryOptionsProps) => {
  const [isPrimaryView, setIsPrimaryView] = useState(true);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const {
    filteredDeliveryOptions,
    isDeliveryOptionDisabled,
    selectedVehicle,
    deliveryVehicles,
    deliveryOptionsQuery,
    fromDeliveryDateTime,
    scheduledTime,
    isPastDate,
    isSaturday,
    isInvalidScheduledTime,
    handleSelectDeliveryDate,
    handleChangeScheduledTime,
    deliveryVehicleId,
    deliveryOptionId,
    deliveryPrice,
    serviceCharge,
    deliveryWindowStart,
    deliveryWindowEnd,
    isSectionComplete,
    totalTradeazeCharges,
    showDeliveryPrice,
    isVehicleAvailable,
    handleOptionAndItemsOnDeliveryVehicle,
    scaleDimensionsMaintainingAspectRatio,
  } = useDeliveryValues({
    initialDate,
    merchantAccount,
    isAdmin,
    hidePriorityDelivery,
    isAnyStopOverThreshold,
    isDeliveryPriceLoading: deliveryPriceQuery.isLoading,
  });

  const ref = useOnEntry(() => setIsVehicleModalOpen(true));

  return (
    <div ref={ref}>
      <OrderSection
        name="Delivery Details"
        icon={<IoOptions />}
        isComplete={isSectionComplete}
        hideHeaderButton={isMobile}
      >
        <Box display={'flex'} flexDirection={'column'} gap={8}>
          <ItemDescriptionSection formMethods={formMethods} />
          <ItemTypeSection posthog={posthog} />
          <ItemLoadSection setIsPrimaryView={setIsPrimaryView} />
          <ItemPriceSection />
          <Divider />
          <DeliveryVehicleSection
            deliveryVehicles={deliveryVehicles}
            handleOptionAndItemsOnDeliveryVehicle={
              handleOptionAndItemsOnDeliveryVehicle
            }
          />

          <DeliveryDateSection
            fromDeliveryDateTime={fromDeliveryDateTime}
            handleSelectDeliveryDate={handleSelectDeliveryDate}
            isAdmin={isAdmin}
            isSaturday={isSaturday}
            isPastDate={isPastDate}
          />
          <DeliveryOptionSection
            deliveryVehicleId={deliveryVehicleId}
            isAnyStopOverThreshold={isAnyStopOverThreshold}
            deliveryOptionsQueryLoading={deliveryOptionsQuery.isLoading}
            deliveryOptionsQueryError={deliveryOptionsQuery.error}
            filteredDeliveryOptions={filteredDeliveryOptions}
            isDeliveryOptionDisabled={isDeliveryOptionDisabled}
            deliveryOptionId={deliveryOptionId}
            isInvalidScheduledTime={isInvalidScheduledTime}
            scheduledTime={scheduledTime}
            handleChangeScheduledTime={handleChangeScheduledTime}
            isVehicleAvailable={isVehicleAvailable}
          />
          <DeliveryWindowAndPriceSection
            deliveryWindowStart={deliveryWindowStart}
            deliveryWindowEnd={deliveryWindowEnd}
            deliveryOptionsQueryLoading={deliveryOptionsQuery.isLoading}
            deliveryPriceQueryLoading={deliveryPriceQuery.isFetching}
            deliveryPriceQueryErrorMessage={deliveryPriceQuery.error?.error.message || null}
            showDeliveryPrice={showDeliveryPrice}
            isAdmin={isAdmin}
            deliveryPrice={deliveryPrice}
            serviceCharge={serviceCharge}
            totalTradeazeCharges={totalTradeazeCharges}
          />
        </Box>
      </OrderSection>
      <Show above={'md'}>
        <VehicleModal
          isVehicleModalOpen={isVehicleModalOpen}
          setIsVehicleModalOpen={setIsVehicleModalOpen}
          selectedVehicle={selectedVehicle}
          loadDimensions={scaleDimensionsMaintainingAspectRatio}
          isPrimaryView={isPrimaryView}
          setIsPrimaryView={setIsPrimaryView}
          isVehicleAvailable={isVehicleAvailable}
        />
        <PreviewVehicleButton setIsVehicleModalOpen={setIsVehicleModalOpen} />
      </Show>
    </div>
  );
};
