import { z } from 'zod';
import { HydratedOrderSchema, OrdersQueryParamsSchema } from '../../../models';

export const GetOrdersByMerchantIdQuerySchema = OrdersQueryParamsSchema.omit({
  merchantId: true,
});

export const GetOrdersByMerchantIdResponseSchema = z.object({
  orders: z.array(HydratedOrderSchema),
  nextPageIndex: z.number().nullable(),
});

export type GetOrdersByMerchantIdQuery = z.infer<
  typeof GetOrdersByMerchantIdQuerySchema
>;

export type GetOrdersByMerchantIdResponse = z.infer<
  typeof GetOrdersByMerchantIdResponseSchema
>;
