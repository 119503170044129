import { Box, Center, Icon, Text } from '@chakra-ui/react';
import { TradeazeLogoNoText } from '../brand/TradeazeLogoNoText';

export const EmptyMerchantCustomersPage = () => {
  return (
    <Center h="60vh" flexDirection={'column'}>
      <Box>
        <Icon as={TradeazeLogoNoText} fontSize={200} h="120" />
      </Box>
      <Box>
        <Text>No Customers</Text>
      </Box>
    </Center>
  );
};
