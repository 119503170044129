import { useQuery } from '@tanstack/react-query';
import { Storage } from 'aws-amplify';

export const useGetRiderProfilePic = (
  profilePicKey: string | null | undefined,
  profilePicBucket: string
) => {
  return useQuery(
    ['riderProfilePic', profilePicKey],
    async () => {
      if (!profilePicKey) {
        return undefined;
      }
      const url = await Storage.get(profilePicKey, {
        bucket: profilePicBucket,
      });
      return url;
    },
    {
      enabled: Boolean(profilePicKey),
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );
};
