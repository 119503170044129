import { Box, Center, Container, Heading, Text } from '@chakra-ui/react';
import ConfirmForgotPasswordForm from '../components/auth/ConfirmForgotPasswordForm';
import { useLocation } from 'react-router-dom';
import { AuthPageAlert, RouterStateAlert } from './AuthPageAlert';

const ConfirmForgotPasswordPage = () => {
  const location: {
    state: {
      username?: string;
      alert?: RouterStateAlert;
    };
  } = useLocation();

  const alert = location.state?.alert;
  const username = location.state?.username;

  const routerStateHasUsername = username !== undefined;

  if (routerStateHasUsername) {
    return (
      <Center h="60vh">
        <Container maxW="72">
          <ConfirmForgotPasswordForm username={username} />
        </Container>
        {alert ? (
          <AuthPageAlert message={alert.message} status={alert.status} />
        ) : null}
      </Center>
    );
  }

  return (
    <Center h="60vh">
      <Box textAlign={'center'}>
        <Heading>Error</Heading>
        <Text>Could not find username to confirm. Contact Tradeaze.</Text>
      </Box>
    </Center>
  );
};

export default ConfirmForgotPasswordPage;
