export const getDeliveryProgressPercentage = (
  deliveryWindowStart?: string,
  deliveryWindowEnd?: string
) => {
  if (!deliveryWindowStart || !deliveryWindowEnd) return null;

  const now = new Date().getTime();
  const start = new Date(deliveryWindowStart).getTime();
  const end = new Date(deliveryWindowEnd).getTime();

  const totalDuration = end - start;
  const elapsedDuration = Math.max(now - start, 0);

  return Math.round((elapsedDuration / totalDuration) * 100);
};
