import { Box, Text } from '@chakra-ui/react';
import { AutocompleteAddress, getAddress } from '@tradeaze/shared/services';
import { useMemo, useState } from 'react';
import { SearchDropdown } from '../shared/SearchDropdown';
import { handleError } from '@tradeaze/frontend/utils';
import { useFindAddresses } from './useFindAddresses';

export const FindAddressesInput: React.FC<{
  onAddressSelected: (address: AutocompleteAddress) => void;
}> = ({ onAddressSelected }) => {
  const [query, setQuery] = useState('');

  const isValidPostcode = useMemo(() => {
    const postcodeRegex = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;
    return postcodeRegex.test(query);
  }, [query]);

  const { data: addressSuggestions, isFetching } = useFindAddresses(query, {
    enabled: isValidPostcode,
  });

  const noResults =
    isValidPostcode && !isFetching && !addressSuggestions?.length;

  const handleSearchChange = (newValue: string) => {
    setQuery(newValue ?? '');
  };

  const handleSelect = async (id: string) => {
    try {
      const address = await getAddress(id, {
        apiKey: import.meta.env['VITE_GET_ADDRESS_API_KEY'],
      });
      onAddressSelected(address);
    } catch (error) {
      handleError(error, {
        sendToSentry: true,
        consoleLog: true,
      });
    }
  };

  const addressResults = useMemo(
    () =>
      addressSuggestions?.map((suggestion) => ({
        label: suggestion.address,
        value: suggestion.id,
      })),
    [addressSuggestions]
  );

  return (
    <Box>
      <SearchDropdown
        value={query}
        inputPlaceholder="Postcode"
        isLoading={isFetching}
        onChange={handleSearchChange}
        onSelect={handleSelect}
        results={addressResults}
        disableSearch={!isValidPostcode}
      />
      {noResults ? (
        <Text fontSize={14} mt={1} color={'red.500'}>
          No results found
        </Text>
      ) : null}
      {!isValidPostcode && query.length > 0 ? (
        <Text fontSize={14} mt={1} color={'red.500'}>
          Invalid Postcode
        </Text>
      ) : null}
    </Box>
  );
};
