import { Box, Center, Icon, Text } from '@chakra-ui/react';
import { TradeazeLogoNoText } from '../brand/TradeazeLogoNoText';

export const PageError: React.FunctionComponent<{ text?: string }> = ({
  text = 'Something went wrong',
}) => {
  return (
    <Center h="60vh" flexDirection={'column'} color="orange.300">
      <Box>
        <Icon
          as={TradeazeLogoNoText}
          fill={'orange.300'}
          fontSize={200}
          h="120"
        />
      </Box>
      <Box>
        <Text>{text}</Text>
      </Box>
    </Center>
  );
};
