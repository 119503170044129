import { z } from 'zod';

export const RiderStatsResponseSchema = z.object({
  ridersCount: z.object({
    total: z.number(),
    approved: z.number(),
    pending: z.number(),
    rejected: z.number(),
  }),
});

export type RiderStatsResponse = z.infer<
  typeof RiderStatsResponseSchema
>;
