import { DeliveryStop } from '@tradeaze-packages/schemas';
import { ProofType } from '../types';

export const getRequiresPod = (
  stop: {
    type: DeliveryStop['type'];
    requiresSignature?: DeliveryStop['requiresSignature'] | null;
    requiresImage?: DeliveryStop['requiresImage'] | null;
  },
  type: ProofType
): boolean => {
  if (type === 'signature') {
    return stop.type === 'DELIVERY' || stop.requiresSignature || false;
  }
  if (type === 'image') {
    return stop.type === 'DELIVERY' || stop.requiresImage || false;
  }
  return false;
};
