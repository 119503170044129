import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GetRidersQuery, RiderAccount } from '@tradeaze-packages/schemas';
import { fetchRiderAccounts } from '@tradeaze-packages/api-client';

export const getRidersQueryKey = (filters?: GetRidersQuery) => {
  const key: (string | object)[] = ['getRiders']
  if (filters) {
    key.push(filters);
  }
  return key;
}

export const useGetRiders = (
  filters: GetRidersQuery,
  options?: UseQueryOptions<RiderAccount[]>
) => {
  return useQuery<RiderAccount[]>({
    queryKey: getRidersQueryKey(filters),
    queryFn: async () => fetchRiderAccounts(filters),
    ...options,
  });
};
