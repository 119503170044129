import { z } from 'zod';
import { DeliveryOptionIdSchema } from '../../../models';

export const EnableDeliveryOptionBodySchema = z.object({
  deliveryOptionId: DeliveryOptionIdSchema,
  regionId: z.string(),
  date: z.string().datetime(),
});

export const EnableDeliveryOptionResponseSchema = z.boolean();

export type EnableDeliveryOptionBody = z.infer<
  typeof EnableDeliveryOptionBodySchema
>;
export type EnableDeliveryOptionResponse = z.infer<
  typeof EnableDeliveryOptionResponseSchema
>;
