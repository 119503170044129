import { HStack, Icon, Text } from '@chakra-ui/react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { FaLongArrowAltRight } from 'react-icons/fa';

export const DeliveryPostcodes: React.FC<{
  pickup: HydratedDelivery['pickup'];
  dropOff: HydratedDelivery['dropOff'];
}> = ({ pickup, dropOff }) => {
  return (
    <HStack>
      <Text noOfLines={1}>{pickup.postCode?.toLocaleUpperCase()}</Text>
      <Icon as={FaLongArrowAltRight} color="blackAlpha.500" />
      <Text noOfLines={1}>{dropOff.postCode?.toLocaleUpperCase()}</Text>
    </HStack>
  );
};
