import { Box } from '@chakra-ui/react';
import { CreateOrder } from '@tradeaze-packages/schemas';
import { useFormContext } from 'react-hook-form';
import { FormAttribute } from '../../../form';

export const ItemPriceSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CreateOrder>();
  return (
    <Box>
      <FormAttribute
        id={'deliveryItems.0.price'}
        inputType={'number'}
        currency="£"
        label={'Total Item Price (exc. VAT)'}
        isRequired={true}
        error={errors.deliveryItems?.[0]?.price}
        description="Required to insure your items"
        {...register('deliveryItems.0.price', {
          setValueAs: (value) => (value === '' ? undefined : parseFloat(value)),
        })}
      />
    </Box>
  );
};
