import React from 'react';
import { DescriptionColorScheme } from '../../utils/getDescriptionColorScheme';
import { Box, Text } from '@chakra-ui/react';

export const ListCardStatusDescription: React.FC<{
  colorScheme: DescriptionColorScheme;
  statusDescription: string;
}> = ({ colorScheme, statusDescription }) => {
  const borderColor = {
    yellow: 'yellow.300',
    blue: 'blue.300',
    green: 'green.300',
    red: 'red.300',
    gray: 'gray.300',
  }[colorScheme];

  const backgroundColor = {
    yellow: 'yellow.50',
    blue: 'blue.50',
    green: 'green.50',
    red: 'red.50',
    gray: 'gray.50',
  }[colorScheme];

  return (
    <Box
      borderTopWidth={1}
      borderColor={borderColor}
      paddingY={2}
      paddingX={4}
      backgroundColor={backgroundColor}
      fontSize={'0.9em'}
    >
      <Text>{statusDescription}</Text>
    </Box>
  );
};
