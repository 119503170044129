import { z } from 'zod';
import { DistanceAndDurationSchema } from '../delivery-price';

export const GetThresholdDistancesResponseSchema = z.object({
  thresholdDistances: z.array(DistanceAndDurationSchema),
});

export const GetThresholdDistancesArrayQuerySchema = z.object({
  stops: z.string().array(),
});

export type GetThresholdDistancesResponse = z.infer<
  typeof GetThresholdDistancesResponseSchema
>;

export type GetThresholdDistancesArrayQuery = z.infer<
  typeof GetThresholdDistancesArrayQuerySchema
>;