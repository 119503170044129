import { buildProofFilePath } from '@tradeaze-packages/proof-of-delivery';
import { Storage } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import { Button, HStack, Text } from '@chakra-ui/react';
import { FiUpload } from 'react-icons/fi';

export const UploadProof: React.FC<{
  orderId: string;
  stopId: string;
  bucket: string;
  onUploadComplete: () => void;
}> = ({ orderId, stopId, bucket, onUploadComplete }) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    const s3Key = buildProofFilePath({
      orderId,
      stopId,
      type: 'image',
      date: new Date(),
    });
    try {
      await Storage.put(s3Key, file, {
        bucket,
        level: 'public',
      });
      toast.success('Upload successful');
      onUploadComplete();
    } catch (error) {
      toast.error('Failed to upload image');
    }
  };

  return (
    <label>
      <input
        type="file"
        onChange={onChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
      <Button size={'sm'} as="span" cursor={'pointer'}>
        <HStack>
          <FiUpload />
          <Text>Upload Image</Text>
        </HStack>
      </Button>
    </label>
  );
};
