import { HydratedOrder, UpdateOrder } from '@tradeaze-packages/schemas';

// temporary function - delete or update after multi-drop introduction
export const translateHydratedOrderToUpdateOrder = (
  hydratedOrder: HydratedOrder
): UpdateOrder => {
  const allPickups = hydratedOrder.deliveries.flatMap((delivery) => [
    delivery.pickup,
  ]);
  const lastDropOff =
    hydratedOrder.deliveries[hydratedOrder.deliveries.length - 1]?.dropOff;

  /**
   * TEMP: We are dividing the width of the items by the number of deliveries on the backend
   * So we need to multiply the width by the number of deliveries again for now.
   * Remove after we have a dedicated order type schemas and delivery items are part of each delivery.
   */
  const numberOfDeliveries = hydratedOrder.deliveries.length;

  const adjustedSizeDeliveryItems =
    hydratedOrder.deliveries[0]?.deliveryItems.map((item) => {
      return {
        ...item,
        width: item.width * numberOfDeliveries,
        weight: item.weight * numberOfDeliveries,
      };
    });

  return {
    orderId: hydratedOrder.orderId,
    bookedBy: hydratedOrder.bookedBy,
    deliveryPrice: hydratedOrder.deliveryPrice,
    merchantServiceCharge: hydratedOrder.merchantServiceCharge,
    deliveryWindowStart: hydratedOrder.deliveryWindowStart,
    deliveryWindowEnd: hydratedOrder.deliveryWindowEnd,
    companyId: hydratedOrder.companyId,
    companyName: hydratedOrder.companyName || '',
    confirmedAt: hydratedOrder.confirmedAt || undefined,
    createdAt: hydratedOrder.createdAt || undefined,
    deliveredAt: hydratedOrder.deliveredAt || undefined,
    deliveryDuration: hydratedOrder.deliveryDuration,
    deliveryDistance: hydratedOrder.deliveryDistance,
    endUserId: hydratedOrder.endUserId,
    isPriceEdited: hydratedOrder.isPriceEdited,
    merchantOrderReference: hydratedOrder.merchantOrderReference || '',
    orderStatus: hydratedOrder.orderStatus,
    updatedAt: hydratedOrder.updatedAt,
    notes: hydratedOrder.notes,
    metadata: hydratedOrder.metadata,
    deliveryStops: [...allPickups, lastDropOff].filter(Boolean),
    deliveryItems: adjustedSizeDeliveryItems || [],
    deliveryOption: hydratedOrder.deliveries[0]?.deliveryOptionId,
    deliveryVehicle: hydratedOrder.deliveries[0]?.deliveryVehicleId,
    heavySideItems: hydratedOrder.deliveries[0]?.heavySideItems || false,
    invoicingName: hydratedOrder.invoicingName || '',
    merchantId: hydratedOrder.merchantId || undefined,
  };
};
