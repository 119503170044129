import { z } from 'zod';

export const AppNameSchema = z.enum([
  'merchant-app',
  'admin-app',
  'tracking-app',
  'rider-app',
]);

export const EnvironmentSchema = z.enum(['prod', 'stage', 'dev', 'test', 'ci']);

export type AppName = z.infer<typeof AppNameSchema>;
export type Environment = z.infer<typeof EnvironmentSchema>;
