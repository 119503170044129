import { z } from 'zod';
import { CreateOrderSchema, OrderSchema } from '../../../models';

export const CreateOrderBodySchema = CreateOrderSchema;

export const CreateOrderResponseSchema = OrderSchema;

export type CreateOrderBody = z.infer<typeof CreateOrderBodySchema>;

export type CreateOrderResponse = z.infer<typeof CreateOrderResponseSchema>;
