import React from 'react';
import { HStack, Stack, Text } from '@chakra-ui/react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { VscDebugStart } from 'react-icons/vsc';

export const PickupMarker: React.FC<{
  stopSequence?: number;
  opacity?: number;
}> = ({ stopSequence, opacity }) => {
  return (
    <Stack
      opacity={opacity}
      direction={'column'}
      alignItems="center"
      spacing={1}
    >
      <HStack
        bg="white"
        borderRadius={10}
        paddingX={2}
        fontSize={14}
        spacing={0.5}
      >
        <VscDebugStart size={20} color={'#2B6CB0'} />
        <Text>
          Pick Up{stopSequence !== undefined ? ` - ${stopSequence + 1}` : ''}
        </Text>
      </HStack>
      <FaMapMarkerAlt size={34} color={'#2B6CB0'} />
    </Stack>
  );
};
