import React from 'react';
import { Box } from '@chakra-ui/react';
import { Company } from '@tradeaze-packages/schemas';
import { MerchantCustomerPreviewGrid } from './MerchantCustomerPreviewCard';
import { PageError, PageLoading } from '../layout';
import { EmptyMerchantCustomersPage } from './EmptyMerchantCustomersPage';

export const MerchantCustomerQueryPage = (props: {
  children?: React.ReactNode;
  merchantId: string;
  merchantCustomers: Company[];
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  isError: boolean;
  columns?: number[];
}) => (
  <Box>
    {props.merchantCustomers?.length && props.merchantCustomers.length > 0 ? (
      <MerchantCustomerPreviewGrid
        merchantCustomers={props.merchantCustomers}
        onWaypointEnter={props.fetchNextPage}
        hasNextPage={props.hasNextPage}
        isFetchingNextPage={props.isFetchingNextPage}
        columns={props.columns}
        merchantId={props.merchantId}
      />
    ) : props.isLoading ? (
      <PageLoading />
    ) : props.isError ? (
      <PageError />
    ) : (
      <EmptyMerchantCustomersPage />
    )}
  </Box>
);
