import { Text } from '@chakra-ui/react';
import { Contact } from './ContactCard';

type CollapsedContactFormProps = {
  contact: Contact;
};

export const CollapsedContactForm = ({
  contact,
}: CollapsedContactFormProps) => {
  const nameAndNumber = [contact.contactName, contact.contactNumber]
    .filter(Boolean)
    .join(' | ');

  const completeDataText = 'Please complete details';

  const showCompleteDetails = !contact.contactName || !contact.contactNumber;

  return (
    <>
      <Text
        fontSize="sm"
        color={showCompleteDetails ? 'red.500' : 'gray.500'}
        mb={1}
      >
        {showCompleteDetails ? completeDataText : nameAndNumber}
      </Text>
      <Text fontSize="sm" color="gray.500" mb={2}>
        Tracking SMS:{' '}
        <span style={{ fontWeight: '600', color: '#3A3A3C' }}>
          {contact.trackingEnabled ? 'YES' : 'NO'}
        </span>
      </Text>
    </>
  );
};
