import { z } from 'zod';
import { MerchantSiteContactSchema, SiteContactSchema } from '../../../models';

export const UpdateSiteContactBodySchema =
  MerchantSiteContactSchema.partial().strict();

export const UpdateSiteContactResponseSchema = z.object({
  message: z.string(),
  data: SiteContactSchema,
});

export type UpdateSiteContactBody = z.infer<typeof UpdateSiteContactBodySchema>;

export type UpdateSiteContactResponse = z.infer<
  typeof UpdateSiteContactResponseSchema
>;
