import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { MERCHANT_APP_VERSION } from '@tradeaze/frontend/versioning';

posthog.init(import.meta.env['VITE_MERCHANT_POSTHOG_API_KEY'], {
  api_host: import.meta.env['VITE_POSTHOG_HOST'],
  mask_all_text: false,
});

Sentry.init({
  enabled: import.meta.env['VITE_ENV_NAME'] === 'prod',
  dsn:
    import.meta.env['VITE_ENV_NAME'] === 'prod'
      ? import.meta.env['VITE_MERCHANT_SENTRY_DSN']
      : undefined,
  integrations: [
    new Sentry.BrowserTracing(),
    new posthog.SentryIntegration(
      posthog,
      import.meta.env['VITE_SENTRY_ORGANIZATION'],
      Number(import.meta.env['VITE_MERCHANT_SENTRY_PROJECT_ID'])
    ),
  ],
  tracesSampleRate: 1.0,
  environment: import.meta.env['VITE_ENV_NAME'],
  release: `merchant-app-${MERCHANT_APP_VERSION}`,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <Sentry.ErrorBoundary
        fallback={
          <div
            style={{
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p>
              Oops! We've run into an error. Please reload the page to get back
              to work.
            </p>
          </div>
        }
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </PostHogProvider>
  </React.StrictMode>
);
