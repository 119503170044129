import { UseFormReturn } from 'react-hook-form';
import { OrderSection } from '../OrderSection';
import { CreateOrder } from '@tradeaze-packages/schemas';
import {
  orderKeyToDescription,
  orderKeyToLabel,
  orderKeyToPlaceholder,
  travisPerkinsOrderKeyToDescription,
  travisPerkinsOrderKeyToLabel,
  travisPerkinsOrderKeyToPlaceholder,
} from '@tradeaze/shared/utils';
import { FormAttribute } from '../../form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useFieldVisibility } from './hooks/useFieldVisibility';
import { Button } from '@chakra-ui/react';

const inputKeys = {
  bookedBy: 'bookedBy',
  merchantOrderReference: 'merchantOrderReference',
  notes: 'notes',
} as const;

export const AdditionalInfoFormSection: React.FC<{
  formMethods: UseFormReturn<CreateOrder>;
  isTravisPerkins?: boolean;
  referencePrefix?: string | null;
}> = ({ formMethods, isTravisPerkins = false, referencePrefix }) => {
  const {
    setValue,
    register,
    watch,
    formState: { errors },
    getValues,
    getFieldState,
  } = formMethods;

  const notesVisibility = useFieldVisibility(Boolean(getValues('notes')));

  const bookedBy = watch(inputKeys.bookedBy);
  const merchantOrderReference = watch(inputKeys.merchantOrderReference);

  const onChangeMerchantOrderReference = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isTravisPerkins) {
      return;
    }
    const allowedText = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
    setValue('merchantOrderReference', allowedText);
  };

  const labelMap = isTravisPerkins
    ? travisPerkinsOrderKeyToLabel
    : orderKeyToLabel;

  const descriptionMap = isTravisPerkins
    ? travisPerkinsOrderKeyToDescription
    : orderKeyToDescription;

  const placeholderMap = isTravisPerkins
    ? travisPerkinsOrderKeyToPlaceholder
    : orderKeyToPlaceholder;

  const isSectionComplete =
    Boolean(bookedBy) &&
    Boolean(merchantOrderReference) &&
    merchantOrderReference !== referencePrefix &&
    !getFieldState(inputKeys.bookedBy).invalid &&
    !getFieldState(inputKeys.merchantOrderReference).invalid;

  return (
    <OrderSection
      name="Additional Information"
      icon={<AiOutlineInfoCircle />}
      isComplete={isSectionComplete}
    >
      {/* BOOKED BY */}
      <FormAttribute
        id={inputKeys.bookedBy}
        label={labelMap[inputKeys.bookedBy]}
        description={descriptionMap[inputKeys.bookedBy]}
        placeholder={placeholderMap[inputKeys.bookedBy]}
        isRequired={true}
        error={errors[inputKeys.bookedBy]}
        mb={4}
        {...register(inputKeys.bookedBy)}
      />
      {/* MERCHANT ORDER REFERENCE */}
      <FormAttribute
        id={inputKeys.merchantOrderReference}
        label={labelMap[inputKeys.merchantOrderReference]}
        description={descriptionMap[inputKeys.merchantOrderReference]}
        placeholder={placeholderMap[inputKeys.merchantOrderReference]}
        isRequired={true}
        error={errors[inputKeys.merchantOrderReference]}
        mb={4}
        {...register(inputKeys.merchantOrderReference, {
          onChange: onChangeMerchantOrderReference,
        })}
      />
      {/* NOTES FOR OPERATOR */}
      {notesVisibility.isVisible ? (
        <FormAttribute
          id={inputKeys.notes}
          label={labelMap.notes}
          description={descriptionMap.notes}
          isRequired={false}
          error={errors.notes}
          inputType={'textarea'}
          {...register('notes')}
        />
      ) : (
        <Button
          colorScheme="blue"
          variant={'link'}
          onClick={notesVisibility.handleShow}
        >
          Add {labelMap.notes}
        </Button>
      )}
    </OrderSection>
  );
};
