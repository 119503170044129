import { Spinner, Stack, Text } from '@chakra-ui/react';
import { DeliveryOptionId } from '@tradeaze-packages/schemas';

type DeliveryTotalSectionProps = {
  deliveryPriceQueryLoading: boolean;
  showDeliveryPrice: false | DeliveryOptionId;
  totalTradeazeCharges: number;
  deliveryPriceQueryErrorMessage: string | null;
};

export const DeliveryTotalSection = ({
  deliveryPriceQueryLoading,
  showDeliveryPrice,
  totalTradeazeCharges,
  deliveryPriceQueryErrorMessage,
}: DeliveryTotalSectionProps) => {
  return (
    <Stack justify={'space-between'} spacing={4} direction={['column', 'row']}>
      <Text fontWeight={'bold'}>
        Total{' '}
        <Text fontSize={14} color={'grey'}>
          (exc. VAT)
        </Text>
      </Text>
      {deliveryPriceQueryLoading ? (
        <Spinner />
      ) : showDeliveryPrice ? (
        <Text fontSize={18} fontWeight={'bold'}>
          £{totalTradeazeCharges.toFixed(2)}
        </Text>
      ) : (
        <Text color={'red.500'}>{deliveryPriceQueryErrorMessage || 'Missing required info'}</Text>
      )}
    </Stack>
  );
};
