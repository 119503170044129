import { z } from 'zod';

export const FeatureFlagTypeSchema = z.enum(['GLOBAL', 'MERCHANT', 'RIDER']);

export const FeatureFlagSchema = z.object({
  featureFlagId: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  enabled: z.boolean(),
  type: FeatureFlagTypeSchema,
});

export const CreateFeatureFlagSchema = FeatureFlagSchema.extend({
  featureFlagId: z.string().optional(),
  enabled: z.boolean().optional(),
});
export type CreateFeatureFlagBody = z.infer<typeof CreateFeatureFlagSchema>;

export const UpdateFeatureFlagSchema = FeatureFlagSchema;

export type UpdateFeatureFlagBody = z.infer<typeof UpdateFeatureFlagSchema>;

export const CreateFeatureFlagResponseSchema = z.object({
  message: z.string(),
  data: FeatureFlagSchema,
});

export const UpdateFeatureFlagResponseSchema = z.object({
  message: z.string(),
  data: UpdateFeatureFlagSchema,
});

export const UpdateMerchantFeatureFlagSchema = z.object({
  enabled: z.boolean(),
});

export const UpdateMerchantFeatureFlagResponseSchema =
  UpdateMerchantFeatureFlagSchema.extend({
    featureFlagId: z.string(),
    merchantId: z.string(),
  });

export const DeleteFeatureFlagResponseSchema = z.literal('OK');

export const GetFeatureFlagSchema = z.object({
  type: FeatureFlagTypeSchema.optional(),
  merchantId: z.string().optional(),
});

export const GetFeatureFlagsResponseSchema = z.array(FeatureFlagSchema);

export type CreateFeatureFlagResponse = z.infer<
  typeof CreateFeatureFlagResponseSchema
>;

export type UpdateFeatureFlagResponse = z.infer<
  typeof UpdateFeatureFlagResponseSchema
>;

export type UpdateFeatureFlag = z.infer<typeof UpdateFeatureFlagSchema>;

export type DeleteFeatureFlagResponse = z.infer<
  typeof DeleteFeatureFlagResponseSchema
>;

export type UpdateMerchantFeatureFlagResponse = z.infer<
  typeof UpdateMerchantFeatureFlagResponseSchema
>;

export type GetFeatureFlagsResponse = z.infer<
  typeof GetFeatureFlagsResponseSchema
>;

export type FeatureFlagType = z.infer<typeof FeatureFlagTypeSchema>;

export type UpdateMerchantFeatureFlagBody = z.infer<
  typeof UpdateMerchantFeatureFlagSchema
>;

export type FeatureFlag = z.infer<typeof FeatureFlagSchema>;
