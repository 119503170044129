import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { MerchantAccount } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

export const getMerchantQueryKey = (id: string) => [
  'getMerchantAccount',
  { id },
];

export const useGetMerchantAccount = (
  cognitoUserId: string | undefined | null,
  options?: UseQueryOptions<MerchantAccount>
) => {
  return useQuery<MerchantAccount>(
    getMerchantQueryKey(cognitoUserId ?? ''),
    async () => {
      const apiName = 'MerchantApi';
      const path = '/getMerchantById/' + cognitoUserId;
      const options = {};
      const response = await API.get(apiName, path, options);
      return response;
    },
    {
      enabled: Boolean(cognitoUserId),
      refetchOnWindowFocus: false,
      retry: false,
      ...options,
    }
  );
};
