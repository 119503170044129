import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { useGetAllMerchants } from '@tradeaze/frontend/hooks';
import { useMemo } from 'react';

const MerchantMenuItemOption: React.FC<
  React.ComponentProps<typeof MenuItemOption> & {
    merchantId: string;
    merchantName: string;
    handleChangeMerchantFilter: (merchantId: string) => void;
  }
> = ({ merchantId, merchantName, handleChangeMerchantFilter, ...props }) => {
  return (
    <MenuItemOption
      {...props}
      width="350px"
      onClick={() => handleChangeMerchantFilter(merchantId)}
    >
      {merchantName}
    </MenuItemOption>
  );
};

export const MerchantFilterMenu: React.FC<{
  handleMerchantFilterChange: (merchantId?: string) => void;
  selectedMerchantId?: string;
}> = ({ handleMerchantFilterChange, selectedMerchantId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const merchantQuery = useGetAllMerchants({ enabled: isOpen });

  const filterTitle = useMemo(() => {
    if (!merchantQuery.data || !selectedMerchantId) return 'Merchant';
    const merchant = merchantQuery.data.find(
      (merchant) => merchant.merchantId === selectedMerchantId
    );
    if (!merchant) return null;
    return `${merchant.merchantName}`;
  }, [merchantQuery.data, selectedMerchantId]);

  const ListItems = useMemo(() => {
    if (!merchantQuery.data) return null;
    return merchantQuery.data
      .sort((a, b) => {
        // Sort by merchant name
        if (a.merchantName < b.merchantName) return -1;
        if (a.merchantName > b.merchantName) return 1;
        return 0;
      })
      .map((merchant) => (
        <MerchantMenuItemOption
          key={merchant.merchantId}
          merchantId={merchant.merchantId}
          merchantName={merchant.merchantName}
          handleChangeMerchantFilter={handleMerchantFilterChange}
          isChecked={selectedMerchantId === merchant.merchantId}
        />
      ));
  }, [merchantQuery.data, selectedMerchantId, handleMerchantFilterChange]);

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      closeOnSelect={false}
    >
      <MenuButton
        as={Button}
        colorScheme={'gray'}
        size={'sm'}
        rightIcon={<ChevronDownIcon />}
        onClick={onOpen}
        color={selectedMerchantId ? 'black' : 'gray.500'}
      >
        {filterTitle}
      </MenuButton>
      <MenuList maxHeight={'300px'} overflow="scroll" minWidth={'200px'}>
        <MenuItemOption
          isChecked={selectedMerchantId === undefined}
          onClick={() => handleMerchantFilterChange(undefined)}
        >
          All
        </MenuItemOption>
        {ListItems}
      </MenuList>
    </Menu>
  );
};
