import { CreateOrder } from '@tradeaze-packages/schemas';
import { UseFormReturn } from 'react-hook-form';
import { OrderSection } from '../OrderSection';
import { BiReceipt } from 'react-icons/bi';
import { FormAttribute } from '../../form';
import { orderKeyToLabel } from '@tradeaze/shared/utils';

export const InvoicingDetailsFormSection: React.FC<{
  formMethods: UseFormReturn<CreateOrder>;
}> = ({ formMethods }) => {
  const {
    register,
    watch,
    formState: { errors },
    getFieldState,
  } = formMethods;

  const invoicingName = watch('invoicingName');

  const isSectionComplete =
    Boolean(invoicingName) && !getFieldState('invoicingName').invalid;

  return (
    <OrderSection
      name="Invoicing Details"
      icon={<BiReceipt />}
      isComplete={isSectionComplete}
    >
      <FormAttribute
        id={'invoicingName'}
        label={orderKeyToLabel['invoicingName']}
        error={errors.invoicingName}
        placeholder={'Invoicing Name'}
        isRequired={true} // this component will only be rendered when orderType is 'GUEST' - so always required
        description={'The name to be used for invoicing purposes'}
        {...register('invoicingName')}
      />
    </OrderSection>
  );
};
