import { Position } from '@tradeaze-packages/schemas';

import { useCallback, useState } from 'react';
import { PickupMarker } from '../PickupMarker';
import { MapMarkerProps } from '../Map';
import { DropOffMarker } from '../DropOffMarker';
import { MerchantMarker } from '../MerchantMarker';

export type AddMarkerFn = (args: {
  id: string;
  position: Position;
  stopSequence?: number;
}) => void;

export type RemoveMarkerFn = (id: string) => void;

export const useFormMapMarkers = (defaultMarkers?: MapMarkerProps[] | null) => {
  const [mapMarkers, setMapMarkers] = useState<MapMarkerProps[]>(
    defaultMarkers || []
  );

  const addPickupMarker = useCallback<AddMarkerFn>(
    ({ id, position, stopSequence }) => {
      setMapMarkers((prev) => [
        ...prev.filter((marker) => marker.id !== id),
        {
          id,
          type: 'PICK_UP',
          position,
          children: <PickupMarker stopSequence={stopSequence} />,
          updateZoom: true,
        },
      ]);
    },
    []
  );

  const addDeliveryMarker = useCallback<AddMarkerFn>(
    ({ id, position, stopSequence }) => {
      setMapMarkers((prev) => [
        ...prev.filter((marker) => marker.id !== id),
        {
          id,
          type: 'DROP_OFF',
          position,
          children: <DropOffMarker stopSequence={stopSequence} />,
          updateZoom: true,
        },
      ]);
    },
    []
  );

  const addMerchantMarker = useCallback<AddMarkerFn>(({ id, position }) => {
    setMapMarkers((prev) => [
      ...prev.filter((marker) => marker.id !== id),
      {
        id,
        type: 'MERCHANT',
        position,
        children: <MerchantMarker name="Your location" />,
        anchor: 'center',
        updateZoom: true,
      },
    ]);
  }, []);

  const removePickupMarker = useCallback<RemoveMarkerFn>((id) => {
    setMapMarkers((prev) => prev.filter((marker) => marker.id !== id));
  }, []);

  const removeDeliveryMarker = useCallback<RemoveMarkerFn>((id) => {
    setMapMarkers((prev) => prev.filter((marker) => marker.id !== id));
  }, []);

  const removeMerchantMarker = useCallback<RemoveMarkerFn>((id) => {
    setMapMarkers((prev) => prev.filter((marker) => marker.id !== id));
  }, []);

  return {
    mapMarkers,
    addPickupMarker,
    addDeliveryMarker,
    addMerchantMarker,
    removePickupMarker,
    removeDeliveryMarker,
    removeMerchantMarker,
  };
};
