import { z } from 'zod';
import { validateUkPhoneNumber } from './../utils/validateUkPhoneNumber';
import { PaginationParamsSchema } from './paginated-data-schema';

export const MerchantDefinedSiteContactSchema = z.object({
  companyId: z.string(),
  contactName: z.string().min(1, { message: 'Contact name is required' }),
  contactNumber: z.string().refine((arg) => validateUkPhoneNumber(arg), {
    message: 'Invalid UK phone number',
  }),
});

export const MerchantSiteContactSchema =
  MerchantDefinedSiteContactSchema.extend({
    siteContactId: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
  });

export const SiteContactSchema = MerchantDefinedSiteContactSchema.omit({
  companyId: true,
}).extend({
  siteContactId: z.string(),
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
});

export const SiteContactQueryParamsSchema = z.object({
  companyId: z.string().optional(),
  ...PaginationParamsSchema.shape,
});

export type MerchantDefinedSiteContact = z.infer<
  typeof MerchantDefinedSiteContactSchema
>;
export type MerchantSiteContact = z.infer<typeof MerchantSiteContactSchema>;
export type SiteContact = z.infer<typeof SiteContactSchema>;
