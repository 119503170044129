import { z } from 'zod';
import {
  DeliveryVehicleIdSchema,
  ClockInWorkTypeSchema,
} from '../../../models';

export const GetRiderClockInResponseSchema = z
  .object({
    id: z.string(),
    clockedInAt: z.string(),
    regNumber: z.string(),
    clockedOutAt: z.string().nullish(),
    deliveryVehicleId: DeliveryVehicleIdSchema.nullish(),
    riderId: z.string().nullish(),
    fleetVehicleId: z.string().nullish(),
    workType: ClockInWorkTypeSchema,
  })
  .nullable();

export type GetRiderClockInResponse = z.infer<
  typeof GetRiderClockInResponseSchema
>;
