import { useGetNotificationStats } from '@tradeaze/frontend/hooks';
import React, { createContext, ReactNode } from 'react';
import { useAuthStore } from '../auth';
import { useLocation } from 'react-router-dom';

export interface NotificationStats {
  riderCount: number,
  fleetVehicleCount: number
}

interface NotificationProviderProps {
  children: ReactNode;
}
const REFRESH_INTERVAL_MS = 30 * 1000;

export const NotificationsContext = createContext<NotificationStats>({} as NotificationStats);

export const NotificationsProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const { user } = useAuthStore();

  const location = useLocation();

  const isLoggedInUser = !(!user || location.pathname === '/signin');
  const { data } = useGetNotificationStats({
    refetchInterval: REFRESH_INTERVAL_MS,
    enabled: isLoggedInUser,
  });
  const stats: NotificationStats = {
    riderCount: Number(data?.riderCount || 0),
    fleetVehicleCount: Number(data?.fleetVehicleCount || 0),
  }
  return (
    <NotificationsContext.Provider value={stats}>
      {children}
    </NotificationsContext.Provider>
  );
};
