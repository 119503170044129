import { Input, FormLabel, Button, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { createAuthErrToast, useAuthStore } from '@tradeaze/frontend/ui-web';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

const ConfirmSignupForm: React.FC<{
  username: string;
}> = ({ username }) => {
  const navigate = useNavigate();

  const [confirmationCode, setConfirmationCode] = useState('');

  const { confirmSignUp, resendConfirmationCode } = useAuthStore();

  const handleConfirm = async () => {
    if (!confirmationCode) {
      return;
    }

    const onSuccess = () => {
      navigate('/signin', {
        state: {
          alert: {
            status: 'success',
            message: 'Account confirmed, you can now sign in',
          },
        },
      });
    };

    const onError = (error: unknown) => {
      createAuthErrToast(error);
    };

    confirmSignUp(username, confirmationCode, onSuccess, onError);
  };

  const handleResendConfirmationCode = async () => {
    const onSuccess = () => {
      toast.success('Verification code sent');
    };

    const onError = (error: unknown) => {
      createAuthErrToast(error);
    };

    resendConfirmationCode(username, onSuccess, onError);
  };

  return (
    <Box textAlign={'center'}>
      <FormLabel textAlign={'center'}>Confirmation Code</FormLabel>
      <Box>
        <Input
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
        />
        <Button
          size="xs"
          mt={3}
          variant={'link'}
          onClick={handleResendConfirmationCode}
        >
          Send another code
        </Button>
      </Box>
      <Button mt={7} onClick={handleConfirm}>
        Confirm
      </Button>
    </Box>
  );
};

export default ConfirmSignupForm;
