import {
  ClientDeliveryOption,
  DeliveryOptionId,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';
import { useCallback, useMemo } from 'react';

type UseDeliveryOptionsParams = {
  deliveryOptions: ClientDeliveryOption[];
  deliveryVehicle: DeliveryVehicleId;
  hidePriorityDelivery: boolean;
  isAdmin: boolean;
  isAnyStopOverThreshold?: boolean;
};

// TODO: Redesign response to getDeliveryOptions and move this logic to the backend
export const useDeliveryOptions = ({
  deliveryOptions,
  deliveryVehicle,
  hidePriorityDelivery,
  isAdmin,
  isAnyStopOverThreshold,
}: UseDeliveryOptionsParams) => {
  const sortDeliveryOptions = useCallback(
    (a: ClientDeliveryOption, b: ClientDeliveryOption) => {
      const customOrder: DeliveryOptionId[] = [
        'BIKE_PRIORITY',
        'BIKE_THREE_HOUR',
        'BIKE_DAY',
        'BIKE_SCHEDULED',
        'CAR_MORNING',
        'CAR_PRIORITY',
        'CAR_DAY',
        'CAR_EVENING',
        'VAN_LARGE_MORNING',
        'VAN_LARGE_PRIORITY',
        'VAN_LARGE_DAY',
        'VAN_LARGE_EVENING',
        'VAN_XLARGE_MORNING',
        'VAN_XLARGE_PRIORITY',
        'VAN_XLARGE_DAY',
        'VAN_XLARGE_EVENING',
        'LUTON_MORNING',
        'LUTON_PRIORITY',
        'LUTON_DAY',
        'LUTON_EVENING',
        'FLATBED_MORNING',
        'FLATBED_PRIORITY',
        'FLATBED_DAY',
        'FLATBED_EVENING',
      ];

      return (
        customOrder.indexOf(a.deliveryOptionId) -
        customOrder.indexOf(b.deliveryOptionId)
      );
    },
    []
  );

  const filterDeliveryOptions = useCallback(
    (options: ClientDeliveryOption[]) =>
      options.filter((o) => {
        if (
          hidePriorityDelivery &&
          o.deliveryOptionId.includes('PRIORITY') &&
          o.deliveryVehicleId !== 'BIKE'
        ) {
          return false;
        }
        return o.deliveryOptionId.includes(deliveryVehicle);
      }),
    [hidePriorityDelivery, deliveryVehicle]
  );

  const getIsOptionAvailable = useCallback(
    (option: ClientDeliveryOption) => {
      if (isAdmin) {
        return true;
      }
      if (option.isUnavailable || option.isPastCutOff) {
        return false;
      }
      if (
        hidePriorityDelivery &&
        option.deliveryOptionId.includes('PRIORITY') &&
        option.deliveryVehicleId !== 'BIKE'
      ) {
        return false;
      }
      if (isAnyStopOverThreshold) {
        const isMorningOrPriority = ['MORNING', 'PRIORITY'].some((keyword) =>
          option.deliveryOptionId.includes(keyword)
        );
        const isBike = option.deliveryVehicleId === 'BIKE';

        return !isMorningOrPriority && !isBike;
      }
      return true;
    },
    [isAdmin, hidePriorityDelivery, isAnyStopOverThreshold]
  );

  const filteredDeliveryOptions = useMemo(
    () => filterDeliveryOptions(deliveryOptions).sort(sortDeliveryOptions),
    [deliveryOptions, filterDeliveryOptions, sortDeliveryOptions]
  );

  const availableDeliveryOptions = useMemo(
    () => deliveryOptions.filter(getIsOptionAvailable),
    [deliveryOptions, getIsOptionAvailable]
  );

  const isDeliveryOptionDisabled = useCallback(
    (option: ClientDeliveryOption) => {
      return !availableDeliveryOptions.some(
        (o) => o.deliveryOptionId === option.deliveryOptionId
      );
    },
    [availableDeliveryOptions]
  );

  return useMemo(
    () => ({
      filteredDeliveryOptions,
      availableDeliveryOptions,
      isDeliveryOptionDisabled,
    }),
    [
      filteredDeliveryOptions,
      availableDeliveryOptions,
      isDeliveryOptionDisabled,
    ]
  );
};
