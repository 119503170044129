import { z } from 'zod';
import {
  MerchantDefinedSiteContactSchema,
  SiteContactSchema,
} from '../../../models';

export const CreateSiteContactBodySchema = MerchantDefinedSiteContactSchema;

export const CreateSiteContactResponseSchema = z.object({
  message: z.string(),
  data: SiteContactSchema,
});

export type CreateSiteContactBody = z.infer<typeof CreateSiteContactBodySchema>;

export type CreateSiteContactResponse = z.infer<
  typeof CreateSiteContactResponseSchema
>;
