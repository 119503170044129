import { DeliveryOptionId } from '@tradeaze-packages/schemas';
import { sub, add, set } from 'date-fns';

const OPENING_HOUR = 8;
const CLOSING_HOUR = 16;

export const BIKE_SCHEDULED_BUFFER_SECS = 15 * 60;

export const BIKE_PRIORITY_BUFFER_SECS = 30 * 60;

export const MIN_TIME_FOR_DAY_DELIVERY_SECS = 4 * 60 * 60;

const assertNever = (value: never): never => {
  throw new Error(`Unknown delivery option ${value}`);
};

export const calculateDeliveryWindow = ({
  deliveryOptionId,
  fromDateTime,
  scheduledTime,
  totalTimeEstimate,
  openingHour = OPENING_HOUR,
  closingHour = CLOSING_HOUR,
}: {
  deliveryOptionId: DeliveryOptionId;
  fromDateTime: Date;
  scheduledTime?: Date;
  totalTimeEstimate?: number;
  closingHour?: number;
  openingHour?: number;
}): {
  start: Date;
  end: Date;
} => {
  const isBeforeOpening = fromDateTime.getHours() < openingHour;

  const defaultStart = isBeforeOpening
    ? set(fromDateTime, {
        hours: openingHour,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      })
    : fromDateTime;

  const earliestDeliveryEstimate = totalTimeEstimate
    ? add(defaultStart, {
        seconds: totalTimeEstimate,
      })
    : add(defaultStart, {
        hours: 1,
      });

  switch (deliveryOptionId) {
    case 'BIKE_PRIORITY':
    case 'BIKE_DIRECT': // deprecated
      return {
        start: defaultStart,
        end: add(earliestDeliveryEstimate, {
          seconds: BIKE_PRIORITY_BUFFER_SECS,
        }),
      };
    case 'BIKE_ONE_HOUR': // deprecated
    case 'ONE_HOUR': // deprecated
      return {
        start: defaultStart,
        end: add(defaultStart, {
          hours: 1,
        }),
      };
    case 'BIKE_THREE_HOUR':
    case 'THREE_HOUR': // deprecated
      return {
        start: defaultStart,
        end: add(defaultStart, {
          hours: 3,
        }),
      };
    case 'BIKE_SCHEDULED':
    case 'SCHEDULED': {
      if (!scheduledTime || scheduledTime < earliestDeliveryEstimate) {
        return {
          start: sub(earliestDeliveryEstimate, {
            seconds: BIKE_SCHEDULED_BUFFER_SECS,
          }),
          end: add(earliestDeliveryEstimate, {
            seconds: BIKE_SCHEDULED_BUFFER_SECS,
          }),
        };
      }

      return {
        start: sub(scheduledTime, {
          seconds: BIKE_SCHEDULED_BUFFER_SECS,
        }),
        end: add(scheduledTime, {
          seconds: BIKE_SCHEDULED_BUFFER_SECS,
        }),
      };
    }
    case 'VAN_LARGE_MORNING':
    case 'VAN_XLARGE_MORNING':
    case 'CAR_MORNING':
    case 'FLATBED_MORNING':
    case 'LUTON_MORNING':
    case 'VAN_MORNING': // deprecated
    case 'AM': // deprecated
      return {
        start: set(fromDateTime, {
          hours: openingHour,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }),
        end: set(fromDateTime, {
          hours: 10,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }),
      };
    case 'VAN_LARGE_DAY':
    case 'VAN_XLARGE_DAY':
    case 'CAR_DAY':
    case 'BIKE_DAY':
    case 'FLATBED_DAY':
    case 'LUTON_DAY':
    case 'VAN_DAY': // deprecated
    case 'ANYTIME_TODAY': // deprecated
    case 'PM': // deprecated
    case 'DAY': {
      // deprecated
      const closingTimeSameDay = set(fromDateTime, {
        hours: closingHour,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      const fourHoursFromStart = add(fromDateTime, {
        seconds: MIN_TIME_FOR_DAY_DELIVERY_SECS,
      });

      const latestWindow = set(fromDateTime, {
        hours: 18,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      const getEndWindow = () => {
        if (fourHoursFromStart > latestWindow) {
          return latestWindow;
        }
        if (fourHoursFromStart > closingTimeSameDay) {
          return fourHoursFromStart;
        }
        return closingTimeSameDay;
      };

      return {
        start: defaultStart,
        end: getEndWindow(),
      };
    }
    case 'VAN_LARGE_EVENING':
    case 'VAN_XLARGE_EVENING':
    case 'CAR_EVENING':
    case 'FLATBED_EVENING':
    case 'LUTON_EVENING':
    case 'VAN_EVENING': // deprecated
      return {
        start: set(fromDateTime, {
          hours: closingHour,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }),
        end: set(fromDateTime, {
          hours: 20,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }),
      };
    case 'VAN_LARGE_PRIORITY':
    case 'VAN_XLARGE_PRIORITY':
    case 'CAR_PRIORITY':
    case 'FLATBED_PRIORITY':
    case 'LUTON_PRIORITY':
    case 'VAN_PRIORITY': // deprecated
      return {
        start: defaultStart,
        end: add(defaultStart, {
          hours: 3,
        }),
      };
    default:
      return assertNever(deliveryOptionId);
  }
};
