import { z } from 'zod';

export const DeliveryVehicleIdSchema = z.enum([
  'BIKE',
  'CAR',
  'FLATBED',
  'VAN_LARGE',
  'VAN_XLARGE',
  'LUTON',
]);

export const DeliveryVehicleSchema = z.object({
  deliveryVehicleId: DeliveryVehicleIdSchema,
  name: z.string(),
  description: z.string().nullish(),
  lengthCapacity: z.number().nullish(),
  weightCapacity: z.number().nullish(),
  widthCapacity: z.number().nullish(),
  heightCapacity: z.number().nullish(),
});

export type DeliveryVehicleId = z.infer<typeof DeliveryVehicleIdSchema>;

export type DeliveryVehicle = z.infer<typeof DeliveryVehicleSchema>;
