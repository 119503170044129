import { z } from 'zod';
import {
  CurrentDeliveryOptionIdSchema,
  CutOffTimeSchema,
  DeliveryVehicleIdSchema,
  PositionSchema,
  PublicDeliveryVehicleSchema,
  PublicPriceSchema,
} from '../../../models';

const PricingStopDetailsSchema = z.object(
  {
    position: PositionSchema,
  },
  {
    description: 'The location details of the stop.',
  }
);

export const PublicPostQuoteRequestSchema = z.object({
  pickup: PricingStopDetailsSchema,
  dropoff: PricingStopDetailsSchema,
  startTime: z
    .string({
      description:
        'An ISO string date of the time the delivery is scheduled to start. If not provided, defaults to the current time.',
    })
    .nullish(),
});

export const PublicPostQuoteResponseSchema = z.object({
  options: z.array(
    z.object({
      id: CurrentDeliveryOptionIdSchema,
      deliveryVehicleId: DeliveryVehicleIdSchema,
      cutOffTime: CutOffTimeSchema,
      deliveryPrice: PublicPriceSchema,
      serviceCharge: PublicPriceSchema,
      isAvailable: z.boolean(),
      windowStart: z.string(),
      windowEnd: z.string(),
    })
  ),
  vehicles: z.array(PublicDeliveryVehicleSchema),
});

export type PublicPostQuoteRequest = z.infer<
  typeof PublicPostQuoteRequestSchema
>;
export type PublicPostQuoteResponse = z.infer<
  typeof PublicPostQuoteResponseSchema
>;
export type PricingStopDetails = z.infer<typeof PricingStopDetailsSchema>;
