import { z } from 'zod';
import { AddressSchema, AddressStringQueryParamsSchema } from '../../../models';

export const GetAddressesByCompanyQuerySchema = AddressStringQueryParamsSchema.omit({
  companyId: true,
});

export const GetAddressesByCompanyResponseSchema = z.object({
  addresses: z.array(AddressSchema),
  nextPageIndex: z.number().nullable(),
});

export type GetAddressesByCompanyQuery = z.infer<
  typeof GetAddressesByCompanyQuerySchema
>;

export type GetAddressesByCompanyResponse = z.infer<
  typeof GetAddressesByCompanyResponseSchema
>;
