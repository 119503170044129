import { z } from 'zod';
import { DeliveryScheduleWithStopsSchema } from '../../../models';

export const GetDeliverySchedulesForDateQuerySchema = z.object({
  regionId: z.string(),
  date: z.string(),
});

export const GetDeliverySchedulesForDateResponseSchema = z.array(
  DeliveryScheduleWithStopsSchema
);

export type GetDeliverySchedulesForDateQuery = z.infer<
  typeof GetDeliverySchedulesForDateQuerySchema
>;

export type GetDeliverySchedulesForDateResponse = z.infer<
  typeof GetDeliverySchedulesForDateResponseSchema
>;
