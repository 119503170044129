import { HydratedOrder, CreateOrder } from '@tradeaze-packages/schemas';
import { v4 as uuid } from 'uuid';

type PickupWithoutIds = Omit<
  HydratedOrder['deliveries'][0]['pickup'],
  'deliveryStopId' | 'orderId' | 'deliveryId'
>;

type DropOffWithoutIds = Omit<
  HydratedOrder['deliveries'][0]['dropOff'],
  'deliveryStopId' | 'orderId' | 'deliveryId'
>;

type HydratedOrderWithPickupAndDropOffWithoutIds = Omit<
  HydratedOrder,
  'deliveries'
> & {
  deliveries: Array<
    Omit<HydratedOrder['deliveries'][0], 'pickup' | 'dropOff'> & {
      pickup: PickupWithoutIds;
      dropOff: DropOffWithoutIds;
    }
  >;
};

// temporary function - delete or update after multi-drop introduction with dedicated order type schemas
export const translateHydratedOrderToCreateOrder = (
  hydratedOrder: HydratedOrderWithPickupAndDropOffWithoutIds
): CreateOrder => {
  const allPickups = hydratedOrder.deliveries.flatMap((delivery) => [
    delivery.pickup,
  ]);

  const lastDropOff =
    hydratedOrder.deliveries[hydratedOrder.deliveries.length - 1]?.dropOff;

  /**
   * TEMP: We are dividing the width of the items by the number of deliveries on the backend
   * So we need to multiply the width by the number of deliveries again for now.
   * Remove after we have a dedicated order type schemas and delivery items are part of each delivery.
   */
  const numberOfDeliveries = hydratedOrder.deliveries.length;

  const adjustSizeDeliveryItems = hydratedOrder.deliveries[0]?.deliveryItems.map((item) => {
    return {
      ...item,
      width: item.width * numberOfDeliveries,
      weight: item.weight * numberOfDeliveries,
    };
  });

  return {
    merchantId: hydratedOrder.merchantId || '',
    bookedBy: hydratedOrder.bookedBy || '',
    deliveryWindowStart: hydratedOrder.deliveryWindowStart,
    deliveryWindowEnd: hydratedOrder.deliveryWindowEnd,
    companyName: hydratedOrder.companyName || '',
    merchantOrderReference: hydratedOrder.merchantOrderReference || '',
    deliveryPrice: hydratedOrder.deliveryPrice,
    merchantServiceCharge: hydratedOrder.merchantServiceCharge,
    deliveryItems: adjustSizeDeliveryItems || [],
    deliveryStops: [
      ...allPickups.map((pickup) => ({
        ...pickup,
        deliveryStopId: uuid(),
        collectionReference: pickup.collectionReference || '',
        contacts: pickup.contacts || [],
      })),
      { ...lastDropOff, deliveryStopId: uuid(), collectionReference: '' },
    ].filter(Boolean),
    companyId: hydratedOrder.companyId,
    deliveryDuration: hydratedOrder.deliveryDuration,
    deliveryDistance: hydratedOrder.deliveryDistance,
    isPriceEdited: hydratedOrder.isPriceEdited,
    totalTimeEstimate: hydratedOrder.totalTimeEstimate,
    deliveryOption: hydratedOrder.deliveries[0]?.deliveryOptionId,
    deliveryVehicle: hydratedOrder.deliveries[0]?.deliveryVehicleId,
    heavySideItems: hydratedOrder.deliveries[0]?.heavySideItems || false,
  };
};
