import { z } from 'zod';

export const SetDeliveryStopsSequenceBodySchema = z.object({
  deliveryScheduleId: z.string(),
  deliveryStopIds: z.array(z.string()),
});

export const SetDeliveryStopsSequenceResponseSchema = z.array(
  z.object({
    deliveryStopId: z.string(),
    stopSequence: z.number(),
  })
);

export type SetDeliveryStopsSequenceBody = z.infer<
  typeof SetDeliveryStopsSequenceBodySchema
>;

export type SetDeliveryStopsSequenceResponse = z.infer<
  typeof SetDeliveryStopsSequenceResponseSchema
>;
