import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Divider,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import {
  buildTrackingLink,
  formatAddressDisplayName,
  formatTimeRange,
  getItemDimensionString,
} from '@tradeaze/shared/utils';
import React from 'react';
import { CgTimelapse } from 'react-icons/cg';
import { FiShoppingCart } from 'react-icons/fi';
import { GrLocation } from 'react-icons/gr';
import {
  getStopTitle,
  MultiContactsSection,
  OrderSection,
  ProofOfDelivery,
} from '../orders';
import {
  Attribute,
  DateTimeValue,
  DeliveryStatusValue,
  PriceValue,
  TextValue,
  TitleValue,
} from '../shared';
import { DeliveryPostcodes } from './DeliveryPostcodes';

const sectionColumns = [2, 3, 2, 2, 3];

export const DeliverySummary: React.FunctionComponent<{
  delivery: HydratedDelivery;
  proofOfDeliveryBucketname: string;
  isAdmin: boolean;
  shouldShowHeader?: boolean;
  showMerchantName?: boolean;
  columns?: number[];
  podLeftAlign?: boolean;
}> = ({
  delivery,
  proofOfDeliveryBucketname,
  columns,
  shouldShowHeader = true,
  showMerchantName = false,
  isAdmin = false,
  podLeftAlign = false,
}) => {
  return (
    <Box>
      <Box>
        {shouldShowHeader ? (
          isAdmin ? (
            <Box mb={10}>
              <Stack
                justify={'space-between'}
                direction={['column', 'row']}
                spacing={[3, 10]}
                mb={3}
              >
                {showMerchantName && (
                  <Box>
                    <Heading size="md">
                      {delivery.merchant?.merchantName ||
                        'Guest Delivery - ' + delivery.order.invoicingName}
                    </Heading>
                  </Box>
                )}
                {delivery.order.merchantOrderReference && (
                  <Heading size={'md'}>
                    {delivery.order.merchantOrderReference}
                  </Heading>
                )}
              </Stack>
              <DeliveryPostcodes
                pickup={delivery.pickup}
                dropOff={delivery.dropOff}
              />
            </Box>
          ) : (
            <Stack
              justify={'space-between'}
              direction={['column', 'row']}
              spacing={[3, 10]}
            >
              {delivery.order.merchantOrderReference && (
                <Heading size={'md'}>
                  {delivery.order.merchantOrderReference}
                </Heading>
              )}
              <DeliveryPostcodes
                pickup={delivery.pickup}
                dropOff={delivery.dropOff}
              />
            </Stack>
          )
        ) : null}
      </Box>
      <SimpleGrid columns={columns} spacing={12}>
        <OrderSection name="Status" icon={<CgTimelapse />}>
          <SimpleGrid columns={sectionColumns} spacing={4}>
            <Attribute name="Delivery Status">
              <DeliveryStatusValue deliveryStatus={delivery.status} />
            </Attribute>
            <Attribute name="Created">
              <DateTimeValue dateTime={delivery.createdAt} />
            </Attribute>
            <Attribute name="Updated">
              <DateTimeValue dateTime={delivery.updatedAt} />
            </Attribute>
            <Attribute name="Confirmed">
              <DateTimeValue dateTime={delivery.confirmedAt} />
            </Attribute>
            <Attribute name="Collected">
              <DateTimeValue dateTime={delivery.pickup.completedAt} />
            </Attribute>
            <Attribute name="Delivered">
              <DateTimeValue dateTime={delivery.deliveredAt} />
            </Attribute>
            {delivery.cancelledAt ? (
              <Attribute name="Cancelled">
                <DateTimeValue dateTime={delivery.cancelledAt} />
              </Attribute>
            ) : null}
            {delivery.cancellationReason ? (
              <Attribute name="Cancellation Reason">
                <TextValue text={delivery.cancellationReason} />
              </Attribute>
            ) : null}
            {delivery.rider ? (
              <Attribute name="Courier">
                <HStack>
                  <Avatar
                    size={'xs'}
                    name={`${delivery.rider.firstName} ${delivery.rider.lastName}`}
                  />
                  <TextValue text={delivery.rider.firstName} />
                </HStack>
              </Attribute>
            ) : null}
          </SimpleGrid>
          {isAdmin && import.meta.env['VITE_ENV_NAME'] ? (
            <Link
              color={'blackAlpha.700'}
              fontSize={'sm'}
              href={buildTrackingLink(
                delivery.deliveryId,
                import.meta.env['VITE_ENV_NAME'],
              )}
            >
              {buildTrackingLink(
                delivery.deliveryId,
                import.meta.env['VITE_ENV_NAME'],
              )}
            </Link>
          ) : null}
        </OrderSection>

        {/* Map over delivery stops */}
        {[delivery.pickup, delivery.dropOff]?.map((stop, index) => (
          <OrderSection
            key={`delivery-stop-${index}`}
            name={getStopTitle({
              stopType: stop.type,
              stopSequence: index,
              stopsCount: 2,
            })}
            icon={<GrLocation />}
          >
            <SimpleGrid columns={sectionColumns} spacing={4}>
              <Attribute name="Address & Postcode">
                <TextValue
                  text={formatAddressDisplayName({
                    addressLine1: stop.addressLine1,
                    addressLine2: stop.addressLine2,
                    addressLine3: stop.addressLine3,
                    postCode: stop.postCode,
                  })}
                />
              </Attribute>
              {stop.companyName ? (
                <Attribute name="Company Name">
                  <TextValue text={stop.companyName} />
                </Attribute>
              ) : null}
              {stop.windowStart && stop.windowEnd ? (
                <Attribute name="Delivery Window">
                  <TextValue
                    text={formatTimeRange({
                      from: stop.windowStart,
                      to: stop.windowEnd,
                    })}
                  />
                </Attribute>
              ) : null}
              {stop.collectionReference ? (
                <Attribute name="Collection Reference">
                  <TextValue text={stop.collectionReference} />
                </Attribute>
              ) : null}
              {stop.deliveryNotes ? (
                <Attribute name="Delivery Notes">
                  <TextValue text={stop.deliveryNotes} />
                </Attribute>
              ) : null}
              {stop.arrivedAt ? (
                <Attribute name="Arrived">
                  <DateTimeValue dateTime={stop.arrivedAt} />
                </Attribute>
              ) : null}
              {stop.completedAt ? (
                <Attribute name="Completed">
                  <DateTimeValue dateTime={stop.completedAt} />
                </Attribute>
              ) : null}
              {isAdmin && stop.leftAt ? (
                <Attribute name="Left">
                  <DateTimeValue dateTime={stop.leftAt} />
                </Attribute>
              ) : null}
              {stop.requiresSignature ? (
                <Attribute name="Requires Signature">
                  <CheckCircleIcon color={'green.500'} />
                </Attribute>
              ) : null}
              {stop.requiresImage ? (
                <Attribute name="Requires Image">
                  <CheckCircleIcon color={'green.500'} />
                </Attribute>
              ) : null}
              {stop.proofName ? (
                <Attribute
                  name={
                    stop.type === 'PICK_UP' ? 'Collected From' : 'Delivered To'
                  }
                >
                  <TextValue text={stop.proofName} />
                </Attribute>
              ) : null}
            </SimpleGrid>
            {stop.contacts.length ? (
              <>
                <Divider />
                <MultiContactsSection contacts={stop.contacts} />
              </>
            ) : null}
            <ProofOfDelivery
              orderId={delivery.orderId}
              stopId={stop.deliveryStopId}
              bucket={proofOfDeliveryBucketname}
              alwaysShow={true}
              showUpload={isAdmin}
              allowDelete={isAdmin}
              podLeftAlign={podLeftAlign}
            />
          </OrderSection>
        ))}

        <OrderSection name="Items" icon={<FiShoppingCart />}>
          <VStack alignItems={'flex-start'} gap={2}>
            {delivery.deliveryItems?.map((item, index) => (
              <React.Fragment key={item.id}>
                {index > 0 ? <Divider /> : null}
                <TitleValue text={item.name} />
                {item.description ? (
                  <Attribute name="Description">
                    <TextValue text={item.description} />
                  </Attribute>
                ) : null}
                <Attribute name="Weight">{item.weight}kg</Attribute>
                <Attribute name="Dimensions">
                  {getItemDimensionString(item)}
                </Attribute>
                <Attribute name="Price (exec. VAT)">
                  <PriceValue price={item.price} />
                </Attribute>
              </React.Fragment>
            ))}
          </VStack>
        </OrderSection>
      </SimpleGrid>
    </Box>
  );
};
