import { Icon, Text } from '@chakra-ui/react';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { brandPrimaryColor } from '../../../../theme';
import { formatDeliveryOption } from '@tradeaze/shared/utils';
import { DeliveryOptionId } from '@tradeaze-packages/schemas';

export const CardDeliveryOption: React.FC<{
  deliveryOptionId: DeliveryOptionId;
}> = ({ deliveryOptionId }) => {
  const optionName = formatDeliveryOption(deliveryOptionId);

  return (
    <Text color="blackAlpha.800" fontSize={'0.9em'} noOfLines={1}>
      {`${optionName} Delivery`}
      {optionName.toLocaleUpperCase().includes('PRIORITY') && (
        <Icon as={BsFillLightningChargeFill} ml="2" color={brandPrimaryColor} />
      )}
    </Text>
  );
};
