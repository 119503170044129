import { useInfiniteQuery } from '@tanstack/react-query';
import { MerchantSiteContact } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

interface SiteContactsResponse {
  siteContacts: MerchantSiteContact[];
  nextPageIndex: number | null;
}

export const useGetSiteContacts = (companyId: string | undefined | null) =>
  useInfiniteQuery<SiteContactsResponse>(
    ['getSiteContacts', companyId],
    async ({ pageParam }) => {
      if (!companyId) {
        throw new Error('No company id provided');
      }
      const apiName = 'MerchantApi',
        path = '/getSiteContactsByCompany/' + companyId,
        options = {
          queryStringParameters: {
            startIndex: pageParam?.startIndex,
          },
        };
      return await API.get(apiName, path, options);
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.nextPageIndex) {
          return undefined;
        }
        return { startIndex: lastPage.nextPageIndex };
      },
      retry: false,
      enabled: !!companyId,
    }
  );
