import { DeliveryOptionId } from '@tradeaze-packages/schemas';

export const optionDescriptions: Record<DeliveryOptionId, string> = {
  BIKE_PRIORITY: 'Delivered ASAP, typically collected within an hour',
  BIKE_THREE_HOUR: 'Delivered within 3 hours from the time of order',
  BIKE_DAY: 'Delivered before the end of the day',
  BIKE_SCHEDULED: 'Delivered within half an hour of the scheduled time',
  CAR_PRIORITY: 'Delivered ASAP, typically collected within 2 hours',
  CAR_MORNING: 'Delivered before 10am',
  CAR_DAY: 'Delivered before the end of the day',
  CAR_EVENING: 'Delivered after 4pm and before 8pm',
  FLATBED_PRIORITY: 'Delivered ASAP, typically collected within 2 hours',
  FLATBED_MORNING: 'Delivered before 10am',
  FLATBED_DAY: 'Delivered before the end of the day',
  FLATBED_EVENING: 'Delivered after 4pm and before 8pm',
  VAN_LARGE_PRIORITY: 'Delivered ASAP, typically collected within 2 hours',
  VAN_LARGE_MORNING: 'Delivered before 10am',
  VAN_LARGE_DAY: 'Delivered before the end of the day',
  VAN_LARGE_EVENING: 'Delivered after 4pm and before 8pm',
  VAN_XLARGE_PRIORITY: 'Delivered ASAP, typically collected within 2 hours',
  VAN_XLARGE_MORNING: 'Delivered before 10am',
  VAN_XLARGE_DAY: 'Delivered before the end of the day',
  VAN_XLARGE_EVENING: 'Delivered after 4pm and before 8pm',
  LUTON_PRIORITY: 'Delivered ASAP, typically collected within 2 hours',
  LUTON_MORNING: 'Delivered before 10am',
  LUTON_DAY: 'Delivered before the end of the day',
  LUTON_EVENING: 'Delivered after 4pm and before 8pm',
  // deprecated options below
  VAN_PRIORITY: 'Delivered ASAP, typically within 2-3 hours',
  VAN_MORNING: 'Delivered before 10am',
  VAN_DAY: 'Delivered before the end of the day',
  VAN_EVENING: 'Delivered after 4pm and before 8pm',
  BIKE_DIRECT: '',
  SCHEDULED: '',
  THREE_HOUR: '',
  PM: '',
  DAY: '',
  ONE_HOUR: '',
  AM: '',
  BIKE_ONE_HOUR: '',
  ANYTIME_TODAY: '',
};
