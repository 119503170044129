import axios from 'axios';

const MAPBOX_GEOCODE_API_URL =
  'https://api.mapbox.com/geocoding/v5/mapbox.places';

export type MapboxFeature = {
  id: string;
  address: string;
  text: string;
  relevance: number;
  place_name: string;
  center: [number, number];
  context: Array<{
    id: string;
    text: string;
  }>;
};

export const getGeocodeResults = async (
  query: string,
  options: {
    mapboxAccessKey: string;
  }
) => {
  const response = await axios.get<{ features: MapboxFeature[] }>(
    `${MAPBOX_GEOCODE_API_URL}/${query}.json`,
    {
      params: {
        access_token: options.mapboxAccessKey,
        country: 'gb',
      },
    }
  );
  const { data } = response;
  return data;
};
