import { z } from 'zod';

export const UndoCompleteDeliveryStopResponseSchema = z.object({
  deliveryStopId: z.string(),
  completedAt: z.string().nullable(),
});

export type UndoCompleteDeliveryStopResponse = z.infer<
  typeof UndoCompleteDeliveryStopResponseSchema
>;
