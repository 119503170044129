import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import startCase from 'lodash.startcase';
import React from 'react';
import { DotIcon } from '../../../shared';
import { CardEtaTag } from '../components';
import {
  CardAdminMenu,
  CardDeliveryOption,
  CardDeliveryWindow,
  CardDriverInfo,
  CardItemsInfo,
  CardPostcodes,
  CardProgressBar,
  CardTimeRemaining,
  LeftStrip,
  ListCardContainer,
  ListCardInfo,
  ListCardNotesPreview,
  ListCardStatusDescription
} from './components';
import { useDeliveryCardData } from './hooks/useDeliveryCardData';

export const ListDeliveryCard: React.FC<{
  delivery: HydratedDelivery;
  isAdmin: boolean;
  isSelected: boolean;
  isHovered: boolean;
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onHoverDelivery?: (deliveryId: string) => void;
  onHoverLeaveDelivery?: () => void;
  onPressGoTo: (deliveryId: string) => void;
}> = ({
  delivery,
  isAdmin,
  isSelected,
  isHovered,
  onHoverLeaveDelivery,
  onHoverDelivery,
  onSelectDelivery,
  onPressGoTo,
}) => {
  const {
    cancelledAt,
    companyName,
    createdAt,
    deliveredAt,
    deliveryOptionId,
    deliveryId,
    deliveryItems,
    pickup,
    dropOff,
    deliveryVehicleId,
    deliveryWindowEnd,
    deliveryWindowStart,
    descriptionColorScheme,
    earliestArrival,
    hasNotes,
    isToday,
    isHeavyside,
    orderType,
    latestArrival,
    merchant,
    merchantOrderReference,
    nextStopType,
    deliveryStatus,
    progressColorScheme,
    rider,
    showEta,
    showItemSizing,
    showLate,
    showMerchantName,
    showNotesPreview,
    showProgress,
    showRiderVehicle,
    notesPreview,
    statusDescription,
    updatedAt,
    handleCardClick,
    onCloseMenu,
    ref,
  } = useDeliveryCardData(delivery, isAdmin, onSelectDelivery);

  return (
    <Box
      ref={ref}
      cursor="pointer"
      onClick={handleCardClick}
      onMouseEnter={() => onHoverDelivery?.(deliveryId)}
      onMouseLeave={() => onHoverLeaveDelivery?.()}
      width={'100%'}
    >
      <ListCardContainer>
        <Flex>
          <LeftStrip deliveryStatus={deliveryStatus} />
          <Box width={'100%'}>
            <Box>
              {isSelected && isAdmin ? (
                <CardAdminMenu
                  deliveryStatus={deliveryStatus}
                  assignedRiderId={rider?.riderId}
                  onPressGoTo={onPressGoTo}
                  onCloseMenu={onCloseMenu}
                  deliveryDate={new Date(dropOff.windowEnd)}
                  delivery={delivery}
                />
              ) : null}
              <ListCardInfo
                createdAt={createdAt}
                deliveryVehicleId={deliveryVehicleId}
                hasNotes={hasNotes}
                isHeavyside={isHeavyside}
                deliveryStatus={deliveryStatus}
                merchantName={merchant?.merchantName || ''}
                showMerchantName={showMerchantName}
                companyName={companyName}
              />
              <HStack
                alignItems={'start'}
                justifyContent={'space-between'}
                paddingY={3}
              >
                <VStack paddingX={3} spacing={1} alignItems={'start'}>
                  <CardDeliveryOption deliveryOptionId={deliveryOptionId} />
                  <CardPostcodes
                    deliveryStops={[pickup, dropOff]}
                    deliveryStatus={deliveryStatus}
                  />
                  <HStack fontSize={'0.9em'}>
                    <Text>{startCase(orderType.toLowerCase())}</Text>
                    <DotIcon />
                    <Text fontSize={'0.9em'} color={'blackAlpha.800'}>
                      {merchantOrderReference}
                    </Text>
                  </HStack>
                </VStack>
                <VStack paddingX={3} spacing={2} alignItems={'start'}>
                  <CardDeliveryWindow
                    deliveryWindowStart={deliveryWindowStart}
                    deliveryWindowEnd={deliveryWindowEnd}
                  />
                  {showProgress ? (
                    <CardProgressBar
                      deliveryWindowStart={deliveryWindowStart}
                      deliveryWindowEnd={deliveryWindowEnd}
                      colorScheme={progressColorScheme}
                      showLate={showLate}
                    />
                  ) : null}
                  {showEta &&
                  earliestArrival &&
                  latestArrival &&
                  nextStopType ? (
                    <CardEtaTag
                      stopType={nextStopType}
                      colorScheme={progressColorScheme}
                      earliestArrival={earliestArrival}
                      latestArrival={latestArrival}
                    />
                  ) : (
                    <CardTimeRemaining
                      deliveryWindowEnd={deliveryWindowEnd}
                      showLate={showLate}
                      deliveryStatus={deliveryStatus}
                      updatedAt={updatedAt}
                      deliveredAt={deliveredAt}
                      isToday={isToday}
                      cancelledAt={cancelledAt}
                    />
                  )}
                </VStack>
                <Box alignSelf={'center'} paddingRight={4}>
                  <CardDriverInfo
                    driver={
                      rider
                        ? {
                            firstName: rider?.firstName,
                            lastName: rider?.lastName,
                            vehicleId: rider?.deliveryVehicle,
                          }
                        : null
                    }
                    showVehicle={showRiderVehicle}
                    showGigJobTag={isAdmin}
                    isGigJob={Boolean(delivery.gigJobId)}
                  />
                </Box>
              </HStack>
            </Box>
            {showNotesPreview || showItemSizing ? (
              <HStack
                borderTopWidth={0.5}
                paddingX={4}
                borderColor={'blackAlpha.400'}
                paddingY={1}
                spacing={4}
              >
                {showItemSizing && deliveryItems?.length ? (
                  <CardItemsInfo
                    deliveryItems={deliveryItems}
                    direction={'row'}
                    gap={5}
                  />
                ) : null}
                {showItemSizing && showNotesPreview && notesPreview ? (
                  <DotIcon />
                ) : null}
                {showNotesPreview && notesPreview ? (
                  <ListCardNotesPreview notesPreview={notesPreview} />
                ) : null}
              </HStack>
            ) : null}
            <ListCardStatusDescription
              colorScheme={descriptionColorScheme}
              statusDescription={statusDescription}
            />
          </Box>
        </Flex>
      </ListCardContainer>
    </Box>
  );
};
