import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AdminAppConfigStore = {
  showSuggestedFirst: boolean;
  toggleShowSuggestedFirst: () => void;
};

export const useAdminAppConfigStore = create<AdminAppConfigStore>()(
  persist(
    (set) => ({
      showSuggestedFirst: true,
      toggleShowSuggestedFirst: () =>
        set((state) => ({
          showSuggestedFirst: !state.showSuggestedFirst,
        })),
    }),
    {
      name: 'admin-app-config-store',
      getStorage: () => sessionStorage,
    }
  )
);
