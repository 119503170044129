import { Box, Progress } from '@chakra-ui/react';
import React from 'react';
import { isToday as getIsToday } from 'date-fns';
import {
  ProgressColorScheme,
  getDeliveryProgressPercentage,
} from '../../utils';

export const CardProgressBar: React.FC<
  React.ComponentProps<typeof Box> & {
    deliveryWindowStart: string;
    deliveryWindowEnd: string;
    colorScheme: ProgressColorScheme;
    showLate?: boolean;
  }
> = ({
  deliveryWindowStart,
  deliveryWindowEnd,
  colorScheme,
  showLate,
  ...props
}) => {
  const progress = getDeliveryProgressPercentage(
    deliveryWindowStart,
    deliveryWindowEnd
  );

  const isLate = (progress || 0) > 100;

  const isToday = getIsToday(new Date(deliveryWindowEnd));

  const unfilledColor = {
    green: 'green.50',
    blue: 'blue.50',
    yellow: 'yellow.50',
  }[colorScheme];

  if (!isToday) {
    return null;
  }

  return (
    <Box w="100%" {...props}>
      <Progress
        borderRadius={'lg'}
        value={progress ?? undefined}
        colorScheme={showLate && isLate ? 'red' : colorScheme}
        backgroundColor={unfilledColor}
        height={'5px'}
      />
    </Box>
  );
};
