import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CreateOrder, DeliveryOptionId } from '@tradeaze-packages/schemas';
import { useFormContext } from 'react-hook-form';

type DeliveryFeeSectionProps = {
  deliveryPriceQueryLoading: boolean;
  showDeliveryPrice: false | DeliveryOptionId;
  isAdmin: boolean;
  deliveryPrice: number;
};

export const DeliveryFeesSection = ({
  deliveryPriceQueryLoading,
  showDeliveryPrice,
  isAdmin,
  deliveryPrice,
}: DeliveryFeeSectionProps) => {
  const { register, setValue } = useFormContext<CreateOrder>();
  return (
    <Stack justify={'space-between'} direction={['column', 'row']}>
      <Text fontWeight={'bold'}>
        Delivery Fee{' '}
        <Text fontSize={14} color={'grey'}>
          (exc. VAT)
        </Text>
      </Text>
      {deliveryPriceQueryLoading ? (
        <Spinner />
      ) : showDeliveryPrice ? (
        isAdmin ? (
          <Box>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.300"
                fontSize="1.2em"
                children="£"
              />
              <Input
                width={120}
                {...register('deliveryPrice', {
                  valueAsNumber: true,
                  min: 0,
                  onBlur: (e) => {
                    const minZeroValue = Math.max(
                      0,
                      parseFloat(e.target.value)
                    );
                    setValue(
                      'deliveryPrice',
                      parseFloat(minZeroValue.toFixed(2))
                    );
                    setValue('isPriceEdited', true);
                  },
                })}
              />
            </InputGroup>
          </Box>
        ) : (
          <Text fontSize={18}>£{deliveryPrice.toFixed(2)}</Text>
        )
      ) : (
        <Text color={'red.500'}>Missing required info</Text>
      )}
    </Stack>
  );
};
