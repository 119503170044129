import { z } from 'zod';
import {
  HydratedGigJobSchema,
  GigJobsQueryParamsSchema,
} from '../../../models';

export const GetGigJobsQuerySchema = GigJobsQueryParamsSchema;

export const GetGigJobsResponseSchema = z.object({
  gigJobs: z.array(HydratedGigJobSchema),
  nextPageIndex: z.number().nullable(),
});

export type GetGigJobsQuery = z.infer<typeof GetGigJobsQuerySchema>;

export type GetGigJobsResponse = z.infer<typeof GetGigJobsResponseSchema>;
