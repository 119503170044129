import {
  useMutation,
  useQueryClient,
  QueryKey,
  UseMutationOptions,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';

interface UseDeleteMerchantCompanyOptions
  extends UseMutationOptions<unknown, unknown, { companyId: string }> {
  invalidateQueryKeys?: QueryKey[];
}

export const useDeleteMerchantCompany = (
  options?: UseDeleteMerchantCompanyOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { merchantId: string; companyId: string }
  >({
    ...options,
    mutationFn: ({ companyId, merchantId }) => {
      const apiName = 'MerchantApi';
      const path = `/deleteMerchantCompany/${merchantId}/${companyId}`;
      const options = {};
      return API.del(apiName, path, options);
    },
    onSuccess: (data, variables, context) => {
      options?.invalidateQueryKeys?.forEach((queryKey) => {
        queryClient.invalidateQueries(queryKey);
      });
      options?.onSuccess?.(data, variables, context);
    },
  });
};
