import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { endOfDate, startOfDate } from '@tradeaze/shared/utils';
import {
  GigJobSortBy,
  GigJobSortByPaymentStatus,
  GetGigJobsQuery,
  GigJobStatus,
} from '@tradeaze-packages/schemas';

export type GigJobsFiltersStore = {
  filters: GetGigJobsQuery;
  handleSelectFromDate: (date: Date) => void;
  handleSelectToDate: (date: Date) => void;
  handleChangeName: (name: string) => void;
  handleChangeDeliveryId: (deliveryId: string) => void;
  handleChangeGigJobId: (orderId: string) => void;
  handleChangeSortBy: (sortBy: GigJobSortBy) => void;
  handleChangePaymentStatus: (paidStatus: GigJobSortByPaymentStatus) => void;
  handleToggleStatus: (status: GigJobStatus) => void;
  handleClearJobStatuses: () => void;
};

export const useGigJobsFiltersStore = create<GigJobsFiltersStore>()(
  persist(
    (set) => ({
      filters: {
        sortBy: 'createdAt',
        fromDate: startOfDate(new Date()),
        toDate: endOfDate(new Date()),
        status: [],
        driverName: undefined,
        deliveryId: undefined,
        gigJobId: undefined,
        paidStatus: undefined,
      },
      handleChangeSortBy: (sortBy: GigJobSortBy) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, sortBy },
        })),
      handleSelectFromDate: (date: Date) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, fromDate: startOfDate(date) },
        })),
      handleSelectToDate: (date: Date) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, toDate: endOfDate(date) },
        })),
      handleToggleStatus: (status: GigJobStatus) => {
        set((state) => ({
          ...state,
          filters: {
            ...state.filters,
            status: state.filters.status?.includes(status)
              ? state.filters.status.filter((s) => s !== status)
              : [...(state.filters.status ?? []), status],
          },
        }));
      },
      handleClearJobStatuses: () =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, status: [] },
        })),
      handleChangeName: (name: string) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, driverName: name },
        })),
      handleChangeDeliveryId: (deliveryId: string) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, deliveryId },
        })),
      handleChangeGigJobId: (gigJobId: string) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, gigJobId },
        })),
      handleChangePaymentStatus: (paidStatus: GigJobSortByPaymentStatus) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, paidStatus },
        })),
    }),
    {
      name: 'gig-jobs-filters',
      getStorage: () => sessionStorage,
    },
  ),
);
