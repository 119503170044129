import { Box } from '@chakra-ui/react';
import { Map } from './Map';
import { MobileMapToggleButton } from './MobileMapToggleButton';
import { useState } from 'react';

export const MobileMapWithToggle: React.FC<React.ComponentProps<typeof Map> & {
  extraTopPadding?: number;

}> = (
  props
) => {
  const [showMobileMap, setShowMobileMap] = useState(false);

  return (
    <>
      <MobileMapToggleButton
        showMobileMap={showMobileMap}
        setShowMobileMap={setShowMobileMap}
        extraTopPadding={props.extraTopPadding}
      />
      {showMobileMap && (
        <Box h="100vh" w="100vw" position={'fixed'} top={0} left={0}>
          <Map {...props} />
        </Box>
      )}
    </>
  );
};
