import { z } from 'zod';
import parsePhoneNumberFromString from 'libphonenumber-js';

export const ForgotPasswordSchema = z.object({
  phone: z
    .string()
    .refine(
      (value) => parsePhoneNumberFromString(value, 'GB')?.isValid() || false,
      {
        message: 'Invalid UK phone number',
      }
    ),
});

export type ForgotPasswordSchemaType = z.infer<typeof ForgotPasswordSchema>;
