import { Storage } from 'aws-amplify';

const MAX_PAGE_SIZE = 100;

export const searchFilesS3 =
  (bucket: string) =>
  async (directory: `${string}/`, searchString = '') => {
    // Fetch the list of files in the specified directory
    const directoryResponse = await Storage.list(directory, {
      bucket,
      level: 'public',
      pageSize: MAX_PAGE_SIZE,
    });

    const fileList = directoryResponse.results;

    // Filter the list based on the search string
    const matchedFiles = fileList.filter((file) =>
      file.key?.includes(searchString)
    );

    return matchedFiles
      .map((file) => file.key)
      .filter((k): k is NonNullable<typeof k> => Boolean(k));
  };
