import { Position } from '@tradeaze-packages/schemas';
import { useEffect } from 'react';
import { getGeocodeResults } from '@tradeaze/shared/utils';

const MAPBOX_ACCESS_TOKEN = import.meta.env[
  'VITE_MAPBOX_PUBLIC_ACCESS_KEY'
] as string;

export function useGetManualAddressPosition({
  isManualAddress,
  isValidPostcode,
  handleSetPosition,
  postCode,
}: {
  isManualAddress: boolean;
  isValidPostcode: boolean;
  handleSetPosition: (position: Position | undefined) => void;
  postCode: string;
}) {
  useEffect(() => {
    if (!isManualAddress) {
      return;
    }
    if (!isValidPostcode) {
      handleSetPosition(undefined);
      return;
    }
    getGeocodeResults(postCode, {
      mapboxAccessKey: MAPBOX_ACCESS_TOKEN,
    }).then((results) => {
      const postcodeMatch = results.features.find(
        (feature: { id: string; text: string; center: [number, number] }) =>
          feature.text.toLowerCase().replace(/\s/g, '') ===
          postCode.toLowerCase().replace(/\s/g, '')
      );
      if (!postcodeMatch) {
        handleSetPosition(undefined);
        return;
      }
      const position = {
        latitude: postcodeMatch.center[1],
        longitude: postcodeMatch.center[0],
      };
      handleSetPosition(position);
    });
  }, [postCode, isValidPostcode, isManualAddress, handleSetPosition]);
}
