import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { endOfDate, startOfDate } from '@tradeaze/shared/utils';
import { GetClockInsQuery } from '@tradeaze-packages/schemas';

export type ClockInFiltersStore = {
  filters: GetClockInsQuery;
  handleSelectFromDate: (date: Date) => void;
  handleSelectToDate: (date: Date) => void;
  handleChangeName: (name: string) => void;
  handleChangeRegNumber: (regNumber: string) => void;
};

export const useClockInFiltersStore = create<ClockInFiltersStore>()(
  persist(
    (set) => ({
      filters: {
        fromDate: startOfDate(new Date()),
        toDate: endOfDate(new Date()),
        riderName: undefined,
        regNumber: undefined,
      },
      handleSelectFromDate: (date: Date) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, fromDate: startOfDate(date) },
        })),
      handleSelectToDate: (date: Date) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, toDate: endOfDate(date) },
        })),
      handleChangeName: (name: string) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, riderName: name },
        })),
      handleChangeRegNumber: (regNumber: string) =>
        set((state) => ({
          ...state,
          filters: { ...state.filters, regNumber },
        })),
    }),
    {
      name: 'clock-in-filters',
      getStorage: () => sessionStorage,
    }
  )
);
