import { z } from 'zod';
import { FleetVehicleSchema } from '../../../models/fleet-vehicle-schema';

export const SearchFleetVehicleBodySchema = z.object({
  regNumber: z.string(),
});

export const SearchFleetVehicleResponseSchema = z.object({
  message: z.string(),
  fleetVehicle: FleetVehicleSchema.nullable(),
});

export type SearchFleetVehicleBody = z.infer<
  typeof SearchFleetVehicleBodySchema
>;
export type SearchFleetVehicleResponse = z.infer<
  typeof SearchFleetVehicleResponseSchema
>;
