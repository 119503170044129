import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

export const PageLoading = (props?: React.ComponentProps<typeof Center>) => {
  return (
    <Center h="60vh" {...props}>
      <Spinner />
    </Center>
  );
};
