import { useInfiniteQuery } from '@tanstack/react-query';
import {
  GetAdminOrdersQuery,
  OrderQueryParams,
} from '@tradeaze-packages/schemas';
import { getAdminOrdersAmplify } from '@tradeaze-packages/api-client';

export const getAdminOrdersQueryKey = (filters?: OrderQueryParams) => {
  const key: (string | object)[] = ['getAdminOrders'];
  if (filters) {
    key.push(filters);
  }
  return key;
};

export const useGetAdminOrders = (filters: GetAdminOrdersQuery) => {
  return useInfiniteQuery(
    getAdminOrdersQueryKey(filters),
    async ({ pageParam }) =>
      getAdminOrdersAmplify({
        ...filters,
        startIndex: pageParam?.startIndex,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.nextPageIndex) {
          return undefined;
        }
        return { startIndex: lastPage.nextPageIndex };
      },
      retry: false,
      staleTime: 0,
    }
  );
};
