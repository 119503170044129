import { useQuery } from '@tanstack/react-query';
import { fetchDeliveryOptions } from '@tradeaze-packages/api-client';
import { CustomUseQueryOptions } from '../shared/CustomUseQueryOptions';
import { RegionId } from '@tradeaze-packages/schemas';

export const useGetDeliveryOptions = (
  args: {
    regionId: RegionId;
    date: Date;
  },
  options?: CustomUseQueryOptions<typeof fetchDeliveryOptions>
) => {
  return useQuery({
    queryKey: ['getDeliveryOptions', args],
    queryFn: async () => fetchDeliveryOptions(args.regionId, args.date),
    keepPreviousData: true,
    ...options,
  });
};
