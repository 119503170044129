import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { getIsOnDuty } from '@tradeaze/shared/utils';
import { UNASSIGNED_RIDER_ID } from '@tradeaze-packages/schemas';
import { useGetRiders } from '@tradeaze/frontend/hooks';
import { useMemo } from 'react';
import { RiderAvatar } from './RiderAvatar';
import { RiderDutyTag } from './RiderDutyTag';

const RiderMenuItemOption: React.FC<
  React.ComponentProps<typeof MenuItemOption> & {
    riderId: string;
    firstName: string;
    lastName?: string | null;
    handleToggleRider: (riderId: string) => void;
    isOnDuty: boolean;
    isChecked: boolean;
  }
> = ({
  riderId,
  firstName,
  lastName,
  isOnDuty,
  isChecked,
  handleToggleRider,
  ...props
}) => {
  return (
    <MenuItemOption
      width="350px"
      onClick={() => handleToggleRider(riderId)}
      isChecked={isChecked}
      {...props}
    >
      <Flex justifyContent={'space-between'}>
        <HStack spacing={0} maxWidth="60%">
          <RiderAvatar size={'xs'} marginRight={2} riderId={riderId} />
          <Text noOfLines={1}>
            {firstName} {lastName}
          </Text>
        </HStack>
        <Box>
          <RiderDutyTag onDuty={isOnDuty} />
        </Box>
      </Flex>
    </MenuItemOption>
  );
};

export const RiderFilterMenu: React.FC<{
  handleToggleRider: (riderId: string) => void;
  handleToggleSelectAllRiders?: () => void;
  selectedRiderIds?: string[];
  showUnassigned?: boolean;
}> = ({
  handleToggleRider,
  handleToggleSelectAllRiders,
  selectedRiderIds = [],
  showUnassigned = true,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const riderQuery = useGetRiders(
    { includeDeleted: false, withLocation: false, includeUnassignable: true },
    { enabled: isOpen }
  );

  const filterTitle = useMemo(() => {
    if (selectedRiderIds.length === 1) {
      if (selectedRiderIds.includes(UNASSIGNED_RIDER_ID)) {
        return 'Unassigned';
      } else {
        const rider = riderQuery.data?.find(
          (rider) => rider.riderId === selectedRiderIds[0]
        );
        if (rider) {
          return `${rider.firstName} ${rider.lastName ?? ''}`;
        } else {
          return '1 Driver';
        }
      }
    }
    if (selectedRiderIds.length > 1) {
      if (selectedRiderIds.includes(UNASSIGNED_RIDER_ID)) {
        return `${selectedRiderIds.length - 1} Driver(s) + Unassigned`;
      }
      return `${selectedRiderIds.length} Drivers`;
    }
    return 'Drivers';
  }, [riderQuery.data, selectedRiderIds]);

  const ListItems = useMemo(() => {
    if (riderQuery.isLoading) {
      return (
        <Center>
          <Spinner size={'sm'} />
        </Center>
      );
    }
    if (riderQuery.error) {
      return <Text>Something went wrong</Text>;
    }
    if (riderQuery.data) {
      return riderQuery.data
        .map(({ riderId, firstName, lastName, lastClockedInAt, onDuty }) => {
          return (
            <RiderMenuItemOption
              key={riderId}
              riderId={riderId}
              firstName={firstName}
              lastName={lastName}
              handleToggleRider={handleToggleRider}
              isOnDuty={
                lastClockedInAt ? getIsOnDuty(lastClockedInAt, onDuty) : false
              }
              isChecked={selectedRiderIds?.includes(riderId) ?? false}
            />
          );
        })
        .sort((a, b) => {
          if (a.props.isOnDuty && !b.props.isOnDuty) return -1;
          if (!a.props.isOnDuty && b.props.isOnDuty) return 1;
          return 0;
        });
    }
    return <Text>No riders found</Text>;
  }, [riderQuery, selectedRiderIds]);

  return (
    <Menu
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      closeOnSelect={false}
    >
      <MenuButton
        colorScheme={'gray'}
        size={'sm'}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        color={selectedRiderIds?.length > 0 ? 'black' : 'gray.500'}
      >
        {filterTitle ?? 'Filter Rider'}
      </MenuButton>
      <MenuList maxHeight={'300px'} overflow="scroll">
        {handleToggleSelectAllRiders ? (
          <MenuItemOption
            isChecked={selectedRiderIds.length === 0 || !selectedRiderIds}
            onClick={() => handleToggleSelectAllRiders()}
          >
            All
          </MenuItemOption>
        ) : null}
        {showUnassigned ? (
          <MenuItemOption
            isChecked={selectedRiderIds.includes(UNASSIGNED_RIDER_ID)}
            onClick={() => handleToggleRider(UNASSIGNED_RIDER_ID)}
          >
            Unassigned
          </MenuItemOption>
        ) : null}
        {ListItems}
      </MenuList>
    </Menu>
  );
};
