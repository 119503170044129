import { z } from 'zod';
import { HydratedOrderSchema, OrdersQueryParamsSchema } from '../../../models';

export const GetOrdersByEndUserIdQuerySchema = OrdersQueryParamsSchema

export const GetOrdersByEndUserIdResponseSchema = z.object({
  orders: z.array(HydratedOrderSchema),
  nextPageIndex: z.number().nullable(),
});

export type GetOrdersByEndUserIdQuery = z.infer<
  typeof GetOrdersByEndUserIdQuerySchema
>;

export type GetOrdersByEndUserIdResponse = z.infer<
  typeof GetOrdersByEndUserIdResponseSchema
>;
