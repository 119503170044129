import { Heading, Show, Stack, Text } from '@chakra-ui/react';
import {
  useGetMerchantAccount,
  useGetOrder,
  useMerchantUpdateOrder,
} from '@tradeaze/frontend/hooks';
import { CreateOrder, UpdateOrderSchema } from '@tradeaze-packages/schemas';
import {
  Map,
  CreateOrderForm,
  PageError,
  PageLoading,
  TwoColumnLayout,
  useFormMapMarkers,
  Layout,
  MobileMapWithToggle,
  translateHydratedOrderToUpdateOrder,
} from '@tradeaze/frontend/ui-web';
import { handleError } from '@tradeaze/frontend/utils';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../components/auth/AuthProvider';
import { useMemo } from 'react';

export const EditOrderPage = () => {
  const { orderId } = useParams();

  const navigate = useNavigate();

  const { cognitoUserId } = useAuthContext();

  const {
    data: merchantAccount,
    error: merchantError,
    isLoading: isLoadingMerchantAccount,
  } = useGetMerchantAccount(cognitoUserId);

  const {
    data: order,
    isLoading: isLoadingOrder,
    error: orderError,
  } = useGetOrder(orderId);

  const { mutate: updateOrder, isLoading: isLoadingUpdate } =
    useMerchantUpdateOrder(orderId, {
      callback: () => navigate(`/orders/${orderId}`),
    });

  const handleUpdateOrder = async (orderToCreate: CreateOrder) => {
    if (!merchantAccount || !orderToCreate) {
      toast.error('Missing required data to update');
      return;
    }
    if (merchantAccount.merchantId !== orderToCreate?.merchantId) {
      toast.error('You do not have permission to edit this order');
      return;
    }
    updateOrder(orderToCreate);
  };

  const {
    mapMarkers,
    addDeliveryMarker,
    addPickupMarker,
    removeDeliveryMarker,
    removePickupMarker,
  } = useFormMapMarkers();

  const existingOrder = useMemo(() => {
    if (!order) {
      return;
    }
    const result = UpdateOrderSchema.safeParse(
      translateHydratedOrderToUpdateOrder(order)
    );
    if (result.success) {
      return result.data;
    }
    handleError(result.error, {
      sendToSentry: true,
      notificationMessage: 'Error parsing order',
      showNotification: true,
    });
    return;
  }, [order]);

  const restrictedEditing = order?.orderStatus === 'CONFIRMED';

  if (isLoadingOrder || isLoadingMerchantAccount) {
    return <PageLoading />;
  }

  if (orderError || merchantError) {
    return <PageError />;
  }

  if (!order || !merchantAccount) {
    return <PageError />;
  }

  if (order.merchantId !== merchantAccount.merchantId) {
    return <PageError text="Unauthorized to edit" />;
  }

  const MainComponent = (
    <>
      <Stack mb={12}>
        <Heading size={'md'}>Editing order</Heading>
        <Text>
          {order.merchantOrderReference
            ? `Reference: ${order.merchantOrderReference}`
            : `ID: ${order.orderId}`}
        </Text>
      </Stack>
      <CreateOrderForm
        existingOrder={existingOrder}
        merchantAccount={merchantAccount}
        onSubmit={handleUpdateOrder}
        isLoading={isLoadingUpdate}
        restrictedEditing={restrictedEditing}
        handleAddDeliveryMarker={addDeliveryMarker}
        handleAddPickupMarker={addPickupMarker}
        removeDeliveryMarker={removeDeliveryMarker}
        removePickupMarker={removePickupMarker}
      />
    </>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={MainComponent}
          right={<Map markers={mapMarkers} shouldUpdateBounds={true} />}
        />
      </Show>
      <Show below="md">
        <Layout>
          {MainComponent}
          <MobileMapWithToggle markers={mapMarkers} shouldUpdateBounds={true} />
        </Layout>
      </Show>
    </>
  );
};
