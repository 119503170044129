import { z } from 'zod';
import {
  CutOffTimeSchema,
  DeliveryOptionIdSchema,
  DeliveryVehicleIdSchema,
  PositionSchema,
} from '../../../models';

export const DistanceAndDurationSchema = z.object({
  duration: z.number(),
  distance: z.number(),
});

export const GetDeliveryPriceStringQuerySchema = z.object({
  deliveryOptionId: DeliveryOptionIdSchema,
  scheduledTime: z.string(),
  fromTime: z.string().optional(),
  trackingLink: z.preprocess((arg) => arg === 'true', z.boolean()).optional(),
  throwOverThreshold: z
    .preprocess((arg) => arg === 'true' || arg === true, z.boolean())
    .optional(),
  throwOnBikeOverThreshold: z
    .preprocess((arg) => arg === 'true' || arg === true, z.boolean())
    .optional(),
  isEndUser: z
    .preprocess((arg) => arg === 'true' || arg === true, z.boolean())
    .optional(),
  heavySideItems: z
    .preprocess((arg) => arg === 'true' || arg === true, z.boolean())
    .optional(),
});

export const GetDeliveryPriceArrayQuerySchema = z.object({
  deliveryStopLocations: z.string().array(),
});

export const GetDeliveryPriceQuerySchema =
  GetDeliveryPriceStringQuerySchema.merge(GetDeliveryPriceArrayQuerySchema);

export const GetDeliveryPriceResponseSchema = z.object({
  deliveryVehicleId: DeliveryVehicleIdSchema,
  deliveryOptionId: DeliveryOptionIdSchema,
  deliveryPrice: z.number(),
  serviceCharge: z.number(),
  totalTimeEstimate: z.number(),
  distance: z.number(),
  duration: z.number(),
  thresholdDistances: z.array(DistanceAndDurationSchema),
  isAvailable: z.boolean(),
  cutOffTime: CutOffTimeSchema,
  windowStart: z.string(),
  windowEnd: z.string(),
  deliveryJourney: z.object({
    distance: z.number(),
    duration: z.number(),
    routeLegs: z.array(
      z.object({
        startLocation: PositionSchema,
        endLocation: PositionSchema,
        distance: z.number(),
        duration: z.number(),
      })
    ),
  }),
});

export const GetDeliveryPriceErrorDataSchema = z.object({
  deliveryStops: z.array(
    z.object({
      distance: z.number(),
      duration: z.number(),
      isOverThreshold: z.boolean(),
    })
  ),
  thresholdMetres: z.number(),
});

export type DistanceAndDuration = z.infer<typeof DistanceAndDurationSchema>;
export type GetDeliveryPriceQuery = z.infer<typeof GetDeliveryPriceQuerySchema>;
export type GetDeliveryPriceResponse = z.infer<
  typeof GetDeliveryPriceResponseSchema
>;
export type GetDeliveryPriceErrorData = z.infer<
  typeof GetDeliveryPriceErrorDataSchema
>;
