import { z } from 'zod';
import { HydratedDeliveryStopSchema } from './hydrated-schema';

export const DeliveryScheduleSchema = z.object({
  createdAt: z.string(),
  updatedAt: z.string(),
  date: z.string(),
  deliveryScheduleId: z.string(),
  riderId: z.string(),
  regionId: z.string(),
});

export const CreateDeliveryScheduleSchema = DeliveryScheduleSchema.omit({
  createdAt: true,
  updatedAt: true,
  deliveryScheduleId: true,
});

export const DeliveryScheduleWithStopsSchema = DeliveryScheduleSchema.extend({
  deliveryStops: z.array(HydratedDeliveryStopSchema),
});

export const DurationToStopsSchema = z.record(z.string(), z.number());

export const EtasSchemas = z.record(z.string(), z.string());

export type CreateDeliverySchedule = z.infer<
  typeof CreateDeliveryScheduleSchema
>;

export type DeliverySchedule = z.infer<typeof DeliveryScheduleSchema>;

export type DeliveryScheduleWithStops = z.infer<
  typeof DeliveryScheduleWithStopsSchema
>;

export type Etas = z.infer<typeof EtasSchemas>;

export type DurationToStops = z.infer<typeof DurationToStopsSchema>;
