import { cancelDelivery } from "@tradeaze-packages/api-client";
import { CustomUseMutationOptions } from "../shared";
import { useMutation } from "@tanstack/react-query";
import { useInvalidateQueries } from "../utility";
import { getDeliveryQueryKey } from "./useGetDelivery";
import { getAdminDeliveriesQueryKey } from "./useGetAdminDeliveries";
import { getMerchantDeliveriesQueryKey } from "./useGetMerchantDeliveries";
import { getAdminOrdersQueryKey, getMerchantOrdersQueryKey, getOrderQueryKey } from "../orders";

export const useCancelDelivery = (options: CustomUseMutationOptions<typeof cancelDelivery>) => {
    const invalidateQueries = useInvalidateQueries();

    return useMutation({
      ...options,
      mutationFn: cancelDelivery,
      onSuccess: (data, variables, context) => {
        invalidateQueries(
          [
            getOrderQueryKey(),
            getDeliveryQueryKey(variables.deliveryId),
            getAdminDeliveriesQueryKey(),
            getMerchantDeliveriesQueryKey(),
            getAdminOrdersQueryKey(),
            getMerchantOrdersQueryKey()
          ]
        );
  
        options?.onSuccess?.(data, variables, context);
      },
    });
}