import { z } from 'zod';
import { HydratedDeliverySchema, DeliveriesQueryParamsSchema } from '../../../models';

export const GetDeliveriesByEndUserIdQuerySchema = DeliveriesQueryParamsSchema;

export const GetDeliveriesByEndUserIdResponseSchema = z.object({
  deliveries: z.array(HydratedDeliverySchema),
  nextPageIndex: z.number().nullable(),
});

export type GetDeliveriesByEndUserIdQuery = z.infer<
  typeof GetDeliveriesByEndUserIdQuerySchema
>;

export type GetDeliveriesByEndUserIdResponse = z.infer<
  typeof GetDeliveriesByEndUserIdResponseSchema
>;
