import { z } from 'zod';

export const GetLatestAppVersionResponseSchema = z.object({
  appName: z.string(),
  version: z.string(),
  createdAt: z.string(),
});

export type GetLatestAppVersionResponse = z.infer<
  typeof GetLatestAppVersionResponseSchema
>;
