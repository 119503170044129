import { createClient } from '@hey-api/client-fetch';

export const setupApiClient = ({
  getJwt,
  baseUrl,
}: {
  getJwt: (() => Promise<string>) | null;
  baseUrl: string;
}) => {
  /**
   * Creates a global client - do not need to use the returned client
   */
  const client = createClient({
    baseUrl,
  });

  if (getJwt) {
    client.interceptors.request.use(async (request) => {
      const jwt = await getJwt();
      request.headers.set('Authorization', `Bearer ${jwt}`);
      return request;
    });
  }

  return client;
};
