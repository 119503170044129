import React, { useState } from 'react';
import { Box, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { RiderIdentity } from '@tradeaze-packages/schemas';
import { formatDate } from '@tradeaze/shared/utils';
import { differenceInMinutes } from 'date-fns';
import { RiderAvatar } from '../rider/RiderAvatar';
import { FaHourglassHalf } from 'react-icons/fa';
import { DeliveryVehicleIcon } from '../delivery';

const idleTimeToColor = (idleTime: number) => {
  if (idleTime < 5) {
    return 'blackAlpha.500';
  } else if (idleTime < 15) {
    return 'orange.300';
  } else {
    return 'red.400';
  }
};

export const RiderMarker: React.FC<{
  rider: RiderIdentity;
  updatedAt?: string;
  alwaysShowDetails?: boolean;
  showProfilePic?: boolean;
  showInactive?: boolean;
}> = ({
  rider,
  updatedAt,
  alwaysShowDetails = false,
  showProfilePic = false,
  showInactive = false,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const idleTime = updatedAt
    ? differenceInMinutes(new Date(), new Date(updatedAt))
    : 0;

  const isInactive = idleTime > 15;

  return (
    <Stack
      alignItems="center"
      position={'relative'}
      onMouseOver={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}
    >
      <RiderAvatar
        firstName={rider.firstName}
        lastName={rider.lastName}
        size={'xs'}
        profilePicKey={rider.avatarUrl}
        showProfilePic={showProfilePic}
      />
      {showInactive && isInactive ? (
        <Icon
          as={FaHourglassHalf}
          position={'absolute'}
          top={-2}
          right={-2}
          fontSize={12}
          color={idleTimeToColor(idleTime)}
        />
      ) : null}
      {(showDetails || alwaysShowDetails) && (
        <Box
          fontSize={11}
          bg="white"
          px={2}
          borderRadius={10}
          shadow={'xl'}
          position={'absolute'}
          top={-12}
          whiteSpace={'nowrap'}
        >
          <HStack>
            <Text>{rider.firstName}</Text>
            {rider.deliveryVehicle ? (
              <DeliveryVehicleIcon
                fontSize={14}
                deliveryVehicle={rider.deliveryVehicle}
              />
            ) : null}
          </HStack>
          {updatedAt ? <Text>{formatDate(new Date(updatedAt))}</Text> : null}
        </Box>
      )}
    </Stack>
  );
};
