import { Position } from '@tradeaze-packages/schemas';

// --- move the below

export const buildGoogleDirectionsUrl = (coordinates: Position[]) => {
  const origin = coordinates[0];
  const destination = coordinates[coordinates.length - 1];
  const waypoints = coordinates
    .slice(1, coordinates.length - 1)
    .map((position) => `${position.latitude},${position.longitude}`)
    .join('|');
  return `https://www.google.com/maps/dir/?api=1&origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&waypoints=${waypoints}`;
};
