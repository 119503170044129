import { Alert, AlertIcon, Box, Text } from '@chakra-ui/react';

export type RouterStateAlert = {
  status: 'error' | 'success' | 'warning' | 'info';
  message: string;
};

export const AuthPageAlert = ({ status, message }: RouterStateAlert) => {
  return (
    <Box position={'fixed'} bottom={0} p={4}>
      <Alert status={status} borderRadius={'md'} my={4} size="sm">
        <AlertIcon />
        <Text>{message}</Text>
      </Alert>
    </Box>
  );
};
