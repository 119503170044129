import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import {
  DuplicateOrderSchema,
  HydratedOrder,
  buildDuplicateDeliveryStopSchema,
} from '@tradeaze-packages/schemas';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { captureEvent, handleError } from '@tradeaze/frontend/utils';
import { OrderPageButton } from './OrderPageButton';
import { BiCopy } from 'react-icons/bi';
import { FaUndo } from 'react-icons/fa';
import { buildReturnJourneyOrder } from './utils/buildReturnJourneyOrder';
import { z } from 'zod';
import { usePostHog } from 'posthog-js/react';
import { translateHydratedOrderToCreateOrder } from './utils';

type AttributeGroup = 'items' | 'site-contact';

type DuplicationType = 'standard' | 'return';

const ConfigCheckbox: React.FC<{
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  children: React.ReactNode;
}> = ({ isChecked, onChange, children }) => {
  return (
    <Box mb={2}>
      <Checkbox
        isChecked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
      >
        {children}
      </Checkbox>
    </Box>
  );
};

export const DuplicateAndReturnOrder: React.FC<{
  order: HydratedOrder;
}> = ({ order }) => {
  const posthog = usePostHog();

  const [config, setConfig] = useState<{
    [key in AttributeGroup]: boolean;
  }>({
    items: false,
    'site-contact': false,
  });

  const handleConfigChange = (group: AttributeGroup, isChecked: boolean) => {
    setConfig((prev) => ({
      ...prev,
      [group]: isChecked,
    }));
    captureEvent(posthog)('toggle_duplicate_config', {
      config: group,
      isChecked,
    });
  };

  const [type, setType] = useState<DuplicationType>();

  const navigate = useNavigate();

  const modalDisclosure = useDisclosure();

  const allowReturn = order.deliveries.length === 1;

  const duplicateOrder = () => {
    if (!order) return;

    if (type === 'return' && !allowReturn) {
      return;
    }

    if (type === 'return' && !order.deliveries[0]) {
      return;
    }

    const orderToParse =
      type === 'return' ? buildReturnJourneyOrder(order) : order;

    // todo - type this properly
    let schema: z.ZodObject<any> = DuplicateOrderSchema;

    if (!config['items']) {
      schema = schema.omit({
        heavySideItems: true,
        deliveryItems: true,
      });
    }

    if (!config['site-contact']) {
      schema = schema.extend({
        deliveryStops: z.array(
          buildDuplicateDeliveryStopSchema({
            contacts: true,
          })
        ),
      });
    }

    const createOrderRequest =
      translateHydratedOrderToCreateOrder(orderToParse);

    const parsedNewOrder = schema.safeParse(createOrderRequest);

    if (parsedNewOrder.success) {
      navigate('/create-order', {
        state: {
          order: parsedNewOrder.data,
        },
      });
      captureEvent(posthog)('duplicate_order');
    } else {
      handleError(parsedNewOrder.error, {
        notificationMessage: 'Something went wrong parsing the order',
        showNotification: true,
        sendToSentry: true,
        consoleLog: true,
      });
    }
  };

  const handleClickReturn = () => {
    setType('return');
    modalDisclosure.onOpen();
    captureEvent(posthog)('click_return_order');
  };

  const handleClickDuplicate = () => {
    setType('standard');
    modalDisclosure.onOpen();
    captureEvent(posthog)('click_duplicate_order');
  };

  const modalTitle = type === 'standard' ? 'Duplicate' : 'Return';

  return (
    <>
      <OrderPageButton
        icon={<BiCopy fontSize={24} />}
        label="Duplicate"
        onClick={handleClickDuplicate}
      />
      {allowReturn ? <OrderPageButton
        icon={<FaUndo fontSize={18} />}
        label="Return"
        onClick={handleClickReturn}
      /> : null}
      <Modal
        isCentered
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalTitle} Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <ConfigCheckbox
                isChecked={config['items']}
                onChange={(isChecked) => handleConfigChange('items', isChecked)}
              >
                Copy item details
              </ConfigCheckbox>
            </Box>
            {type === 'standard' && (
              <Box>
                <ConfigCheckbox
                  isChecked={config['site-contact']}
                  onChange={(isChecked) =>
                    handleConfigChange('site-contact', isChecked)
                  }
                >
                  Copy site contacts
                </ConfigCheckbox>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={duplicateOrder}>Continue</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
