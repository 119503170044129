import { Grid, GridItem } from '@chakra-ui/react';
import { NAVBAR_HEIGHT } from '../navbar';

export const TwoColumnLayout = ({
  left,
  right,
  leftContainerProps,
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  leftContainerProps?: React.ComponentProps<typeof GridItem>;
}) => {
  return (
    <Grid
      position={'absolute'}
      paddingTop={`${NAVBAR_HEIGHT}px`}
      templateColumns={'repeat(6, 1fr)'}
      w="100vw"
    >
      <GridItem
        colSpan={3}
        minWidth={[null, null, '450px']}
        overflowY={'auto'}
        paddingX={[6, 8, 10]}
        paddingY={8}
        h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
        {...leftContainerProps}
      >
        {left}
      </GridItem>
      <GridItem
        colSpan={3}
        overflowY={'hidden'}
        position={'sticky'}
        top={`${NAVBAR_HEIGHT}px`}
        bottom={0}
        h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      >
        {right}
      </GridItem>
    </Grid>
  );
};
