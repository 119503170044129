import { Box, HStack, Text } from '@chakra-ui/react';
import { DeliveryStopType } from '@tradeaze-packages/schemas';
import { formatTimeRange } from '@tradeaze/shared/utils';

export const CardEtaTag: React.FC<{
  colorScheme: 'green' | 'blue' | 'yellow';
  earliestArrival: string;
  latestArrival: string;
  stopType: DeliveryStopType;
}> = ({ colorScheme, earliestArrival, latestArrival, stopType }) => {
  const backgroundColor = colorScheme === 'green' ? 'green.50' : 'blue.50';

  const borderColor = colorScheme === 'green' ? 'green.100' : 'blue.100';

  const etaType = stopType === 'PICK_UP' ? 'ETA Pickup' : 'ETA Delivery';

  return (
    <Box
      width={'fit-content'}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      paddingY={'1px'}
      paddingX={'2px'}
      borderRadius={'5px'}
      borderWidth={'1px'}
    >
      <Text lineHeight={'1.2em'} fontSize={'0.8em'}>
        <HStack>
          <Text fontWeight={'bold'}>{etaType}:</Text>
          <Text>
            {formatTimeRange(
              {
                from: earliestArrival,
                to: latestArrival,
              },
              false
            )}
          </Text>
        </HStack>
      </Text>
    </Box>
  );
};
