import {
  ALL_DELIVERY_VEHICLE_IDS,
  ClockInWorkType,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';
import { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type OptimisationConfigStore = {
  allowedVehicleTypes: DeliveryVehicleId[];
  autoRun: boolean;
  excludedRiderIds: string[];
  workType: ClockInWorkType | undefined;
  setAllowedVehicleTypes: (vehicleTypes?: DeliveryVehicleId[]) => void;
  handleToggleAllowedVehicleType: (vehicleType: DeliveryVehicleId) => void;
  handleToggleAutoRun: () => void;
  handleToggleExcludedRider: (riderId: string) => void;
  handleChangeWorkType: (workType: ClockInWorkType | undefined) => void;
};

export const useOptimisationConfigStore = create<OptimisationConfigStore>()(
  persist(
    (set) => ({
      // Initial state
      allowedVehicleTypes: ALL_DELIVERY_VEHICLE_IDS,
      autoRun: false,
      excludedRiderIds: [],
      workType: 'SHIFT',

      // Actions
      setAllowedVehicleTypes: (vehicleTypes?: DeliveryVehicleId[]) => {
        set({
          allowedVehicleTypes: vehicleTypes && vehicleTypes.length > 0
            ? vehicleTypes
            : ALL_DELIVERY_VEHICLE_IDS,
        });
      },

      handleToggleAllowedVehicleType: (vehicleType: DeliveryVehicleId) => {
        set((state) => ({
          allowedVehicleTypes: state.allowedVehicleTypes.includes(vehicleType)
            ? state.allowedVehicleTypes.filter((v) => v !== vehicleType)
            : [...state.allowedVehicleTypes, vehicleType],
        }));
      },

      handleToggleAutoRun: () => {
        set((state) => ({ autoRun: !state.autoRun }));
      },

      handleToggleExcludedRider: (riderId: string) => {
        set((state) => ({
          excludedRiderIds: state.excludedRiderIds.includes(riderId)
            ? state.excludedRiderIds.filter((v) => v !== riderId)
            : [...state.excludedRiderIds, riderId],
        }));
      },

      handleChangeWorkType: (workType: ClockInWorkType | undefined) => {
        set({ workType });
      },
    }),
    {
      name: 'optimisation-config',
      getStorage: () => sessionStorage,
      // do not persist allowedVehicleTypes
      // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#partialize
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) => !['allowedVehicleTypes'].includes(key),
          ),
        ),
    },
  ),
);

export const useInitialiseOptimisationConfig = ({
  deliveryVehicleIds,
  workType,
  onSuccessfulSet,
}: {
  deliveryVehicleIds: DeliveryVehicleId[];
  workType?: ClockInWorkType;
  onSuccessfulSet?: () => void;
}) => {
  const optimisationConfig = useOptimisationConfigStore();

  useEffect(() => {
    optimisationConfig.setAllowedVehicleTypes(
      deliveryVehicleIds && deliveryVehicleIds.length > 0
        ? deliveryVehicleIds
        : ALL_DELIVERY_VEHICLE_IDS,
    );
    optimisationConfig.handleChangeWorkType(workType);
    onSuccessfulSet?.();
  }, [JSON.stringify(deliveryVehicleIds), workType]);
};
