import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

interface Props {
  handleForgotPassword: ({ username }: { username: string }) => void;
  authLoading: boolean;
}

interface ForgotPasswordInput {
  username: string;
}

const ForgotPasswordForm = ({ handleForgotPassword, authLoading }: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordInput>();

  return (
    <form onSubmit={handleSubmit(handleForgotPassword)}>
      <Stack>
        {/* USERNAME */}
        <FormControl my={6} isInvalid={Boolean(errors.username)}>
          <FormLabel htmlFor="username">Username or Email</FormLabel>
          <Input
            id="username"
            placeholder="Username or Email"
            {...register('username', {
              required: 'This is required',
            })}
          />
          <FormErrorMessage>
            <span>{errors.username?.message}</span>
          </FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          colorScheme="yellow"
          isLoading={isSubmitting || authLoading}
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default ForgotPasswordForm;
