import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { Order, CreateOrder } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';
import toast from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { useInvalidateQueries } from '../utility';
import { getMerchantOrdersQueryKey } from './useGetMerchantOrders';

export const useMerchantCreateOrder = (
  options?: UseMutationOptions<Order, unknown, CreateOrder>
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation<Order, unknown, CreateOrder>(
    (order) => {
      const apiName = 'OrderApi';
      const path = '/merchantCreateOrder';
      const options: {
        body: CreateOrder;
      } = {
        body: order,
      };
      return API.post(apiName, path, options);
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        toast.success('Order created');
        invalidateQueries([getMerchantOrdersQueryKey()]);
        options?.onSuccess?.(data, variables, context);
      },
      onError: (error, variables, context) => {
        toast.error('Error creating order');
        Sentry.captureException(error);
        options?.onError?.(error, variables, context);
      },
    }
  );
};
