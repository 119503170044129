import React from 'react';
import { Box } from '@chakra-ui/react';

export const GridCardContent: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <Box
      borderRadius={'xl'}
      backgroundColor={'white'}
      marginTop={-4}
      paddingY={'8px'}
      paddingX={'16px'}
    >
      {children}
    </Box>
  );
};
