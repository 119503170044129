import { Box, Text } from '@chakra-ui/react';
import { DeliveryStatus } from '@tradeaze-packages/schemas';
import { formatDate, getDifferenceInTime } from '@tradeaze/shared/utils';
import startCase from 'lodash.startcase';
import { useMemo } from 'react';

export const CardTimeRemaining: React.FC<{
  deliveryWindowEnd: string;
  deliveryStatus: DeliveryStatus;
  showLate?: boolean;
  updatedAt?: string | null;
  cancelledAt?: string | null;
  deliveredAt?: string | null;
  isToday: boolean;
}> = ({
  deliveryWindowEnd,
  deliveryStatus,
  showLate,
  updatedAt,
  cancelledAt,
  deliveredAt,
  isToday,
}) => {
  const timeRemaining = getDifferenceInTime(
    new Date(),
    new Date(deliveryWindowEnd)
  );

  const colorScheme = useMemo(() => {
    if (deliveryStatus === 'DELIVERED') {
      return 'green';
    }
    if (deliveryStatus === 'REJECTED' || deliveryStatus === 'CANCELLED') {
      return 'red';
    }
    if (timeRemaining.text.includes('late')) {
      if (showLate) {
        return 'red';
      }
    }
    if (isToday) {
      return 'yellow';
    }
    return 'blackAlpha';
  }, [timeRemaining, showLate, deliveryStatus, isToday]);

  const { backgroundColor, borderColor, textColor } = {
    green: {
      backgroundColor: 'green.50',
      borderColor: 'green.100',
      textColor: 'green.800',
    },
    red: {
      backgroundColor: 'red.50',
      borderColor: 'red.100',
      textColor: 'red.800',
    },
    yellow: {
      backgroundColor: 'yellow.50',
      borderColor: 'yellow.100',
      textColor: 'yellow.800',
    },
    blackAlpha: {
      backgroundColor: 'blackAlpha.50',
      borderColor: 'blackAlpha.100',
      textColor: 'blackAlpha.800',
    },
  }[colorScheme];

  const text = useMemo(() => {
    if (deliveryStatus === 'PENDING' || deliveryStatus === 'CONFIRMED') {
      return timeRemaining.text.includes('late')
        ? showLate
          ? timeRemaining.text
          : 'Due'
        : timeRemaining.text;
    }

    if (deliveryStatus === 'DELIVERED') {
      return deliveredAt
        ? `Delivered: ${formatDate(new Date(deliveredAt))}`
        : null;
    }

    if (deliveryStatus === 'REJECTED' || deliveryStatus === 'CANCELLED') {
      const cancelledTime = cancelledAt
        ? new Date(cancelledAt)
        : updatedAt
        ? new Date(updatedAt)
        : null;

      return cancelledTime
        ? `${startCase(deliveryStatus.toLowerCase())}: ${formatDate(
            cancelledTime
          )}`
        : null;
    }

    return null;
  }, [
    deliveryStatus,
    timeRemaining,
    showLate,
    updatedAt,
    deliveredAt,
    cancelledAt,
  ]);

  return (
    <Box
      width={'fit-content'}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      paddingY={'1px'}
      paddingX={'4px'}
      borderRadius={'5px'}
      borderWidth={'1px'}
    >
      <Text
        color={textColor}
        fontWeight={'bold'}
        lineHeight={'1.2em'}
        fontSize={'0.8em'}
      >
        {text}
      </Text>
    </Box>
  );
};
