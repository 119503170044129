import { DuplicateOrderSchema } from '@tradeaze-packages/schemas';
import { z } from 'zod';

export const parseDuplicateOrderFromState = (state: unknown) => {
  const parsedResult = z
    .object({
      order: DuplicateOrderSchema,
    })
    .safeParse(state);

  if (!parsedResult.success) {
    return undefined;
  }

  return parsedResult.data.order;
};
