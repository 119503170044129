import { Icon, Stack, Text } from '@chakra-ui/react';
import { Address } from '@tradeaze-packages/schemas';
import React, { useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { brandSecondaryColor } from '../theme/colors';

export const AddressMarker: React.FC<{
  address: Address;
  isActive?: boolean;
}> = ({ address, isActive }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="center"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {(isActive || isHovered) && (
        <Stack
          bg="white"
          borderRadius={5}
          paddingX={2}
          spacing={-2}
          textAlign="center"
        >
          <Text fontSize="m" color="black.500">
            {address.postCode}
          </Text>
        </Stack>
      )}
      <Icon as={FaMapMarkerAlt} fontSize={30} color={brandSecondaryColor} />
    </Stack>
  );
};
