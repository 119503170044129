import { PublicDeliveryCreateSchema } from '../../../models';
import { z } from 'zod';

export const PublicPostDeliveryRequestSchema = PublicDeliveryCreateSchema;

export const PublicPostDeliveryResponseSchema = z.object({
  id: z.string(),
});

export type PublicPostDeliveryResponse = z.infer<
  typeof PublicPostDeliveryResponseSchema
>;
export type PublicPostDeliveryRequest = z.infer<
  typeof PublicPostDeliveryRequestSchema
>;
