import { z } from 'zod';
import { HydratedOrderSchema, OrdersQueryParamsSchema } from '../../../models';

export const GetOrdersByRiderIdQuerySchema = OrdersQueryParamsSchema.omit({
  riderIds: true,
});

export const GetOrdersByRiderIdResponseSchema = z.object({
  orders: z.array(HydratedOrderSchema),
  nextPageIndex: z.number().nullable(),
});

export type GetOrdersByRiderIdQuery = z.infer<
  typeof GetOrdersByRiderIdQuerySchema
>;

export type GetOrdersByRiderIdResponse = z.infer<
  typeof GetOrdersByRiderIdResponseSchema
>;
