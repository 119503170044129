import { z } from 'zod';
import { GeofenceEventSchema } from '../../../models';

export const LeftDeliveryStopBodySchema = GeofenceEventSchema;

export const LeftDeliveryStopResponseSchema = z.object({
  deliveryStopId: z.string(),
  leftAt: z.string().datetime(),
});

export type LeftDeliveryStopBody = z.infer<typeof LeftDeliveryStopBodySchema>;

export type LeftDeliveryStopResponse = z.infer<
  typeof LeftDeliveryStopResponseSchema
>;
