import React from 'react';
import { motion, Transition, Variants } from 'framer-motion';

interface Props {
  children: React.ReactNode;
}

export const PageTransition = ({ children }: Props) => {
  const pageVariants: Variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const pageTransition: Transition = {
    duration: 0.2,
  };

  return (
    <motion.div
      initial={'initial'}
      animate={'animate'}
      exit={'exit'}
      variants={pageVariants}
      transition={pageTransition}
    >
      {children}
    </motion.div>
  );
};
