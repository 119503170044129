import { startCase } from 'lodash';
import { DeliveryOptionId } from '@tradeaze-packages/schemas';

export const formatDeliveryOption = (option: DeliveryOptionId) => {
  const replacements = {
    xlarge: '(XL)',
    large: '(L)',
    medium: '(M)',
    small: '(S)',
  };

  let startCaseName = startCase(option.toLocaleLowerCase());

  for (const key in replacements) {
    const regex = new RegExp(key, 'gi');
    startCaseName = startCaseName.replace(
      regex,
      replacements[key as keyof typeof replacements]
    );
  }

  return startCaseName;
};
