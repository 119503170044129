import { Box, FormLabel, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import { DeliveryVehicleCard } from './DeliveryVehicleCard';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CreateOrder,
  DeliveryOptionId,
  DeliveryVehicle,
  DeliveryVehicleId,
} from '@tradeaze-packages/schemas';
import { FormInputNote } from '../../../form';
import { filterVehiclesByItemSize } from './deliveryOptionsUtils';
import { useEffect, useMemo } from 'react';
import { WarningTwoIcon } from '@chakra-ui/icons';

type DeliveryVehicleSectionProps = {
  deliveryVehicles: DeliveryVehicle[];
  handleOptionAndItemsOnDeliveryVehicle: (
    deliveryVehicleId: DeliveryVehicleId
  ) => void;
};

const vehicleSortOrder: DeliveryVehicleId[] = [
  'BIKE',
  'CAR',
  'VAN_LARGE',
  'VAN_XLARGE',
  'LUTON',
  'FLATBED',
];

export const DeliveryVehicleSection = ({
  deliveryVehicles,
  handleOptionAndItemsOnDeliveryVehicle,
}: DeliveryVehicleSectionProps) => {
  const { control, watch, setValue } = useFormContext<CreateOrder>();
  const measurmentUnit = watch('deliveryItems.0.widthUnit');
  const deliveryItemsValues = watch('deliveryItems.0');
  const selectedVehicle = watch('deliveryVehicle');

  const filteredVehicles = useMemo(() => {
    if (!deliveryVehicles || deliveryVehicles.length === 0) return undefined;
    return deliveryVehicles
      .slice(0)
      .sort((a, b) => {
        return (
          vehicleSortOrder.indexOf(a.deliveryVehicleId) -
          vehicleSortOrder.indexOf(b.deliveryVehicleId)
        );
      })
      .filter((deliveryVehicle) =>
        filterVehiclesByItemSize(deliveryVehicle, deliveryItemsValues)
      );
  }, [JSON.stringify(deliveryItemsValues), deliveryVehicles]);

  useEffect(() => {
    if (!filteredVehicles) return;

    const isSelectedVehicleAvailable = filteredVehicles.some(
      (vehicle) => vehicle.deliveryVehicleId === selectedVehicle
    );

    if (isSelectedVehicleAvailable) {
      handleOptionAndItemsOnDeliveryVehicle(selectedVehicle);
    } else {
      setValue('deliveryVehicle', filteredVehicles[0]?.deliveryVehicleId);
      setValue('deliveryOption', null as unknown as DeliveryOptionId);
    }
  }, [filteredVehicles]);

  return (
    <Controller
      name="deliveryVehicle"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box display={'flex'} flexDirection={'column'}>
          <Box mb={6}>
            <FormLabel>Delivery Vehicle</FormLabel>
            <Text color={'blackAlpha.600'}>
              Based on the sizing details estimated above, you can choose
              between the following available options:
            </Text>
          </Box>
          <SimpleGrid columns={[1, 2]} gap={4}>
            {filteredVehicles && filteredVehicles.length > 0 ? (
              filteredVehicles.map((deliveryVehicle, index) => (
                <DeliveryVehicleCard
                  key={deliveryVehicle.deliveryVehicleId}
                  deliveryVehicle={deliveryVehicle}
                  measurmentUnit={measurmentUnit}
                  isSelected={value === deliveryVehicle.deliveryVehicleId}
                  handleClick={() => {
                    onChange(deliveryVehicle.deliveryVehicleId);
                    handleOptionAndItemsOnDeliveryVehicle(
                      deliveryVehicle.deliveryVehicleId
                    );
                  }}
                />
              ))
            ) : (
              <Text color="orange.400" fontSize={14}>
                <Icon as={WarningTwoIcon} mr={1} />
                No vehicles can take the dimensions/weight given. Contact
                Tradeaze for any enquiries.
              </Text>
            )}
          </SimpleGrid>
        </Box>
      )}
    />
  );
};
