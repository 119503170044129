import { z } from 'zod';

export const DeleteAddressParamsSchema = z.object({
  addressId: z.string(),
});

export const DeleteAddressResponseSchema = z.literal('OK');

export type DeleteAddressParams = z.infer<typeof DeleteAddressParamsSchema>;

export type DeleteAddressResponse = z.infer<typeof DeleteAddressResponseSchema>;
