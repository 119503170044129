import { Address } from '@tradeaze-packages/schemas';
import { AddressMarker } from '../AddressMarker';
import { MapMarkerProps } from '../Map';

export const addressesToMarkers = (
  addresses: Address[],
  activeAddressId?: string | null
): MapMarkerProps<'ADDRESS'>[] =>
  addresses.reduce<MapMarkerProps<'ADDRESS'>[]>((acc, address) => {
    if (address.position) {
      return [
        ...acc,
        {
          id: address.addressId,
          type: 'ADDRESS',
          children: (
            <AddressMarker
              address={address}
              isActive={activeAddressId === address.addressId}
            />
          ),
          position: address.position,
          anchor: 'bottom',
          data: {
            addressId: address.addressId,
          },
          isHovered: activeAddressId === address.addressId,
          updateZoom: true,
        },
      ];
    }
    return acc;
  }, []);
