import { z } from 'zod';
import { FleetVehicleSchema } from '../../../models';

export const ApproveFleetVehicleResponseSchema = z.object({
  message: z.string(),
  fleetVehicle: FleetVehicleSchema,
});

export type ApproveFleetVehicleResponse = z.infer<
  typeof ApproveFleetVehicleResponseSchema
>;
