import React, { useMemo } from 'react';
import { Box, Tag, TagLabel } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { TradeazeLogoNoText } from '../brand';

export const RiderDutyTag: React.FC<{
  onDuty: boolean;
}> = ({ onDuty }) => {
  const { colorScheme, bg, name, icon } = useMemo(
    () =>
      onDuty
        ? {
            colorScheme: 'yellow',
            bg: 'yellow.400',
            name: 'On Duty',
            icon: <TradeazeLogoNoText height={12} />,
          }
        : {
            colorScheme: 'red',
            name: 'Off Duty',
            icon: <SmallCloseIcon />,
          },
    [onDuty]
  );

  return (
    <Tag
      size="md"
      colorScheme={colorScheme}
      bg={bg}
      alignItems="center"
      width={'fit-content'}
    >
      <TagLabel>{name}</TagLabel>
    </Tag>
  );
};
