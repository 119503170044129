import { useForm, FormProvider } from 'react-hook-form';
import { Stack, Center, Alert, AlertIcon } from '@chakra-ui/react';
import {
  CreateOrder,
  CreateOrderMpMoranSchema,
  CreateOrderSchema,
  CreateOrderTravisPerkinsSchema,
  DuplicateOrder,
  MerchantAccount,
  UpdateOrder,
} from '@tradeaze-packages/schemas';
import { useGetDeliveryPrice } from '@tradeaze/frontend/hooks';
import { CompleteOrderFormButton } from './CompleteOrderFormButton';
import { AdditionalInfoFormSection } from './AdditionalInfoFormSection';
import { useEffect, useMemo, useState } from 'react';
import { CustomerDetailsFormSection } from './CustomerDetailsFormSection';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  formatMetresToKm,
  getApiErrorMessage,
  getStopPositionsFromRouteLegs,
  validatePostcode,
} from '@tradeaze/shared/utils';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { PostHog } from 'posthog-js/react';
import { captureEvent } from '@tradeaze/frontend/utils';
import { AllDeliveryStopsFormSection } from './AllDeliveryStopsFormSection';
import { v4 as uuid } from 'uuid';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';
import { InvoicingDetailsFormSection } from './InvoicingDetailsFormSection';
import { serializeStopPostcodes, splitSerializedPostcodes } from './utils';
import { getRequiresPod } from '@tradeaze-packages/proof-of-delivery';
import { useThresholdDistances } from './hooks/useThresholdDistances';
import { DeliveryDetailsSection } from './deliveryOptions/DeliveryDetailsSection';
import { unitConverter } from './deliveryOptions/deliveryOptionsUtils';

export const CreateOrderForm = ({
  posthog,
  existingOrder,
  duplicatedOrder,
  merchantAccount,
  onSubmit,
  handleAddDeliveryMarker,
  handleAddPickupMarker,
  removePickupMarker,
  removeDeliveryMarker,
  isLoading,
  isAdmin = false,
  restrictedEditing = false,
}: {
  posthog?: PostHog;
  merchantAccount?: MerchantAccount;
  existingOrder?: UpdateOrder;
  duplicatedOrder?: DuplicateOrder;
  onSubmit: (order: CreateOrder) => void;
  handleAddDeliveryMarker: AddMarkerFn;
  handleAddPickupMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  isLoading: boolean;
  isAdmin?: boolean;
  restrictedEditing?: boolean;
}) => {
  const isExistingOrder = Boolean(existingOrder);
  const isDuplicate = Boolean(duplicatedOrder);
  const [isVehicleModalOpen, setIsVehicleModalOpen] = useState(false);
  const isTravisPerkins = merchantAccount?.featureFlags?.travisPerkins;
  const isMpMoran = merchantAccount?.featureFlags?.mpMoran;

  const hidePriorityDelivery =
    merchantAccount?.featureFlags?.hidePriorityDelivery || false;

  const allowLongDistance = Boolean(
    isAdmin || merchantAccount?.featureFlags?.allowLongDistance
  );

  const FormSchema = useMemo(() => {
    let schema: z.ZodObject<any>;

    if (isTravisPerkins) {
      schema = CreateOrderTravisPerkinsSchema;
    } else if (isMpMoran) {
      schema = CreateOrderMpMoranSchema;
    } else {
      schema = CreateOrderSchema;
    }

    return schema;
  }, [isTravisPerkins, isMpMoran]);

  const defaultValues = useMemo<Partial<CreateOrder>>(() => {
    if (existingOrder) {
      return {
        ...existingOrder,
        bookedBy: existingOrder.bookedBy ?? '',
        deliveryStops:
          existingOrder.deliveryStops.map((ds) => ({
            ...ds,
            collectionReference: ds.collectionReference ?? '',
            contacts: ds.contacts?.length ? ds.contacts : [],
            requiresImage: getRequiresPod(ds, 'image'),
            requiresSignature: getRequiresPod(ds, 'signature'),
            windowStart: ds.windowStart ?? undefined, // Ensure windowStart is set
            windowEnd: ds.windowEnd ?? undefined, // Ensure windowEnd is set
          })) ?? [],
        deliveryItems: existingOrder.deliveryItems.map((di) => ({
          ...di,
          widthUnit: di.widthUnit ?? 'mm',
          lengthUnit: di.widthUnit ?? 'mm',
          heightUnit: di.widthUnit ?? 'mm',
          length: unitConverter(
            di.length,
            di.lengthUnit,
            di.widthUnit
          ) as unknown as number,
          width: unitConverter(
            di.width,
            di.widthUnit,
            di.widthUnit
          ) as unknown as number,
          height: unitConverter(
            di.height,
            di.heightUnit,
            di.widthUnit
          ) as unknown as number,
        })),
      };
    }
    if (duplicatedOrder) {
      return {
        ...duplicatedOrder,
        merchantOrderReference: merchantAccount?.referencePrefix ?? undefined,
        bookedBy: '',
        deliveryStops: duplicatedOrder.deliveryStops?.map((ds, index) => ({
          ...ds,
          deliveryStopId: uuid(),
          collectionReference: '',
          contacts: ds.contacts?.length
            ? ds.contacts
            : ds.type === 'DELIVERY'
            ? [
                {
                  contactName: '',
                  contactNumber: '',
                  isPrimary: true,
                  trackingEnabled: true,
                },
              ]
            : [],
          deliveryScheduleId: undefined,
          stopSequence: index,
          requiresImage: getRequiresPod(ds, 'image'),
          requiresSignature: getRequiresPod(ds, 'signature'),
          windowStart: ds.windowStart ?? undefined, // Ensure windowStart is set
          windowEnd: ds.windowEnd ?? undefined, // Ensure windowEnd is set
        })) ?? [],
      };
    }

    return {
      merchantId: merchantAccount?.merchantId,
      bookedBy: undefined,
      companyId: undefined,
      companyName: undefined,
      deliveryNotes: undefined,
      deliveryTime: undefined,
      deliveryOption: undefined,
      deliveryVehicle: undefined,
      deliveryDistance: undefined,
      deliveryDuration: undefined,
      deliveryWindowStart: undefined,
      deliveryWindowEnd: undefined,
      endUserId: undefined,
      merchantOrderReference: merchantAccount?.referencePrefix ?? undefined,
      pickUpContact: undefined,
      itemsTotal: undefined,
      itemsSize: undefined,
      deliveryStops: [
        {
          deliveryStopId: uuid(),
          type: 'PICK_UP' as const,
          addressLine1: merchantAccount?.address ?? '',
          addressLine2: undefined,
          addressLine3: undefined,
          postCode: merchantAccount?.postCode ?? '',
          position: merchantAccount?.position,
          companyName: merchantAccount?.merchantName ?? '',
          contacts: [],
          collectionReference: '',
          stopSequence: 0,
          requiresImage: false,
          requiresSignature: false,
          windowStart: '', // Ensure windowStart is set
          windowEnd: '', // Ensure windowEnd is set
        },
        {
          deliveryStopId: uuid(),
          type: 'DELIVERY' as const,
          addressLine1: '',
          addressLine2: undefined,
          addressLine3: undefined,
          postCode: '',
          position: undefined,
          companyName: '',
          contacts: [
            {
              contactName: '',
              contactNumber: '',
              isPrimary: true,
              trackingEnabled: true,
            },
          ],
          stopSequence: 1,
          requiresImage: true,
          requiresSignature: true,
          windowStart: '', // Ensure windowStart is set
          windowEnd: '', // Ensure windowEnd is set
        },
      ],
      deliveryItems: [
        {
          id: uuid(),
          name: 'Total Load',
          description: null,
          price: undefined as unknown as number,
          weight: undefined as unknown as number,
          height: undefined as unknown as number,
          width: undefined as unknown as number,
          length: undefined as unknown as number,
          quantity: 1,
          widthUnit: 'mm',
          lengthUnit: 'mm',
          heightUnit: 'mm',
          weightUnit: 'kg',
        },
      ],
      isPriceEdited: false,
      deliveryPrice: undefined,
      heavySideItems: undefined,
      merchantServiceCharge: undefined,
      trackingAppLink: true,
      totalTimeEstimate: undefined,
    };
  }, [existingOrder, duplicatedOrder, merchantAccount]);

  const methods = useForm<CreateOrder>({
    defaultValues,
    mode: 'all',
    resolver: zodResolver(FormSchema),
  });

  const { handleSubmit, watch, setError, setValue, clearErrors } = methods;

  const deliveryStops = watch('deliveryStops');
  const deliveryOptionId = watch('deliveryOption');
  const deliveryVehicle = watch('deliveryVehicle');
  const heavySideItems = watch('heavySideItems');
  const deliveryWindowStart = watch('deliveryWindowStart');
  const isPriceEdited = watch('isPriceEdited');

  const serializedPostcodes = serializeStopPostcodes(deliveryStops);

  const [shouldFreezePriceForEdit, setShouldFreezePriceForEdit] =
    useState<boolean>(existingOrder ? true : false);

  useEffect(() => {
    const checkIfPricingConfigIsSame = () =>
      existingOrder?.deliveryOption === deliveryOptionId &&
      existingOrder?.deliveryVehicle === deliveryVehicle &&
      existingOrder?.heavySideItems === heavySideItems &&
      existingOrder?.deliveryWindowStart === deliveryWindowStart &&
      serializeStopPostcodes(existingOrder?.deliveryStops) ===
        serializedPostcodes;

    if (existingOrder) {
      setShouldFreezePriceForEdit((prevState) => {
        if (prevState === false) {
          /**
           * once config has been changed, we don't want to freeze price again, even if the config changes
           * back to what it was. Otherwise we get unexpected stale pricing when changing between options.
           */
          return prevState;
        }
        return checkIfPricingConfigIsSame();
      });
    }
  }, [
    existingOrder,
    deliveryOptionId,
    deliveryVehicle,
    heavySideItems,
    serializedPostcodes,
    deliveryWindowStart,
  ]);

  const isEveryPostcodeValid = useMemo(
    () =>
      splitSerializedPostcodes(serializedPostcodes).every((p) =>
        validatePostcode(p)
      ) ?? false,
    // object/array references change on each render, so we need to stringify to trigger a change
    [serializedPostcodes]
  );

  const shouldCalculateDelivery = useMemo(
    () =>
      isEveryPostcodeValid &&
      !shouldFreezePriceForEdit &&
      !!deliveryOptionId &&
      !!deliveryVehicle &&
      !!deliveryWindowStart &&
      !isPriceEdited,
    [
      isEveryPostcodeValid,
      deliveryOptionId,
      deliveryVehicle,
      shouldFreezePriceForEdit,
      deliveryWindowStart,
      isPriceEdited,
    ]
  );

  const deliveryPriceQuery = useGetDeliveryPrice(
    {
      deliveryOptionId: deliveryOptionId,
      scheduledTime: deliveryWindowStart,
      deliveryStopLocations: deliveryStops?.map((stop) => stop.postCode) ?? [],
      throwOverThreshold: !allowLongDistance,
      throwOnBikeOverThreshold: !isAdmin,
      heavySideItems: heavySideItems ?? false,
    },
    {
      enabled: shouldCalculateDelivery,
      onError(e) {
        const message = getApiErrorMessage(e);
        const data = e.error?.data;

        data?.deliveryStops?.forEach((stop, index) => {
          if (stop.isOverThreshold) {
            setError(`deliveryStops.${index}.postCode`, {
              message: `Stop location is too far from the centre of London (${formatMetresToKm(
                stop.distance
              )}km > ${formatMetresToKm(data.thresholdMetres)}km)`,
            });
          }
        });

        toast.error(message);
      },
      onSuccess(data) {
        clearErrors('deliveryStops'); // make sure this works
        if (posthog) {
          captureEvent(posthog)('delivery_price_calculated', {
            deliveryOption: deliveryOptionId,
            deliveryVehicle,
            heavySideItems,
            deliveryPrice: data?.deliveryPrice,
            serviceCharge: data?.serviceCharge,
            totalTimeEstimate: data?.totalTimeEstimate,
          });
        }
      },
      onSettled(data) {
        setValue('merchantServiceCharge', data?.serviceCharge as any); // happy for this to get set to undefined - typecast to allow
        setValue('deliveryPrice', data?.deliveryPrice as any); // happy for this to get set to undefined - typecast to allow
        setValue('deliveryDuration', data?.duration);
        setValue('deliveryDistance', data?.distance);
        setValue('totalTimeEstimate', data?.totalTimeEstimate);
        setValue('isPriceEdited', false);

        const stopPositions = data?.deliveryJourney.routeLegs
          ? getStopPositionsFromRouteLegs(data?.deliveryJourney.routeLegs)
          : null;

        if (stopPositions) {
          deliveryStops?.forEach((stop, index) => {
            if (stop.position) {
              return;
            }
            setValue(`deliveryStops.${index}.position`, stopPositions[index]);
          });
        }
      },
    }
  );

  const thresholdDistances = useThresholdDistances(
    deliveryStops,
    isEveryPostcodeValid
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Stack spacing="14">
          {!merchantAccount ? (
            <InvoicingDetailsFormSection formMethods={methods} />
          ) : null}
          <CustomerDetailsFormSection
            merchantAccount={merchantAccount}
            formMethods={methods}
          />
          <AllDeliveryStopsFormSection
            restrictedEditing={restrictedEditing}
            isExistingOrder={isExistingOrder}
            isDuplicate={isDuplicate}
            methods={methods}
            handleAddDeliveryMarker={handleAddDeliveryMarker}
            handleAddPickupMarker={handleAddPickupMarker}
            merchantAccount={merchantAccount}
            removeDeliveryMarker={removeDeliveryMarker}
            removePickupMarker={removePickupMarker}
          />
          {restrictedEditing ? null : (
            <DeliveryDetailsSection
              formMethods={methods}
              posthog={posthog}
              initialDate={
                existingOrder?.deliveryWindowStart
                  ? new Date(existingOrder?.deliveryWindowStart)
                  : undefined
              }
              isVehicleModalOpen={isVehicleModalOpen}
              setIsVehicleModalOpen={setIsVehicleModalOpen}
              isAnyStopOverThreshold={thresholdDistances.isAnyStopOverThreshold}
              hidePriorityDelivery={hidePriorityDelivery}
              deliveryPriceQuery={deliveryPriceQuery}
              isAdmin={isAdmin}
            />
          )}
          <AdditionalInfoFormSection
            formMethods={methods}
            isTravisPerkins={isTravisPerkins}
            referencePrefix={merchantAccount?.referencePrefix}
          />
          <Stack spacing={6}>
            {thresholdDistances.isEveryStopOverThreshold ? (
              allowLongDistance ? (
                <Alert status="warning" borderRadius={'lg'}>
                  <AlertIcon />
                  All delivery stops are over the threshold distance from the
                  centre of London. Please confirm with Tradeaze before booking.
                </Alert>
              ) : (
                <Alert status="error" borderRadius={'lg'}>
                  <AlertIcon />
                  At least one delivery stop must be inside the London area.
                  Please contact Tradeaze to book this.
                </Alert>
              )
            ) : null}
            <Center>
              <CompleteOrderFormButton
                isTravisPerkins={isTravisPerkins}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                isLoading={isLoading}
                isUpdatingOrder={isExistingOrder}
              />
            </Center>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
