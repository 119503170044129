import { AppName } from '@tradeaze-packages/schemas';
import {
  ADMIN_APP_VERSION,
  MERCHANT_APP_VERSION,
  TRACKING_APP_VERSION,
} from '../versions';

export const getCurrentAppVersion = (
  appName: Exclude<AppName, 'rider-app'>
) => {
  switch (appName) {
    case 'admin-app':
      return ADMIN_APP_VERSION;
    case 'tracking-app':
      return TRACKING_APP_VERSION;
    case 'merchant-app':
      return MERCHANT_APP_VERSION;
  }
};
