import { Box, Text, Divider, Flex } from '@chakra-ui/react';

type Props = React.ComponentProps<typeof Flex>;

export const OrDivider: React.FC<Props> = (props) => {
  return (
    <Flex align="center" opacity={0.4} {...props}>
      <Box flex="1">
        <Divider borderColor="blackAlpha.500" />
      </Box>
      <Text mx="3">or</Text>
      <Box flex="1">
        <Divider borderColor="blackAlpha.500" />
      </Box>
    </Flex>
  );
};
