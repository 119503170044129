import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { findAddresses } from '@tradeaze/shared/services';

type Response = Awaited<ReturnType<typeof findAddresses>>;

export const useFindAddresses = (
  postcode: string,
  options?: UseQueryOptions<Response>
) =>
  useQuery<Response>({
    queryKey: ['findAddresses', postcode],
    queryFn: async () => await findAddresses(postcode, {
      apiKey: import.meta.env['VITE_GET_ADDRESS_API_KEY']
    }),
    enabled: false, // use refetch to trigger,
    ...options,
  });
