import { z } from 'zod';
import {
  AddressSchema,
  MerchantDefinedCompanyAddressSchema,
} from '../../../models';

export const CreateMerchantCompanyAddressBodySchema =
  MerchantDefinedCompanyAddressSchema.omit({
    companyId: true,
  });

export const CreateMerchantCompanyAddressResponseSchema = AddressSchema;

export type CreateMerchantCompanyAddressBody = z.infer<
  typeof CreateMerchantCompanyAddressBodySchema
>;

export type CreateMerchantCompanyAddressResponse = z.infer<
  typeof CreateMerchantCompanyAddressResponseSchema
>;
