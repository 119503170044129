import { z } from 'zod';
import { OrdersQueryParamsSchema } from '../../../models';

export const DownloadOrdersCsvQuerySchema = OrdersQueryParamsSchema.omit({
  startIndex: true,
  limit: true,
});

export const DownloadOrdersCsvResponseSchema = z.object({
  url: z.string(),
});

export type DownloadOrdersCsvQuery = z.infer<
  typeof DownloadOrdersCsvQuerySchema
>;

export type DownloadOrdersCsvResponse = z.infer<
  typeof DownloadOrdersCsvResponseSchema
>;
