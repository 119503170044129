import { z } from 'zod';
import { FleetVehicleSchema } from '../../../models/fleet-vehicle-schema';

export const RecentFleetVehicleResponseSchema = z.object({
  message: z.string(),
  fleetVehicle: FleetVehicleSchema.nullable(),
});

export type RecentFleetVehicleResponse = z.infer<
  typeof RecentFleetVehicleResponseSchema
>;
