import { Center, Container } from '@chakra-ui/react';
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm';
import { createAuthErrToast, useAuthStore } from '@tradeaze/frontend/ui-web';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { forgotPassword, loading } = useAuthStore();

  const handleForgotPassword = ({ username }: { username: string }) => {
    if (!username) {
      return;
    }

    const onSuccess = () => {
      navigate('/confirm-forgot-password', {
        state: {
          username,
          alert: {
            status: 'success',
            message: 'Password reset code sent to your email',
          },
        },
      });
    };

    const onError = (error: unknown) => {
      createAuthErrToast(error);
    };

    forgotPassword(username, onSuccess, onError);
  };

  return (
    <Center h="60vh">
      <Container maxW="72">
        <ForgotPasswordForm
          handleForgotPassword={handleForgotPassword}
          authLoading={loading}
        />
      </Container>
    </Center>
  );
};

export default ForgotPasswordPage;
