import React, { useCallback, useMemo, useState } from 'react';

import { Center, Text } from '@chakra-ui/react';
import { Position } from '@tradeaze-packages/schemas';
import { MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';
import { buildGoogleDirectionsUrl } from '../utils/buildGoogleDirectionsUrl';
import { MapMarkerProps } from '../Map';
import { brandPrimaryColor } from '../../theme';

export const useMapPointSelect = ({
  initialEnabled = false,
  multiPoint = false,
}: {
  initialEnabled?: boolean;
  multiPoint?: boolean;
}) => {
  const [enabled, setEnabled] = useState(initialEnabled);
  const [coordinates, setCoordinates] = useState<Position[]>();

  const markers = useMemo<MapMarkerProps[]>(() => {
    if (!coordinates) {
      return [];
    }
    return coordinates.map((position, index) => ({
      id: `point-${index}`,
      type: 'ADDRESS',
      updateZoom: false,
      children: (
        <Center
          borderRadius={'full'}
          w={6}
          h={6}
          backgroundColor={brandPrimaryColor}
        >
          <Text fontWeight={'bold'}>{index + 1}</Text>
        </Center>
      ),
      position,
      anchor: 'center',
    }));
  }, [coordinates]);

  const handleToggleEnabled = useCallback(() => {
    setEnabled((prev) => !prev);
  }, []);

  const handleClick = useCallback(
    (event: MapLayerMouseEvent | MapLayerTouchEvent) => {
      if (!enabled) {
        return;
      }
      const { lat, lng } = event.lngLat;
      if (!multiPoint) {
        setCoordinates([{ latitude: lat, longitude: lng }]);
        return;
      }
      setCoordinates((prev) => [
        ...(prev || []),
        { latitude: lat, longitude: lng },
      ]);
    },
    [enabled, multiPoint]
  );

  const handleCancel = useCallback(() => {
    setCoordinates(undefined);
    setEnabled(false);
  }, []);

  const handleGoToGoogleMaps = useCallback(() => {
    if (!coordinates) {
      return;
    }
    const url = buildGoogleDirectionsUrl(coordinates);
    window.open(url, '_blank');
  }, [coordinates]);

  return useMemo(
    () => ({
      enabled,
      coordinates,
      markers,
      handleClick,
      handleCancel,
      handleToggleEnabled,
      handleGoToGoogleMaps,
    }),
    [
      enabled,
      coordinates,
      markers,
      handleClick,
      handleCancel,
      handleToggleEnabled,
      handleGoToGoogleMaps,
    ]
  );
};
