import React from 'react';
import { Box } from '@chakra-ui/react';
import { DeliveryStatus } from '@tradeaze-packages/schemas';

export const LeftStrip: React.FC<{
  deliveryStatus: DeliveryStatus;
}> = ({ deliveryStatus }) => {
  const backgroundColor = {
    PENDING: 'gray.300',
    CONFIRMED: 'yellow.300',
    DELIVERED: 'green.300',
    CANCELLED: 'red.300',
    REJECTED: 'red.300',
  }[deliveryStatus];

  return <Box backgroundColor={backgroundColor} width={'12px'} />;
};
