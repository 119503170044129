import { Box, FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { CreateOrder } from '@tradeaze-packages/schemas';
import { FormAttribute } from '../../form';
import { Contact } from './ContactCard';
import { formatNumberIfValid } from './utils/formatNumberIfValid';

type ExpandedContactFormProps = {
  contact: Contact;
  contactsAccessor: `deliveryStops.${number}.contacts.${number}`;
  stopSequence: number;
  index: number;
  handleUpdateContact: (index: number, data: Partial<Contact>) => void;
};

export const ExpandedContactForm = ({
  contact: data,
  contactsAccessor,
  stopSequence,
  index,
  handleUpdateContact,
}: ExpandedContactFormProps) => {
  const {
    formState: { errors },
    register,
    clearErrors,
  } = useFormContext<CreateOrder>();

  const fieldError = errors.deliveryStops?.[stopSequence]?.contacts?.[index];

  const onChangePhoneNumber = (value: string) => {
    const formattedNumber = formatNumberIfValid(value);

    if (!formattedNumber) {
      return;
    }

    handleUpdateContact(index, {
      contactNumber: formattedNumber,
    });
    clearErrors(`${contactsAccessor}.contactNumber`);
  };

  return (
    <Box>
      <FormAttribute
        id={'contactName'}
        label={'Name'}
        isRequired={true}
        placeholder={'Contact Name'}
        description={
          'The name of the person who will be on site to receive the delivery'
        }
        mb={4}
        error={fieldError?.contactName}
        {...register(`${contactsAccessor}.contactName` as const)}
      />
      <FormAttribute
        id={'contactNumber'}
        inputType={'text'}
        label={'Number'}
        isRequired={true}
        placeholder={'Contact Number'}
        description={
          'The number of the person who will be on site to receive the delivery'
        }
        mb={4}
        error={fieldError?.contactNumber}
        {...register(`${contactsAccessor}.contactNumber` as const, {
          onChange: (e) => onChangePhoneNumber(e.target.value),
        })}
      />
      <FormControl display="flex" alignItems="center" mt={6}>
        <FormLabel htmlFor="tracking-link" mb="0">
          Send site contact a tracking SMS?
        </FormLabel>
        <Switch
          id="tracking-link"
          colorScheme="yellow"
          size="md"
          isChecked={data.trackingEnabled}
          {...register(`${contactsAccessor}.trackingEnabled` as const)}
        />
      </FormControl>
    </Box>
  );
};