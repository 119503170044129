import { useMemo } from 'react';

export function useCalculateTotalFees({
  deliveryPrice,
  serviceCharge,
}: {
  deliveryPrice?: number;
  serviceCharge?: number;
}) {
  return useMemo(() => {
    let sum = 0;
    if (deliveryPrice) {
      sum += deliveryPrice;
    }
    if (serviceCharge) {
      sum += serviceCharge;
    }
    return sum;
  }, [deliveryPrice, serviceCharge]);
}
