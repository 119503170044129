import { z } from 'zod';
import { FleetVehicleStatusSchema } from '../../../models';

export const DeleteFleetVehicleResponseSchema = z.object({
  fleetVehicleId: z.string(),
  deletedAt: z.string(),
  status: FleetVehicleStatusSchema,
  ownerId: z.string().nullable(),
});

export type DeleteFleetVehicleResponse = z.infer<typeof DeleteFleetVehicleResponseSchema>;