import {
  OpenAPIRegistry,
  extendZodWithOpenApi,
} from '@asteasolutions/zod-to-openapi';
import {
  
  CreateGigJobBodySchema,
  CreateGigJobResponseSchema,
  UpdateGigJobBodySchema,
  UpdateGigJobResponseSchema,
  CancelGigJobResponseSchema,
  AcceptGigJobResponseSchema,
  RejectGigJobResponseSchema,
  RejectGigJobBodySchema,
  GetGigJobsQuerySchema,
  GetGigJobsResponseSchema,
  GetGigJobsByRiderIdQuerySchema,
  GetGigJobsByRiderIdResponseSchema,
  AcceptGigJobResponseResponseSchema,
  RejectGigJobResponseBodySchema,
  RejectGigJobResponseResponseSchema,
  GetGigJobByIdResponseSchema,
} from '../../delivery-service';
import { ApiErrorSchema } from '../../shared';
import { z } from 'zod';
import { commonErrorResponses } from './common';

extendZodWithOpenApi(z);

const registry = new OpenAPIRegistry();

const basePath = '/gig-jobs';

/**
 * GET /getGigJobs/
 */
registry.registerPath({
  operationId: 'getGigJobs',
  path: `${basePath}/`,
  description: 'Get gig jobs.',
  method: 'get',
  request: {
    query: GetGigJobsQuerySchema,
  },
  responses: {
    200: {
      description: 'Gig jobs fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobsResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getGigJob/{gigJobId}
 */
registry.registerPath({
  operationId: 'getGigJobById',
  path: `${basePath}/{gigJobId}`,
  description: 'Get gig job by ID.',
  method: 'get',
  request: {
    params: z.object({
      gigJobId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Gig job fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobByIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /createGigJob
 */
registry.registerPath({
  operationId: 'createGigJob',
  path: `${basePath}/`,
  description: 'Create a gig job.',
  method: 'post',
  request: {
    body: {
      content: {
        'application/json': {
          schema: CreateGigJobBodySchema,
        },
      },
    },
  },
  responses: {
    201: {
      description: 'Gig job created successfully.',
      content: {
        'application/json': {
          schema: CreateGigJobResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * PUT /updateGigJob/{gigJobId}
 */
registry.registerPath({
  operationId: 'updateGigJob',
  path: `${basePath}/{gigJobId}`,
  description: 'Update a gig job.',
  method: 'put',
  request: {
    params: z.object({
      gigJobId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: UpdateGigJobBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig job updated successfully.',
      content: {
        'application/json': {
          schema: UpdateGigJobResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * GET /getGigJobsByRiderId/{riderId}
 */
registry.registerPath({
  operationId: 'getGigJobsByRiderId',
  path: `${basePath}/rider/{riderId}`,
  description: 'Get gig jobs by rider ID.',
  method: 'get',
  request: {
    params: z.object({
      riderId: z.string(),
    }),
    query: GetGigJobsByRiderIdQuerySchema,
  },
  responses: {
    200: {
      description: 'Gig jobs fetched successfully.',
      content: {
        'application/json': {
          schema: GetGigJobsByRiderIdResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /cancelGigJob/{gigJobId}
 */
registry.registerPath({
  operationId: 'cancelGigJob',
  path: `${basePath}/cancel/{gigJobId}`,
  description: 'Cancel a gig job.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Gig job cancelled successfully.',
      content: {
        'application/json': {
          schema: CancelGigJobResponseSchema,
        },
      },
    },
    409: {
      description: 'Gig job is already cancelled.',
      content: {
        'application/json': {
          schema: ApiErrorSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /acceptGigJob/{gigJobId}/{riderId}
 */
registry.registerPath({
  operationId: 'acceptGigJob',
  path: `${basePath}/accept/{gigJobId}/{riderId}`,
  description: 'Accept a gig job.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Gig job accepted successfully.',
      content: {
        'application/json': {
          schema: AcceptGigJobResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /rejectGigJob/{gigJobId}/{riderId}
 */
registry.registerPath({
  operationId: 'rejectGigJob',
  path: `${basePath}/reject/{gigJobId}/{riderId}`,
  description: 'Reject a gig job.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: RejectGigJobBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig job rejected successfully.',
      content: {
        'application/json': {
          schema: RejectGigJobResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /rejectGigJobResponse/{gigJobId}/{riderId}
 */
registry.registerPath({
  operationId: 'rejectGigJobResponse',
  path: `${basePath}/reject/response/{gigJobId}/{riderId}`,
  description: 'Reject a gig job response.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
      riderId: z.string(),
    }),
    body: {
      content: {
        'application/json': {
          schema: RejectGigJobResponseBodySchema,
        },
      },
    },
  },
  responses: {
    200: {
      description: 'Gig job response rejected successfully.',
      content: {
        'application/json': {
          schema: RejectGigJobResponseResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

/**
 * POST /acceptGigJobResponse/{gigJobId}/{riderId}
 */
registry.registerPath({
  operationId: 'acceptGigJobResponse',
  path: `${basePath}/accept/response/{gigJobId}/{riderId}`,
  description: 'Accept a gig job response.',
  method: 'post',
  request: {
    params: z.object({
      gigJobId: z.string(),
      riderId: z.string(),
    }),
  },
  responses: {
    200: {
      description: 'Gig job response accepted successfully.',
      content: {
        'application/json': {
          schema: AcceptGigJobResponseResponseSchema,
        },
      },
    },
    ...commonErrorResponses,
  },
});

export const gigJobsApiRegistry = registry;
