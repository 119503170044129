import React, { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Divider,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import {
  Company,
  CreateOrder,
  MerchantAccount,
  MerchantDefinedCompany,
} from '@tradeaze-packages/schemas';
import { GrUserWorker } from 'react-icons/gr';
import { OrderSection } from '../OrderSection';
import {
  useCreateMerchantCompany,
  useGetCompaniesByMerchant,
} from '@tradeaze/frontend/hooks';
import { FormInputNote } from '../../form/FormInputNote';
import { SelectDropdown } from '../../shared';
import { handleError } from '@tradeaze/frontend/utils';

export const CustomerDetailsFormSection: React.FC<{
  merchantAccount?: MerchantAccount;
  formMethods: UseFormReturn<CreateOrder>;
}> = ({ merchantAccount, formMethods }) => {
  const {
    register,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
    getFieldState,
  } = formMethods;

  const companyName = watch('companyName');

  const companyId = watch('companyId');

  /**
   * Merchant customers
   */
  const {
    data: paginatedMerchantCustomers,
    isLoading: isLoadingMerchantCustomers,
  } = useGetCompaniesByMerchant(merchantAccount?.merchantId);

  const {
    mutateAsync: createMerchantCompany,
    isLoading: isLoadingCreateMerchantCompany,
  } = useCreateMerchantCompany({
    invalidateQueryKeys: [['getCompaniesByMerchant']],
  });

  const merchantCustomers = useMemo(
    () =>
      paginatedMerchantCustomers?.pages[0].companies.sort((a, b) =>
        a.companyName.localeCompare(b.companyName)
      ),
    [paginatedMerchantCustomers]
  );

  const handleSaveMerchantCustomer = async () => {
    try {
      if (!companyName || !merchantAccount) {
        return;
      }
      const newCompany: MerchantDefinedCompany = {
        merchantId: merchantAccount.merchantId,
        companyName,
      };
      const company = await createMerchantCompany(newCompany);
      setValue('companyId', company.companyId);
    } catch (error) {
      handleError(error, {
        sendToSentry: true,
      });
    }
  };

  const handleSelectMerchantCustomer = (customer: Company) => {
    if (!customer) {
      setValue('companyName', '');
      setValue('companyId', undefined);
      return;
    }

    setValue('companyName', customer.companyName);
    setValue('companyId', customer.companyId);
    clearErrors('companyName');
  };

  const hasSelectedMerchantCustomer = useMemo(
    () => merchantCustomers?.some((el) => el.companyId === companyId),
    [merchantCustomers, companyId]
  );

  const canSaveMerchantCustomer = useMemo(
    () =>
      merchantAccount &&
      companyName &&
      !merchantCustomers?.some((el) => el.companyId === companyId),
    [companyName, merchantAccount, merchantCustomers, companyId]
  );

  const isSectionComplete =
    Boolean(companyName) && !getFieldState('companyName').invalid;

  return (
    <OrderSection
      name="Recipient Details"
      icon={<GrUserWorker />}
      isComplete={isSectionComplete}
    >
      {/* COMPANY NAME */}
      {merchantAccount ? (
        <>
          <SelectDropdown
            isLoading={isLoadingMerchantCustomers}
            onSelect={handleSelectMerchantCustomer}
            showClear={hasSelectedMerchantCustomer}
            results={merchantCustomers?.map((customer) => ({
              label: customer.companyName,
              value: customer,
            }))}
            placeholder={
              merchantCustomers?.length
                ? 'Select existing customer'
                : isLoadingMerchantCustomers
                ? 'Loading saved customers'
                : 'No saved customers'
            }
            isMultiSelect={false}
          />
          <Divider my={6} opacity={0.3} />
        </>
      ) : null}
      {/* COMPANY NAME */}
      <FormControl
        isRequired
        borderRadius={10}
        mb={4}
        isInvalid={Boolean(errors.companyName)}
        isDisabled={hasSelectedMerchantCustomer}
      >
        <FormLabel htmlFor="contactName">Company Name</FormLabel>
        <Input
          id="companyName"
          placeholder="Company Name"
          {...register('companyName', {
            required: 'This is required',
          })}
        />
        <FormInputNote>
          The name of the company that will be receiving the order
        </FormInputNote>
        <FormErrorMessage>
          <Text>{errors.companyName?.message}</Text>
        </FormErrorMessage>
      </FormControl>
      {canSaveMerchantCustomer && (
        <Flex justifyContent={'end'}>
          <Button
            size={'sm'}
            alignSelf="end"
            onClick={handleSaveMerchantCustomer}
            isLoading={isLoadingCreateMerchantCompany}
          >
            Save Customer
          </Button>
        </Flex>
      )}
    </OrderSection>
  );
};
