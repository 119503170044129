import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Order, CreateOrder } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';
import toast from 'react-hot-toast';

export const useMerchantUpdateOrder = (
  orderId: string | undefined,
  options?: {
    callback: (order: Order) => void;
  }
) => {
  const queryClient = useQueryClient();

  return useMutation<Order, unknown, Partial<CreateOrder>>(
    (order) => {
      const apiName = 'OrderApi';
      const path = '/merchantUpdateOrder/' + orderId;
      const options = {
        body: order,
      };
      return API.post(apiName, path, options);
    },
    {
      onSuccess: (data) => {
        // Invalidate and refetch
        toast.success('Updated successfully');
        queryClient.invalidateQueries(['getOrder', { id: orderId }]);
        options?.callback(data);
      },
      onError: (error) => {
        console.error(error);
        toast.error('Error updating');
      },
    }
  );
};
