import { Center, SimpleGrid, Spinner, VStack } from '@chakra-ui/react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { Waypoint } from 'react-waypoint';
import { GridDeliveryCard, ListDeliveryCard } from './delivery-cards';

export const DeliveryPreviews: React.FC<{
  view: 'grid' | 'list';
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  deliveries: HydratedDelivery[];
  isAdmin: boolean;
  columns?: number[];
  selectedDelivery?: HydratedDelivery;
  hoveredDeliveryId?: string;
  onWaypointEnter: () => void;
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onHoverDelivery?: (deliveryId: string) => void;
  onHoverLeaveDelivery?: () => void;
  onPressGoTo: (deliveryId: string) => void;
}> = ({
  view,
  isAdmin,
  isFetchingNextPage,
  hasNextPage,
  deliveries,
  columns,
  selectedDelivery,
  hoveredDeliveryId,
  onSelectDelivery,
  onHoverDelivery,
  onHoverLeaveDelivery,
  onPressGoTo,
  onWaypointEnter,
}) => {
  return (
    <>
      {view === 'grid' ? (
        <SimpleGrid spacing={4} columns={columns} paddingBottom={20}>
          {deliveries.map((delivery) => (
            <GridDeliveryCard
              key={delivery.deliveryId}
              isAdmin={isAdmin}
              isSelected={Boolean(
                selectedDelivery && selectedDelivery.deliveryId === delivery.deliveryId
              )}
              isHovered={hoveredDeliveryId === delivery.deliveryId}
              delivery={delivery}
              onPressGoTo={onPressGoTo}
              onSelectDelivery={onSelectDelivery}
              onHoverDelivery={onHoverDelivery}
              onHoverLeaveDelivery={onHoverLeaveDelivery}
            />
          ))}
        </SimpleGrid>
      ) : null}
      {view === 'list' ? (
        <VStack spacing={4} paddingBottom={20}>
          {deliveries.map((delivery) => (
            <ListDeliveryCard
              key={delivery.deliveryId}
              isAdmin={isAdmin}
              isSelected={Boolean(
                selectedDelivery && selectedDelivery.deliveryId === delivery.deliveryId
              )}
              isHovered={hoveredDeliveryId === delivery.deliveryId}
              delivery={delivery}
              onSelectDelivery={onSelectDelivery}
              onHoverDelivery={onHoverDelivery}
              onHoverLeaveDelivery={onHoverLeaveDelivery}
              onPressGoTo={onPressGoTo}
            />
          ))}
        </VStack>
      ) : null}
      {isFetchingNextPage && (
        <Center h="15vh">
          <Spinner />
        </Center>
      )}
      {hasNextPage && onWaypointEnter && <Waypoint onEnter={onWaypointEnter} />}
    </>
  );
};
