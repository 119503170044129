import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  MerchantAccount,
  UpdateMerchantAccount,
} from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';
import toast from 'react-hot-toast';

export const useUpdateMerchantAccount = (
  merchantId: string | null | undefined,
  options?: {
    callback: (data: MerchantAccount) => void;
  }
) => {
  const queryClient = useQueryClient();

  return useMutation<MerchantAccount, unknown, UpdateMerchantAccount>(
    (merchantAccountDetails) => {
      const apiName = 'MerchantApi';
      const path = '/updateMerchantById/' + merchantId;
      const options = {
        body: merchantAccountDetails,
      };
      return API.post(apiName, path, options);
    },
    {
      onSuccess: (data) => {
        // Invalidate and refetch
        toast.success('Updated successfully');
        queryClient.invalidateQueries(['getMerchantAccount']);
        options?.callback(data);
      },
      onError: (error) => {
        console.error(error);
        toast.error('Error updating');
      },
    }
  );
};
