import { z } from 'zod';
import { DeliveryVehicleIdSchema } from './delivery-vehicle-schema';
import { FleetVehicleSchema } from './fleet-vehicle-schema';

export const RiderLocationSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
  updatedAt: z.string(),
});

export const ClockInWorkTypeSchema = z.enum(['SHIFT', 'GIG']);

export const RiderStatusSchema = z.enum([
  'PENDING',
  'APPROVED',
  'REJECTED',
  'DELETED',
]);

export const RiderLocationMapSchema = z.record(z.string(), RiderLocationSchema);

export const SuggestedRiderSchema = z.object({
  riderId: z.string(),
  score: z.number(),
  orderId: z.string(),
});

export const RiderLocationInputSchema = RiderLocationSchema.omit({
  updatedAt: true,
});

export const RiderIdentitySchema = z.object({
  riderId: z.string(),
  firstName: z
    .string()
    .transform((value) => value.trim())
    .pipe(z.string().min(1, { message: 'First name is required' })),
  lastName: z.string().nullish(),
  avatarUrl: z.string().nullish(),
  contactNumber: z.string().nullish(),
  deliveryVehicle: DeliveryVehicleIdSchema.nullish(),
  lastUsedVehicle: FleetVehicleSchema.nullish(),
  riderLocation: RiderLocationSchema.nullish(),
  workType: ClockInWorkTypeSchema,
  approvedAt: z.string().nullish(),
  rejectedAt: z.string().nullish(),
  rejectedReason: z.string().nullish(),
});

export const RiderAccountSchema = RiderIdentitySchema.extend({
  deletedAt: z.string().nullish(),
  address: z.string().nullish(),
  city: z.string().nullish(),
  postCode: z.string().nullish(),
  contactEmail: z.string().nullish(),
  lastClockedInAt: z.string().nullish(),
  username: z.string().nullish(),
  assignable: z.boolean().nullish(),
  onDuty: z.boolean().nullish(),
  expoPushToken: z.string().nullish(),
  appVersion: z.string().nullish(),
  status: RiderStatusSchema,
  createdAt: z.string().nullish(),
});

export const RiderAccountUpdateSchema = RiderAccountSchema.omit({
  deletedAt: true,
  lastClockedInAt: true,
  onDuty: true,
  riderId: true,
  riderLocation: true,
  username: true,
  status: true,
  workType: true,
}).extend({
  lastName: z
    .string()
    .transform((value) => value.trim())
    .pipe(z.string().min(1, { message: 'Last name is required' })),
  contactNumber: z
    .string()
    .transform((value) => value.trim())
    .pipe(z.string().min(1, { message: 'Contact number is required' })),
  address: z
    .string()
    .transform((value) => value.trim())
    .pipe(z.string().min(1, { message: 'Address is required' })),
  city: z
    .string()
    .transform((value) => value.trim())
    .pipe(z.string().min(1, { message: 'City is required' })),
  postCode: z
    .string()
    .transform((value) => value.trim())
    .pipe(z.string().min(1, { message: 'Postcode is required' })),
  resetVerification: z.boolean().optional(),
});

export const RiderAccountCreateSchema = RiderAccountSchema.omit({
  assignable: true,
  deletedAt: true,
  lastClockedInAt: true,
  onDuty: true,
  riderLocation: true,
}).extend({
  workType: ClockInWorkTypeSchema.optional(),
  status: RiderStatusSchema.optional(),
});

export type SuggestedRider = z.infer<typeof SuggestedRiderSchema>;

export type RiderLocation = z.infer<typeof RiderLocationSchema>;

export type RiderLocationMap = z.infer<typeof RiderLocationMapSchema>;

export type RiderIdentity = z.infer<typeof RiderIdentitySchema>;

export type RiderAccount = z.infer<typeof RiderAccountSchema>;

export type RiderAccountUpdate = z.infer<typeof RiderAccountUpdateSchema>;

export type RiderAccountCreate = z.infer<typeof RiderAccountCreateSchema>;

export type UserDefinedRiderAccountDetails = Omit<RiderAccount, 'riderId'>;

export type RiderLocationInput = z.infer<typeof RiderLocationInputSchema>;

export type ClockInWorkType = z.infer<typeof ClockInWorkTypeSchema>;

export type RiderStatus = z.infer<typeof RiderStatusSchema>;
