import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { CreateOrder, DeliveryOptionId } from '@tradeaze-packages/schemas';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CLOSING_HOUR, OPENING_HOUR } from '@tradeaze-packages/schemas';
import { add, isBefore, set } from 'date-fns';
import { setDeliveryTimes } from '../utils/setDeliveryTimes';
import { getDateFromScheduledTime } from '../utils/getDateFromScheduledTime';
import { getFromDeliveryDateTime } from '../utils/getFromDeliveryDateTime';

const REFRESH_INTERVAL_MS = 60 * 1000;

export const useDeliveryTimeManagement = ({
  initialDate,
  deliveryOptionId,
  totalTimeEstimate,
  setValue,
  getValues,
}: {
  initialDate?: Date;
  deliveryOptionId: DeliveryOptionId;
  totalTimeEstimate?: number;
  setValue: UseFormSetValue<CreateOrder>;
  getValues: UseFormGetValues<CreateOrder>;
}) => {
  const [fromDeliveryDateTime, setFromDeliveryDateTime] = useState<Date>(
    getFromDeliveryDateTime(initialDate)
  );

  const [scheduledTime, setScheduledTime] = useState<string>(
    `${CLOSING_HOUR}:00`
  );

  const isInvalidScheduledTime = useMemo(() => {
    const now = new Date();
    const scheduledDateTime = getDateFromScheduledTime(
      scheduledTime,
      fromDeliveryDateTime
    );
    const isBeforeOpening = scheduledDateTime.getHours() < OPENING_HOUR;
    const isAfterClosing =
      (scheduledDateTime.getHours() === CLOSING_HOUR &&
        scheduledDateTime.getMinutes() > 0) ||
      scheduledDateTime.getHours() > CLOSING_HOUR;
    const isAfterNowPlusTotalTimeEstimate = isBefore(
      scheduledDateTime,
      add(now, { seconds: totalTimeEstimate })
    );
    return isBeforeOpening || isAfterClosing || isAfterNowPlusTotalTimeEstimate;
  }, [fromDeliveryDateTime, scheduledTime, totalTimeEstimate]);

  const isToday = useMemo(
    () => fromDeliveryDateTime.getDate() === new Date().getDate(),
    [fromDeliveryDateTime]
  );

  const isPastDate = useMemo(
    () => isBefore(fromDeliveryDateTime, new Date().setHours(0, 0, 0, 0)),
    [fromDeliveryDateTime]
  );

  const isSaturday = fromDeliveryDateTime.getDay() === 6;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isToday) {
        return;
      }
      setFromDeliveryDateTime(getFromDeliveryDateTime(initialDate));
    }, REFRESH_INTERVAL_MS);
    return () => clearInterval(interval);
  }, [initialDate, isToday]);

  const handleSelectDeliveryDate = useCallback((date: Date | undefined) => {
    if (!date) {
      return;
    }
    const isToday = date.getDate() === new Date().getDate();
    if (isToday) {
      // use new Date() so we also get the current time
      // and not just the date pickers default time of midnight
      setFromDeliveryDateTime(new Date());
      return;
    }
    const openingDateTime = set(date, {
      hours: OPENING_HOUR,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    setFromDeliveryDateTime(openingDateTime);
  }, []);

  const handleChangeScheduledTime = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const time = e.target.value;
      setScheduledTime(time);
    },
    []
  );

  useEffect(() => {
    setDeliveryTimes({
      deliveryOption: deliveryOptionId,
      fromDateTime: fromDeliveryDateTime,
      scheduledDateTime: getDateFromScheduledTime(
        scheduledTime,
        fromDeliveryDateTime
      ),
      totalTimeEstimate,
      setValue,
      getValues,
    });
  }, [
    fromDeliveryDateTime,
    scheduledTime,
    deliveryOptionId,
    totalTimeEstimate,
    getValues,
    setValue,
  ]);

  return useMemo(
    () => ({
      fromDeliveryDateTime,
      scheduledTime,
      isPastDate,
      isSaturday,
      isInvalidScheduledTime,
      handleSelectDeliveryDate,
      handleChangeScheduledTime,
    }),
    [
      fromDeliveryDateTime,
      scheduledTime,
      isPastDate,
      isSaturday,
      isInvalidScheduledTime,
      handleSelectDeliveryDate,
      handleChangeScheduledTime,
    ]
  );
};
