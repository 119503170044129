import { z } from 'zod';

export const RejectGigJobBodySchema = z.object({
  rejectedReason: z.string().optional(),
});


export type RejectGigJobBody = z.infer<typeof RejectGigJobBodySchema>;

export const RejectGigJobResponseSchema = z.object({
  gigJobId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  status: z.string(),
  riderId: z.string().nullable(),
  acceptedAt: z.string().nullable(),
  rejectedAt: z.string().nullable(),
  rejectedReason: z.string().nullable(),
  riderGigJobId: z.string(),
  adminAcceptedAt: z.string().nullable(),
  adminRejectedAt: z.string().nullable(),
});

export type RejectGigJobResponse = z.infer<typeof RejectGigJobResponseSchema>;