import { useQuery } from '@tanstack/react-query';
import { Company } from '@tradeaze-packages/schemas';
import { API } from 'aws-amplify';

export const useGetCompanyById = (companyId: string | undefined) => {
  return useQuery<Company>(['getCompanyById', { companyId }], async () => {
    if (!companyId) {
      return;
    }
    const apiName = 'MerchantApi';
    const path = '/getCompanyById/' + companyId;
    const options = {};
    return await API.get(apiName, path, options);
  });
};
