import { UseFormReturn } from 'react-hook-form';
import { FormAttribute } from '../../../form';
import { CreateOrder } from '@tradeaze-packages/schemas';

type ItemDescriptionSectionProps = {
  formMethods: UseFormReturn<CreateOrder>;
};

export const ItemDescriptionSection = ({
  formMethods,
}: ItemDescriptionSectionProps) => {
  const {
    register,
    formState: { errors },
  } = formMethods;
  return (
    <FormAttribute
      id={'deliveryItems.0.description'}
      label={'Item Description'}
      isRequired={false}
      error={
        errors.deliveryItems ? errors.deliveryItems[0]?.description : undefined
      }
      inputType={'textarea'}
      description={"A description of the items you're sending"}
      placeholder={'e.g. 10 bags of plaster'}
      {...register('deliveryItems.0.description')}
    />
  );
};
