import { Text, Stack } from '@chakra-ui/react';

export const VehicleDimensions: React.FC<
  React.ComponentProps<typeof Stack> & {
    weight: number;
    length: number;
    width: number;
    height: number;
  }
> = ({ weight, length, width, height, ...props }) => {
  return (
    <Stack {...props}>
      <Text fontWeight="bold">
        Weight:{' '}
        <Text as="span" fontWeight="normal">
          {weight} kg
        </Text>
      </Text>
      <Text fontWeight="bold">
        Length:{' '}
        <Text as="span" fontWeight="normal">
          {length} m
        </Text>
      </Text>
      <Text fontWeight="bold">
        Width:{' '}
        <Text as="span" fontWeight="normal">
          {width} m
        </Text>
      </Text>
      <Text fontWeight="bold">
        Height:{' '}
        <Text as="span" fontWeight="normal">
          {height} m
        </Text>
      </Text>
    </Stack>
  );
};
