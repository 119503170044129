import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { FaWeightHanging } from 'react-icons/fa';
import { IoMdResize } from 'react-icons/io';
import { DeliveryItem } from '@tradeaze-packages/schemas';
import {
  calculateTotalWeightKg,
  getLongestDimension,
} from '@tradeaze/shared/utils';

type CardItemsInfoProps = {
  deliveryItems: DeliveryItem[];
} & React.ComponentProps<typeof Stack>;

export const CardItemsInfo = ({
  deliveryItems,
  ...props
}: CardItemsInfoProps) => {
  const weight = calculateTotalWeightKg(deliveryItems);
  const longestDimension = getLongestDimension(deliveryItems);

  return (
    <HStack
      gap={0.5}
      py={1}
      color={'blackAlpha.800'}
      fontSize={'0.9em'}
      {...props}
    >
      <HStack>
        <Icon as={FaWeightHanging} color={'blackAlpha.600'} />
        <Text noOfLines={1}>{`${weight}kg`}</Text>
      </HStack>

      <HStack>
        <Icon as={IoMdResize} color={'blackAlpha.600'} />
        <Text noOfLines={1}>Max {longestDimension.toPrecision(2)}m</Text>
      </HStack>
    </HStack>
  );
};
