import { EtaStatus, DeliveryStatus } from '@tradeaze-packages/schemas';

export type DescriptionColorScheme =
  | 'green'
  | 'blue'
  | 'yellow'
  | 'gray'
  | 'red';

export const getDescriptionColorScheme = ({
  deliveryStatus,
  isToday,
  etaStatus,
}: {
  deliveryStatus: DeliveryStatus;
  isToday: boolean;
  etaStatus?: EtaStatus | null;
}): DescriptionColorScheme => {
  switch (etaStatus) {
    case 'PICKUP_COMPLETE':
    case 'DROPOFF_ARRIVED':
    case 'DROPOFF_ENROUTE':
    case 'DROPOFF_COMPLETE':
    case 'DROPOFF_FAILED':
      return 'green';
    case 'PICKUP_ARRIVED':
    case 'PICKUP_ENROUTE':
    case 'PICKUP_FAILED':
      return 'blue';
    case 'CANCELLED':
      return 'red';
    case 'ASSIGNED': {
      if (isToday) {
        return 'blue';
      }
      return 'yellow';
    }
    case 'NOT_ASSIGNED': {
      if (deliveryStatus === 'CONFIRMED') {
        return 'yellow';
      }
      return 'gray';
    }
    default:
      return 'yellow';
  }
};
