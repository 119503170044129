export const getIsAnyStopOverThreshold = (
  distancesFromCentre: {
    distance: number;
    duration: number;
  }[],
  maxDistance: number
) =>
  distancesFromCentre.some(
    (distanceFromCentre) =>
      distanceFromCentre.distance > maxDistance
  );