import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { TextSearch } from '../shared/TextSearch';

type Props = Partial<React.ComponentProps<typeof TextSearch>> & {
  inputPlaceholder: string;
  handleSearchChange: (newValue: string) => void;
  defaultValue?: string;
};

export const DebounceSearchInput: React.FC<Props> = ({
  inputPlaceholder,
  handleSearchChange,
  defaultValue,
  ...props
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [searchTerm, setSearchTerm] = useState(defaultValue ?? '');

  const onTextChange = (newValue: string) => {
    setSearchTerm(newValue ?? '');
    setHasChanged(true);
  };

  useDebounce(
    () => {
      if (!hasChanged) {
        return;
      }
      handleSearchChange(searchTerm);
    },
    500,
    [searchTerm]
  );

  return (
    <TextSearch
      {...props}
      value={searchTerm}
      inputPlaceholder={inputPlaceholder}
      onTextChange={onTextChange}
      isLoading={false}
    />
  );
};
