import { z } from 'zod';
import parsePhoneNumberFromString from 'libphonenumber-js';

export const SignInSchema = z.object({
  phone: z
    .string()
    .refine(
      (value) => parsePhoneNumberFromString(value, 'GB')?.isValid() || false,
      {
        message: 'Invalid UK phone number',
      }
    ),
  password: z.string().min(6, {
    message: 'Password must be at least 6 characters',
  }),
});
