import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useInvalidateQueries = () => {
  const queryClient = useQueryClient();

  const invalidateQueries = useCallback(
    (keys: QueryKey[]) => keys.forEach((k) => queryClient.invalidateQueries(k)),
    [queryClient]
  );

  return invalidateQueries;
};
