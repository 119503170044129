import { useMutation } from '@tanstack/react-query';
import { updateAddress } from '@tradeaze-packages/api-client';
import { CustomUseMutationOptions } from '../shared';
import { useInvalidateQueries } from '../utility';
import { getAddressesQueryKey } from './useGetAddresses';

export const useUpdateAddress = (
  options?: CustomUseMutationOptions<typeof updateAddress>
) => {
  const invalidateQueries = useInvalidateQueries();

  return useMutation({
    mutationFn: updateAddress,
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      invalidateQueries([getAddressesQueryKey()]);
    },
  });
};
