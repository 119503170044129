import { z } from 'zod';
import {
  GigJobsQueryParamsSchema,
  HydratedGigJobSchema,
} from '../../../models';

export const GetGigJobsByRiderIdQuerySchema = GigJobsQueryParamsSchema;

export const WeeklyJobsSchema = z.array(
  z.object({
    createdAt: z.string(),
    totalPayment: z.number(),
    jobCount: z.number(),
  }),
);

export const GetGigJobsByRiderIdResponseSchema = z.object({
  gigJobs: z.array(HydratedGigJobSchema),
  nextPageIndex: z.number().nullable(),
  totalPaidAmount: z.number().nullable(),
  totalAmountToBePaid: z.number().nullable(),
  totalEarnings: z.number().nullable(),
  totalCompletedGigJobs: z.number().nullable(),
  totalWeeklyPayment: z.number().nullable(),
  weeklyJobs: WeeklyJobsSchema || [],
});

export type GetGigJobsByRiderIdQuery = z.infer<
  typeof GetGigJobsByRiderIdQuerySchema
>;

export type WeeklyJobsBody = z.infer<typeof WeeklyJobsSchema>;

export type GetGigJobsByRiderIdResponse = z.infer<
  typeof GetGigJobsByRiderIdResponseSchema
>;

export const WeeklyJobsResponseSchema = z.object({
  weeklyJobs: WeeklyJobsSchema,
  totalWeeklyPayment: z.number().nullable(),
});

export type WeeklyJobsResponse = z.infer<typeof WeeklyJobsResponseSchema>;
