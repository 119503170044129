import { HStack, Stack, Text } from '@chakra-ui/react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GiCheckMark } from 'react-icons/gi';

export const DropOffMarker: React.FC<{
  stopSequence?: number;
  opacity?: number;
}> = ({ stopSequence, opacity }) => {
  return (
    <Stack
      opacity={opacity}
      direction={'column'}
      alignItems="center"
      spacing={1}
    >
      <HStack
        bg="white"
        borderRadius={10}
        paddingX={2}
        fontSize={14}
        spacing={1}
      >
        <GiCheckMark size={16} color={'#38A169'} />
        <Text>
          Drop Off{stopSequence !== undefined ? ` - ${stopSequence + 1}` : ''}
        </Text>
      </HStack>
      <FaMapMarkerAlt size={34} color={'#38A169'} />
    </Stack>
  );
};
