import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  FormLabel,
  FormControl,
  Divider,
  Button,
  Switch,
  Box,
  Text,
} from '@chakra-ui/react';
import { CreateOrder } from '@tradeaze-packages/schemas';
import { useGetSiteContacts } from '@tradeaze/frontend/hooks';
import { SelectDropdown } from '../../shared';
import { ContactCard } from './ContactCard';
import { useContactCardActions } from './hooks/useContactCardActions';
import { HiMiniUserCircle } from 'react-icons/hi2';

export const DeliveryStopSiteContacts: React.FC<{
  stopSequence: number;
}> = ({ stopSequence }) => {
  const contactsAccessor = `deliveryStops.${stopSequence}.contacts` as const;

  const {
    watch,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<CreateOrder>();

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: contactsAccessor,
  });

  const companyId = watch('companyId');

  const contacts = watch(contactsAccessor);

  const { data: paginatedSavedContacts, isFetching: isLoadingSavedContacts } =
    useGetSiteContacts(companyId);

  const savedContacts = useMemo(
    () =>
      paginatedSavedContacts?.pages
        .flatMap((page) => page.siteContacts)
        .sort((a, b) => a.contactName.localeCompare(b.contactName)),
    [paginatedSavedContacts]
  );

  const {
    handleDropdownSelect,
    handleRemoveContactCard,
    getIsContactSelected,
    handleAddContactCard,
    handleGroupTracking,
    handleUpdateContact,
    getIsDropdownDisabled,
  } = useContactCardActions({
    setValue,
    contacts,
    contactsAccessor,
    append,
    remove,
    insert,
  });

  const savedContactPlaceholder = useMemo(() => {
    if (savedContacts?.length) return 'Select existing site contact';
    if (isLoadingSavedContacts) return 'Loading saved site contacts';
    return 'No saved site contacts';
  }, [savedContacts?.length, isLoadingSavedContacts]);

  return (
    <Box>
      {companyId ? (
        <>
          <SelectDropdown
            placeholder={savedContactPlaceholder}
            results={savedContacts?.map((siteContact) => ({
              label: `${siteContact.contactName} - ${siteContact.contactNumber}`,
              value: siteContact,
              isSelected: getIsContactSelected(siteContact),
            }))}
            onSelect={handleDropdownSelect}
            isLoading={isLoadingSavedContacts}
            isDropDownDisabled={getIsDropdownDisabled()}
            isMultiSelect
            icon={<Box as={HiMiniUserCircle} marginRight={2} />}
          />
          <Divider my={6} opacity={0.3} />
        </>
      ) : null}
      {contacts.map((field, index) => {
        const contactErrors =
          errors?.deliveryStops?.[stopSequence]?.contacts?.[index];

        const hasEmptyField =
          field.contactName === '' || field.contactNumber === '';

        const isInvalid = !!contactErrors || hasEmptyField;

        const isNotLastContact = index !== contacts.length - 1;

        const isSavedContact = Boolean(
          savedContacts?.find(
            (savedContact) =>
              savedContact.contactName === field.contactName &&
              savedContact.contactNumber === field.contactNumber
          )
        );

        return (
          <ContactCard
            key={index}
            contact={field}
            stopSequence={stopSequence}
            index={index}
            contactAccessor={`${contactsAccessor}.${index}` as const}
            isInvalid={isInvalid}
            handleRemoveCard={handleRemoveContactCard}
            handleUpdateContact={handleUpdateContact}
            collapseCard={(isNotLastContact && !isInvalid) || isSavedContact}
          />
        );
      })}

      {contacts.length === 5 ? (
        <Text color={'blackAlpha.500'}>
          You have reached the maximum number of contacts allowed
        </Text>
      ) : (
        <Button
          variant="outline"
          mt={6}
          width="100%"
          onClick={handleAddContactCard}
          isDisabled={contacts.length === 5}
        >
          Add more contacts
        </Button>
      )}
      {contacts.length > 1 ? (
        <FormControl display="flex" alignItems="center" mt={6}>
          <FormLabel htmlFor="tracking-link" mb="0">
            Send tracking SMS to all contacts?
          </FormLabel>
          <Switch
            id="tracking-link"
            colorScheme="yellow"
            size="md"
            isChecked={contacts.every((contact) => contact.trackingEnabled)}
            onChange={handleGroupTracking}
          />
        </FormControl>
      ) : null}
    </Box>
  );
};
