import { ApiErrorSchema } from "../../../shared";

export const commonErrorResponses = {
    400: {
      description: 'Bad request',
      content: {
        'application/json': {
          schema: ApiErrorSchema,
        },
      },
    },
    401: {
      description: 'Unauthenticated',
      content: {
        'application/json': {
          schema: ApiErrorSchema,
        },
      },
    },
    403: {
      description: 'Unauthorized',
      content: {
        'application/json': {
          schema: ApiErrorSchema,
        },
      },
    },
    500: {
      description: 'Internal server error',
      content: {
        'application/json': {
          schema: ApiErrorSchema,
        },
      },
    },
  };