import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { MapMarkerProps } from '../Map';
import { adjustOverlappingPositions } from './adjustOverlappingPositions';
import { DeliveryMarker } from '../DeliveryMarker';

export const deliveriesToMarkers = ({
  deliveries,
  isAdmin = false,
  hoveredDeliveryId,
  onHover,
  onHoverLeave,
  onClickDelivery,
}: {
  deliveries: HydratedDelivery[];
  isAdmin?: boolean;
  hoveredDeliveryId?: string;
  onHover?: (deliveryId: string) => void;
  onHoverLeave?: () => void;
  onClickDelivery?: (delivery: HydratedDelivery) => void;
}): MapMarkerProps<'DELIVERY'>[] => {
  const allDeliveryStops = deliveries.flatMap((d) => [d.pickup, d.dropOff]);

  const shouldIncludeInAdjustment = (
    stop: HydratedDelivery['pickup' | 'dropOff']
  ) => {
    const deliveryForStop = deliveries.find(
      (o) => o.deliveryId === stop.deliveryId
    );

    const deliveryStatus = deliveryForStop?.status;

    if (deliveryStatus === 'CONFIRMED' || deliveryStatus === 'PENDING') {
      return true;
    }

    return false;
  };

  const adjustedStops = adjustOverlappingPositions(
    allDeliveryStops,
    shouldIncludeInAdjustment
  );

  const deliveriesWithAdjustedStops = deliveries.map((d) => ({
    ...d,
    pickup: adjustedStops.find(
      (s) => s.deliveryStopId === d.pickup.deliveryStopId
    ),
    dropOff: adjustedStops.find(
      (s) => s.deliveryStopId === d.dropOff.deliveryStopId
    ),
  }));

  return deliveriesWithAdjustedStops.reduce<MapMarkerProps<'DELIVERY'>[]>(
    (acc, delivery) => {
      if (!delivery.pickup || !delivery.dropOff) {
        return acc;
      }

      const firstStop = delivery.pickup;
      const lastStop = delivery.dropOff;

      const pickUpPosition = firstStop.position;
      const deliveryPosition = lastStop.position;

      const pickUpPostcode = firstStop.postCode;
      const deliveryPostcode = lastStop.postCode;

      const deliveryTime = new Date(lastStop.windowEnd);

      if (
        !pickUpPosition ||
        !deliveryPosition ||
        !pickUpPostcode ||
        !deliveryPostcode
      ) {
        return acc;
      }

      if (!(delivery.status === 'CONFIRMED' || delivery.status === 'PENDING')) {
        return acc;
      }
      if (isAdmin) {
        return [
          ...acc,
          ...([delivery.pickup, delivery.dropOff]
            ?.map((ds) => {
              if (!ds.position) {
                return undefined;
              }
              return {
                id: ds.deliveryStopId,
                type: 'DELIVERY' as const,
                position: ds.position,
                updateZoom: false,
                children: (
                  <DeliveryMarker
                    opacity={ds.completedAt ? 0.5 : 1}
                    deliveryId={delivery.deliveryId}
                    isAdmin={isAdmin}
                    deliveryWindowEnd={ds.windowEnd}
                    deliveryPostcode={deliveryPostcode}
                    pickUpPostcode={pickUpPostcode}
                    rider={
                      delivery.rider
                        ? {
                            firstName: delivery.rider.firstName,
                            lastName: delivery.rider.lastName,
                          }
                        : undefined
                    }
                    deliveryVehicle={delivery.deliveryVehicleId}
                    stopType={ds.type}
                    onClick={() =>
                      onClickDelivery?.(delivery as HydratedDelivery)
                    } // todo - stop type casting
                    isHovered={hoveredDeliveryId === delivery.deliveryId}
                    onHover={onHover}
                    onHoverLeave={onHoverLeave}
                  />
                ),
              };
            })
            .filter((m): m is NonNullable<typeof m> => Boolean(m)) ?? []),
        ];
      } else {
        return [
          ...acc,
          {
            id: delivery.deliveryId,
            type: 'DELIVERY',
            position: deliveryPosition,
            data: {
              deliveryId: delivery.deliveryId,
              deliveryTime: deliveryTime,
            },
            anchor: 'bottom',
            updateZoom: true,
            children: (
              <DeliveryMarker
                deliveryId={delivery.deliveryId}
                isAdmin={isAdmin}
                deliveryWindowEnd={delivery.dropOff.windowEnd}
                deliveryPostcode={deliveryPostcode}
                pickUpPostcode={pickUpPostcode}
                rider={
                  delivery.rider
                    ? {
                        firstName: delivery.rider.firstName,
                        lastName: delivery.rider.lastName,
                      }
                    : undefined
                }
                deliveryVehicle={delivery.deliveryVehicleId}
                stopType="DELIVERY"
                onHover={onHover}
                onHoverLeave={onHoverLeave}
                onClick={() => onClickDelivery?.(delivery as HydratedDelivery)} // todo - prevent type casting
                isHovered={hoveredDeliveryId === delivery.deliveryId}
              />
            ),
          },
        ];
      }
    },
    [] as MapMarkerProps<'DELIVERY'>[]
  );
};
