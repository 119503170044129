import { useQuery } from '@tanstack/react-query';
import { AppName } from '@tradeaze-packages/schemas';
import { fetchLatestAppVersion } from '@tradeaze-packages/api-client';

const getLatestAppVersionQueryKey = (appName: AppName) => [
  'getLatestAppVersion',
  appName,
];

export const useGetLatestAppVersion = (appName: AppName) =>
  useQuery({
    queryKey: getLatestAppVersionQueryKey(appName),
    queryFn: async () => fetchLatestAppVersion(appName),
  });
