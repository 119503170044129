import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { captureEvent } from '@tradeaze/frontend/utils';
import { CreateOrder } from '@tradeaze-packages/schemas';
import { orderKeyToLabel } from '@tradeaze/shared/utils';
import { PostHog } from 'posthog-js/react';
import { Controller, useFormContext } from 'react-hook-form';

type ItemTypeSectionProps = {
  posthog?: PostHog;
};

export const ItemTypeSection = ({ posthog }: ItemTypeSectionProps) => {
  const {
    setValue,
    formState: { errors },
    control,
  } = useFormContext<CreateOrder>();

  const onToggleHeavySideItems = (value: string) => {
    if (value === 'YES') {
      setValue('heavySideItems', true);
    } else if (value === 'NO') {
      setValue('heavySideItems', false);
    }
    if (posthog) {
      captureEvent(posthog)(`toggle_heavy_side_items_${value ? 'on' : 'off'}`);
    }
  };
  return (
    <FormControl isInvalid={Boolean(errors.heavySideItems)}>
      <FormLabel>{orderKeyToLabel['heavySideItems']}</FormLabel>
      <Controller
        name="heavySideItems"
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            value={value === true ? 'YES' : value === false ? 'NO' : undefined}
            onChange={(value) => {
              onToggleHeavySideItems(value);
              onChange(value === 'YES');
            }}
          >
            <Stack direction={'column'} spacing={6} p={3}>
              <Radio value={'YES'}>
                <Text>
                  <Text>Includes yard items (heavyside)</Text>
                  <Text color={'blackAlpha.500'} fontSize={14}>
                    (e.g. bricks, sand, timber, plasterboard etc..)
                  </Text>
                </Text>
              </Radio>
              <Radio value={'NO'}>
                <Text>Shop items only</Text>
              </Radio>
            </Stack>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
