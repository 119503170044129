import {
  Box,
  Center,
  Heading,
  IconButton,
  Show,
  Spinner,
} from '@chakra-ui/react';
import {
  useMerchantCreateOrder,
  useGetMerchantAccount,
} from '@tradeaze/frontend/hooks';
import { CreateOrder, DuplicateOrder } from '@tradeaze-packages/schemas';
import {
  Layout,
  Map,
  CreateOrderForm,
  MobileMapWithToggle,
  parseDuplicateOrderFromState,
  TwoColumnLayout,
  useFormMapMarkers,
} from '@tradeaze/frontend/ui-web';
import { captureEvent } from '@tradeaze/frontend/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../components/auth/AuthProvider';
import { CloseIcon } from '@chakra-ui/icons';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';

const CreateOrderPage = () => {
  const posthog = usePostHog();

  const location = useLocation();

  const [duplicatedOrder] = useState<DuplicateOrder | undefined>(
    parseDuplicateOrderFromState(location.state)
  );

  const { cognitoUserId } = useAuthContext();
  const { data: merchantAccount, isLoading: accountLoading } =
    useGetMerchantAccount(cognitoUserId);

  const createOrderMutation = useMerchantCreateOrder({
    onSuccess: (data) => {
      navigate(`/orders/${data.orderId}`);
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (order: CreateOrder) => {
    if (!cognitoUserId) {
      throw new Error('User ID is not defined');
    }
    if (!merchantAccount) {
      throw new Error('Merchant account is not defined');
    }
    const orderToCreate: CreateOrder = {
      ...order,
      merchantId: cognitoUserId,
    };
    createOrderMutation.mutate(orderToCreate);
    captureEvent(posthog)('complete_create_order', {
      order: orderToCreate,
    });
  };

  const {
    mapMarkers,
    addPickupMarker,
    addDeliveryMarker,
    removePickupMarker,
    removeDeliveryMarker,
  } = useFormMapMarkers();

  if (accountLoading) {
    return (
      <Center h="60vh">
        <Spinner />
      </Center>
    );
  }

  if (!merchantAccount) {
    return (
      <Center h="60vh">
        <Heading size="sm">
          No merchant account found. Set up account before creating an order
        </Heading>
      </Center>
    );
  }

  const OrderFormComponent = (
    <Box position={'relative'}>
      <IconButton
        aria-label="Full Screen"
        position={'fixed'}
        top={'1rem'}
        right={'1rem'}
        icon={<CloseIcon />}
      />
      <Heading size="md" mb={14}>
        Creating Order
      </Heading>
      <CreateOrderForm
        posthog={posthog}
        removeDeliveryMarker={removeDeliveryMarker}
        removePickupMarker={removePickupMarker}
        isLoading={createOrderMutation.isLoading}
        onSubmit={onSubmit}
        merchantAccount={merchantAccount}
        handleAddPickupMarker={addPickupMarker}
        handleAddDeliveryMarker={addDeliveryMarker}
        duplicatedOrder={duplicatedOrder}
      />
    </Box>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={OrderFormComponent}
          right={<Map markers={mapMarkers} shouldUpdateBounds={true} />}
        />
      </Show>
      <Show below="md">
        <Layout>
          {OrderFormComponent}
          <MobileMapWithToggle markers={mapMarkers} />
        </Layout>
      </Show>
    </>
  );
};

export default CreateOrderPage;
