import { z } from 'zod';
import { CreateMerchantProfileSchema } from '../../../models';

export const CreateMerchantBodySchema = CreateMerchantProfileSchema;

export const CreateMerchantResponseSchema = z.object({
  message: z.string(),
  data: CreateMerchantProfileSchema,
});

export type CreateMerchantBody = z.infer<typeof CreateMerchantBodySchema>;

export type CreateMerchantResponse = z.infer<
  typeof CreateMerchantResponseSchema
>;
