import { z } from 'zod';
import { OrderSchema } from '../../../models';

export const CancelOrderRequestSchema = z.object({
  cancellationReason: z.string(),
});

export const CancelOrderResponseSchema = z.object({
  message: z.string(),
  data: OrderSchema,
});

export type CancelOrderRequest = z.infer<typeof CancelOrderRequestSchema>;

export type CancelOrderResponse = z.infer<typeof CancelOrderResponseSchema>;
