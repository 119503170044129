import { Center, Container } from '@chakra-ui/react';
import SignUpForm from '../components/auth/SignUpForm';
import { createAuthErrToast, useAuthStore } from '@tradeaze/frontend/ui-web';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const SignUpPage = () => {
  const { signUp, loading } = useAuthStore();
  const navigate = useNavigate();

  const handleSignUp = async ({
    email,
    username,
    password,
  }: {
    email: string;
    username: string;
    password: string;
  }) => {
    if (!username || !email || !password) {
      toast.error('Missing username, email or password');
      return;
    }

    const onSuccess = () => {
      navigate('/confirm-signup', {
        state: {
          username,
          alert: {
            status: 'info',
            message: 'Check your email for a confirmation code',
          },
        },
      });
    };

    const onError = (error: unknown) => {
      createAuthErrToast(error);
    };

    signUp(username, email, password, onSuccess, onError);
  };

  return (
    <Center h="70vh">
      <Container maxW="72">
        <SignUpForm handleSignUp={handleSignUp} authLoading={loading} />
      </Container>
    </Center>
  );
};

export default SignUpPage;
