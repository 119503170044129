import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { searchProofS3 } from '@tradeaze/shared/utils';

const REMOTE_PROOF_IMAGE_CACHE_KEY = 'getRemoteProofImage';

export const getRemoteProofImagesQueryKey = (
  orderId?: string,
  stopId?: string
) => {
  const queryKey = [REMOTE_PROOF_IMAGE_CACHE_KEY];
  if (orderId) {
    queryKey.push(orderId);
  }
  if (stopId) {
    queryKey.push(stopId);
  }
  return queryKey;
};

export const useGetRemoteProofImages = (
  proofBucket: string,
  orderId: string,
  stopId?: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof searchProofS3>>>
  >
) => {
  return useQuery({
    queryKey: getRemoteProofImagesQueryKey(orderId, stopId),
    queryFn: async () =>
      searchProofS3(proofBucket)({ orderId, stopId, type: 'image' }),
    ...options,
  });
};
