import {
  useCalculateTotalFees,
  useDeliveryOptions,
  useGetDeliveryOptions,
} from '@tradeaze/frontend/hooks';
import { useDeliveryTimeManagement } from '../hooks/useDeliveryTimeManagement';
import { useFormContext } from 'react-hook-form';
import {
  CreateOrder,
  DeliveryOptionId,
  DeliveryVehicleId,
  MerchantAccount,
  RegionId,
} from '@tradeaze-packages/schemas';
import { useCallback, useMemo } from 'react';
import {
  getNextOptionOnVehicleChange,
  // handleItemsOnVehicleChange,
} from '../utils';
import { calc } from '@chakra-ui/react';
import { convertToMeters, getVehicleDimensions } from './deliveryOptionsUtils';

type UseDeliveryValuesParams = {
  initialDate?: Date;
  merchantAccount?: MerchantAccount;
  isAdmin: boolean;
  hidePriorityDelivery: boolean;
  isAnyStopOverThreshold: boolean;
  isDeliveryPriceLoading: boolean;
};

export const useDeliveryValues = ({
  initialDate,
  merchantAccount,
  isAdmin,
  hidePriorityDelivery,
  isAnyStopOverThreshold,
  isDeliveryPriceLoading,
}: UseDeliveryValuesParams) => {
  const { setValue, getValues, watch, getFieldState } =
    useFormContext<CreateOrder>();

  const deliveryVehicleId = watch('deliveryVehicle');
  const deliveryOptionId = watch('deliveryOption');
  const deliveryPrice = watch('deliveryPrice');
  const serviceCharge = watch('merchantServiceCharge');
  const heavySideItems = watch('heavySideItems');
  const deliveryWindowStart = watch('deliveryWindowStart');
  const deliveryWindowEnd = watch('deliveryWindowEnd');
  const totalTimeEstimate = watch('totalTimeEstimate') ?? undefined;

  const deliveryItem = watch('deliveryItems.0');

  const {
    fromDeliveryDateTime,
    scheduledTime,
    isPastDate,
    isSaturday,
    isInvalidScheduledTime,
    handleSelectDeliveryDate,
    handleChangeScheduledTime,
  } = useDeliveryTimeManagement({
    initialDate,
    deliveryOptionId,
    totalTimeEstimate,
    setValue,
    getValues,
  });

  const deliveryOptionsQuery = useGetDeliveryOptions({
    date: fromDeliveryDateTime,
    regionId: (merchantAccount?.regionId as RegionId) ?? 'LONDON',
  });

  const deliveryOptions = useMemo(
    () => deliveryOptionsQuery.data?.deliveryOptions ?? [],
    [deliveryOptionsQuery.data?.deliveryOptions],
  );

  const deliveryVehicles = useMemo(
    () => deliveryOptionsQuery.data?.deliveryVehicles ?? [],
    [deliveryOptionsQuery.data?.deliveryVehicles],
  );

  const selectedVehicle = useMemo(
    () =>
      deliveryVehicles.find((el) => el.deliveryVehicleId === deliveryVehicleId),
    [deliveryVehicles, deliveryVehicleId],
  );

  const scaleDimensionsMaintainingAspectRatio = (sideView: boolean) => {
    const vehicleHeight = selectedVehicle?.heightCapacity ?? 0;
    const vehicleWidth = selectedVehicle?.widthCapacity ?? 0;
    const vehicleLength = selectedVehicle?.lengthCapacity ?? 0;

    const selectedUnit = deliveryItem?.widthUnit || 'mm';

    const loadHeight = convertToMeters(deliveryItem?.height || 0, selectedUnit);
    const loadWidth = convertToMeters(deliveryItem?.width || 0, selectedUnit);
    const loadDepth = convertToMeters(deliveryItem?.length || 0, selectedUnit);

    const targetDimensions = getVehicleDimensions(
      selectedVehicle?.deliveryVehicleId,
      sideView ? 'SIDE' : 'REAR',
    );
    const svgHeight = targetDimensions?.length ?? 0;
    const svgWidth = targetDimensions?.width ?? 0;

    const heightScale = loadHeight / vehicleHeight;
    const widthScale =
      (sideView ? loadDepth : loadWidth) /
      (sideView ? vehicleLength : vehicleWidth);

    const yArea = Math.max(svgHeight * heightScale, 0.05 * svgHeight); // Ensure minimum 5% of SVG height
    const xArea = Math.max(svgWidth * widthScale, 0.05 * svgWidth); // Ensure minimum 5% of SVG width

    const xPosition = calc(
      Number(targetDimensions?.xPosition ?? 0) + (svgWidth - xArea),
    ).toString();

    const yPosition = calc(
      Number(targetDimensions?.yPosition ?? 0) + (svgHeight - yArea),
    ).toString();

    return { xArea, yArea, xPosition, yPosition };
  };

  const {
    availableDeliveryOptions,
    filteredDeliveryOptions,
    isDeliveryOptionDisabled,
  } = useDeliveryOptions({
    deliveryOptions,
    deliveryVehicle: deliveryVehicleId,
    hidePriorityDelivery,
    isAdmin,
    isAnyStopOverThreshold,
  });

  const isSectionComplete =
    Boolean(deliveryOptionId) &&
    Boolean(deliveryVehicleId) &&
    heavySideItems !== undefined &&
    !getFieldState('deliveryOption').invalid &&
    !getFieldState('deliveryVehicle').invalid &&
    !getFieldState('heavySideItems').invalid &&
    !isDeliveryPriceLoading;

  const isVehicleAvailable = useMemo(
    () =>
      availableDeliveryOptions.some((el) =>
        el.deliveryVehicleId.includes(deliveryVehicleId),
      ),
    [availableDeliveryOptions, deliveryVehicleId],
  );

  const totalTradeazeCharges = useCalculateTotalFees({
    deliveryPrice,
    serviceCharge,
  });

  const showDeliveryPrice =
    deliveryPrice !== undefined && deliveryPrice !== null && deliveryOptionId;

  const setOptionToNull = useCallback(() => {
    setValue('deliveryOption', null as unknown as DeliveryOptionId);
  }, [setValue]);

  const handleOptionAndItemsOnDeliveryVehicle = (
    vehicle: DeliveryVehicleId,
  ) => {
    const option = getNextOptionOnVehicleChange({
      currentOption: deliveryOptionId,
      newVehicle: vehicle,
      availableDeliveryOptions: availableDeliveryOptions,
      currentVehicle: deliveryVehicleId,
    });

    if (availableDeliveryOptions.find((el) => el.deliveryOptionId === option)) {
      setValue('deliveryOption', option);
    } else {
      setOptionToNull();
    }
  };
  return {
    filteredDeliveryOptions,
    isDeliveryOptionDisabled,
    selectedVehicle,
    deliveryVehicles,
    deliveryOptionsQuery,
    fromDeliveryDateTime,
    scheduledTime,
    isPastDate,
    isSaturday,
    isInvalidScheduledTime,
    handleSelectDeliveryDate,
    handleChangeScheduledTime,
    deliveryVehicleId,
    deliveryOptionId,
    deliveryPrice,
    serviceCharge,
    deliveryWindowStart,
    deliveryWindowEnd,
    isSectionComplete,
    isVehicleAvailable,
    totalTradeazeCharges,
    showDeliveryPrice,
    handleOptionAndItemsOnDeliveryVehicle,
    scaleDimensionsMaintainingAspectRatio,
  };
};
