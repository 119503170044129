import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Text,
} from '@chakra-ui/react';
import {
  Address,
  CreateOrder,
  DeliveryStopType,
  MerchantAccount,
  MerchantDefinedCompanyAddress,
  Position,
  validatePostcode,
} from '@tradeaze-packages/schemas';
import { formatAddressDisplayName } from '@tradeaze/shared/utils';
import { FormAttribute } from '../../form';
import {
  getAddressesQueryKey,
  useCreateMerchantCompanyAddress,
  useGetAddresses,
  useGetManualAddressPosition,
  useUpdateAddress,
} from '@tradeaze/frontend/hooks';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { GrLocation } from 'react-icons/gr';
import { FindAddressesInput } from '../../location/FindAddressesInput';
import { AddMarkerFn, RemoveMarkerFn } from '../../map';
import { Attribute, OrDivider, SelectDropdown, TextValue } from '../../shared';
import { OrderSection } from '../OrderSection';
import { DeliveryStopSiteContacts } from './DeliveryStopSiteContacts';
import { getStopFormKeys } from './utils/getStopFormKeys';
import { getStopTitle } from './utils/getStopTitle';
import { useFieldVisibility } from './hooks/useFieldVisibility';
import { hasAddressChanged } from './utils/hasAddressChanged';
import { AutocompleteAddress } from '@tradeaze/shared/services';

export const DeliveryStopFormSection: React.FC<{
  formMethods: UseFormReturn<CreateOrder>;
  merchantAccount?: MerchantAccount;
  showExpanded?: boolean;
  isExistingOrder?: boolean;
  stopType: DeliveryStopType;
  stopSequence: number;
  restrictedEditing?: boolean;
  handleAddPositionMarker: AddMarkerFn;
  removePositionMarker: RemoveMarkerFn;
  onRemove?: () => void;
}> = ({
  formMethods,
  merchantAccount,
  showExpanded,
  isExistingOrder = false,
  stopSequence,
  stopType,
  restrictedEditing,
  handleAddPositionMarker,
  removePositionMarker,
  onRemove,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
    watch,
    trigger,
    getFieldState,
  } = formMethods;

  const defaultPickupAddress = merchantAccount?.address;
  const defaultPickupPostcode = merchantAccount?.postCode;
  const defaultPickupPosition = merchantAccount?.position;

  const stopsCount = watch('deliveryStops').length;

  const sectionName = useMemo(
    () =>
      getStopTitle({
        stopType,
        stopSequence,
        stopsCount,
      }),
    [stopType, stopSequence, stopsCount]
  );

  const companyId = watch('companyId');
  const orderCompanyName = watch('companyName');

  const stopKeys = useMemo(
    () => getStopFormKeys(`deliveryStops.${stopSequence}`),
    [stopSequence]
  );

  const deliveryStops = watch('deliveryStops');

  const deliveryStop = deliveryStops?.[stopSequence];

  const {
    companyName,
    addressId,
    postCode,
    position,
    addressLine1,
    addressLine2,
    addressLine3,
    deliveryNotes,
    collectionReference,
    contacts,
  } = deliveryStop || {};

  const stopErrors = errors.deliveryStops?.[stopSequence];

  const [showFullAddress, setShowFullAddress] = useState(
    showExpanded || addressLine1 || postCode
  );

  const [isManualAddress, setIsManualAddress] = useState(false);

  const [shouldUseDefaultPickup, setShouldUseDefaultPickup] = useState<boolean>(
    stopSequence === 0 && !showExpanded && !!merchantAccount
  );

  const instructionsVisibility = useFieldVisibility(Boolean(deliveryNotes));

  const { data: paginatedSavedAddresses, isLoading: isLoadingSavedAddresses } =
    useGetAddresses({
      companyId: stopType === 'DELIVERY' ? companyId : undefined,
      merchantId: merchantAccount?.merchantId,
      addressType: stopType,
    });

  const { mutate: createAddress, isLoading: isLoadingCreateAddress } =
    useCreateMerchantCompanyAddress({
      invalidateQueryKeys: [getAddressesQueryKey()],
      onSuccess: ({ addressId }) => {
        setValue(stopKeys.addressId, addressId);
      },
    });

  const updateAddressMutation = useUpdateAddress();

  const isValidPostcode = useMemo(() => validatePostcode(postCode), [postCode]);

  const savedAddresses = useMemo(
    () =>
      paginatedSavedAddresses?.pages[0].addresses
        .map((address) => ({
          address,
          displayName: formatAddressDisplayName(address),
        }))
        .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [paginatedSavedAddresses]
  );

  const canSaveAddress = useMemo<boolean>(
    () =>
      Boolean(merchantAccount) &&
      Boolean(companyId) &&
      Boolean(deliveryStop?.addressLine1) &&
      Boolean(deliveryStop.postCode) &&
      !shouldUseDefaultPickup &&
      !deliveryStop.addressId &&
      !restrictedEditing,
    [
      deliveryStop.addressLine1,
      deliveryStop.postCode,
      deliveryStop.addressId,
      shouldUseDefaultPickup,
      merchantAccount,
      companyId,
      restrictedEditing,
    ]
  );

  const canUpdateAddress = useMemo<boolean>(() => {
    if (!deliveryStop.addressId || !savedAddresses || restrictedEditing) {
      return false;
    }
    const savedAddress = savedAddresses.find(
      (el) => el.address.addressId === addressId
    );
    if (!savedAddress) {
      return false;
    }
    return hasAddressChanged(savedAddress.address, deliveryStop);
  }, [
    JSON.stringify(deliveryStop),
    addressId,
    savedAddresses,
    restrictedEditing,
  ]);

  const handleSetPosition = useCallback(
    (position: Position | undefined) => {
      setValue(stopKeys.position, position);
    },
    [setValue, stopKeys]
  );

  useGetManualAddressPosition({
    isValidPostcode,
    isManualAddress,
    postCode,
    handleSetPosition,
  });

  const handleManualAddress = useCallback(() => {
    setIsManualAddress(true);
    setShowFullAddress(true);
  }, []);

  const handleToggleDefaultPickup = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked && defaultPickupAddress && defaultPickupPostcode) {
      setShouldUseDefaultPickup(true);
      setValue(stopKeys.companyName, merchantAccount?.merchantName);
      setValue(stopKeys.addressLine1, defaultPickupAddress);
      setValue(stopKeys.postCode, defaultPickupPostcode);
      setValue(stopKeys.position, defaultPickupPosition);
    } else {
      setShouldUseDefaultPickup(false);
      setValue(stopKeys.companyName, '');
      setValue(stopKeys.addressLine1, '');
      setValue(stopKeys.postCode, '');
      setValue(stopKeys.position, undefined);
    }
  };

  const handleSelectLocation = (addressResult: AutocompleteAddress) => {
    const position = {
      latitude: addressResult.latitude,
      longitude: addressResult.longitude,
    };
    setValue(stopKeys.position, position);
    setValue(stopKeys.addressId, null);

    const postcode = addressResult.postcode;
    const address = [addressResult.line_1, addressResult.line_2]
      .filter((el) => !!el)
      .join(', ');

    if (postcode) {
      setValue(stopKeys.postCode, postcode);
    } else {
      setValue(stopKeys.postCode, '');
    }
    if (address) {
      setValue(stopKeys.addressLine1, address);
    } else {
      setValue(stopKeys.addressLine1, '');
    }

    trigger(stopKeys.addressLine1);
    trigger(stopKeys.postCode);

    setIsManualAddress(false);
    setShowFullAddress(true);
  };

  const handleSaveAddress = async () => {
    if (!addressLine1 || !postCode || !merchantAccount) {
      return;
    }

    const newAddress: MerchantDefinedCompanyAddress = {
      companyId,
      companyName,
      instructions: deliveryNotes,
      merchantId: merchantAccount.merchantId,
      addressLine1,
      addressLine2,
      addressLine3,
      postCode,
      position,
      addressType: stopType,
    };

    createAddress(newAddress);
  };

  const handleUpdateAddress = () => {
    if (!addressId) {
      return;
    }
    updateAddressMutation.mutate({
      addressId,
      body: {
        contactIds: deliveryStop.contacts.map(
          (contact) => contact.siteContactId || ''
        ),
        instructions: deliveryStop.deliveryNotes,
        companyName: deliveryStop.companyName,
        addressLine1,
        position,
        postCode,
      },
    });
  };

  useEffect(() => {
    if (stopType === 'DELIVERY') {
      setValue(stopKeys.companyName, orderCompanyName);
    }
  }, [stopKeys, orderCompanyName, stopType, setValue]);

  const handleSelectExistingAddress = (address: Address) => {
    if (!address) {
      setValue(stopKeys.addressId, '');
      setValue(stopKeys.companyName, '');
      setValue(stopKeys.addressLine1, '');
      setValue(stopKeys.addressLine2, '');
      setValue(stopKeys.addressLine3, '');
      setValue(stopKeys.postCode, '');
      setValue(stopKeys.position, undefined);
      setValue(stopKeys.deliveryNotes, '');
      return;
    }

    setValue(stopKeys.addressId, address.addressId);
    setValue(stopKeys.companyName, address.companyName);
    setValue(stopKeys.addressLine1, address.addressLine1);
    setValue(stopKeys.addressLine2, address.addressLine2);
    setValue(stopKeys.addressLine3, address.addressLine3);
    setValue(stopKeys.postCode, address.postCode);
    setValue(stopKeys.position, address.position);
    if (!deliveryStop?.deliveryNotes) {
      setValue(stopKeys.deliveryNotes, address.instructions);
    }

    trigger(stopKeys.companyName);
    trigger(stopKeys.addressLine1);
    trigger(stopKeys.addressLine2);
    trigger(stopKeys.addressLine3);
    trigger(stopKeys.postCode);

    if (address.instructions) {
      instructionsVisibility.handleShow();
    }
    setShowFullAddress(true);
    setIsManualAddress(false);
  };

  useEffect(() => {
    if (position) {
      handleAddPositionMarker?.({
        id: stopKeys.deliveryStopId,
        position,
        stopSequence,
      });
    } else {
      removePositionMarker(stopKeys.deliveryStopId);
    }
  }, [
    stopKeys,
    stopSequence,
    handleAddPositionMarker,
    removePositionMarker,
    position,
  ]);

  const savedAddressPlaceholder = useMemo(() => {
    if (isLoadingSavedAddresses) {
      return 'Loading addresses';
    }
    if (savedAddresses?.length) {
      if (orderCompanyName && stopType === 'DELIVERY') {
        return `Select saved address for '${orderCompanyName}'`;
      } else {
        return 'Select saved address';
      }
    }
    if (orderCompanyName && stopType === 'DELIVERY') {
      return `No saved addresses for '${orderCompanyName}'`;
    }
    return 'No saved addresses';
  }, [savedAddresses, isLoadingSavedAddresses, orderCompanyName, stopType]);

  const isSectionComplete = (() => {
    if (stopType === 'PICK_UP') {
      return (
        Boolean(postCode) &&
        Boolean(addressLine1) &&
        Boolean(collectionReference) &&
        !getFieldState(stopKeys.addressLine1).invalid &&
        !getFieldState(stopKeys.postCode).invalid &&
        !getFieldState(stopKeys.collectionReference).invalid
      );
    } else if (stopType === 'DELIVERY') {
      return (
        Boolean(postCode) &&
        Boolean(addressLine1) &&
        Boolean(contacts?.length) &&
        contacts.every(
          (contact) => contact.contactName && contact.contactNumber
        ) &&
        Boolean(
          errors.deliveryStops?.[stopSequence]?.contacts?.length === undefined
        ) &&
        !getFieldState(stopKeys.addressLine1).invalid &&
        !getFieldState(stopKeys.postCode).invalid
      );
    }
    return false;
  })();

  return (
    <OrderSection
      name={sectionName}
      icon={<GrLocation />}
      onRemove={onRemove}
      isComplete={isSectionComplete}
    >
      {restrictedEditing ? (
        <Box>
          {companyName ? (
            <Attribute name={'Company Name'}>
              <TextValue text={companyName} />
            </Attribute>
          ) : null}
          <Attribute name={'Address'}>
            <TextValue text={addressLine1} />
          </Attribute>
          <Attribute name={'Postcode'}>
            <TextValue text={postCode} />
          </Attribute>
        </Box>
      ) : (
        <Box mb={4}>
          {deliveryStop?.type === 'PICK_UP' &&
          defaultPickupAddress &&
          defaultPickupPostcode ? (
            <Flex align={'center'}>
              <Text>
                Use default pick up address - {defaultPickupAddress},{' '}
                {defaultPickupPostcode}
              </Text>
              <Switch
                ml={4}
                defaultChecked={shouldUseDefaultPickup}
                onChange={handleToggleDefaultPickup}
                colorScheme="yellow"
              />
            </Flex>
          ) : null}
          {shouldUseDefaultPickup ? null : (
            <Box>
              {merchantAccount ? (
                <>
                  <Box my={6}>
                    <SelectDropdown
                      isLoading={isLoadingSavedAddresses}
                      onSelect={handleSelectExistingAddress}
                      results={savedAddresses?.map((a) => ({
                        label: a.displayName,
                        value: a.address,
                      }))}
                      placeholder={savedAddressPlaceholder}
                      showClear
                      isMultiSelect={false}
                    />
                  </Box>
                  <OrDivider my={6} />
                </>
              ) : null}
              <Box my={6}>
                <FindAddressesInput onAddressSelected={handleSelectLocation} />
                <Text
                  fontSize={12}
                  decoration={'underline'}
                  cursor={'pointer'}
                  my={2}
                  onClick={handleManualAddress}
                  color={'blackAlpha.500'}
                >
                  Can't find address? Click here to enter manually
                </Text>
              </Box>
              {showFullAddress ? (
                <>
                  <Divider marginY={6} opacity={0.3} />
                  {/* COMPANY NAME */}
                  <FormAttribute
                    id={stopKeys.companyName}
                    label="Company Name"
                    // isRequired
                    isInvalid={Boolean(stopErrors?.companyName)}
                    placeholder={'Company Name'}
                    error={stopErrors?.companyName}
                    mb={4}
                    description={
                      stopType === 'PICK_UP'
                        ? 'The name of the company the order will be collected from'
                        : 'The name of the company that will be receiving the order'
                    }
                    {...register(stopKeys.companyName)}
                  />
                  {/* ADDRESS LINE 1 */}
                  <FormAttribute
                    id={stopKeys.addressLine1}
                    label="Address"
                    disabledTooltip={
                      'You must find the address via the postcode dropdown'
                    }
                    register={register}
                    isRequired
                    isDisabled={!isManualAddress}
                    isInvalid={Boolean(stopErrors?.addressLine1)}
                    placeholder="Address"
                    mb={4}
                    error={stopErrors?.addressLine1}
                    {...register(stopKeys.addressLine1)}
                  />
                  {/* POSTCODE */}
                  <FormAttribute
                    id={stopKeys.postCode}
                    label="Postcode"
                    disabledTooltip={
                      'You must find the address via the postcode dropdown'
                    }
                    isRequired
                    isDisabled={!isManualAddress}
                    isInvalid={Boolean(stopErrors?.postCode)}
                    placeholder="Postcode"
                    mb={4}
                    error={stopErrors?.postCode}
                    {...register(stopKeys.postCode, {
                      onChange(event) {
                        setValue(
                          stopKeys.postCode,
                          event.target.value.toLocaleUpperCase()
                        );
                      },
                    })}
                  />
                </>
              ) : null}
            </Box>
          )}
        </Box>
      )}
      {instructionsVisibility.isVisible ? (
        <Box>
          <FormAttribute
            id={stopKeys.deliveryNotes}
            inputType={'textarea'}
            label="Driver Instructions"
            register={register}
            placeholder={'e.g. Ring doorbell'}
            description={
              'Any instructions for the driver for this specific stop'
            }
            error={stopErrors?.deliveryNotes}
            {...register(stopKeys.deliveryNotes)}
          />
        </Box>
      ) : (
        <Button
          variant={'link'}
          onClick={instructionsVisibility.handleShow}
          colorScheme="blue"
        >
          Add Driver Instructions
        </Button>
      )}
      <Flex justifyContent={'end'} mt={3}>
        {canSaveAddress && (
          <Flex justifyContent={'end'}>
            <Button
              isLoading={isLoadingCreateAddress}
              onClick={handleSaveAddress}
              size="sm"
            >
              Save Address
            </Button>
          </Flex>
        )}
        {canUpdateAddress ? (
          <Button
            onClick={handleUpdateAddress}
            isLoading={updateAddressMutation.isLoading}
            size={'sm'}
          >
            Update Address
          </Button>
        ) : null}
      </Flex>
      <Divider my={8} />
      {deliveryStop?.type === 'PICK_UP' && (
        <FormAttribute
          id={stopKeys.collectionReference}
          label="Collection Reference"
          isRequired
          isInvalid={Boolean(stopErrors?.collectionReference)}
          placeholder={'e.g. John Smith / Ticket #123456'}
          mt={'6'}
          error={stopErrors?.collectionReference}
          description={'Someone to find or a code to reference on arrival'}
          {...register(stopKeys.collectionReference)}
        />
      )}
      {deliveryStop?.type === 'DELIVERY' && (
        <DeliveryStopSiteContacts stopSequence={stopSequence} />
      )}
      <Divider my={6} />
      {/* POD Requirement Switches */}
      <HStack spacing={5}>
        <FormControl
          isInvalid={Boolean(stopErrors?.requiresImage)}
          display={'flex'}
          isDisabled={deliveryStop.type === 'DELIVERY'}
        >
          <FormLabel mb={0} htmlFor={stopKeys.requiresImage}>
            Require Image
          </FormLabel>
          <Checkbox
            id={stopKeys.requiresImage}
            colorScheme="yellow"
            {...register(stopKeys.requiresImage)}
          />
        </FormControl>
        <FormControl
          isInvalid={Boolean(stopErrors?.requiresSignature)}
          display={'flex'}
          isDisabled={deliveryStop.type === 'DELIVERY'}
        >
          <FormLabel mb={0} htmlFor={stopKeys.requiresSignature}>
            Require Signature
          </FormLabel>
          <Checkbox
            id={stopKeys.requiresSignature}
            colorScheme="yellow"
            {...register(stopKeys.requiresSignature)}
          />
        </FormControl>
      </HStack>
    </OrderSection>
  );
};
