import { Divider, Stack } from '@chakra-ui/react';
import { DeliveryWindowSection } from './DeliveryWindowSection';
import { DeliveryFeesSection } from './DeliveryFeeSection';
import { DeliveryServiceChargeSection } from './DeliveryServiceChargeSection';
import { DeliveryTotalSection } from './DeliveryTotalSection';
import { DeliveryOptionId } from '@tradeaze-packages/schemas';

type DeliveryWindowAndPriceSectionProps = {
  deliveryWindowStart: string;
  deliveryWindowEnd: string;
  deliveryOptionsQueryLoading: boolean;
  deliveryPriceQueryLoading: boolean;
  deliveryPriceQueryErrorMessage: string | null;
  showDeliveryPrice: false | DeliveryOptionId;
  isAdmin: boolean;
  deliveryPrice: number;
  serviceCharge: number;
  totalTradeazeCharges: number;
};

export const DeliveryWindowAndPriceSection = ({
  deliveryWindowStart,
  deliveryWindowEnd,
  deliveryOptionsQueryLoading,
  deliveryPriceQueryLoading,
  deliveryPriceQueryErrorMessage,
  showDeliveryPrice,
  isAdmin,
  deliveryPrice,
  serviceCharge,
  totalTradeazeCharges,
}: DeliveryWindowAndPriceSectionProps) => {
  return (
    <>
      <Divider opacity={0.3} />
      <DeliveryWindowSection
        deliveryWindowStart={deliveryWindowStart}
        deliveryWindowEnd={deliveryWindowEnd}
        deliveryOptionsQueryLoading={deliveryOptionsQueryLoading}
      />
      <Divider opacity={0.3} />
      <Stack spacing={6}>
        <DeliveryFeesSection
          deliveryPriceQueryLoading={deliveryPriceQueryLoading}
          showDeliveryPrice={showDeliveryPrice}
          isAdmin={isAdmin}
          deliveryPrice={deliveryPrice}
        />
        <DeliveryServiceChargeSection
          deliveryPriceQueryLoading={deliveryPriceQueryLoading}
          showDeliveryPrice={showDeliveryPrice}
          isAdmin={isAdmin}
          serviceCharge={serviceCharge}
        />
      </Stack>
      <Divider opacity={0.3} />
      <DeliveryTotalSection
        deliveryPriceQueryLoading={deliveryPriceQueryLoading}
        showDeliveryPrice={showDeliveryPrice}
        totalTradeazeCharges={totalTradeazeCharges}
        deliveryPriceQueryErrorMessage={deliveryPriceQueryErrorMessage}
      />
    </>
  );
};
