import { z } from 'zod';
import { HydratedDeliverySchema, DeliveriesQueryParamsSchema } from '../../../models';

export const GetDeliveriesByRiderIdQuerySchema = DeliveriesQueryParamsSchema;

export const GetDeliveriesByRiderIdResponseSchema = z.object({
  deliveries: z.array(HydratedDeliverySchema),
  nextPageIndex: z.number().nullable(),
});

export type GetDeliveriesByRiderIdQuery = z.infer<
  typeof GetDeliveriesByRiderIdQuerySchema
>;

export type GetDeliveriesByRiderIdResponse = z.infer<
  typeof GetDeliveriesByRiderIdResponseSchema
>;
