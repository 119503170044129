import { z } from 'zod';
import { validateUkPhoneNumber } from './../utils/validateUkPhoneNumber';

export const SignUpSchema = z
  .object({
    username: z
      .string()
      .min(3, { message: 'Username must be at least 3 characters' }),
    email: z.string().email(),
    phone: z.string().refine((value) => validateUkPhoneNumber(value), {
      message: 'Invalid UK phone number',
    }),
    password: z
      .string()
      .min(6, { message: 'Password must be at least 6 characters' }),
    confirmPassword: z
      .string()
      .min(6, { message: 'Password must be at least 6 characters' }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

export type SignUpSchemaType = z.infer<typeof SignUpSchema>;
