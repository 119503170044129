import {
  AppName,
  GetFeatureFlagsResponse,
  UpdateMerchantFeatureFlagBody,
  UpdateMerchantFeatureFlagResponse,
} from '@tradeaze-packages/schemas';
import * as client from './client';
import { handleResponse } from './handle-response';

export const fetchLatestAppVersion = async (appName: AppName) =>
  handleResponse(client.getLatestAppVersion({ path: { appName } }));

export const fetchFeatureFlags = async (
  type: string | undefined,
): Promise<GetFeatureFlagsResponse> =>
  handleResponse(client.getFeatureFlags({ query: { type } }));

export const updateMerchantFeatureFlag = async (
  args: UpdateMerchantFeatureFlagBody & {
    featureFlagId: string;
    merchantId: string;
  },
): Promise<UpdateMerchantFeatureFlagResponse> =>
  handleResponse(
    client.updateMerchantFeatureFlag({
      path: {
        featureFlagId: args.featureFlagId,
        merchantId: args.merchantId,
      },
      body: {
        enabled: args.enabled,
      },
    }),
  );
