import { z } from 'zod';
import { DeliveryVehicleSchema, RegionIdEnumSchema } from '../../../models';
import { ClientDeliveryOptionSchema } from '../../../models/delivery-option-schema';

export const GetDeliveryOptionsQuerySchema = z.object({
  regionId: RegionIdEnumSchema,
  date: z.string().datetime(),
});

export const GetDeliveryOptionsResponseSchema = z.object({
  deliveryOptions: z.array(ClientDeliveryOptionSchema),
  deliveryVehicles: z.array(DeliveryVehicleSchema),
});

export type GetDeliveryOptionsQuery = z.infer<
  typeof GetDeliveryOptionsQuerySchema
>;
export type GetDeliveryOptionsResponse = z.infer<
  typeof GetDeliveryOptionsResponseSchema
>;
