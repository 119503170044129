import { z } from 'zod';
import { ActiveFleetVehicleSchema } from '../../../models';

export const GetActiveFleetVehiclesResponseSchema = z.array(
  ActiveFleetVehicleSchema
);

export type GetActiveFleetVehiclesResponse = z.infer<
  typeof GetActiveFleetVehiclesResponseSchema
>;
