import {
  Button,
  Center,
  Heading,
  Show,
  Spinner,
  Container,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { CreateMerchantProfile } from '@tradeaze-packages/schemas';

import {
  useGetMerchantAccount,
  useUpdateMerchantAccount,
} from '@tradeaze/frontend/hooks';
import {
  AccountDetailsForm,
  TwoColumnLayout,
  useFormMapMarkers,
  Layout,
  Map,
  useAuthStore,
} from '@tradeaze/frontend/ui-web';
import { useAuthContext } from '../components/auth/AuthProvider';

const AccountPage = () => {
  const [isEditing, setIsEditing] = useState(false);

  const { signOut } = useAuthStore();
  const { cognitoUserId } = useAuthContext();

  const {
    data: merchantAccount,
    isLoading,
    error,
  } = useGetMerchantAccount(cognitoUserId);

  const { mapMarkers, addMerchantMarker, removeMerchantMarker } =
    useFormMapMarkers();

  const { mutate: updateMerchantAccount, isLoading: updateisLoading } =
    useUpdateMerchantAccount(cognitoUserId, {
      callback: () => setIsEditing(false),
    });

  const handleSaveChanges = async (data: CreateMerchantProfile) => {
    return updateMerchantAccount(data);
  };

  useEffect(() => {
    if (merchantAccount?.position) {
      addMerchantMarker({
        id: 'merchant',
        position: merchantAccount?.position,
      });
    } else {
      removeMerchantMarker('merchant');
    }
  }, [merchantAccount?.position, addMerchantMarker, removeMerchantMarker]);

  const handleCancelChanges = () => {
    setIsEditing(false);
  };

  const handleStartEditing = () => {
    setIsEditing(true);
  };

  if (isLoading) {
    return (
      <Center h="60vh">
        <Spinner />
      </Center>
    );
  }

  if (error instanceof Error && (error as any)?.response?.status !== 404) {
    return (
      <Center h="60vh" flexDirection={'column'}>
        <Heading>Error</Heading>
        <Text>{error.message}</Text>
      </Center>
    );
  }

  if (!merchantAccount && !isLoading) {
    return <Navigate to="/create-account" />;
  }

  const AccountComponent = (
    <Container maxW={'container.sm'}>
      <Heading size="md">Account Details</Heading>
      <AccountDetailsForm
        isCreatingNew={false}
        isLoading={updateisLoading}
        isEditing={isEditing}
        handleCancelChanges={handleCancelChanges}
        handleSaveChanges={handleSaveChanges}
        handleStartEditing={handleStartEditing}
        removeMerchantMarker={removeMerchantMarker}
        handleAddMerchantMarker={addMerchantMarker}
        initialAccountDetails={merchantAccount}
      />
      <Center mt={'16'}>
        <Button
          variant={'ghost'}
          size="lg"
          onClick={() => signOut()}
          data-cy="sign-out-button"
        >
          Sign out
        </Button>
      </Center>
    </Container>
  );

  return (
    <>
      <Show above="md">
        <TwoColumnLayout
          left={AccountComponent}
          right={<Map markers={mapMarkers} />}
        />
      </Show>
      <Show below="md">
        <Layout>{AccountComponent}</Layout>
      </Show>
    </>
  );
};

export default AccountPage;
