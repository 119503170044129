import { z } from 'zod';
import { RiderAccountSchema, RiderAccountUpdateSchema } from '../../../models';

export const UpdateRiderBodySchema =
  RiderAccountUpdateSchema.partial().strict();

export const UpdateRiderResponseSchema = RiderAccountSchema;

export type UpdateRiderBody = z.infer<typeof UpdateRiderBodySchema>;

export type UpdateRiderResponse = z.infer<typeof UpdateRiderResponseSchema>;
