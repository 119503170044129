import { z } from 'zod';

export const CompleteDeliveryStopResponseSchema = z.object({
  deliveryStopId: z.string(),
  completedAt: z.string().datetime(),
});

export type CompleteDeliveryStopResponse = z.infer<
  typeof CompleteDeliveryStopResponseSchema
>;
