import {
  useMutation,
  useQueryClient,
  QueryKey,
  UseMutationOptions,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';

interface UseDeleteMerchantCompanyAddressOptions
  extends UseMutationOptions<unknown, unknown, { addressId: string }> {
  invalidateQueryKeys?: QueryKey[];
}

export const useDeleteMerchantCompanyAddress = (
  options?: UseDeleteMerchantCompanyAddressOptions
) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, { addressId: string }>({
    ...options,
    mutationFn: ({ addressId }) => {
      console.log('addressId', addressId);
      const apiName = 'MerchantApi';
      const path = `/deleteMerchantCompanyAddress/${addressId}`;
      const options = {};
      return API.del(apiName, path, options);
    },
    onSuccess: (data, variables, context) => {
      options?.invalidateQueryKeys?.forEach((queryKey) => {
        queryClient.invalidateQueries(queryKey);
      });
      options?.onSuccess?.(data, variables, context);
    },
  });
};
