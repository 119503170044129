import { DimensionUnit } from '@tradeaze-packages/schemas';

export const convertToMetres = (value: number, unit: DimensionUnit) => {
  switch (unit) {
    case 'mm':
      return value / 1000;
    case 'cm':
      return value / 100;
    case 'm':
      return value;
    default:
      return value;
  }
};
